import { render, staticRenderFns } from "./fp-card.vue?vue&type=template&id=16cbc42f&scoped=true&"
import script from "./fp-card.vue?vue&type=script&lang=ts&"
export * from "./fp-card.vue?vue&type=script&lang=ts&"
import style0 from "./fp-card.vue?vue&type=style&index=0&id=16cbc42f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16cbc42f",
  null
  
)

export default component.exports