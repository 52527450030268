import {Module} from "vuex";

interface ICustomerTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const customerTableIndexState: Module<ICustomerTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
		ClientUid(state, getters, rootState) { return getConfig("Customer.ColumnOptions.ClientUid", rootState, true); },
        Id(state, getters, rootState) { return getConfig("Customer.ColumnOptions.Id", rootState, true); },
        Name(state, getters, rootState) { return getConfig("Customer.ColumnOptions.Name", rootState, true); },
        Phone(state, getters, rootState) { return getConfig("Customer.ColumnOptions.Phone", rootState, true); },
        Email(state, getters, rootState) { return getConfig("Customer.ColumnOptions.Email", rootState, true); },
        Address(state, getters, rootState) { return getConfig("Customer.ColumnOptions.Address", rootState, true); },
        ZipCode(state, getters, rootState) { return getConfig("Customer.ColumnOptions.ZipCode", rootState, true); },
        City(state, getters, rootState) { return getConfig("Customer.ColumnOptions.City", rootState, true); },
        TypeName(state, getters, rootState) { return getConfig("Customer.ColumnOptions.TypeName", rootState, true); },
        GroupName(state, getters, rootState) { return getConfig("Customer.ColumnOptions.GroupName", rootState, false); },
        EndDate(state, getters, rootState) { return getConfig("Customer.ColumnOptions.EndDate", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUid: getters.ClientUid,
                Id: getters.Id,
                Name: getters.Name,
                Phone: getters.Phone,
                Email: getters.Email,
                Address: getters.Address,
                ZipCode: getters.ZipCode,
                City: getters.City,
                TypeName: getters.TypeName,
                GroupName: getters.GroupName,
                EndDate: getters.EndDate,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUid: true,
                Id: true,
                Name: true,
                Phone: true,
                Email: true,
                Address: true,
                ZipCode: true,
                City: true,
                TypeName: true,
                GroupName: true,
                EndDate: true,
            };
        }
    }
}

export default customerTableIndexState;