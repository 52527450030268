import {Module} from "vuex";

interface IProductBrandTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const productBrandTableIndexState: Module<IProductBrandTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
		ClientUid(state, getters, rootState) { return getConfig("ProductBrands.ColumnOptions.ClientUid", rootState, true); },
        Id(state, getters, rootState) { return getConfig("ProductBrands.ColumnOptions.Id", rootState, true); },
        Name(state, getters, rootState) { return getConfig("ProductBrands.ColumnOptions.Name", rootState, true); },
        ContactPerson(state, getters, rootState) { return getConfig("ProductBrands.ColumnOptions.ContactPerson", rootState, true); },
        Phone(state, getters, rootState) { return getConfig("ProductBrands.ColumnOptions.Phone", rootState, true); },
        Email(state, getters, rootState) { return getConfig("ProductBrands.ColumnOptions.Email", rootState, true); },
        IsActive(state, getters, rootState) { return getConfig("ProductBrands.ColumnOptions.IsActive", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUid: getters.ClientUid,
                Id: getters.Id,
                Name: getters.Name,
                ContactPerson: getters.ContactPerson,
                Phone: getters.Phone,
                Email: getters.Email,
                IsActive: getters.IsActive,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUid: true,
                Id: true,
                Name: true,
                ContactPerson: true,
                Phone: true,
                Email: true,
                IsActive :true,
            };
        }
    }
}

export default productBrandTableIndexState;