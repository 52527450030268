import {Module} from "vuex";

interface ICashManagementTableIndexState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const cashManagementTableIndexState: Module<ICashManagementTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
		Amount(state, getters, rootState) { return getConfig("CashManagement.ColumnOptions.Amount", rootState, true); },
		AccountFromUid(state, getters, rootState) { return getConfig("CashManagement.ColumnOptions.AccountFromUid", rootState, true); },
		AccountToUid(state, getters, rootState) { return getConfig("CashManagement.ColumnOptions.AccountToUid", rootState, true); },
		Username(state, getters, rootState) { return getConfig("CashManagement.ColumnOptions.Username", rootState, true); },
		IsProcessedForAccounting(state, getters, rootState) { return getConfig("CashManagement.ColumnOptions.IsProcessedForAccounting", rootState, true); },
		CreatedDate(state, getters, rootState) { return getConfig("CashManagement.ColumnOptions.CreatedDate", rootState, true); }, 
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				Amount: getters.Amount,
				AccountFromUid: getters.AccountFromUid,
				AccountToUid: getters.AccountToUid,
				Username: getters.Username,
				IsProcessedForAccounting: getters.IsProcessedForAccounting,
				CreatedDate: getters.CreatedDate,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				Amount: true,
				AccountFromUid: true,
				AccountToUid: true,
				Username: true,
				IsProcessedForAccounting: true,
				CreatedDate: true,
            };
        }
    }
}

export default cashManagementTableIndexState;