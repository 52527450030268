


































































import {mapGetters} from "vuex";
import FpModal from "@components/modals/fp-modal.vue";
import FpInputPassword from "@components/inputs/fp-input-password.vue";
import {validationMixin} from "vuelidate";
import {required, sameAs} from "vuelidate/lib/validators";
import ValidationPanel from "@components/ValidationPanel.vue";
import ValidationPasswordField from "@components/forms/validation-password-field.vue";
import vuelidateToErrorList from "@app/vuelidateToErrorList";
import {UserApi} from "@services/Autogenerated_v4/V1/api/user-api";

export default {
	name: "change-password-modal",
	components: {ValidationPasswordField, FpInputPassword, FpModal, ValidationPanel},
	props: {
		value: {
			required: true,
			validator: function (value) {
				return typeof value === "boolean";
			}
		}
	},
	data() {
		return {
			state: "awaiting-input",
			transition: false,
			val: null,
			backdrop: null,

			UserService: new UserApi(this.$swaggerAxiosOptions),

			// Password stuff
			currentPassword: "",
			newPassword: "",
			newPasswordError: false,
			confirmPassword: "",
			confirmPasswordError: false,

			// Validation
			validationErrors: [],
		}
	},
	mixins: [validationMixin],
	validations: {
		currentPassword: {
			required
		},
		newPassword: {
			required,
		},
		confirmPassword: {
			required,
			sameAs: sameAs('newPassword'),
		}
	},
	watch: {
		val(val, old) {
			this.$emit('input', val);
			this.validationErrors = [];
			this.currentPassword = "";
			this.newPassword = "";
			this.confirmPassword = "";
			this.$v.$reset();
		},
		value(val, old) {
			if (val !== old) this.val = val
		}
	},
	computed: {
		...mapGetters({
			activeModal: "getActiveModal",
			getUserUID: "getUserUID",
		}),
		AWAITING_INPUT() {
			return "awaiting-input"
		},
		AWAITING_ACTION() {
			return "awaiting-action"
		},
		SUCCESS() {
			return "success"
		},
		NOT_STARTED() {
			return "not-started"
		},
		RUNNING() {
			return "running"
		},
		allIsNotPopulated() {
			return !(this.currentPassword !== "" && this.newPassword !== "" && this.confirmPassword !== "");
		}
	},
	methods: {
		passwordError(val) {
			this.newPasswordError = val;
		},
		passwordError2(val) {
			this.confirmPasswordError = val;
		},
		trans(val) {
			this.transition = val;
		},
		async ok(event) {
			this.validationErrors = [];
			if (this.$v.$invalid) {
				this.$v.$touch()
				this.$nextTick(() => this.validationErrors = vuelidateToErrorList(this.$v));

				return;
			}

			try {
				await this.UserService.changePassword(
					{
						APIInternalFrontendAPIModelsUserChangePasswordRequestModel: {
							Uid: this.getUserUID,
							OldPassword: this.currentPassword,
							NewPassword: this.newPassword,
							NewPasswordAgain: this.confirmPassword,
						}
					},
				);
			} catch (e) {
				if (e.response.status == 400) {
					this.validationErrors = e.response.data.errors;
					return;
				}
			}

			this.state = this.AWAITING_ACTION;
			this.$emit("ok-clicked")
			this.close(event);
			this.$emit("ok");
		},
		confirm(event) {
			this.ok(event);
		},
		close(event) {
			this.val = false;
			this.$emit("closing")
		},
		closed() {
			this.state = this.AWAITING_INPUT;
		},
		cancel(event) {
			this.val = false;
			this.$emit("cancel")
			this.$emit("closing")
		},
		browserEvent(event) {
			if (!(event && (event.code === "Escape" || event.code === "Enter"))) return;
			if (event.defaultPrevented || this.val === false) {
				return;
			}
			const activeModal = this.activeModal();
			if (activeModal.$el !== this.$refs.modal.$el) return;

			if (event.key === "Escape") {
				if (this.state === this.AWAITING_INPUT)
					this.cancel(event)
				else
					this.val = false
			} else if (event.key === "Enter") {
				if (this.state === this.AWAITING_INPUT)
					this.confirm(event)
				else
					this.val = false
			}
		}
	},
	async mounted() {
		window.addEventListener("keyup", this.browserEvent);
		this.val = this.value;
	},
	beforeDestroy() {
		window.removeEventListener("keyup", this.browserEvent);
	},
}
