/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesClientTreeNode } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientClientMinimalTreeViewModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientGetEditClientRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientPostCreateClientRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel } from '../models';
/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsClientPostCreateClientRequestModel} [APIInternalFrontendAPIModelsClientPostCreateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createClient: async (APIInternalFrontendAPIModelsClientPostCreateClientRequestModel?: APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2.0/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editModel: async (uid: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('editModel', 'uid', uid)
            const localVarPath = `/api/v2.0/client/{uid}/edit-model`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {boolean} [showDeleted] 
         * @param {string} [types] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildClients: async (uid?: string, showDeleted?: boolean, types?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2.0/client/get-child-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleSearch: async (clientUid?: string, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2.0/client/get-simple-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel} [APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient: async (uid: string, APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel?: APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('updateClient', 'uid', uid)
            const localVarPath = `/api/v2.0/client/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsClientPostCreateClientRequestModel} [APIInternalFrontendAPIModelsClientPostCreateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createClient(APIInternalFrontendAPIModelsClientPostCreateClientRequestModel?: APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClient(APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editModel(uid: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsClientGetEditClientRequestModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editModel(uid, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {boolean} [showDeleted] 
         * @param {string} [types] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildClients(uid?: string, showDeleted?: boolean, types?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesClientTreeNode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildClients(uid, showDeleted, types, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleSearch(clientUid?: string, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsClientClientMinimalTreeViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimpleSearch(clientUid, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel} [APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClient(uid: string, APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel?: APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClient(uid, APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsClientPostCreateClientRequestModel} [APIInternalFrontendAPIModelsClientPostCreateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createClient(APIInternalFrontendAPIModelsClientPostCreateClientRequestModel?: APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.createClient(APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editModel(uid: string, languageUid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsClientGetEditClientRequestModel> {
            return localVarFp.editModel(uid, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {boolean} [showDeleted] 
         * @param {string} [types] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildClients(uid?: string, showDeleted?: boolean, types?: string, search?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesClientTreeNode>> {
            return localVarFp.getChildClients(uid, showDeleted, types, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleSearch(clientUid?: string, query?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsClientClientMinimalTreeViewModel>> {
            return localVarFp.getSimpleSearch(clientUid, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel} [APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient(uid: string, APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel?: APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.updateClient(uid, APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createClient operation in ClientApi.
 * @export
 * @interface ClientApiCreateClientRequest
 */
export interface ClientApiCreateClientRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsClientPostCreateClientRequestModel}
     * @memberof ClientApiCreateClient
     */
    readonly APIInternalFrontendAPIModelsClientPostCreateClientRequestModel?: APIInternalFrontendAPIModelsClientPostCreateClientRequestModel
}

/**
 * Request parameters for editModel operation in ClientApi.
 * @export
 * @interface ClientApiEditModelRequest
 */
export interface ClientApiEditModelRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiEditModel
     */
    readonly uid: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiEditModel
     */
    readonly languageUid?: string
}

/**
 * Request parameters for getChildClients operation in ClientApi.
 * @export
 * @interface ClientApiGetChildClientsRequest
 */
export interface ClientApiGetChildClientsRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildClients
     */
    readonly uid?: string

    /**
     * 
     * @type {boolean}
     * @memberof ClientApiGetChildClients
     */
    readonly showDeleted?: boolean

    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildClients
     */
    readonly types?: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildClients
     */
    readonly search?: string
}

/**
 * Request parameters for getSimpleSearch operation in ClientApi.
 * @export
 * @interface ClientApiGetSimpleSearchRequest
 */
export interface ClientApiGetSimpleSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetSimpleSearch
     */
    readonly clientUid?: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiGetSimpleSearch
     */
    readonly query?: string
}

/**
 * Request parameters for updateClient operation in ClientApi.
 * @export
 * @interface ClientApiUpdateClientRequest
 */
export interface ClientApiUpdateClientRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiUpdateClient
     */
    readonly uid: string

    /**
     * 
     * @type {APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel}
     * @memberof ClientApiUpdateClient
     */
    readonly APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel?: APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel
}

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * 
     * @param {ClientApiCreateClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public createClient(requestParameters: ClientApiCreateClientRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).createClient(requestParameters.APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiEditModelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public editModel(requestParameters: ClientApiEditModelRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).editModel(requestParameters.uid, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetChildClientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getChildClients(requestParameters: ClientApiGetChildClientsRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getChildClients(requestParameters.uid, requestParameters.showDeleted, requestParameters.types, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetSimpleSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getSimpleSearch(requestParameters: ClientApiGetSimpleSearchRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getSimpleSearch(requestParameters.clientUid, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiUpdateClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClient(requestParameters: ClientApiUpdateClientRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClient(requestParameters.uid, requestParameters.APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}
