


















export default {
	name: "fp-table-row",
	props: {
		hasSelectColumn: {
			default: false,
			type: Boolean
		},
		rowKey: {
			required: true,
			type: String
		},
		row: {
			required: true,
			type: Object
		},
		columns: {
			required: true,
			type: Array
		}
	},
	data() {
		return {
			classes: {},
			rowClasses: []
		};
	},
	computed: {
		shownColumns() {
			return this.columns.filter(c => c.shown);
		}
	},
	created() {
		for (let i = 0; i < this.columns.length; i++) {
			let column = this.columns[i];
			this.classes[column.field] = this.setColumnClasses(column);
		}
	},
	methods: {
		clickedRow() {
			this.$emit("clicked-row");	
		},
		selectRow(id) {
			this.$emit("select-row", id);
		},
		setColumnClasses(column) {
			let classes = [];
			if (column.textAlign === 'left') classes.push("text-start");
			if (column.textAlign === 'right') classes.push("text-end");
			if (column.textAlign === 'center') classes.push("text-center");
			if (column.sticky) classes.push(`sticky-${column.sticky}`);
			
			if (column.$attrs.class)
				return [...classes, column.$attrs.class];
			else            	
				return [...classes];

		},
		updateColumnClassesEvent(args: {field: string, add: string[], remove: string[]}) {
			this.$nextTick(() => {
				let classes = [...this.classes[args.field]];

				for (let i = 0; i < args.remove.length; i++) {
					let index = classes.indexOf(args.remove[i]);
					while (index > -1) {
						classes.splice(index, 1);
						index = classes.indexOf(args.remove[i]);
					}

				}
				
				for (let i = 0; i < args.add.length; i++) {
					classes.push(args.add[i]);
				}

				this.$set(this.classes, args.field, classes);
				this.$forceUpdate();
			});
		},
		updateRowClassesEvent(args: {add: string[], remove: string[]}) {
			this.$nextTick(() => {
				let classes = [...this.rowClasses];

				for (let i = 0; i < args.remove.length; i++) {
					let index = classes.indexOf(args.remove[i]);
					while (index > -1) {
						classes.splice(index, 1);
						index = classes.indexOf(args.remove[i]);
					}

				}

				for (let i = 0; i < args.add.length; i++) {
					classes.push(args.add[i]);
				}

				this.rowClasses = classes;
				//this.$forceUpdate();
			});
		}
	}
};
