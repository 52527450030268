import {Module} from "vuex";

interface IBonLayoutModuleIndexState {
	activeTab: string | null;
}

const bonLayoutModuleIndexState: Module<IBonLayoutModuleIndexState, unknown> = {
	namespaced: true,
	state: () => ({
		activeTab: null,
	}),
	actions: {
		SET_ACTIVE_TAB(context, args) {
			context.commit("SET_ACTIVE_TAB", args);
		},
	},
	mutations: {
		SET_ACTIVE_TAB(state, args) {
			state.activeTab = args;
		},
	},
	getters: {
		getActiveTab(state) { return state.activeTab; },
	}
};

export default bonLayoutModuleIndexState;