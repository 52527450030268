import _Vue from "vue";

export function VueLog<T>(Vue: typeof _Vue): void {

	function log(...value) {		
		if (process.env.NODE_ENV == "production") {
			if (typeof localStorage != "undefined" && localStorage.getItem("vue-log-enabled") == null)
				return;
		}

		// @ts-ignore
		const arr = [Vue.moment().format("HH:mm:ss.SSSS"), ...value];
		console.log.call(console, ...arr);
	}

	Vue.prototype.$log = log;
	// @ts-ignore
	Vue.log = log;
}