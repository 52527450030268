








































































































































































































































































































































































































































































































































































































































































































































































































































import isFunction from "underscore/modules/isFunction.js";
import isArray from "underscore/modules/isArray.js";
import throttle from "underscore/modules/throttle.js";
import FpTableColumnHeader from "./fp-table-column-header.vue";
import FpTableRow from "./fp-table-row.vue";
import {mapActions} from "vuex";
import FpTableDetailRow from "@components/tables/fp-table-detail-row.vue";
import {v4 as newGuid} from "uuid";
import * as bootstrap from "bootstrap";
import isNullOrEmpty from "@app/isNullOrEmpty";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const editSelected = function editSelected() {};
// eslint-disable-next-line @typescript-eslint/no-empty-function
const deleteSelected = function deleteSelected() {};

export default {
	name: "fp-table",
	components: {FpTableDetailRow, FpTableColumnHeader, FpTableRow},
	props: {
		id: {
			default: null,
		},
		placeholder: {
			default() {
				return this.Resource.Search;
			},
			type: String
		},
		caption: {
			default: null,
			type: String
		},
		pageSizeOptions: {
			default() {
				return [10,25,50,100];
			},
			type: Array
		},
		title: {
			default: null,
			validator: function (value) {
				return typeof value === "string";
			},
			type: String
		},
		hasTotal: {
			default: true,
		},
		bulkEditText: {
			default() {
				return this.Resource.Edit;
			},
			validator: function (value) {
				return typeof value === "string";
			},
			type: String
		},
		bulkDeleteText: {
			default() {
				return this.Resource.Delete;
			},
			validator: function (value) {
				return typeof value === "string";
			},
			type: String
		},
		limit: {
			default: 50,
			validator: function (value) {
				return value > 0;
			}
		},
		offset: {
			default: 0,
			validator: function (value) {
				return value >= 0;
			}
		},
		data: {
			required: true,
			validator: function (value) {
				return isFunction(value) || isArray(value);
			}
		},
		editSelected: {
			required: false,
			default: function () {
				return editSelected;
			},
			validator: function (value) {
				return isFunction(value);
			}
		},
		deleteSelected: {
			required: false,
			default: function () {
				return deleteSelected;
			},
			validator: function (value) {
				return isFunction(value);
			}
		},
		allowDeleteSelected: {
			required: false,
			type: Boolean,
			default: true
		},
		params: {
			required: false,
			default() {
				return function params(searchArgs) {
					return searchArgs;
				};
			},
			validator: function (value) {
				return isFunction(value);
			}
		},
		rowStyle: {
			required: false,
			default: function () {
				return function () {
					return {};
				};
			},
			validator: function (value) {
				return isFunction(value);
			}
		},
		rowStyleClasses: {
			required: false,
			default: function () {
				return {};
			},
			type: Object
		},
		rowKey: {
			required: false,
			default: "id",
			validator: function (value) {
				return typeof value === "string";
			}
		},
		sort: {
			required: false,
			default: "LastTimeStamp",
			validator: function (value) {
				return typeof value === "string";
			}
		},
		order: {
			required: false,
			default: "desc",
			validator: function (value) {
				return typeof value === "string";
			}
		},
		hasSelectColumn: {
			default: false,
			type: [Boolean, String]
		},
		hasSelectColumnDetailOverride: {
			default: null,
			type: [Boolean, String]
		},
		hasHead: {
			default: true,
			type: [Boolean, String]
		},
		visibilityKey: {
			default: null,
			type: String
		},
		visibleColumns: {
			default: null,
			type: Object
		},
		initialSearch: {
			default: null,
			type: String
		},
		hasResetFilters: {
			default: true,
			type: [Boolean, String]
		},
		hasSearch: {
			default: true,
			type: [Boolean, String]
		},
		hasPagination: {
			default: true,
			type: [Boolean, String]
		},
		initialClearSelectedOnSearch: {
			default: true,
			type: [Boolean, String]
		},
		isCompact: {
			default: false,
			type: [Boolean, String]
		},
		headerHidden: {
			default: false,
			type: [Boolean, String]
		},
		hasPadding: {
			default: true,
			type: Boolean
		},
		hasAutoFocus: {
			default: false,
			type: Boolean
		},
		hasBorderedTable: {
			default: false,
			type: Boolean
		},
		isLoading: {
			default: false,
			validator: function (value) {
				return typeof value === "boolean";
			}
		},
		overrideInternalLoading: {type: Boolean, default: false},
		isSpinnerDisabled: {type: Boolean, default: false},
		additionalSearchParams: {
			type: Object, default() {
				return {};
			}
		},
		setSearchParamsVisibleColumns: {
			type: Boolean, 
			default() {
				return false;
			}
		}
	},
	data() {
		return {
			tableId: newGuid(),
			rows: [],
			total: 0,
			columns: [],
			sortField: this.sort,
			sortDirection: this.order,
			rowLimit: this.limit,
			rowOffset: this.offset,
			selectAllValue: false,
			selected: [],
			currentPage: 1,
			search: this.initialSearch,
			clearSelectedOnSearch: this.initialClearSelectedOnSearch,
			throttle: null,
			isLoadingInternal: this.isLoading,
			scrollOffsetLeft: 0,
			scrollOffsetRight: 0,
			resizeObserver: null,
			searchArgs: {},
			deleteSelectedDefault: deleteSelected,
			editSelectedDefault: editSelected,
			disableSelectAllValueWatch: false,
			isOpen: false,
			windowWidth: 1900,
			isNullOrEmpty: isNullOrEmpty
		};
	},
	computed: {
		configDropdownId() {
			let config = this.visibilityKey;

			while (config.indexOf(".") > -1) {
				config = config.replace(".", "");
			}

			return config;
		},
		activeColumns() {
			let arr = this.columns.filter(c => c.show).map(c => c.field);
			arr.push("LastTimestamp");
			return arr;
		},
		totalPages() {
			return Number(Math.ceil(this.total / this.rowLimit));
		},
		previousPageEnabled() {
			return this.rowOffset !== 0 && this.rows.length > 0 && this.currentPage !== 1;
		},
		nextPageEnabled() {
			return this.currentPage !== this.totalPages && this.rows.length > 0;
		},
		firstPageEnabled() {
			return this.currentPage !== 1;
		},
		lastPageEnabled() {
			return this.currentPage !== this.totalPages;
		},
		paginationPages() {
			let pages = [];
			let currentPage = this.currentPage;
			if (this.totalPages === 0)
				return pages;

			if (this.totalPages - 2 <= currentPage) {
				currentPage = this.totalPages - 4;
			} else if (currentPage - 2 > 0) {
				currentPage = currentPage - 2;
			} else {
				currentPage = 1;
			}

			if (currentPage === 0) {
				currentPage = 1;
			}

			if (currentPage < 0) {
				currentPage = 1;
			}

			while (pages.length < 5) {
				if (currentPage > this.totalPages) break;
				pages.push(currentPage);
				currentPage++;
			}
			return pages;
		}
	},
	watch: {
		isLoading: {
			immediate: true,
			handler(value) {
				this.isLoadingInternal = value;
			}
		},
		initialSearch: {
			immediate: true,
			handler(value) {
				this.search = value;
			}
		},
		sort: {
			immediate: true,
			handler(value) {
				this.sortField = value;
			}
		},
		order: {
			immediate: true,
			handler(value) {
				this.sortDirection = value;
			}
		},
		limit: {
			immediate: true,
			handler(value) {
				this.rowLimit = Number(value);
				this.$nextTick(() => {
					this.currentPage = (Number(this.rowOffset) / Number(this.rowLimit)) + 1;
				});
			}
		},
		offset: {
			immediate: true,
			handler(value) {
				this.rowOffset = Number(value);
				this.$nextTick(() => {
					this.currentPage = (Number(this.rowOffset) / Number(this.rowLimit)) + 1;
				});
			}
		},
		async sortField(value, oldValue) {
			if (value && this.activeColumns.filter(item => item.toLowerCase() === value.toLowerCase()).length === 0) {
				this.sortField = oldValue;
				console.error("sortField: needs to be an active column: ", this.activeColumns.join(", "));
				return;
			}
			this.$nextTick(async () => {
				await this.searchFunction();
			});
		},
		async sortDirection(value, oldValue) {
			if (value !== "desc" && value !== "asc") {
				this.sortDirection = oldValue;
				console.error("sortDirection: needs to be \"desc\" or \"asc\"");
				return;
			}
			this.$nextTick(async () => {
				await this.searchFunction();
			});
		},
		selectAllValue(value) {
			if (this.disableSelectAllValueWatch) return;
			for (let i = 0; i < this.rows.length; i++) {
				let row = this.rows[i];
				let item = this.rows[i][this.rowKey];

				if (row.hasSelect === false) continue;

				let index = this.findIndex(this.selected, item);
				if (index === -1 && value) this.selected.push(item);
				if (index > -1 && !value) this.selected.splice(index, 1);

				this.rows[i].selected = value;
			}

			this.$emit('selected-rows', {rows: this.rows, selected: value});
		},
		hasHead: {
			immediate: true,
			handler(val) {
				this.updateConfigDropdown(val);
			}
		},
		isCompact: {
			immediate: true,
			handler(val) {
				this.updateConfigDropdown(!val);
			}
		},
		search(val,oldVal) {
			if (val !== oldVal) this.$emit("search-updated", val);
		}
	},
	mounted() {
		this.$nextTick(() => {			
			if (this.hasAutoFocus) {
				if (this.$refs.searchText) {
					this.$refs.searchText.focus();
					if (this.$refs.searchText.value) this.$refs.searchText.select();
				}
			}

			if (this.$refs.table) {
				this.resizeObserver = new ResizeObserver(() => {
					try {
						this.scrollOffsetLeft = this.$refs.tableContainer.scrollLeft;
						this.scrollOffsetRight = (this.$refs.table.scrollWidth - this.$refs.tableContainer.offsetWidth) - this.$refs.tableContainer.scrollLeft;
					} catch (e) {
                        // Ignore
					}
				});
				

				this.resizeObserver.observe(this.$refs.table);
			}

			let __this = this;
			//@ts-ignore
			window.addEventListener('resize', function () {
				__this.windowWidth = document.documentElement.clientWidth;

				if (__this.windowWidth < 1100) {
					__this.isOpen = false;
					let collapse = document.getElementById('collapsingElement');
					if(collapse) {
						let collapse1 = bootstrap.Collapse.getInstance(collapse);
						if(collapse1) collapse1.hide();
					}
				}
			});

			this.$refs.tableContainer.addEventListener("scroll", this.setOffsetScroll);
		});
	},
	beforeDestroy() {
		this.$refs.tableContainer.removeEventListener("scroll", this.setOffsetScroll);
		if (this.resizeObserver) this.resizeObserver.unobserve(this.$refs.table);
	},
	created() {
		this._isTable = true;

		if (isArray(this.data)) {
			this.$watch("data", (data) => {
				for (let i = 0; i < data.length; i++) {
					if (data[i].selected === undefined) {
						data[i].selected = false;

						for (let j = 0; j < this.selected.length; j++) {
							if (this.selected[j] === data[i][this.rowKey]) {
								if (!data[i].selected) data[i].selected = true;
								break;
							}
						}
					} else {
						if (!data[i].selected) continue;
						if (this.selected.indexOf(data[i][this.rowKey]) === -1) {
							this.selected.push(data[i][this.rowKey]);
						}
					}
				}

				this.disableSelectAllValueWatch = true;

				let allSelected = data.length !== 0;
				this.rows = [];
				for (let i = 0; i < data.length; i++) {
					if (!data[i].selected) allSelected = false;
					this.rows.push({...data[i]});
				}

				this.selectAllValue = allSelected;
				this.$nextTick(() => this.disableSelectAllValueWatch = false);
				this.total = data.length;
			}, {immediate: true});
		}
	},
	methods: {
		...mapActions({
			setBackendConfig: "setBackendConfig"
		}),
		searchOnInput() {
			if (isArray(this.data)) this.searchFunction(true);
		},
		async updateConfigDropdown(show) {
			await this.$nextTick();
			if (!this.$refs.configDropdown) return;

			if (show) {
				this.$bootstrap.Dropdown.getOrCreateInstance(this.$refs.configDropdown, {
					autoClose: "outside"
				});
			} else {
				let dropdown = this.$bootstrap.Dropdown.getInstance(this.$refs.configDropdown);
				if (dropdown) dropdown.dispose();
			}
		},
		setOffsetScroll() {
			this.scrollOffsetLeft = this.$refs.tableContainer.scrollLeft;
			this.scrollOffsetRight = (this.$refs.table.offsetWidth - this.$refs.tableContainer.offsetWidth)
				- this.$refs.tableContainer.scrollLeft;
		},
		async toggleVisibleColumn(field, ev) {
			await this.setBackendConfig({key: this.visibilityKey + field, value: ev.target.checked});
			this.$emit("visible-columns-updated");
		},
		findIndex(arr, item) {
			let index = -1;
			for (let i = 0; i < arr.length; i++) {
				if (arr[i] === item) {
					index = i;
					break;
				}
			}
			return index;
		},
		deselectSelected() {
			for (let i = 0; i < this.rows.length; i++) {
				this.rows[i].selected = false;
			}

			this.selected = [];
			this.selectAllValue = false;
		},
		setPage(page) {
			this.currentPage = page;

			let val = page - 1;
			this.rowOffset = val * this.rowLimit;
			this.selectAllValue = false;

			this.$nextTick(async () => {
				await this.searchFunction(true, false);
			});
		},
		setPageSize(size) {
			this.rowLimit = Number(size.target.value);
			this.rowOffset = 0;

			this.$nextTick(async () => {
				await this.searchFunction();
			});
		},
		firstPage() {
			this.setPage(1);
		},
		lastPage() {
			this.setPage(this.totalPages);
		},
		previousPage() {
			if (this.previousPageEnabled) {
				this.setPage(this.currentPage - 1);
			}
		},
		nextPage() {
			if (this.nextPageEnabled) {
				this.setPage(this.currentPage + 1);
			}
		},
		columnHeaderClick(column) {
			if (this.sortField === column.field) this.sortDirection = (this.sortDirection === "desc") ? "asc" : "desc";
			else {
                this.sortField = column.field;
                this.sortDirection = "desc";
			}
		},
		columnClasses(column) {
			let classes = [];
			if (this.headerHidden)
				classes.push("visually-hidden");

			if (this.sortField === column.field) classes.push(this.sortDirection);
			if (column.sticky) classes.push(`sticky-${column.sticky}`);

			return classes;
		},
		columnAriaSort(column) {
			if (this.sortField === column.field) return this.sortDirection;
			if (column.sortable) return "none";
			return null;
		},
		async resetFilters() {
			this.search = null;
			this.sortField = this.sort;
			this.sortDirection = this.order;

			await this.$emit("reset-filters");

			this.$nextTick(async () => {
				await this.searchFunction(true);
			});
		},
		arraySearch(search: string, rows: any[]) {
			console.time("Array search");
			if (search == null || search === "") {
				rows.forEach(row => row.IsHidden = false);
				return;
			}
			
			const search1 = search ? search.trim().toLowerCase() : null;
			const guid = new GUID();
	
			for (let i = 0; i < rows.length; i++) {
				let values = Object.values(rows[i]);
				let shown = false;
	
				for (let j = 0; j < values.length && !shown; j++) {
					let value = values[j];
					if (value == null) continue;
	
					if (guid.isGuid(value.toString())) shown = guid.isGuid(search1) && value.toString() === search1;
					else shown = value.toString().toLowerCase().includes(search1);
				}
	
				rows[i].IsHidden = !shown;
			}
	
			console.timeEnd("Array search");
		},
		searchFunction(immediate = false, throttling = true) {
			if (isArray(this.data)) {
				this.throttle = this.throttle || throttle(() => {
					if(!this.overrideInternalLoading) this.isLoadingInternal = true;
					try	{
						this.arraySearch(this.search, this.rows);
						this.$emit("updated");
					}
					catch (e) {
                        // Ignore
					}

					if(!this.overrideInternalLoading) this.$nextTick(() => this.isLoadingInternal = false);

				}, throttling ? 200 : 0, {trailing: true});
				
				this.throttle();

				return;
			}

			this.throttle = this.throttle || throttle(async () => {
				try {
					let visibleColumns = [];
					for (const visibleColumnsKey in this.visibleColumns) {
						if (this.visibleColumns[visibleColumnsKey]) visibleColumns.push(visibleColumnsKey);
					}
					visibleColumns.push("ClientUid");
					visibleColumns.push(this.rowKey);

					this.searchArgs = {
						"sort": this.sortField,
						"order": this.sortDirection,
						"limit": this.rowLimit,
						"offset": this.rowOffset
					};
					if (this.setSearchParamsVisibleColumns) this.searchArgs["visibleColumns"] = visibleColumns.join(",");

					if (this.search) this.searchArgs["search"] = this.search.trim();

					this.searchArgs = this.params(this.searchArgs);
					this.searchArgs = {...this.additionalSearchParams, ...this.searchArgs};

					let data = await this.data(this.searchArgs);

					for (let i = 0; i < data.rows.length; i++) {
						data.rows[i].selected = false;

						if (!this.clearSelectedOnSearch) {
							for (let j = 0; j < this.selected.length; j++) {
								if (this.selected[j] === data.rows[i][this.rowKey]) {
									data.rows[i].selected = true;
									break;
								}
							}
						}
					}

					if (this.clearSelectedOnSearch) this.deselectSelected();
					
					this.rows = data.rows;
					this.total = data.total;

					this.$nextTick(() => {
						if (this.totalPages < this.currentPage && this.currentPage != 1) this.firstPage();
					});
					if(!this.overrideInternalLoading) this.isLoadingInternal = false;
					this.$emit("updated");
				} catch (e) {
					this.isLoadingInternal = false;
				}

			}, throttling ? 200 : 0, {trailing: false});
			this.throttle();
		},
		clickedRow(row) {
			this.$emit('clicked-row', row);
		},
		selectRowBulk(rows, selected) {
			let allSelected = this.rows.length !== 0;
			for (let i = 0; i < rows.length; i++) {
				let index = this.findIndex(this.selected, rows[i].Uid);

				if (index > -1) this.selected.splice(index, 1);
				else this.selected.push(rows[i].Uid);

				for (let b = 0; b < this.rows.length; b++) {
					if (this.rows[b][this.rowKey] === rows[i].Uid) {
						this.rows[b].selected = selected;
						if (!selected) allSelected = false;
					}
				}
			}

			this.disableSelectAllValueWatch = true;
			this.selectAllValue = allSelected;
			this.$nextTick(() => {
				this.disableSelectAllValueWatch = false;
			});
		},
		selectRow(id, selected = null) {
			let index = this.findIndex(this.selected, id);
			let added = false;
			if (selected !== null) {
				added = selected;
				if (index > -1) if (!selected) this.selected.splice(index, 1);
				else if (selected) this.selected.push(id);
			} else {
				if (index > -1) this.selected.splice(index, 1);
				else {
					this.selected.push(id);
					added = true;
				}
			}

			if (!selected) {
				this.disableSelectAllValueWatch = true;
				this.selectAllValue = false;
			}

			this.$nextTick(() => {
				this.disableSelectAllValueWatch = false;
				for (let i = 0; i < this.rows.length; i++) {
					if (this.rows[i][this.rowKey] === id) {
						this.rows[i].selected = added;
						break;
					}
				}

				this.$emit('selected-row', {id: id, selected: added});
			});
		}
	}
}
