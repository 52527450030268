import {Module} from "vuex";
import {ClientApi} from "@services/Autogenerated_v4/V1/api/client-api";
import {ClientApi as ClientApiV2} from "@services/Autogenerated_v4/V2/api/client-api";

let ClientService: ClientApi;
let ClientServiceV2: ClientApiV2;
import {NIL as NIL_UUID} from 'uuid';
import isNullOrEmpty from "../isNullOrEmpty";


const topMenuState: Module<any, any> = {
	namespaced: true,

	state: () => ({
		clients: {},
		throttle: null,
		query: null,
	}),
	actions: {
		async setClient({state, commit, rootState, getters, rootGetters}, query) {
			state.query = query;
			if (!ClientService) ClientService = new ClientApi(rootGetters["getSwaggerAxiosOptions"]);
			if (!ClientServiceV2) ClientServiceV2 = new ClientApiV2(rootGetters["getSwaggerAxiosOptions"]);

			const cacheItem = getters.getClient(query);

			if (cacheItem) {
				return cacheItem;
			}

			let clientUid;
			if (isNullOrEmpty(query)) {
				if (rootState.client.ClientTypeUid === "8160515d-70fb-4027-ac2d-ea6414e5bf7e") {
					clientUid = rootState.clientUid;
				} else if (rootState.client.PUID === NIL_UUID || rootState.isReseller) {
					clientUid = rootState.clientUid;
				} else if (rootState.parentUserClientUid === rootState.clientUid) {
					clientUid = rootState.clientUid;
				} else {
					clientUid = rootState.parentClientUid;
				}
			} else {
				clientUid = rootState.parentUserClientUid;
			}
			
			if (rootGetters["getBackendConfigBool"]("TopMenu.NestedSearch", false)) {
				const search = (await ClientServiceV2.getSimpleSearch({
					query: state.query,
					clientUid: clientUid
				})).data;

				commit("SET_CLIENTS", {query: state.query, results: search});

				return search;
			}
			else {

				const search = (await ClientService.getSimpleSearch({
					query: state.query,
					clientUid: clientUid
				})).data;

				commit("SET_CLIENTS", {query: state.query, results: search});

				return search;
			}
		},
	},
	mutations: {
		SET_CLIENTS(state, newState) {
			state.clients[`_${newState.query}_`] = newState.results;
		}
	},
	getters: {
		getClient(state) {
			return (query) => {
				return state.clients[`_${query}_`];
			};
		}
	}
};

export default topMenuState;