import {Configuration} from "../_Services/Autogenerated_v4/V1/configuration";
import _Vue from "vue";

export function VueSwaggerAxiosOptions<T>(Vue: typeof _Vue): void {
    // @ts-ignore
    Object.defineProperties(Vue.prototype, {
        $swaggerAxiosOptions: {
            get(): Configuration {
                return this.$store.getters.getSwaggerAxiosOptions;
            },
        },
    });
}
