/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIModelsUserUserTableModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesUser } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsSelectListItemSelectListItem } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsUserChangePasswordRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsUserUserAccessViewModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsUserUserViewModel } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUserChangePasswordRequestModel} [APIInternalFrontendAPIModelsUserChangePasswordRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (APIInternalFrontendAPIModelsUserChangePasswordRequestModel?: APIInternalFrontendAPIModelsUserChangePasswordRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/user/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsUserChangePasswordRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUserUserViewModel} [APIInternalFrontendAPIModelsUserUserViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (APIInternalFrontendAPIModelsUserUserViewModel?: APIInternalFrontendAPIModelsUserUserViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsUserUserViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePassword: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/user/generate-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByClientUidLoyalty: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/user/get-all-by-client-uid-loyalty`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaged: async (sort?: string, order?: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/user/get-paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessSingle: async (clientUid?: string, uid?: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/user/get-user-access-single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [cellPhone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: async (uid?: string, name?: string, email?: string, phone?: string, cellPhone?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/user/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (cellPhone !== undefined) {
                localVarQueryParameter['cellPhone'] = cellPhone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUser: async (uid: string, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('unlockUser', 'uid', uid)
            const localVarPath = `/api/v1.0/user/{uid}/unlock-user`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (password !== undefined) { 
                localVarFormParams.append('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUserUserViewModel} [APIInternalFrontendAPIModelsUserUserViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (APIInternalFrontendAPIModelsUserUserViewModel?: APIInternalFrontendAPIModelsUserUserViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/user/update-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsUserUserViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDELETE: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('userDELETE', 'uid', uid)
            const localVarPath = `/api/v1.0/user/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGET: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('userGET', 'uid', uid)
            const localVarPath = `/api/v1.0/user/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUserChangePasswordRequestModel} [APIInternalFrontendAPIModelsUserChangePasswordRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(APIInternalFrontendAPIModelsUserChangePasswordRequestModel?: APIInternalFrontendAPIModelsUserChangePasswordRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(APIInternalFrontendAPIModelsUserChangePasswordRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUserUserViewModel} [APIInternalFrontendAPIModelsUserUserViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(APIInternalFrontendAPIModelsUserUserViewModel?: APIInternalFrontendAPIModelsUserUserViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(APIInternalFrontendAPIModelsUserUserViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePassword(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePassword(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllByClientUidLoyalty(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsSelectListItemSelectListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllByClientUidLoyalty(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaged(sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIModelsUserUserTableModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaged(sort, order, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAccessSingle(clientUid?: string, uid?: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsUserUserAccessViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAccessSingle(clientUid, uid, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [cellPhone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save(uid?: string, name?: string, email?: string, phone?: string, cellPhone?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save(uid, name, email, phone, cellPhone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockUser(uid: string, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockUser(uid, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUserUserViewModel} [APIInternalFrontendAPIModelsUserUserViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(APIInternalFrontendAPIModelsUserUserViewModel?: APIInternalFrontendAPIModelsUserUserViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(APIInternalFrontendAPIModelsUserUserViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDELETE(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDELETE(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGET(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGET(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUserChangePasswordRequestModel} [APIInternalFrontendAPIModelsUserChangePasswordRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(APIInternalFrontendAPIModelsUserChangePasswordRequestModel?: APIInternalFrontendAPIModelsUserChangePasswordRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.changePassword(APIInternalFrontendAPIModelsUserChangePasswordRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUserUserViewModel} [APIInternalFrontendAPIModelsUserUserViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(APIInternalFrontendAPIModelsUserUserViewModel?: APIInternalFrontendAPIModelsUserUserViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.create(APIInternalFrontendAPIModelsUserUserViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePassword(options?: any): AxiosPromise<string> {
            return localVarFp.generatePassword(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByClientUidLoyalty(options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsSelectListItemSelectListItem>> {
            return localVarFp.getAllByClientUidLoyalty(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaged(sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIModelsUserUserTableModel> {
            return localVarFp.getPaged(sort, order, limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessSingle(clientUid?: string, uid?: string, languageUid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsUserUserAccessViewModel> {
            return localVarFp.getUserAccessSingle(clientUid, uid, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [cellPhone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(uid?: string, name?: string, email?: string, phone?: string, cellPhone?: string, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesUser> {
            return localVarFp.save(uid, name, email, phone, cellPhone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUser(uid: string, password?: string, options?: any): AxiosPromise<void> {
            return localVarFp.unlockUser(uid, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUserUserViewModel} [APIInternalFrontendAPIModelsUserUserViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(APIInternalFrontendAPIModelsUserUserViewModel?: APIInternalFrontendAPIModelsUserUserViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(APIInternalFrontendAPIModelsUserUserViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDELETE(uid: string, options?: any): AxiosPromise<void> {
            return localVarFp.userDELETE(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGET(uid: string, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesUser> {
            return localVarFp.userGET(uid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changePassword operation in UserApi.
 * @export
 * @interface UserApiChangePasswordRequest
 */
export interface UserApiChangePasswordRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsUserChangePasswordRequestModel}
     * @memberof UserApiChangePassword
     */
    readonly APIInternalFrontendAPIModelsUserChangePasswordRequestModel?: APIInternalFrontendAPIModelsUserChangePasswordRequestModel
}

/**
 * Request parameters for create operation in UserApi.
 * @export
 * @interface UserApiCreateRequest
 */
export interface UserApiCreateRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsUserUserViewModel}
     * @memberof UserApiCreate
     */
    readonly APIInternalFrontendAPIModelsUserUserViewModel?: APIInternalFrontendAPIModelsUserUserViewModel
}

/**
 * Request parameters for getPaged operation in UserApi.
 * @export
 * @interface UserApiGetPagedRequest
 */
export interface UserApiGetPagedRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiGetPaged
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiGetPaged
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof UserApiGetPaged
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof UserApiGetPaged
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof UserApiGetPaged
     */
    readonly search?: string
}

/**
 * Request parameters for getUserAccessSingle operation in UserApi.
 * @export
 * @interface UserApiGetUserAccessSingleRequest
 */
export interface UserApiGetUserAccessSingleRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiGetUserAccessSingle
     */
    readonly clientUid?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiGetUserAccessSingle
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiGetUserAccessSingle
     */
    readonly languageUid?: string
}

/**
 * Request parameters for save operation in UserApi.
 * @export
 * @interface UserApiSaveRequest
 */
export interface UserApiSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiSave
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiSave
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiSave
     */
    readonly email?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiSave
     */
    readonly phone?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiSave
     */
    readonly cellPhone?: string
}

/**
 * Request parameters for unlockUser operation in UserApi.
 * @export
 * @interface UserApiUnlockUserRequest
 */
export interface UserApiUnlockUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUnlockUser
     */
    readonly uid: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUnlockUser
     */
    readonly password?: string
}

/**
 * Request parameters for updateUser operation in UserApi.
 * @export
 * @interface UserApiUpdateUserRequest
 */
export interface UserApiUpdateUserRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsUserUserViewModel}
     * @memberof UserApiUpdateUser
     */
    readonly APIInternalFrontendAPIModelsUserUserViewModel?: APIInternalFrontendAPIModelsUserUserViewModel
}

/**
 * Request parameters for userDELETE operation in UserApi.
 * @export
 * @interface UserApiUserDELETERequest
 */
export interface UserApiUserDELETERequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserDELETE
     */
    readonly uid: string
}

/**
 * Request parameters for userGET operation in UserApi.
 * @export
 * @interface UserApiUserGETRequest
 */
export interface UserApiUserGETRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserGET
     */
    readonly uid: string
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {UserApiChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changePassword(requestParameters: UserApiChangePasswordRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).changePassword(requestParameters.APIInternalFrontendAPIModelsUserChangePasswordRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public create(requestParameters: UserApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).create(requestParameters.APIInternalFrontendAPIModelsUserUserViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public generatePassword(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).generatePassword(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllByClientUidLoyalty(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAllByClientUidLoyalty(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiGetPagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getPaged(requestParameters: UserApiGetPagedRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getPaged(requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiGetUserAccessSingleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserAccessSingle(requestParameters: UserApiGetUserAccessSingleRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserAccessSingle(requestParameters.clientUid, requestParameters.uid, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiSaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public save(requestParameters: UserApiSaveRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).save(requestParameters.uid, requestParameters.name, requestParameters.email, requestParameters.phone, requestParameters.cellPhone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUnlockUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public unlockUser(requestParameters: UserApiUnlockUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).unlockUser(requestParameters.uid, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(requestParameters: UserApiUpdateUserRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(requestParameters.APIInternalFrontendAPIModelsUserUserViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDELETE(requestParameters: UserApiUserDELETERequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userDELETE(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGET(requestParameters: UserApiUserGETRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGET(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }
}
