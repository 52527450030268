import {Module} from "vuex";

interface ITransactionHistoryTableIndexState {
    savedSearchParams: object;
    transactionUids: string[];
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const transactionHistoryTableIndexState: Module<ITransactionHistoryTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null,
        transactionUids: [],
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        }, 
        SET_TRANSACTION_UIDS(state, args) {
            state.transactionUids = args;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        getTransactionUids(state) {
            return state.transactionUids;
        },
        Store(state, getters, rootState) { return getConfig("Stores.ColumnOptions.Store", rootState, true); },
        Unit(state, getters, rootState) { return getConfig("Stores.ColumnOptions.Unit", rootState, true); },
        ReceiptNumber(state, getters, rootState) { return getConfig("Stores.ColumnOptions.ReceiptNumber", rootState, true); },
        Timestamp(state, getters, rootState) { return getConfig("Stores.ColumnOptions.Timestamp", rootState, true); },
        Date(state, getters, rootState) { return getConfig("Stores.ColumnOptions.Date", rootState, true); },
        Type(state, getters, rootState) { return getConfig("Stores.ColumnOptions.Type", rootState, true); },
        PaidWith(state, getters, rootState) { return getConfig("Stores.ColumnOptions.PaidWith", rootState, true); },
        Amount(state, getters, rootState) { return getConfig("Stores.ColumnOptions.Amount", rootState, true); },
        Seller(state, getters, rootState) { return getConfig("Stores.ColumnOptions.Seller", rootState, true); },
        Customer(state, getters, rootState) { return getConfig("Stores.ColumnOptions.Customer", rootState, true); },
        AccountingID(state, getters, rootState) { return getConfig("Stores.ColumnOptions.AccountingID", rootState, true); },
        DayTotalNumber(state, getters, rootState) { return getConfig("Stores.ColumnOptions.DayTotalNumber", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Store: getters.Store,
                Unit: getters.Unit,
                ReceiptNumber: getters.ReceiptNumber,
                Timestamp: getters.Timestamp,
                Date: getters.Date,
                Type: getters.Type,
                PaidWith: getters.PaidWith,
                Amount: getters.Amount,
                Seller: getters.Seller,
                Customer: getters.Customer,
                AccountingID: getters.AccountingID,
                DayTotalNumber: getters.DayTotalNumber,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Store: true,
                Unit: true,
                ReceiptNumber: true,
                Timestamp: true,
                Date: true,
                Type: true,
                PaidWith: true,
                Amount: true,
                Seller: true,
                Customer: true,
                AccountingID: true,
                DayTotalNumber: true
            };
        }
    }
}

export default transactionHistoryTableIndexState;