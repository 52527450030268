import {Module} from "vuex";

interface IPosUserTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const posUserTableIndexState: Module<IPosUserTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        Initials(state, getters, rootState) { return getConfig("PosUser.ColumnOptions.Initials", rootState, true); },
        Name(state, getters, rootState) { return getConfig("PosUser.ColumnOptions.Name", rootState, true); },
        UserLevel(state, getters, rootState) { return getConfig("PosUser.ColumnOptions.UserLevel", rootState, true); },
        IsActive(state, getters, rootState) { return getConfig("PosUser.ColumnOptions.IsActive", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Initials: getters.Initials,
                Name: getters.Name,
                UserLevel: getters.UserLevel,
                IsActive: getters.IsActive,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Initials: true,
                Name: true,
                UserLevel: true,
                IsActive: true,
            };
        }
    }
}

export default posUserTableIndexState;