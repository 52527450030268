

























































import {v4 as newGuid} from "uuid";

export default {
	name: "fp-accordion-item",
	data() {
		return {
			parentID: null,
			collapsedLocal: this.collapsed,
		}
	},
	watch: {
		collapsedLocal(val) {
			this.$emit("input", val);
		},
		collapsed(val) {
			this.collapsedLocal = val;
		}
	},
	props: {
		title: {
			validator: function (value) {
				return typeof value === "string";
			}
		},
		idField: {
			default() {
				return newGuid();
			}
		},
		collapsed: {
			default: false,	
		},
		hasNoPadding: {
			default: false,
		},
		hasNoTopPadding: {
			default: false,
		},
		hasNoBottomPadding: {
			default: false,
		},
		noArrow: {
			default: false,
		},
	},
	mounted() {
		let item = this;
		this.parentID = item.$parent.$el.id;
	},
}
