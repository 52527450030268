import {Module} from "vuex";

const navigationGuardState: Module<any, any> = {
    namespaced: true,

    state: () => ({}),
    actions: {
    },
    mutations: {
    },
    getters: {
		getIsFrontpageAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Frontpage', true);
		},
		getIsCurrencyAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Currency', true);
		},
        getIsPosUsersAllowed(state, getters, rootState, rootGetters) {
            return rootGetters["getBackendConfigBool"]('UserAccess.PosUsers', true);
        },
		getIsUsersAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Users', true);
		},
		getIsModulesAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Modules', true);
		},
		getIsSettingsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings', true);
		},
		getIsFlexAdAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.FlexAd', true) && rootGetters.getActiveAddons('21103EB7-2E4E-44E3-8879-61A3D872F3B5', false);
		},
		getIsLanguageManagementAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]("UserAccess.LanguageManagementModule", true) && rootGetters["activeAddonsState/getIsResourceActive"];
		},
		getIsLanguageManagementAllowed2(state, getters, rootState, rootGetters) {
			return rootGetters.getIsFlexPosAdmin || rootGetters.getIsFlexPosReseller && rootGetters["getBackendConfigBool"]("UserAccess.LanguageManagementModule", true) && rootGetters["activeAddonsState/getIsResourceActive"];
		},
		/*#region ERPModules*/
		getIsEDAccountAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.EDAccount', true) && getters.hasERPModuleAccess;
		},
		getIsEconomicAccountAllowed(state, getters, rootState, rootGetters) {
			return rootGetters.getActiveAddons("EB7902BF-3D8E-406D-8860-11ACEA0179E1");
		},
		getIsServiceModuleAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.ServiceModule', true) && rootGetters.getActiveAddons("06F84AA6-99AF-4D55-167E-32D01F0E4F38", false);
		},
		getIsFlexGoAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.FlexGo', true) && rootGetters.getActiveAddons("C9B42966-90F7-4C85-28B0-0696B40C4967", false);
		},
		getIsEventOrdersModuleAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.EventOrders', true) && rootGetters.getActiveAddons("11C89CD0-C608-4B36-4575-CAA43F3A4A81", false);
		},
		getIsDineroAccountAllowed(state, getters, rootState, rootGetters) {
			return rootGetters.getActiveAddons("9CB2485F-2943-4A5F-A199-DBEAB2EC14F0");
		},
		getIsUnicontaAccountAllowed(state, getters, rootState, rootGetters) {
			return rootGetters.getActiveAddons("C6FB0C97-F205-4062-AB85-319FDCDD22D6");
		},
		getIsInventioAccountAllowed(state, getters, rootState, rootGetters) {
			return rootGetters.getActiveAddons("EC120F25-5BD2-407D-AAC3-EEC77195631E");
		},
		getIsVismaNetAccountAllowed(state, getters, rootState, rootGetters) {
			return rootGetters.getActiveAddons("59e39a38-1534-48ac-b1ba-61e07793d813");
		},
		getIsPlectoAccountAllowed(state, getters, rootState, rootGetters) {
			return rootGetters.getActiveAddons("3F4938D3-08A0-4E5B-AC0B-8216FC2E7085");
		},
		getIsDelfiBreeceAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.DelfiBreece', true) && rootGetters.getActiveAddons('B2BB7D77-4063-496C-91E2-66CC3EFC9DC8', false);
		},
		getIsNordiskeDatabaseAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.NordiskeDatabase', true) && rootGetters.getActiveAddons('39D59B64-F21B-4D47-BF37-6C3354B9CA86', false);
		},
		/*#endregion ERPModules*/
		getIsInterventionsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.Interventions', true) && rootGetters.getActiveAddons('D9A6D3C5-5A51-4E09-88A7-3B7DAF2A6330', false);
		},
		getIsSameSystemAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.SameSystem', true) && rootGetters.getActiveAddons('3657f1e9-df13-40f1-b830-9e610281c8d0', false);
		},
		getIsLoyaltyAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.Loyalty', true) && rootGetters.getActiveAddons('BF57A16A-0AC0-4794-B36E-05643C3AE2BC', false);
		},
		getIsCustomerStatisticsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.CustomerStatistics', true) && rootGetters.getActiveAddons('D9644011-D747-49D0-9674-9918B5AD9B4C', false);
		},
		getIsCardFeeAnalyticsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters.getIsFlexPosAdmin || rootGetters.getIsAmeroAdmin;
		},
		
		getIsFlexTicketConfigAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.FlexTicketConfig', true) && rootGetters.getActiveAddons('29BE91A6-89E0-463F-8A54-07EA06939743', false);
		},
		getIsFlexAdAdvancedAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.FlexAdConfig', true) && rootGetters.getActiveAddons('21103EB7-2E4E-44E3-8879-61A3D872F3B5', false);
		},
		getIsMobilePayAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.MobilePay', true) && rootGetters.getActiveAddons('72D413C4-AAA7-4938-AEAA-0E3554F8C5FB', false);
		},
		getIsCardGroupAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.CardGroup', true);
		},
		getIsSyncJobAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.SyncJob', true);
		},
		getIsImportAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.ProductImport', true) && rootGetters.getActiveAddons('B22BC0FC-0028-4C68-A52E-0010C398A7A9', false);
		},
		getIsPaymentTypeAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.PaymentType', true);
		},
		getIsDesignAndLayoutAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.ButtonFunctions', true);
		},
		getIsBonLayoutAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.BonLayout', true);
		},
		getIsExchangeLabelsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.ExchangeLabels', true);
		},
		getIsFlexVoucherAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.FlexVoucher', true) && rootGetters.getActiveAddons('52D9E242-924A-4AC9-A277-F3C4BC88128B', false);
		},
		getIsFlexPosConfigAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.FlexPosConfig', true);
		},
		getIsFlexPosConfigDynamicAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Settings.FlexPosConfig', true) && (rootGetters.getIsFlexPosAdmin || rootGetters.getIsFlexPosReseller);
		},
		getIsProductsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Products', true);
		},
		getIsCreateProductAllowed(state, getters, rootState, rootGetters) {
			let allowed = false;
			if (!rootGetters.getDataFromParent) allowed = true;
			else if (rootGetters.getDataFromParent && rootGetters["productConfigState/getShowCreateProductOnChildClient"]) allowed = true;

			return allowed;
		},
		getIsProductDraftAccessAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.ProductDraftAccess', true) && rootGetters.getActiveAddons('E6AB2DD9-21E9-4C3F-A9B3-6509372FBE15', false);
		},
		getIsProductListsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Products', true) && rootGetters.getActiveAddons('D3B0A40F-8C18-4146-B4BF-FD8DFAC28AD0', false);
		},
		getIsWarrantyProfilesAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.WarrantyProfiles', true) && rootGetters.getActiveAddons('8552BB2B-6210-4548-9343-0D61E3C24269', false) && rootGetters.getActiveAddons('8E818B29-74D9-4DC8-A13B-B0F99739A1D5', false);
		},
		getIsStockAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Stock', true) && rootGetters.getActiveAddons('8E818B29-74D9-4DC8-A13B-B0F99739A1D5', false);
		},
		getIsDisplayGroupsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.DisplayGroups', true);
		},
		getIsFinanceGroupsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.FinanceGroups', true);
		},
		getIsBrandsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Brands', true);
		},
		getIsCustomersAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Customers', true);
		},
		getIsVendorModuleAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.VendorModule', true) && rootGetters.getActiveAddons('1205C5D0-A88A-4549-BA54-E761938DC03B', false);
		},
		getIsVendorOrdersEnabledAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('VendorOrdersEnabled', true) && rootGetters["getBackendConfigBool"]('UserAccess.VendorModule', true) && rootGetters.getActiveAddons('1205C5D0-A88A-4549-BA54-E761938DC03B', false);
		},
		getIsReportsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Reports', true);
		},
		getIsTransactionHistoryAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.TransactionHistory', true);
		},
		getIsStatisticsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Statistics', true);
		},
		getIsStockModuleAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.StockModule', true) && rootGetters.getActiveAddons('42FCD9AC-2034-46A9-8438-3B5078AF23D1', false);
		},
		getIsFlexVoucherModuleAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.FlexVoucherModule', true) && rootGetters.getActiveAddons('52D9E242-924A-4AC9-A277-F3C4BC88128B', false);
		},
		getIsGiftcardsAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Giftcards', true);
		},
		getIsCashManagementAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.CashManagement', true) && rootGetters.getActiveAddons('4edf6d2a-df6d-41c2-f68d-16a1b3f18a99', false);
		},
		getIsCampaignAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.Campaign', true) && rootGetters.getActiveAddons('50AF6A06-02F3-4A79-9B03-B616FEA518F4', false);
		},
		getIsSAFTAllowed(state, getters, rootState, rootGetters) {
			return rootGetters.getActiveAddons('de4cbcc4-f070-43c6-efb4-f25e56275167', false);
		},
		getIsCashManagementAllowed(state, getters, rootState, rootGetters) {
			return  rootGetters["getBackendConfigBool"]('UserAccess.CashManagement', true) &&  rootGetters.getActiveAddons('4edf6d2a-df6d-41c2-f68d-16a1b3f18a99', false);
		},
		getIsSAFTAllowedSideMenu(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]("UserAccess.Settings.Saft", true);
		},
		getIsExternalPageAllowed(state, getters, rootState, rootGetters) {
			return rootGetters["getBackendConfigBool"]('UserAccess.ExternalPage', true);
		},
		hasERPModuleAccess(state, getters, rootState, rootGetters) {
			let returnThis = false;
			if (rootGetters.getActiveAddons("EB7902BF-3D8E-406D-8860-11ACEA0179E1")) {
				returnThis = true;
			} else if (rootGetters.getActiveAddons("9CB2485F-2943-4A5F-A199-DBEAB2EC14F0")) {
				returnThis = true;
			} else if (rootGetters.getActiveAddons("C6FB0C97-F205-4062-AB85-319FDCDD22D6")) {
				returnThis = true;
			} else if (rootGetters.getActiveAddons("EC120F25-5BD2-407D-AAC3-EEC77195631E")) {
				returnThis = true;
			} else if (rootGetters.getActiveAddons("59e39a38-1534-48ac-b1ba-61e07793d813")) {
				returnThis = true;
			} else if (rootGetters.getActiveAddons("3F4938D3-08A0-4E5B-AC0B-8216FC2E7085")) {
				returnThis = true;
			}

			return returnThis;
		},
    }
}

export default navigationGuardState;