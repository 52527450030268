<template>
	<div class="d-flex side-menu navbar-background flex-column noPrintThis"
		:class="sideMenu ? 'side-menu-in' : 'side-menu-out'"
		v-if="showMenu && !forceHideSideMenu">
		<nav class="d-flex flex-column flex-shrink-0 w-100">
			<router-link to="/Dashboard/Overview" class="pb-4 d-block text-center"
				v-if="getIsFrontpageAllowed">
				<div>
					<flexpos-logo class="flexpos-logo"
						:flex-color="isDarkTheme ? '#F5F5F5' : '#707070'" />
				</div>
				<div>
					<backoffice-logo class="backoffice-logo"
						:back-color="isDarkTheme ? '#F5F5F5' : '#707070'" />
				</div>
			</router-link>
			<div class="pb-4 d-block text-center" v-else>
				<div>
					<flexpos-logo class="flexpos-logo"
						:flex-color="isDarkTheme ? '#F5F5F5' : '#707070'" />
				</div>
				<div>
					<backoffice-logo class="backoffice-logo"
						:back-color="isDarkTheme ? '#F5F5F5' : '#707070'" />
				</div>
			</div>
			<router-link :title="Resource.FronPage" to="/Dashboard/Overview" class="menu-link"
				v-if="getIsFrontpageAllowed"
				:class="{'router-link-active': linkStartWith('/dashboard/overview/') }">
				<font-awesome-icon icon="fa-light fa-home-alt"
					class="menu-link-icon" />
				<span>
					{{ Resource.FronPage }}
				</span>
			</router-link>
			<!--#region Reseller-->
			<router-link :title="Resource.Stores" to="/ResellerModule/Reseller" class="menu-link"
				:class="{'router-link-active': linkStartWith('/ResellerModule/Reseller') }"
				v-if="IsResellerMenu && !IsSettingsMenu">
				<font-awesome-icon icon="fa-light fa-user-friends"
					class="menu-link-icon" />
				<span>
					{{ Resource.Stores }}
				</span>
			</router-link>
			<button :title="Resource.Administrator" class="btn btn-toggle collapsed menu-button"
				data-bs-toggle="collapse"
				data-bs-target="#admin-collapse" aria-expanded="false"
				v-if="IsNormalMenu && IsFlexPosMenu && isFlexPos || IsResellerMenu && !IsSettingsMenu && isReseller && getIsResourceActive">
				<font-awesome-icon icon="fa-light fa-tags"
					class="menu-link-icon" />
				<span>
					{{ Resource.Administrator }}
				</span>
				<font-awesome-icon class="closed" icon="fa-solid fa-chevron-down" />
				<font-awesome-icon class="open" icon="fa-solid fa-chevron-up" />
			</button>
			<div class="collapse" id="admin-collapse" ref="adminCollapse"
				v-if="IsNormalMenu && IsFlexPosMenu && isFlexPos || IsResellerMenu && !IsSettingsMenu && isReseller && getIsResourceActive">
				<ul class="btn-toggle-nav list-unstyled fw-normal pb-1 pt-2 small">
					<li v-if="IsFlexPosMenu && isFlexPos">
						<router-link :title="Resource.Enum" to="/Admin/Enum" class="menu-link"
							:class="{'router-link-active': linkStartWith('/Admin/Enum/') }">
							<font-awesome-icon icon="fa-light fa-list-ol"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.Enum }}
							</span>
						</router-link>
					</li>
					<li v-if="IsFlexPosMenu && isFlexPos">
						<router-link :title="Resource.Notifications" to="/Admin/Notifications" class="menu-link"
							:class="{'router-link-active': linkStartWith('/Admin/Notifications/') }">
							<font-awesome-icon icon="fa-light fa-envelope"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.Notifications }}
							</span>
						</router-link>
					</li>
					<li v-if="IsFlexPosMenu && isFlexPos || IsResellerMenu && !IsSettingsMenu && isReseller && getIsLanguageManagementAllowed">
						<router-link :title="Resource.LanguageManagement" to="/LanguageModule/Language"
							class="menu-link"
							:class="{'router-link-active': linkStartWith('/LanguageModule/Language/') }">
							<font-awesome-icon icon="fa-light fa-language"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.LanguageManagement }}
							</span>
						</router-link>
					</li>
					<li v-if="IsFlexPosMenu && isFlexPos">
						<router-link :title="Resource.FlexDeploy" to="/Admin/FlexDeploy" class="menu-link"
							:class="{'router-link-active': linkStartWith('/Admin/FlexDeploy/') }">
							<font-awesome-icon icon="fa-light fa-language"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.FlexDeploy }}
							</span>
						</router-link>
					</li>
				</ul>
			</div>

			<router-link :title="Resource.Billing" to="/ResellerModule/Billing" class="menu-link"
				:class="{'router-link-active': linkStartWith('/ResellerModule/Billing/') }"
				v-if="IsResellerMenu && !IsSettingsMenu">
				<font-awesome-icon icon="fa-light fa-money-bill-wave"
					class="menu-link-icon" />
				<span>
					{{ Resource.Billing }}
				</span>
			</router-link>

			<!--#endregion-->
			<!--#region Settings-->
			<template v-if="IsSettingsMenu && !IsResellerMenu || IsSettingsMenu && IsFlexPosMenu">
				<template v-if="getIsEDAccountAllowed">
					<router-link :title="Resource.EconomicAccount" to="/Settings/EDAccount" class="menu-link"
						:class="{'router-link-active': linkStartWith('/Settings/EDAccount/') }"
						v-if="getIsEconomicAccountAllowed">
						<font-awesome-icon icon="fa-light fa-chart-network"
							class="menu-link-icon" />
						<span>
							{{ Resource.EconomicAccount }}
						</span>
					</router-link>
					<router-link :title="Resource.DineroAccount" to="/Settings/EDAccount" class="menu-link"
						:class="{'router-link-active': linkStartWith('/Settings/EDAccount/') }"
						v-else-if="getIsDineroAccountAllowed">
						<font-awesome-icon icon="fa-light fa-chart-network"
							class="menu-link-icon" />
						<span>
							{{ Resource.DineroAccount }}
						</span>
					</router-link>
					<router-link :title="Resource.UnicontaAccount" to="/Settings/EDAccount" class="menu-link"
						:class="{'router-link-active': linkStartWith('/Settings/EDAccount/') }"
						v-else-if="getIsUnicontaAccountAllowed">
						<font-awesome-icon icon="fa-light fa-chart-network"
							class="menu-link-icon" />
						<span>
							{{ Resource.UnicontaAccount }}
						</span>
					</router-link>
					<router-link :title="Resource.InventioAccount" to="/Settings/EDAccount" class="menu-link"
						:class="{'router-link-active': linkStartWith('/Settings/EDAccount/') }"
						v-else-if="getIsInventioAccountAllowed">
						<font-awesome-icon icon="fa-light fa-chart-network"
							class="menu-link-icon" />
						<span>
							{{ Resource.InventioAccount }}
						</span>
					</router-link>
					<router-link :title="Resource.VismaNetAccount" to="/Settings/EDAccount" class="menu-link"
						:class="{'router-link-active': linkStartWith('/Settings/EDAccount/') }"
						v-else-if="getIsVismaNetAccountAllowed">
						<font-awesome-icon icon="fa-light fa-chart-network"
							class="menu-link-icon" />
						<span>
							{{ Resource.VismaNetAccount }}
						</span>
					</router-link>
					<router-link :title="Resource.Plecto" to="/Settings/Plecto" class="menu-link"
						:class="{'router-link-active': linkStartWith('/Settings/Plecto/') }"
						v-if="getIsPlectoAccountAllowed">
						<font-awesome-icon icon="fa-light fa-user-chart"
							class="menu-link-icon" />
						<span>
							{{ Resource.Plecto }}
						</span>
					</router-link>
				</template>

				<router-link :title="Resource.Breece" to="/Settings/Breece" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/Breece/') }"
					v-if="getIsDelfiBreeceAllowed">
					<font-awesome-icon icon="fa-light fa-tags"
						class="menu-link-icon" />
					<span>
						{{ Resource.Breece }}
					</span>
				</router-link>

				<router-link :title="Resource.Interventions" to="/settings/interventions" class="menu-link"
					:class="{'router-link-active': linkStartWith('/settings/interventions/') }"
					v-if="getIsInterventionsAllowed">
					<font-awesome-icon icon="fa-light fa-tags"
						class="menu-link-icon" />
					<span>
						{{ Resource.Interventions }}
					</span>
				</router-link>

				<router-link :title="Resource.NordiskeDatabase" to="/Settings/NordiskeDatabase" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/NordiskeDatabase/') }"
					v-if="getIsNordiskeDatabaseAllowed">
					<font-awesome-icon icon="fa-light fa-money-bill-wave"
						class="menu-link-icon" />
					<span>
						{{ Resource.NordiskeDatabase }}
					</span>
				</router-link>

				<router-link :title="Resource.SameSystem" to="/Settings/SameSystem" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/SameSystem/') }"
					v-if="getIsSameSystemAllowed">
					<font-awesome-icon icon="fa-light fa-cogs"
						class="menu-link-icon" />
					<span>
						{{ Resource.SameSystem }}
					</span>
				</router-link>

				<router-link :title="Resource.Loyalty" to="/Settings/Loyalty" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/Loyalty/') }"
					v-if="getIsLoyaltyAllowed">
					<font-awesome-icon icon="fa-light fa-hands-heart"
						class="menu-link-icon" />
					<span>
						{{ Resource.Loyalty }}
					</span>
				</router-link>
				<!--                <router-link :title="Resource.CardFeeAnalytics" to="/Settings/CardFeeAnalytics" class="menu-link"
											 :class="{'router-link-active': linkStartWith('/Settings/CardFeeAnalytics/') }"
											 v-if="!IsNormalMenu && IsSettingsMenu && getIsCardFeeAnalyticsAllowed">
									<font-awesome-icon icon="fa-light fa-credit-card"
													   class="menu-link-icon"/>
									<span>
										{{ Resource.CardFeeAnalytics }}
									</span>
								</router-link>-->
				<router-link :title="Resource.CustomerStatistics1" to="/Settings/CustomerStatistics"
					class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/CustomerStatistics/') }"
					v-if="getIsCustomerStatisticsAllowed">
					<font-awesome-icon icon="fa-solid fa-globe"
						class="menu-link-icon" />
					<span>
						{{ Resource.CustomerStatistics1 }}
					</span>
				</router-link>
				<router-link :title="Resource.FlexTicket" to="/FlexTicket/Type" class="menu-link"
					:class="{'router-link-active': linkStartWith('/FlexTicket/Type/') }"
					v-if="getIsFlexTicketConfigAllowed">
					<font-awesome-icon icon="fa-light fa-ticket-alt"
						class="menu-link-icon" />
					<span>
						{{ Resource.FlexTicket }}
					</span>
				</router-link>


				<router-link :title="Resource.FlexAd" to="/FlexAd/Config/" class="menu-link"
					:class="{'router-link-active': linkStartWith('/FlexAd/Config/') }"
					v-if="getIsFlexAdAdvancedAllowed">
					<font-awesome-icon icon="fa-light fa-tv"
						class="menu-link-icon" />
					<span>
						{{ Resource.FlexAd }}
					</span>
				</router-link>
				<router-link :title="Resource.MobilePay" to="/Settings/MobilePay" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/MobilePay/') }"
					v-if="getIsMobilePayAllowed">
					<font-awesome-icon icon="fa-light fa-mobile"
						class="menu-link-icon" />
					<span>
						{{ Resource.MobilePay }}
					</span>
				</router-link>
				<router-link :title="Resource.CardGroup" to="/Settings/CardGroup" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/CardGroup/') }"
					v-if="getIsCardGroupAllowed">
					<font-awesome-icon icon="fa-light fa-credit-card"
						class="menu-link-icon" />
					<span>
						{{ Resource.CardGroup }}
					</span>
				</router-link>
				<router-link :title="Resource.SyncJob" to="/Settings/SyncJob" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/SyncJob/') }"
					v-if="getIsSyncJobAllowed">
					<font-awesome-icon icon="fa-light fa-wrench"
						class="menu-link-icon" />
					<span>
						{{ Resource.SyncJob }}
					</span>
				</router-link>

				<button :title="Resource.Import" class="btn btn-toggle collapsed menu-button"
					data-bs-toggle="collapse"
					data-bs-target="#import-collapse" aria-expanded="false"
					v-if="getIsImportAllowed">
					<font-awesome-icon icon="fa-light fa-file-import" transform="left-2"
						class="menu-link-icon" />
					<span>
						{{ Resource.Import }}
						<span class="badge bg-orange">{{ Resource.New }}</span>
					</span>
					<font-awesome-icon class="closed" icon="fa-solid fa-chevron-down" />
					<font-awesome-icon class="open" icon="fa-solid fa-chevron-up" />
				</button>
				<div class="collapse" id="import-collapse" ref="importCollapse"
					v-if="getIsImportAllowed">
					<ul class="btn-toggle-nav list-unstyled fw-normal pb-1 pt-2 small">
						<li>
							<router-link :title="Resource.CustomerImport" to="/Settings/CustomerImport"
								class="menu-link"
								:class="{'router-link-active': linkStartWith('/Settings/CustomerImport/') }">
								<font-awesome-layers>
									<font-awesome-icon icon="fa-light fa-image-user" class="menu-link-icon" />
									<font-awesome-icon icon="fa-light fa-arrow-down-to-line" class="menu-link-icon"
										transform="shrink-7 up-10" />
								</font-awesome-layers>
								<span>
									{{ Resource.CustomerImport }}
									<span class="badge bg-orange">{{ Resource.New }}</span>
								</span>
							</router-link>
						</li>
						<li>
							<router-link :title="Resource.ProductImport" to="/Settings/ProductImport"
								class="menu-link"
								:class="{'router-link-active': linkStartWith('/Settings/ProductImport/') }">
								<font-awesome-layers>
									<font-awesome-icon icon="fa-light fa-box-archive" class="menu-link-icon"
										mask="" />
									<font-awesome-icon icon="fa-light fa-arrow-down-to-line" class="menu-link-icon"
										transform="shrink-7 up-10" />
								</font-awesome-layers>
								<span>
									{{ Resource.ProductImport }}
								</span>
							</router-link>
						</li>
					</ul>
				</div>

				<router-link :title="Resource.PaymentTypes" to="/Settings/PaymentType" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/PaymentType/') }"
					v-if="getIsPaymentTypeAllowed">
					<font-awesome-icon icon="fa-light fa-money-bill-wave"
						class="menu-link-icon" />
					<span>
						{{ Resource.PaymentTypes }}
						<span class="badge bg-orange">{{ Resource.New }}</span>
					</span>
				</router-link>
				<router-link :title="Resource.DesignAndLayout" to="/Settings/DesignAndLayout" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/DesignAndLayout/') }"
					v-if="getIsDesignAndLayoutAllowed">
					<font-awesome-icon icon="fa-light fa-keyboard"
						class="menu-link-icon" />
					<span>
						{{ Resource.DesignAndLayout }}
						<span class="badge bg-orange">{{ Resource.New }}</span>
					</span>
				</router-link>
				<router-link :title="Resource.DesignLayout" to="/Settings/BonLayout" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/BonLayout/') }"
					v-if="getIsBonLayoutAllowed">
					<font-awesome-icon icon="fa-light fa-print"
						class="menu-link-icon" />
					<span>
						{{ Resource.DesignLayout }}
					</span>
				</router-link>
				<router-link :title="Resource.ExchangeLabels" to="/Settings/ExchangeLabels" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/ExchangeLabels/') }"
					v-if="getIsExchangeLabelsAllowed">
					<font-awesome-icon icon="fa-light fa-edit"
						class="menu-link-icon" />
					<span>
						{{ Resource.ExchangeLabels }}
					</span>
				</router-link>
				<router-link :title="Resource.FlexVoucher" to="/Settings/FlexVoucher" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/FlexVoucher/') }"
					v-if="getIsFlexVoucherAllowed">
					<font-awesome-icon icon="fa-light fa-gift"
						class="menu-link-icon" />
					<span>
						{{ Resource.FlexVoucher + ' ' + Resource.Setup }}
					</span>
				</router-link>

				<router-link :title="Resource.SAFTReport" to="/Settings/SAF-T" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/SAF-T/') }"
					v-if="getIsSAFTAllowedSideMenu">
					<font-awesome-icon icon="fa-light fa-gear" class="menu-link-icon" />
					<span>
						{{ Resource.SAFTReport }}
					</span>
				</router-link>

				<router-link :title="Resource.Configuration + ' list'" to="/Settings/FlexPosConfig" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/FlexPosConfig/') }"
					v-if="getIsFlexPosConfigAllowed">
					<font-awesome-icon icon="fa-light fa-tools"
						class="menu-link-icon" />
					<span>
						{{ Resource.ConfigurationList }}
					</span>
				</router-link>
				<router-link :title="Resource.Configuration" to="/Settings/FlexPosConfigDynamic" class="menu-link"
					:class="{'router-link-active': linkStartWith('/Settings/FlexPosConfigDynamic/') }"
					v-if="getIsFlexPosConfigDynamicAllowed">
					<font-awesome-icon icon="fa-light fa-tools"
						class="menu-link-icon" />
					<span>
						{{ Resource.SystemSetup }}
						<span class="badge bg-primary">{{ Resource.Beta }}</span>
					</span>
				</router-link>
			</template>
			<!--#endregion-->
			<!--#region Normal-->
			<button :title="Resource.ProductCatalog" class="btn btn-toggle menu-button collapsed"
				data-bs-toggle="collapse"
				data-bs-target="#product-collapse" aria-expanded="false"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsProductsAllowed">
				<font-awesome-icon icon="fa-light fa-tags"
					class="menu-link-icon" />
				<span>
					{{ Resource.ProductCatalog }} 
				</span>
				<font-awesome-icon class="closed" icon="fa-solid fa-chevron-down" />
				<font-awesome-icon class="open" icon="fa-solid fa-chevron-up" />
			</button>
			<div class="collapse" id="product-collapse" ref="productCollapse"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsProductsAllowed">
				<ul class="btn-toggle-nav list-unstyled fw-normal pb-1 pt-2 small">
					<li v-if="getBackendConfigBool('UserAccess.Products', true)">
						<router-link :title="Resource.Products" :to="{name: 'product-index'}" class="menu-link"
							:class="{'router-link-active': linkStartWith('/dashboard/product/') }"
							@click.native="$store.dispatch('productTableIndexState/SET_SAVED_SEARCH_PARAMS', null)">
							<font-awesome-icon icon="fa-light fa-archive"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.Products }}
							</span>
						</router-link>
					</li>
					<li v-if="getIsProductDraftAccessAllowed">
						<router-link :title="Resource.ProductDrafts" to="/Dashboard/ProductDraft/IndexWithClear"
							class="menu-link"
							:class="{'router-link-active': linkStartWith('/Dashboard/ProductDraft/') }">
							<font-awesome-icon icon="fa-light fa-pencil-ruler"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.ProductDrafts }}
							</span>
						</router-link>
					</li>
					<li v-if="getIsProductListsAllowed">
						<router-link :title="Resource.VpProductList" to="/Dashboard/ProductList" class="menu-link"
							:class="{'router-link-active': linkStartWith('/dashboard/productlist/') }">
							<font-awesome-icon icon="fa-light fa-list"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.VpProductList }}
							</span>
						</router-link>
					</li>
					<li v-if="getIsWarrantyProfilesAllowed">
						<router-link :title="Resource.WarrantyProfiles" to="/Dashboard/Warranty" class="menu-link"
							:class="{'router-link-active': linkStartWith('/dashboard/warranty/') }">
							<font-awesome-icon icon="fa-light fa-cogs"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.WarrantyProfiles }}
							</span>
						</router-link>
					</li>
					<li v-if="getIsStockAllowed">
						<router-link :title="Resource.Stock" :to="{name: 'product-stock-index'}" class="menu-link"
							:class="{'router-link-active': linkStartWith('/dashboard/productstock/') }"
							@click.native="$store.dispatch('productStockTableIndexState/SET_SAVED_SEARCH_PARAMS', null)">
							<font-awesome-icon icon="fa-light fa-cubes"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.Stock }}
							</span>
						</router-link>
					</li>
					<li v-if="getIsDisplayGroupsAllowed">
						<router-link :title="Resource.DisplayGroups" to="/Dashboard/DisplayProductGroups"
							class="menu-link"
							:class="{'router-link-active': linkStartWith('/dashboard/DisplayProductGroups/') }">
							<font-awesome-icon icon="fa-light fa-layer-group"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.DisplayGroups }}
							</span>
						</router-link>
					</li>
					<li v-if="getIsFinanceGroupsAllowed">
						<router-link :title="Resource.FinanceGroups" to="/Dashboard/FinancialProductGroups"
							class="menu-link"
							:class="{'router-link-active': linkStartWith('/dashboard/FinancialProductGroups/') }">
							<font-awesome-icon icon="fa-light fa-layer-group"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.FinanceGroups }}
							</span>
						</router-link>
					</li>
					<li v-if="getIsBrandsAllowed">
						<router-link :title="Resource.Brands" to="/Dashboard/Brand" class="menu-link"
							:class="{'router-link-active': linkStartWith('/dashboard/Brand/') }">
							<font-awesome-icon icon="fa-light fa-copyright"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.Brands }}
							</span>
						</router-link>
					</li>
				</ul>
			</div>

			<button :title="Resource.Customers" class="btn btn-toggle collapsed menu-button"
				data-bs-toggle="collapse"
				data-bs-target="#customer-collapse" aria-expanded="false"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsCustomersAllowed">
				<font-awesome-icon icon="fa-light fa-user-friends"
					class="menu-link-icon" />
				<span>
					{{ Resource.Customers }}
				</span>
				<font-awesome-icon class="closed" icon="fa-solid fa-chevron-down" />
				<font-awesome-icon class="open" icon="fa-solid fa-chevron-up" />
			</button>
			<div class="collapse" id="customer-collapse" ref="customerCollapse"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsCustomersAllowed">
				<ul class="btn-toggle-nav list-unstyled fw-normal pb-1 pt-2 small">
					<li>
						<router-link :title="Resource.Customers" to="/dashboard/customer" class="menu-link"
							:class="{'router-link-active': linkStartWith('/dashboard/customer/') }"
							@click="$store.dispatch('customerTableIndexState/SET_SAVED_SEARCH_PARAMS', null)">
							<font-awesome-icon icon="fa-light fa-user-friends"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.Customers }}
							</span>
						</router-link>
					</li>
					<li>
						<router-link :title="Resource.CustomerGroups" to="/dashboard/customerGroups"
							class="menu-link"
							:class="{'router-link-active': linkStartWith('/dashboard/customerGroups/') }">
							<font-awesome-icon icon="fa-light fa-users"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.CustomerGroups }}
							</span>
						</router-link>
					</li>
				</ul>
			</div>

			<button :title="Resource.Logistics" class="btn btn-toggle collapsed menu-button"
				data-bs-toggle="collapse"
				data-bs-target="#logistics-collapse" aria-expanded="false"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsVendorModuleAllowed">
				<font-awesome-icon icon="fa-light fa-truck"
					class="menu-link-icon" />
				<span>
					{{ Resource.Logistics }}
				</span>
				<span v-if="vendorPurchaseUnreadCount + vendorDispatchUnreadCount > 0"
					class="badge rounded-pill bg-orange px-2">
					{{ vendorPurchaseUnreadCount + vendorDispatchUnreadCount }}
				</span>
				<font-awesome-icon class="closed" icon="fa-solid fa-chevron-down" />
				<font-awesome-icon class="open" icon="fa-solid fa-chevron-up" />
			</button>
			<div class="collapse" id="logistics-collapse" ref="logisticsCollapse"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsVendorModuleAllowed">
				<ul class="btn-toggle-nav list-unstyled fw-normal pb-1 pt-2 small">
					<li>
						<router-link :title="Resource.Supplier_Titel" to="/VendorModule/Vendor" class="menu-link"
							:class="{'router-link-active': linkStartWith('/VendorModule/Vendor/') }"
							@click="$store.dispatch('customerTableIndexState/SET_SAVED_SEARCH_PARAMS', null)">
							<font-awesome-icon icon="fa-light fa-parachute-box"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.Supplier_Titel }}
							</span>
						</router-link>
					</li>
					<li>
						<router-link :title="Resource.PurchaseOrder" to="/VendorModule/GoodsReception"
							class="menu-link"
							:class="{'router-link-active': linkStartWith('/VendorModule/GoodsReception/') }">
							<font-awesome-icon icon="fa-light fa-person-dolly"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.PurchaseOrder }}
							</span>
							<span v-if="vendorPurchaseUnreadCount > 0" class="badge rounded-pill bg-orange px-2">
								{{ vendorPurchaseUnreadCount }}
							</span>
						</router-link>
					</li>
					<li v-if="getIsVendorOrdersEnabledAllowed">
						<router-link :title="Resource.Orders + '\n' + Resource.DIspatches" to="/VendorModule/Orders"
							class="menu-link"
							:class="{'router-link-active': linkStartWith('/VendorModule/Orders/') }">
							<font-awesome-icon icon="fa-light fa-people-carry"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.Orders }} / {{ Resource.DIspatches }}
							</span>
							<span v-if="vendorDispatchUnreadCount > 0" class="badge rounded-pill bg-orange px-2">
								{{ vendorDispatchUnreadCount }}
							</span>
						</router-link>
					</li>
				</ul>
			</div>

			<router-link :title="Resource.Reports" to="/Dashboard/Reports" class="menu-link"
				:class="{'router-link-active': linkStartWith('/Dashboard/Reports/') }"
				v-if="IsNormalMenu && !IsSettingsMenu && getIsReportsAllowed || IsResellerMenu && !IsDegraded && !IsSettingsMenu && getIsReportsAllowed">
				<font-awesome-icon icon="fa-light fa-file-chart-line"
					class="menu-link-icon" />
				<span>
					{{ Resource.Reports }}
				</span>
			</router-link>

			<router-link :title="Resource.TransactionHistory" to="/Dashboard/TransactionHistory" class="menu-link"
				:class="{'router-link-active': linkStartWith('/Dashboard/TransactionHistory/') }"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsTransactionHistoryAllowed">
				<font-awesome-icon icon="fa-light fa-receipt"
					class="menu-link-icon" />
				<span>
					{{ Resource.TransactionHistory }}
				</span>
			</router-link>
			<router-link :title="Resource.StatisticsAndMore" to="/Dashboard/Statistics" class="menu-link"
				:class="{'router-link-active': linkStartWith('/Dashboard/Statistics/') }"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsStatisticsAllowed">
				<font-awesome-icon icon="fa-light fa-chart-pie"
					class="menu-link-icon" />
				<span>
					{{ Resource.StatisticsAndMore }}
				</span>
			</router-link>
			<router-link :title="Resource.Status" :to="{name: 'status-module-index'}" class="menu-link"
				:class="{'router-link-active': linkStartWith('/status-module') }"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsStockModuleAllowed">
				<font-awesome-icon icon="fa-light fa-circle-check" class="menu-link-icon" />
				<span>
					{{ Resource.Status }}
				</span>
			</router-link>

			<button :title="Resource.FlexVoucher" class="btn btn-toggle collapsed menu-button"
				data-bs-toggle="collapse"
				data-bs-target="#flex-voucher-collapse" aria-expanded="false"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsFlexVoucherModuleAllowed">
				<font-awesome-icon icon="fa-light fa-truck" class="menu-link-icon" />
				<span>
					{{ Resource.FlexVoucher }}
				</span>
				<font-awesome-icon class="closed" icon="fa-solid fa-chevron-down" />
				<font-awesome-icon class="open" icon="fa-solid fa-chevron-up" />
			</button>
			<div class="collapse" id="flex-voucher-collapse" ref="flexVoucherCollapse"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsFlexVoucherModuleAllowed">
				<ul class="btn-toggle-nav list-unstyled fw-normal pb-1 pt-2 small">
					<li>
						<router-link :title="Resource.FlexVoucherTotalsOverview" :to="{name: 'flex-voucher-totals'}"
							class="menu-link"
							:class="{'router-link-active': linkStartWith('/flex-voucher/totals-overview') }">
							<font-awesome-icon icon="fa-light fa-parachute-box"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.FlexVoucherTotalsOverview }}
							</span>
						</router-link>
					</li>
					<li>
						<router-link :title="Resource.FlexVoucherTotalsPerMonth"
							:to="{name: 'flex-voucher-totals-per-month'}" class="menu-link"
							:class="{'router-link-active': linkStartWith('/flex-voucher/totals-per-month') }">
							<font-awesome-icon icon="fa-light fa-parachute-box"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.FlexVoucherTotalsPerMonth }}
							</span>
						</router-link>
					</li>
					<li>
						<router-link :title="Resource.TransactionHistory"
							:to="{name: 'flex-voucher-transaction-history'}" class="menu-link"
							:class="{'router-link-active': linkStartWith('/flex-voucher/transaction-history') || linkContainsRegex('/flex-voucher/(.*)\?(.*)transaction-history') }">
							<font-awesome-icon icon="fa-light fa-receipt"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.TransactionHistory }}
							</span>
						</router-link>
					</li>
					<li v-if="IsFlexVoucherOwner">
						<router-link :title="Resource.FlexBatch" :to="{name: 'flex-voucher-batch'}"
							class="menu-link"
							:class="{'router-link-active': linkStartWith('/flex-voucher/batch') || linkContainsRegex('/flex-voucher/(.*)batch') }">
							<font-awesome-icon icon="fa-light fa-receipt"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.FlexBatch }}
							</span>
						</router-link>
					</li>
					<li>
						<router-link :title="Resource.FlexVoucher" :to="{name: 'flex-voucher-overview'}"
							class="menu-link"
							:class="{'router-link-active': linkStartWith('/flex-voucher/voucher-overview') || linkContainsRegex('/flex-voucher/(.*)\?(.*)overview') }">
							<font-awesome-icon icon="fa-light fa-receipt"
								class="menu-link-icon menu-link-icon-under" />
							<span>
								{{ Resource.FlexVoucher }}
							</span>
						</router-link>
					</li>
				</ul>
			</div>

			<router-link :title="Resource.GiftCard" to="/GiftCard/GiftCard" class="menu-link"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsGiftcardsAllowed">
				<font-awesome-icon icon="fa-light fa-gift" class="menu-link-icon" />
				<span>
					{{ Resource.GiftCard }}
				</span>
			</router-link>
			
			<router-link :title="Resource.CashManagement" to="/Dashboard/CashManagement" class="menu-link"
						 v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsCashManagementAllowed">
				<font-awesome-icon icon="fa-light fa-sack-dollar" class="menu-link-icon" />
				<span>
					{{ Resource.CashManagement }}
				</span>
			</router-link>
			
			<router-link :title="Resource.Campaigns" to="/CampaignModule/Campaign" class="menu-link"
				:class="{'router-link-active': linkStartWith('/CampaignModule/Campaign/') }"
				v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && getIsCampaignAllowed">
				<font-awesome-icon icon="fa-light fa-badge-percent"
					class="menu-link-icon" />
				<span>
					{{ Resource.Campaigns }}
				</span>
			</router-link>

			<template v-if="isExternalPagesActive || (getIsServiceModuleAllowed || getIsEventOrdersModuleAllowed || isFlexGoAllowed)">
				<button :title="Resource.ExternalPage" class="btn btn-toggle collapsed menu-button"
					data-bs-toggle="collapse"
					data-bs-target="#external-page-collapse" aria-expanded="false"
					v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && IsExternalPageActive && getIsExternalPageAllowed || IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && (getIsServiceModuleAllowed || getIsEventOrdersModuleAllowed || isFlexGoAllowed)">
					<font-awesome-icon icon="fa-light fa-file-export" transform="left-2"
						class="menu-link-icon" />
					<span>
						{{ getFlexPosConfigString('ExternalSiteDropdownMenuName', Resource.ExternalPages) }}
					</span>
					<font-awesome-icon class="closed" icon="fa-solid fa-chevron-down" />
					<font-awesome-icon class="open" icon="fa-solid fa-chevron-up" />
				</button>
				<div class="collapse" id="external-page-collapse" ref="externalPagesCollapse"
					v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && IsExternalPageActive && getIsExternalPageAllowed 
						|| IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu && (getIsEventOrdersModuleAllowed || getIsServiceModuleAllowed || isFlexGoAllowed)">
					<ul class="btn-toggle-nav list-unstyled fw-normal pb-1 pt-2 small">
						<li v-if="getIsEventOrdersModuleAllowed">
							<router-link :title="Resource.Config_Feature_EventOrdersModule"
								class="menu-link"
								:to="{name: 'external-page', params: {id: 'EventOrdersModule'}}"
								:class="{'router-link-active': linkStartIs('/Dashboard/ExternalPage/EventOrdersModule') }"
								v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu">
								<font-awesome-icon icon="fa-light fa-cogs" class="menu-link-icon" />
								<span>
									{{ Resource.Config_Feature_EventOrdersModule }}
								</span>
							</router-link>
						</li>
						<li v-if="getIsServiceModuleAllowed">
							<router-link :title="Resource.Config_Feature_ServiceModule"
								class="menu-link"
								:to="{name: 'external-page', params: {id: 'ServiceModule'}}"
								:class="{'router-link-active': linkStartIs('/Dashboard/ExternalPage/ServiceModule') }"
								v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu">
								<font-awesome-icon icon="fa-light fa-cogs" class="menu-link-icon" />
								<span>
									{{ Resource.Config_Feature_ServiceModule }}
								</span>
							</router-link>
						</li>
						<li v-if="isFlexGoAllowed">
							<router-link :title="Resource.FlexGo"
								class="menu-link"
								:to="{name: 'external-page', params: {id: 'FlexGo'}}"
								:class="{'router-link-active': linkStartIs('/Dashboard/ExternalPage/FlexGo') }"
								v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu">
								<font-awesome-icon icon="fa-light fa-cogs" class="menu-link-icon" />
								<span>
									{{ Resource.FlexGo }}
								</span>
							</router-link>
						</li>
						<template v-for="externalPage in externalPages">
							<li v-if="externalPage.active">
								<router-link :title="getFlexPosConfigString(externalPage.text, '')"
									class="menu-link"
									:to="{name: 'external-page', params: {id: externalPage.url}}"
									:class="{'router-link-active': linkStartIs('/Dashboard/ExternalPage/' + externalPage.url) }"
									v-if="IsNormalMenu && !IsResellerMenu && !IsFlexPosMenu">
									<font-awesome-icon icon="fa-light fa-cogs" class="menu-link-icon" />
									<span>
										{{ getFlexPosConfigString(externalPage.text, Resource.ExternalPage) }}
									</span>
								</router-link>
							</li>
						</template>
					</ul>
				</div>
			</template>

			<!--#endregion-->
			<div class="d-flex version-number">
				<p>
					v{{ appVersion }}
				</p>
				<button type="button" @click="changeSideMenu" class="float-end">
					<font-awesome-icon v-if="sideMenu" icon="fa-solid fa-chevron-right" />
					<font-awesome-icon v-else icon="fa-solid fa-chevron-left" />
				</button>
			</div>
		</nav>
	</div>
</template>

<script>
import FlexposLogo from "../flexpos-logo.vue";
import BackofficeLogo from "../backoffice-logo.vue";
import {NotificationApi} from "@services/Autogenerated_v4/V1/api/notification-api";
import {mapActions, mapGetters} from "vuex";

export default {
	name: "side-menu",
	components: {
		BackofficeLogo,
		FlexposLogo
	},
	data() {
		return {
			processedLinks: {},
			processedRoutes: {},
			routeMeta: {},
			externalPages: [],

			vendorPurchaseUnreadCount: 0,
			vendorDispatchUnreadCount: 0,

			NotificationService: new NotificationApi(this.$swaggerAxiosOptions),
		};
	},
	computed: {
		sideMenuState: {
			get() {
				return this.sideMenu;
			},
			set(val) {
				this.setSideMenu(val);
			}
		},
		IsNormalMenu() {
			return this.routeMeta && !this.routeMeta.sideMenuType || this.routeMeta.sideMenuType === "main-menu";
		},
		IsSettingsMenu() {
			return this.routeMeta && this.routeMeta.sideMenuType === "settings";
		},
		IsResellerMenu() {
			return (this.routeMeta && this.routeMeta.sideMenuType === "reseller") || this.isReseller;
		},
		IsFlexPosMenu() {
			return (this.routeMeta && this.routeMeta.sideMenuType === "flexpos") || this.isFlexPos;
		},
		IsExternalPageActive() {
			return this.$store.state.isExternalPageActive;
		},
		IsFlexVoucherOwner() {
			return this.$store.state.isFlexVoucherOwner;
		},
		IsDegraded() {
			return this.$store.state.isDegraded;
		},
		showMenu() {
			return this.$store.state.isLoggedIn;
		},
		forceHideSideMenu() {
			let value = this.$route.fullPath;
			if (value.indexOf("Account/ChangePassword") > -1) {
				return true;
			}
			return this.$route.name === "flex-voucher-download-file" || this.$route.name === "economic-app" || this.$route.name === "economic-app-login";
		},
		isExternalPagesActive() {
			for (let i = 0; i < this.externalPages.length; i++) {
				if (this.externalPages[i].active) return true;
			}

			return false;
		},
		...mapGetters({
			sideMenu: "getSideMenu",
			isLoggedIn: "getIsLoggedIn",
			appVersion: "getAppVersion",
			isDarkTheme: "getIsDarkTheme",
			isReseller: "getIsReseller",
			isFlexPos: "getIsFlexPos",
			getIsFlexPosAdmin: "getIsFlexPosAdmin",
			getBackendConfigBool: "getBackendConfigBool",
			getFlexPosConfigBool: "getFlexPosConfigBool",
			getFlexPosConfigString: "getFlexPosConfigString",
			getActiveAddons: "getActiveAddons",
			getIsResourceActive: "activeAddonsState/getIsResourceActive",
			getIsFrontpageAllowed: "navigationGuardState/getIsFrontpageAllowed",
			getIsLanguageManagementAllowed: "navigationGuardState/getIsLanguageManagementAllowed",
			getIsEDAccountAllowed: "navigationGuardState/getIsEDAccountAllowed",
			getIsEconomicAccountAllowed: "navigationGuardState/getIsEconomicAccountAllowed",
			getIsServiceModuleAllowed: "navigationGuardState/getIsServiceModuleAllowed",
			isFlexGoAllowed: "navigationGuardState/getIsFlexGoAllowed",
			getIsEventOrdersModuleAllowed: "navigationGuardState/getIsEventOrdersModuleAllowed",
			getIsDineroAccountAllowed: "navigationGuardState/getIsDineroAccountAllowed",
			getIsUnicontaAccountAllowed: "navigationGuardState/getIsUnicontaAccountAllowed",
			getIsInventioAccountAllowed: "navigationGuardState/getIsInventioAccountAllowed",
			getIsVismaNetAccountAllowed: "navigationGuardState/getIsVismaNetAccountAllowed",
			getIsPlectoAccountAllowed: "navigationGuardState/getIsPlectoAccountAllowed",
			getIsCardFeeAnalyticsAllowed: "navigationGuardState/getIsCardFeeAnalyticsAllowed",
			getIsDelfiBreeceAllowed: "navigationGuardState/getIsDelfiBreeceAllowed",
			getIsInterventionsAllowed: "navigationGuardState/getIsInterventionsAllowed",
			getIsNordiskeDatabaseAllowed: "navigationGuardState/getIsNordiskeDatabaseAllowed",
			getIsSameSystemAllowed: "navigationGuardState/getIsSameSystemAllowed",
			getIsLoyaltyAllowed: "navigationGuardState/getIsLoyaltyAllowed",
			getIsCustomerStatisticsAllowed: "navigationGuardState/getIsCustomerStatisticsAllowed",
			getIsFlexTicketConfigAllowed: "navigationGuardState/getIsFlexTicketConfigAllowed",
			getIsFlexAdAdvancedAllowed: "navigationGuardState/getIsFlexAdAdvancedAllowed",
			getIsMobilePayAllowed: "navigationGuardState/getIsMobilePayAllowed",
			getIsCardGroupAllowed: "navigationGuardState/getIsCardGroupAllowed",
			getIsSyncJobAllowed: "navigationGuardState/getIsSyncJobAllowed",
			getIsImportAllowed: "navigationGuardState/getIsImportAllowed",
			getIsPaymentTypeAllowed: "navigationGuardState/getIsPaymentTypeAllowed",
			getIsDesignAndLayoutAllowed: "navigationGuardState/getIsDesignAndLayoutAllowed",
			getIsBonLayoutAllowed: "navigationGuardState/getIsBonLayoutAllowed",
			getIsExchangeLabelsAllowed: "navigationGuardState/getIsExchangeLabelsAllowed",
			getIsFlexVoucherAllowed: "navigationGuardState/getIsFlexVoucherAllowed",
			getIsFlexPosConfigAllowed: "navigationGuardState/getIsFlexPosConfigAllowed",
			getIsFlexPosConfigDynamicAllowed: "navigationGuardState/getIsFlexPosConfigDynamicAllowed",
			getIsProductsAllowed: "navigationGuardState/getIsProductsAllowed",
			getIsProductListsAllowed: "navigationGuardState/getIsProductListsAllowed",
			getIsProductDraftAccessAllowed: "navigationGuardState/getIsProductDraftAccessAllowed",
			getIsWarrantyProfilesAllowed: "navigationGuardState/getIsWarrantyProfilesAllowed",
			getIsStockAllowed: "navigationGuardState/getIsStockAllowed",
			getIsDisplayGroupsAllowed: "navigationGuardState/getIsDisplayGroupsAllowed",
			getIsFinanceGroupsAllowed: "navigationGuardState/getIsFinanceGroupsAllowed",
			getIsBrandsAllowed: "navigationGuardState/getIsBrandsAllowed",
			getIsCustomersAllowed: "navigationGuardState/getIsCustomersAllowed",
			getIsVendorModuleAllowed: "navigationGuardState/getIsVendorModuleAllowed",
			getIsVendorOrdersEnabledAllowed: "navigationGuardState/getIsVendorOrdersEnabledAllowed",
			getIsReportsAllowed: "navigationGuardState/getIsReportsAllowed",
			getIsTransactionHistoryAllowed: "navigationGuardState/getIsTransactionHistoryAllowed",
			getIsStatisticsAllowed: "navigationGuardState/getIsStatisticsAllowed",
			getIsStockModuleAllowed: "navigationGuardState/getIsStockModuleAllowed",
			getIsFlexVoucherModuleAllowed: "navigationGuardState/getIsFlexVoucherModuleAllowed",
			getIsGiftcardsAllowed: "navigationGuardState/getIsGiftcardsAllowed",
			getIsCashManagementAllowed: "navigationGuardState/getIsCashManagementAllowed",
			getIsCampaignAllowed: "navigationGuardState/getIsCampaignAllowed",
			getIsSAFTAllowedSideMenu: "navigationGuardState/getIsSAFTAllowedSideMenu",
			getIsExternalPageAllowed: "navigationGuardState/getIsExternalPageAllowed"
		})
	},
	watch: {
		$route: {
			immediate: true,
			handler(to) {
				let value = to.fullPath;
				if (value.indexOf("dashboard/product") > -1
					|| value.indexOf("dashboard/warranty") > -1
					|| value.indexOf("dashboard/displayproductgroups") > -1
					|| value.indexOf("dashboard/financialproductgroups") > -1
					|| value.indexOf("dashboard/brand") > -1) {
					this.$nextTick(() => {
						if (this.$refs.productCollapse && !this.$refs.productCollapse.classList.contains("show"))
							this.$refs.productCollapse.classList.add("show");
					});
				} else if (value.indexOf("VendorModule") > -1) {
					this.$nextTick(() => {
						if (this.$refs.logisticsCollapse && !this.$refs.logisticsCollapse.classList.contains("show"))
							this.$refs.logisticsCollapse.classList.add("show");
					});
				} else if (value.indexOf("flex-voucher") > -1) {
					this.$nextTick(() => {
						if (this.$refs.flexVoucherCollapse && !this.$refs.flexVoucherCollapse.classList.contains("show"))
							this.$refs.flexVoucherCollapse.classList.add("show");
					});
				} else if (value.indexOf("Settings/CustomerImport") > -1
					|| value.indexOf("Settings/ProductImport") > -1) {
					this.$nextTick(() => {
						if (this.$refs.importCollapse && !this.$refs.importCollapse.classList.contains("show"))
							this.$refs.importCollapse.classList.add("show");
					});
				} else if (value.indexOf("Settings/ExternalPage") > -1) {
					this.$nextTick(() => {
						if (this.$refs.externalPagesCollapse && !this.$refs.externalPagesCollapse.classList.contains("show"))
							this.$refs.externalPagesCollapse.classList.add("show");
					});
				}


				this.routeMeta = to.meta;

				this.$nextTick(() => {
					this.getNotifications();
				});
			}
		}
	},
	methods: {
		...mapActions({setSideMenu: "changeSideMenu"}),
		getExternalPages() {
			if (!this.getFlexPosConfigBool("ExternalSiteButtonIsActive", false)) this.externalPages = [];
			else {
				this.externalPages = [
					{
						text: "ExternalSiteButtonText",
						url: "ExternalSiteButtonUrl",
						active: "ExternalSiteButtonIsActive",
					}
				];
			}

			for (let i = 1; i < 10; i++) {
				if (!this.getFlexPosConfigBool("ExternalSiteButtonIsActive_" + i, false)) continue;
				this.externalPages.push(
					{
						text: "ExternalSiteButtonText_" + i,
						url: "ExternalSiteButtonUrl_" + i,
						active: "ExternalSiteButtonIsActive_" + i,
					}
				);
			}
		},
		async getNotifications() {
			let logisticsModuleAccess = this.getActiveAddons('1205C5D0-A88A-4549-BA54-E761938DC03B', false);
			if (this.IsNormalMenu && !this.IsResellerMenu && !this.IsFlexPosMenu && this.getBackendConfigBool('UserAccess.VendorModule', true) && logisticsModuleAccess) {
				let notification = (await this.NotificationService.getDots({
					showVendor: logisticsModuleAccess,
				})).data;

				if (notification.PurchaseOrders > 0 || notification.SalesOrders > 0) {
					this.vendorPurchaseUnreadCount = notification.PurchaseOrders;
					this.vendorDispatchUnreadCount = notification.SalesOrders;
				}
			}
		},
		linkStartWith(rawLink) {
			let link = this.processedLinks[rawLink];
			if (!link) {
				link = rawLink.toLowerCase();
				this.processedLinks[rawLink] = link || "";
			}
			let route = this.processedRoutes[this.$route.path];
			if (!route) {
				route = this.$route.path.toLowerCase();
				this.processedRoutes[this.$route.path] = route || "";
			}
			return route.startsWith(link);
		},
		linkStartIs(rawLink) {
			let link = this.processedLinks[rawLink];
			if (!link) {
				link = rawLink.toLowerCase();
				this.processedLinks[rawLink] = link || "";
			}
			let route = this.processedRoutes[this.$route.path];
			if (!route) {
				route = this.$route.path.toLowerCase();
				this.processedRoutes[this.$route.path] = route || "";
			}
			return route === link;
		},
		linkContainsRegex(regex) {
			let route = this.processedRoutes[this.$route.fullPath];
			if (!route) {
				route = this.$route.fullPath.toLowerCase();
				this.processedRoutes[this.$route.fullPath] = route || "";
			}
			let matches = route.match(regex);
			return matches && matches.length > 0;
		},
		async changeSideMenu() {
			await this.$store.dispatch("changeSideMenu");
		}
	},
	async mounted() {
		await this.getNotifications();
		this.getExternalPages();

		this.$nextTick(() => {
			let isWithinList = $(".side-menu .router-link-active").parents(".collapse");
			if (isWithinList.length) {
				let menuItem = $("[data-bs-target='#" + isWithinList.attr("id") + "']");
				if (menuItem.length) {
					let element = $(menuItem[0]);
					if (element.has(".collapsed"))
						$(menuItem[0]).click();
				}
			}
		});

		const appHeight = () => {
			const doc = document.documentElement;
			doc.style.setProperty("--menu-height", `${window.innerHeight}px`);
		};
		window.addEventListener("resize", appHeight);

		this.$on("destroy", () => {
			window.removeEventListener("resize", appHeight);
		});
		appHeight();

		if (this.sideMenu === null && (window.innerWidth <= 640 || this.$isMobile()))
			await this.setSideMenu(false);
	},
};
</script>

<style>
:root {
	--menu-height: 100vh;
}
</style>

<!--suppress CssRedundantUnit -->
<style lang="scss" scoped>
@import "FlexSystem/styles/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@media print {
	.noPrintThis {
		display: none !important;
	}
}

.btn-toggle.menu-button {
	&.collapsed {
		& > .open {
			display: none !important;
		}

		& > .closed {
			display: flex !important;
		}
	}

	& > .open {
		display: flex !important;
		margin-left: auto;
		margin-right: 10px;
		float: right;
	}

	& > .closed {
		display: none !important;
		margin-left: auto;
		margin-right: 10px;
		float: right;
	}
}

.navbar-background {
	background-color: $white;

	body.dark-theme & {
		background-color: $darker-color !important;
		color: $almost-white-color;
	}
}

.flexpos-logo {
	display: inline-block;
	margin-top: 20px;
	width: 135px;
}

.backoffice-logo {
	display: inline-block;
	margin-top: 10px;
	width: 101px;
}

nav {
	min-height: 100vh;
}

.menu-link {
	display: flex;
	align-items: center;
	width: 100%;
	height: 45px;
	padding: 9px 0;
	color: $dark-color;

	body.dark-theme & {
		color: $almost-white-color;
	}

	text-decoration-line: none;
	font-size: 14px;

	span {
		padding: 4px;
	}

	&-icon {
		width: 22px !important;
		height: 22px !important;
	}

	ul & {
		height: 35px;
		padding: 10px 0 10px 58px;
	}

	svg {
		padding-left: 24px;
		padding-right: 10px;
	}

	&.active, &:focus, &:hover, &.router-link-active {
		background-color: $menu-hover-link;
		box-shadow: none;
	}

	li & {
		background-color: transparent;

		&.active, &:focus, &:hover, &.router-link-active {
			color: $brand-color;
			background-color: transparent;
		}
	}
}

.menu-button {
	display: flex;
	align-items: center;
	width: 100%;
	height: 45px;
	padding: 9px 0;
	color: #434343;

	body.dark-theme & {
		color: $almost-white-color;
	}

	text-decoration-line: none;
	font-size: 14px;
	text-align: left;
	border-radius: 0;

	span {
		padding: 4px;
	}

	ul & {
		padding-left: 58px;
	}

	svg {
		padding-left: 24px;
		padding-right: 10px;
	}

	&.active, &:focus, &:hover {
		background-color: rgba(#5490F8, 0.24);
		box-shadow: none;
	}
}

.version-number {
	height: 40px;
	border-top: 1px solid #d4d4d4;
	background-color: #fff;
	position: sticky;
	bottom: 0;
	right: 0;
	left: 0;
	margin-top: auto;

	p {
		margin: 10px auto;
	}

	button {
		background-color: transparent;
		border: none;
		border-left: 1px solid #d4d4d4;
		width: 40px;
	}
}

//noinspection ALL
.side-menu {
	position: fixed;
	top: 0px; // DO NOT REMOVE px 
	left: 0px; // DO NOT REMOVE px 
	//bottom: 0px; // DO NOT REMOVE px 

	height: 100vh;

	height: -webkit-fill-available;

	height: var(--menu-height);

	-webkit-overflow-scrolling: touch;

	border-right: 1px solid #d4d4d4;
	filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.13));
	z-index: 1031;
	width: 100%;

	overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	&.side-menu {
		&-in {
			width: 60px;
			min-width: 60px;

			.menu-button, .menu-link {
				svg {
					padding-left: 18px;
				}

				span {
					display: none;
				}
			}

			ul .menu-link {
				display: none;
			}

			& .version-number > button {
				background-color: transparent;
				border: none;
				width: 60px;
			}

			.version-number > p, .flexpos-logo, .backoffice-logo {
				display: none !important;
			}

			& > nav > .collapse > .btn-toggle-nav {
				padding-left: 0;

				& > li > a {
					display: block;
					padding-left: 30px;

					& > span {
						display: none;
					}
				}
			}

			& > nav > .btn-toggle.menu-button > :not(.menu-link-icon), > nav > .btn-toggle.menu-button > .fa-layers {
				margin-left: -5px;
				padding-left: 0;
			}
		}

		&-out {
			width: 250px;
			min-width: 250px;

			& > nav > div > .btn-toggle-nav > li > a > .menu-link-icon, > nav > div > .btn-toggle-nav > li > a > .fa-layers {
				display: none !important;
			}
		}

	}

	ul {
		padding-left: 35px;

		.menu-link {
			margin-left: -29px;
			width: calc(100% + 35px);
		}

		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 25px;
			bottom: 25px;
			width: 2px;
			background-image: linear-gradient(black 50%, rgba(255, 255, 255, 0) 0%);

			body.dark-theme & {
				background-image: linear-gradient($almost-white-color 50%, rgba(255, 255, 255, 0) 0%);
			}

			background-position: left;
			background-size: 1px 10px;
			background-repeat: repeat-y;
			background-position-y: top;
		}
	}

	body.dark-theme & {
		border-right: 1px solid $darker-color;
	}

	@media print {
		.noPrintThis {
			display: none !important;
		}
	}
}
</style>