import {Module} from "vuex";

interface IButtonFunctionState {
	isLoading: boolean;
	isLoadingStatic: boolean;
	isSubmitting: boolean;
	isCloneOpen: boolean;
	unit: string;
	chosenTab: string;
}
const buttonFunctionState: Module<IButtonFunctionState, any> = {
    namespaced: true,

	state: () => ({
		isLoading: true,
		isLoadingStatic: true,
		isSubmitting: false,
		isCloneOpen: false,
		unit: new GUID().Empty,
		chosenTab: new GUID().Empty,
	}),
	actions: {
		IS_LOADING(context, state) {
			context.commit("IS_LOADING", state);
		},
		IS_LOADING_STATIC(context, state) {
			context.commit("IS_LOADING_STATIC", state);
		},
		IS_SUBMITTING(context, state) {
			context.commit("IS_SUBMITTING", state);
		},
		SET_UNIT(context, state) {
			context.commit("SET_UNIT", state);
		},
		SET_CLONE(context, state) {
			context.commit("SET_CLONE", state);
		},
		SET_TAB(context, state) {
			context.commit("SET_TAB", state);
		},
	},
	mutations: {
		IS_LOADING(state, newState) {
			state.isLoading = newState;
		},
		IS_LOADING_STATIC(state, newState) {
			state.isLoadingStatic = newState;
		},
		IS_SUBMITTING(state, newState) {
			state.isSubmitting = newState;
		},
		SET_UNIT(state, newState) {
			state.unit = newState;
		},
		SET_CLONE(state, newState) {
			state.isCloneOpen = newState;
		},
		SET_TAB(state, newState) {
			state.chosenTab = newState;
		},
	},
	getters: {
		getCombinations(state, getters, rootState, rootGetters) {
			return {
				combination1: { Active: rootGetters.getFlexPosConfigBool("Combination1FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination1FieldName", Resource.Combination1) },
				combination2: { Active: rootGetters.getFlexPosConfigBool("Combination2FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination2FieldName", Resource.Combination2) },
				combination3: { Active: rootGetters.getFlexPosConfigBool("Combination3FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination3FieldName", Resource.Combination3) },
				combination4: { Active: rootGetters.getFlexPosConfigBool("Combination4FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination4FieldName", Resource.Combination4) },
			};
		},
		isLoading(state, getters, rootState, rootGetters) {
			return state.isLoading;
		},
		isLoadingStatic(state, getters, rootState, rootGetters) {
			return state.isLoadingStatic;
		},
		isSubmitting(state, getters, rootState, rootGetters) {
			return state.isSubmitting;
		},
		isCloneOpen(state, getters, rootState, rootGetters) {
			return state.isCloneOpen;
		},
		unit(state, getters, rootState, rootGetters) {
			return state.unit;
		},
		chosenTab(state, getters, rootState, rootGetters) {
			return state.chosenTab;
		},
	}
}

export default buttonFunctionState;