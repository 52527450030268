














































import SideMenu from "@components/side-menu/side-menu.vue";
import TopMenu from "@components/top-menu/top-menu.vue";
import { mapGetters } from 'vuex';
//@ts-ignore
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
//@ts-ignore
import isObject from "underscore/modules/isObject";
import isNullOrEmpty from "./isNullOrEmpty";

const isAxiosError = (error) => {
	return error.isAxiosError;
};
export default {
	name: "app",
	components: {TopMenu, SideMenu, VueJsonPretty},
	metaInfo: {
		titleTemplate: titleChunk => {
			return (titleChunk) ? `${titleChunk} | FlexPOS BackOffice` : "FlexPOS BackOffice"; 
		},
		meta: [
			{ charset: 'utf-8' },
			{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]
	},
	data() {
		return {
			toasts: [],
			isObject: isObject
		};
	},
	computed: {
		...mapGetters({
			getToasts: "getToasts",
			sideMenu: "getSideMenu",
			isLoggedIn: "getIsLoggedIn",
			appLoadingFailed: "getAppLoadingFailed",
		}),
		forcedToChangePassword(){
			let value = this.$route.fullPath;
			if (value.indexOf("Account/ChangePassword") > -1) {
				return true;
			}
			return this.$route.name === "flex-voucher-download-file" || this.$route.name === "economic-app" || this.$route.name === "economic-app-login";
		},		
	},
	watch: {
		getToasts(val, oldVal) {
			if (val === oldVal) return;
			
			for (let i = 0; i < val.length; i++) {
				let item = val[i];
				
				const found = this.$refs[item.Id];

				if (found === undefined) {
					if (this.$store.getters.getIsReseller || this.$store.getters.getIsFlexPosAdmin) {
						const axiosData = item.text;
						if (typeof item.text === "object") {
							let newText = {
								url: axiosData.config["url"],
								data: null,
								method: axiosData.config["method"],
								message: axiosData["message"],
								stack: axiosData["stack"],
								response: null
							};
							if (axiosData.config.data) {
								newText.data = axiosData.config["data"];
							}
							else {
								delete newText["data"];
							}

							if (axiosData.response && axiosData.response.data) {
								newText.response = {
									data: axiosData.response.data,
									status: axiosData.response.status,
									statusText: axiosData.response.statusText
								};
							}

							item.text = newText;
						}
					}
					else if (isAxiosError(item.text))
						item.text = (!isNullOrEmpty(item.text.response.data)) ? item.text.response.data.toString() : item.text.message.toString();

					this.toasts.push(item);
					this.$nextTick(() => this.addToastEvents(item));
				}
			}
		}
	},
	methods: {
		addToastEvents(item) {
			const $el = this.$refs[item.Id][0];
			const toast = new this.$bootstrap.Toast($el);
			toast.show();

			$el.addEventListener('hide.bs.toast', () => {
				this.$store.commit("REMOVE_TOAST", item.Id);
			});
			$el.addEventListener('hidden.bs.toast', () => {
				this.$store.commit("REMOVE_TOAST", item.Id);
				toast.dispose();

				const hiddenIndex = this.toasts.findIndex(function(toast) { return toast.Id === item.Id; });
				this.toasts.splice(hiddenIndex, 1);
			});
		}, 
		toastClasses(toast) {
			if (toast.type === "danger" || toast.type === "primary" || toast.type === "secondary" || toast.type === "black" || toast.type === "success") {
				return ['bg-' + toast.type, "text-white"];
			}
			return ['bg-' + toast.type, "text-black"];
		}
	}
};
