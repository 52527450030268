import {Module} from "vuex";

interface IProductStockTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const productStockTableIndexState: Module<IProductStockTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        Number(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.Number", rootState, true); },
        Name(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.Name", rootState, true); },
        Size(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.Size", rootState, true); },
        Color(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.Color", rootState, true); },
        Combination1(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.Combination1", rootState, true); },
        Combination2(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.Combination2", rootState, true); },
        Combination3(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.Combination3", rootState, true); },
        Combination4(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.Combination4", rootState, true); },
        SyncToWeb(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.SyncToWeb", rootState, false); },
        StockQuantity(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.StockQuantity", rootState, true); },
        MinStock(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.MinStock", rootState, false); },
        MaxStock(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.MaxStock", rootState, false); },
        UnitType(state, getters, rootState) { return getConfig("ProductStock.ColumnOptions.UnitType", rootState, false); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            let combinations = rootGetters["productConfigState/getCombinations"];
            let stockConfigs = rootGetters["productStockConfigState/getConfigs"];
            
            return {
                Number: getters.Number,
                Name: getters.Name,
                Size: getters.Size,
                Color: getters.Color,
                Combination1: getters.Combination1 && combinations.combination1 && combinations.combination1.Active,
                Combination2: getters.Combination2 && combinations.combination2 && combinations.combination2.Active,
                Combination3: getters.Combination3 && combinations.combination3 && combinations.combination3.Active,
                Combination4: getters.Combination4 && combinations.combination4 && combinations.combination4.Active,
                SyncToWeb: getters.SyncToWeb,
                StockQuantity: getters.StockQuantity && stockConfigs.stockActive,
                MinStock: getters.MinStock && stockConfigs.stockActive,
                MaxStock: getters.MaxStock && stockConfigs.stockActive,
                UnitType: getters.UnitType,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            let combinations = rootGetters["productConfigState/getCombinations"];
            let stockConfigs = rootGetters["productStockConfigState/getConfigs"];

            return {
                Number: true,
                Name: true,
                Size: true,
                Color: true,
                Combination1: combinations.combination1 && combinations.combination1.Active,
                Combination2: combinations.combination2 && combinations.combination2.Active,
                Combination3: combinations.combination3 && combinations.combination3.Active,
                Combination4: combinations.combination4 && combinations.combination4.Active,
                SyncToWeb: true,
                StockQuantity: stockConfigs.stockActive,
                MinStock: stockConfigs.stockActive,
                MaxStock: stockConfigs.stockActive,
                UnitType: true,
            };
        }
    }
}

export default productStockTableIndexState;