import { render, staticRenderFns } from "./fp-table-row.vue?vue&type=template&id=1b785896&scoped=true&"
import script from "./fp-table-row.vue?vue&type=script&lang=ts&"
export * from "./fp-table-row.vue?vue&type=script&lang=ts&"
import style0 from "./fp-table-row.vue?vue&type=style&index=0&id=1b785896&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b785896",
  null
  
)

export default component.exports