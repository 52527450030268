/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityCurrentAndPreviousPeriodTurnover } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityCustomBoxes } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityGetByDateResponse } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityGetByHourResponse } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityGetByMonthResponse } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityGetByWeekResponse } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityGetByWeekdayResponse } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTotalAndAvgData } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClient } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClientCompare } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerDay } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerDisplayGroup } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerHour } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerMonth } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPos } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUser } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUserCompare } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProduct } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncapped } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncappedCompare } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClient } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClientCompare } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUser } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUserCompare } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncapped } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncappedCompare } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsResellerResellerLicenseStats } from '../models';
/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentAndPreviousTurnover: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/current-and-previous-turnover`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentAndPreviousTurnoverAverage: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/current-and-previous-turnover-average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentAndPreviousTurnoverParent: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/current-and-previous-turnover-parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentAndPreviousTurnoverParentAverage: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/current-and-previous-turnover-parent-average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [CustomData] 
         * @param {number} [currentDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customBoxes: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, CustomData?: string, currentDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/custom-boxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (CustomData !== undefined) {
                localVarQueryParameter['CustomData'] = CustomData;
            }

            if (currentDate !== undefined) {
                localVarQueryParameter['currentDate'] = currentDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gainedAndLostByUid: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/gained-and-lost-by-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lastUpdated: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/last-updated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [CustomData] 
         * @param {number} [currentDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalsAndAvgData: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, CustomData?: string, currentDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/totals-and-avg-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (CustomData !== undefined) {
                localVarQueryParameter['CustomData'] = CustomData;
            }

            if (currentDate !== undefined) {
                localVarQueryParameter['currentDate'] = currentDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByDay: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-by-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByDayCompare: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-by-day-compare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByHour: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-by-hour`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByHourCompare: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-by-hour-compare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByMonth: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-by-month`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByMonthCompare: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-by-month-compare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByWeek: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-by-week`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByWeekCompare: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-by-week-compare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByWeekDay: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-by-week-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByWeekDayCompare: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-by-week-day-compare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerClientParent: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-client-parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerClientParentCompare: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-client-parent-compare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerClientParentComparePaged: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-client-parent-compare-paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerClientParentPaged: async (startDate?: number, endDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-client-parent-paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerDay: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerDayParent: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-day-parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerDisplayGroup: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-display-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerDisplayGroupParent: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-display-group-parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerHour: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-hour`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerHourParent: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-hour-parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerMonth: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-month`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerMonthParent: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-month-parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPos: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-pos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosParent: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-pos-parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosUser: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-pos-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosUserCompare: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-pos-user-compare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosUserComparePaged: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-pos-user-compare-paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosUserPaged: async (startDate?: number, endDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-pos-user-paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosUserParent: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-pos-user-parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProduct: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProductCompare: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-product-compare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProductComparePaged: async (startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-product-compare-paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (compareStartDate !== undefined) {
                localVarQueryParameter['compareStartDate'] = compareStartDate;
            }

            if (compareEndDate !== undefined) {
                localVarQueryParameter['compareEndDate'] = compareEndDate;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProductParent: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-product-parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProductUncapped: async (startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-product-uncapped`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (HasGiftcardIncluded !== undefined) {
                localVarQueryParameter['HasGiftcardIncluded'] = HasGiftcardIncluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProductUncappedPaged: async (startDate?: number, endDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/statistics/turnover-per-product-uncapped-paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentAndPreviousTurnover(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityCurrentAndPreviousPeriodTurnover>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentAndPreviousTurnover(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentAndPreviousTurnoverAverage(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityCurrentAndPreviousPeriodTurnover>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentAndPreviousTurnoverAverage(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentAndPreviousTurnoverParent(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityCurrentAndPreviousPeriodTurnover>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentAndPreviousTurnoverParent(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentAndPreviousTurnoverParentAverage(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityCurrentAndPreviousPeriodTurnover>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentAndPreviousTurnoverParentAverage(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [CustomData] 
         * @param {number} [currentDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customBoxes(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, CustomData?: string, currentDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityCustomBoxes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customBoxes(startDate, endDate, compareStartDate, compareEndDate, CustomData, currentDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gainedAndLostByUid(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsResellerResellerLicenseStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gainedAndLostByUid(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lastUpdated(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lastUpdated(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [CustomData] 
         * @param {number} [currentDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async totalsAndAvgData(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, CustomData?: string, currentDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityTotalAndAvgData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.totalsAndAvgData(startDate, endDate, compareStartDate, compareEndDate, CustomData, currentDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverByDay(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByDateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverByDay(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverByDayCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByDateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverByDayCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverByHour(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByHourResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverByHour(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverByHourCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByHourResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverByHourCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverByMonth(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByMonthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverByMonth(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverByMonthCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByMonthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverByMonthCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverByWeek(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByWeekResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverByWeek(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverByWeekCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByWeekResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverByWeekCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverByWeekDay(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByWeekdayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverByWeekDay(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverByWeekDayCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByWeekdayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverByWeekDayCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerClientParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClient>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerClientParent(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerClientParentCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClientCompare>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerClientParentCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerClientParentComparePaged(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClientCompare>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerClientParentComparePaged(startDate, endDate, compareStartDate, compareEndDate, sort, order, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerClientParentPaged(startDate?: number, endDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerClientParentPaged(startDate, endDate, sort, order, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerDay(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerDay>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerDay(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerDayParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerDay>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerDayParent(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerDisplayGroup(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerDisplayGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerDisplayGroup(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerDisplayGroupParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerDisplayGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerDisplayGroupParent(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerHour(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerHour>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerHour(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerHourParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerHour>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerHourParent(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerMonth(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerMonth>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerMonth(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerMonthParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerMonth>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerMonthParent(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerPos(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPos>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerPos(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerPosParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPos>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerPosParent(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerPosUser(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerPosUser(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerPosUserCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUserCompare>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerPosUserCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerPosUserComparePaged(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUserCompare>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerPosUserComparePaged(startDate, endDate, compareStartDate, compareEndDate, sort, order, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerPosUserPaged(startDate?: number, endDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerPosUserPaged(startDate, endDate, sort, order, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerPosUserParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerPosUserParent(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerProduct(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerProduct(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerProductCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncappedCompare>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerProductCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerProductComparePaged(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncappedCompare>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerProductComparePaged(startDate, endDate, compareStartDate, compareEndDate, sort, order, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerProductParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerProductParent(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerProductUncapped(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncapped>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerProductUncapped(startDate, endDate, HasGiftcardIncluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async turnoverPerProductUncappedPaged(startDate?: number, endDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncapped>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.turnoverPerProductUncappedPaged(startDate, endDate, sort, order, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentAndPreviousTurnover(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityCurrentAndPreviousPeriodTurnover> {
            return localVarFp.currentAndPreviousTurnover(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentAndPreviousTurnoverAverage(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityCurrentAndPreviousPeriodTurnover> {
            return localVarFp.currentAndPreviousTurnoverAverage(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentAndPreviousTurnoverParent(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityCurrentAndPreviousPeriodTurnover> {
            return localVarFp.currentAndPreviousTurnoverParent(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentAndPreviousTurnoverParentAverage(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityCurrentAndPreviousPeriodTurnover> {
            return localVarFp.currentAndPreviousTurnoverParentAverage(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [CustomData] 
         * @param {number} [currentDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customBoxes(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, CustomData?: string, currentDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityCustomBoxes> {
            return localVarFp.customBoxes(startDate, endDate, compareStartDate, compareEndDate, CustomData, currentDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gainedAndLostByUid(options?: any): AxiosPromise<APIInternalFrontendAPIModelsResellerResellerLicenseStats> {
            return localVarFp.gainedAndLostByUid(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lastUpdated(options?: any): AxiosPromise<string> {
            return localVarFp.lastUpdated(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [CustomData] 
         * @param {number} [currentDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalsAndAvgData(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, CustomData?: string, currentDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityTotalAndAvgData> {
            return localVarFp.totalsAndAvgData(startDate, endDate, compareStartDate, compareEndDate, CustomData, currentDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByDay(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByDateResponse> {
            return localVarFp.turnoverByDay(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByDayCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByDateResponse> {
            return localVarFp.turnoverByDayCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByHour(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByHourResponse> {
            return localVarFp.turnoverByHour(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByHourCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByHourResponse> {
            return localVarFp.turnoverByHourCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByMonth(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByMonthResponse> {
            return localVarFp.turnoverByMonth(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByMonthCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByMonthResponse> {
            return localVarFp.turnoverByMonthCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByWeek(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByWeekResponse> {
            return localVarFp.turnoverByWeek(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByWeekCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByWeekResponse> {
            return localVarFp.turnoverByWeekCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByWeekDay(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByWeekdayResponse> {
            return localVarFp.turnoverByWeekDay(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverByWeekDayCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessCubeEntityGetByWeekdayResponse> {
            return localVarFp.turnoverByWeekDayCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerClientParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClient>> {
            return localVarFp.turnoverPerClientParent(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerClientParentCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClientCompare>> {
            return localVarFp.turnoverPerClientParentCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerClientParentComparePaged(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClientCompare> {
            return localVarFp.turnoverPerClientParentComparePaged(startDate, endDate, compareStartDate, compareEndDate, sort, order, limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerClientParentPaged(startDate?: number, endDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerClient> {
            return localVarFp.turnoverPerClientParentPaged(startDate, endDate, sort, order, limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerDay(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerDay>> {
            return localVarFp.turnoverPerDay(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerDayParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerDay>> {
            return localVarFp.turnoverPerDayParent(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerDisplayGroup(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerDisplayGroup>> {
            return localVarFp.turnoverPerDisplayGroup(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerDisplayGroupParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerDisplayGroup>> {
            return localVarFp.turnoverPerDisplayGroupParent(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerHour(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerHour>> {
            return localVarFp.turnoverPerHour(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerHourParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerHour>> {
            return localVarFp.turnoverPerHourParent(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerMonth(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerMonth>> {
            return localVarFp.turnoverPerMonth(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerMonthParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerMonth>> {
            return localVarFp.turnoverPerMonthParent(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPos(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPos>> {
            return localVarFp.turnoverPerPos(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPos>> {
            return localVarFp.turnoverPerPosParent(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosUser(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUser>> {
            return localVarFp.turnoverPerPosUser(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosUserCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUserCompare>> {
            return localVarFp.turnoverPerPosUserCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosUserComparePaged(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUserCompare> {
            return localVarFp.turnoverPerPosUserComparePaged(startDate, endDate, compareStartDate, compareEndDate, sort, order, limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosUserPaged(startDate?: number, endDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUser> {
            return localVarFp.turnoverPerPosUserPaged(startDate, endDate, sort, order, limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerPosUserParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerPosUser>> {
            return localVarFp.turnoverPerPosUserParent(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProduct(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProduct>> {
            return localVarFp.turnoverPerProduct(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProductCompare(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncappedCompare>> {
            return localVarFp.turnoverPerProductCompare(startDate, endDate, compareStartDate, compareEndDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {number} [compareStartDate] 
         * @param {number} [compareEndDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProductComparePaged(startDate?: number, endDate?: number, compareStartDate?: number, compareEndDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncappedCompare> {
            return localVarFp.turnoverPerProductComparePaged(startDate, endDate, compareStartDate, compareEndDate, sort, order, limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProductParent(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProduct>> {
            return localVarFp.turnoverPerProductParent(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {boolean} [HasGiftcardIncluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProductUncapped(startDate?: number, endDate?: number, HasGiftcardIncluded?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncapped>> {
            return localVarFp.turnoverPerProductUncapped(startDate, endDate, HasGiftcardIncluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startDate] 
         * @param {number} [endDate] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        turnoverPerProductUncappedPaged(startDate?: number, endDate?: number, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIDataAccessCubeEntityTurnoverPerProductUncapped> {
            return localVarFp.turnoverPerProductUncappedPaged(startDate, endDate, sort, order, limit, offset, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for currentAndPreviousTurnover operation in StatisticsApi.
 * @export
 * @interface StatisticsApiCurrentAndPreviousTurnoverRequest
 */
export interface StatisticsApiCurrentAndPreviousTurnoverRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnover
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnover
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnover
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnover
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiCurrentAndPreviousTurnover
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for currentAndPreviousTurnoverAverage operation in StatisticsApi.
 * @export
 * @interface StatisticsApiCurrentAndPreviousTurnoverAverageRequest
 */
export interface StatisticsApiCurrentAndPreviousTurnoverAverageRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverAverage
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverAverage
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverAverage
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverAverage
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverAverage
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for currentAndPreviousTurnoverParent operation in StatisticsApi.
 * @export
 * @interface StatisticsApiCurrentAndPreviousTurnoverParentRequest
 */
export interface StatisticsApiCurrentAndPreviousTurnoverParentRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverParent
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverParent
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverParent
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverParent
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverParent
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for currentAndPreviousTurnoverParentAverage operation in StatisticsApi.
 * @export
 * @interface StatisticsApiCurrentAndPreviousTurnoverParentAverageRequest
 */
export interface StatisticsApiCurrentAndPreviousTurnoverParentAverageRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverParentAverage
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverParentAverage
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverParentAverage
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverParentAverage
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiCurrentAndPreviousTurnoverParentAverage
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for customBoxes operation in StatisticsApi.
 * @export
 * @interface StatisticsApiCustomBoxesRequest
 */
export interface StatisticsApiCustomBoxesRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCustomBoxes
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCustomBoxes
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCustomBoxes
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCustomBoxes
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiCustomBoxes
     */
    readonly CustomData?: string

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiCustomBoxes
     */
    readonly currentDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiCustomBoxes
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for totalsAndAvgData operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTotalsAndAvgDataRequest
 */
export interface StatisticsApiTotalsAndAvgDataRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTotalsAndAvgData
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTotalsAndAvgData
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTotalsAndAvgData
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTotalsAndAvgData
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTotalsAndAvgData
     */
    readonly CustomData?: string

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTotalsAndAvgData
     */
    readonly currentDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTotalsAndAvgData
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverByDay operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverByDayRequest
 */
export interface StatisticsApiTurnoverByDayRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByDay
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByDay
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverByDay
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverByDayCompare operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverByDayCompareRequest
 */
export interface StatisticsApiTurnoverByDayCompareRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByDayCompare
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByDayCompare
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByDayCompare
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByDayCompare
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverByDayCompare
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverByHour operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverByHourRequest
 */
export interface StatisticsApiTurnoverByHourRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByHour
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByHour
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverByHour
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverByHourCompare operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverByHourCompareRequest
 */
export interface StatisticsApiTurnoverByHourCompareRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByHourCompare
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByHourCompare
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByHourCompare
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByHourCompare
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverByHourCompare
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverByMonth operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverByMonthRequest
 */
export interface StatisticsApiTurnoverByMonthRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByMonth
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByMonth
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverByMonth
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverByMonthCompare operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverByMonthCompareRequest
 */
export interface StatisticsApiTurnoverByMonthCompareRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByMonthCompare
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByMonthCompare
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByMonthCompare
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByMonthCompare
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverByMonthCompare
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverByWeek operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverByWeekRequest
 */
export interface StatisticsApiTurnoverByWeekRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeek
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeek
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverByWeek
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverByWeekCompare operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverByWeekCompareRequest
 */
export interface StatisticsApiTurnoverByWeekCompareRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeekCompare
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeekCompare
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeekCompare
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeekCompare
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverByWeekCompare
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverByWeekDay operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverByWeekDayRequest
 */
export interface StatisticsApiTurnoverByWeekDayRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeekDay
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeekDay
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverByWeekDay
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverByWeekDayCompare operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverByWeekDayCompareRequest
 */
export interface StatisticsApiTurnoverByWeekDayCompareRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeekDayCompare
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeekDayCompare
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeekDayCompare
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverByWeekDayCompare
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverByWeekDayCompare
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerClientParent operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerClientParentRequest
 */
export interface StatisticsApiTurnoverPerClientParentRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParent
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParent
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerClientParent
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerClientParentCompare operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerClientParentCompareRequest
 */
export interface StatisticsApiTurnoverPerClientParentCompareRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentCompare
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentCompare
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentCompare
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentCompare
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerClientParentCompare
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerClientParentComparePaged operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerClientParentComparePagedRequest
 */
export interface StatisticsApiTurnoverPerClientParentComparePagedRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentComparePaged
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentComparePaged
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentComparePaged
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentComparePaged
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerClientParentComparePaged
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerClientParentComparePaged
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentComparePaged
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentComparePaged
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerClientParentComparePaged
     */
    readonly search?: string
}

/**
 * Request parameters for turnoverPerClientParentPaged operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerClientParentPagedRequest
 */
export interface StatisticsApiTurnoverPerClientParentPagedRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentPaged
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentPaged
     */
    readonly endDate?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerClientParentPaged
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerClientParentPaged
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentPaged
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerClientParentPaged
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerClientParentPaged
     */
    readonly search?: string
}

/**
 * Request parameters for turnoverPerDay operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerDayRequest
 */
export interface StatisticsApiTurnoverPerDayRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerDay
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerDay
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerDay
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerDayParent operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerDayParentRequest
 */
export interface StatisticsApiTurnoverPerDayParentRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerDayParent
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerDayParent
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerDayParent
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerDisplayGroup operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerDisplayGroupRequest
 */
export interface StatisticsApiTurnoverPerDisplayGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerDisplayGroup
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerDisplayGroup
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerDisplayGroup
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerDisplayGroupParent operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerDisplayGroupParentRequest
 */
export interface StatisticsApiTurnoverPerDisplayGroupParentRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerDisplayGroupParent
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerDisplayGroupParent
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerDisplayGroupParent
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerHour operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerHourRequest
 */
export interface StatisticsApiTurnoverPerHourRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerHour
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerHour
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerHour
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerHourParent operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerHourParentRequest
 */
export interface StatisticsApiTurnoverPerHourParentRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerHourParent
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerHourParent
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerHourParent
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerMonth operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerMonthRequest
 */
export interface StatisticsApiTurnoverPerMonthRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerMonth
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerMonth
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerMonth
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerMonthParent operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerMonthParentRequest
 */
export interface StatisticsApiTurnoverPerMonthParentRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerMonthParent
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerMonthParent
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerMonthParent
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerPos operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerPosRequest
 */
export interface StatisticsApiTurnoverPerPosRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPos
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPos
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerPos
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerPosParent operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerPosParentRequest
 */
export interface StatisticsApiTurnoverPerPosParentRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosParent
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosParent
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerPosParent
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerPosUser operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerPosUserRequest
 */
export interface StatisticsApiTurnoverPerPosUserRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUser
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUser
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerPosUser
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerPosUserCompare operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerPosUserCompareRequest
 */
export interface StatisticsApiTurnoverPerPosUserCompareRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserCompare
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserCompare
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserCompare
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserCompare
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerPosUserCompare
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerPosUserComparePaged operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerPosUserComparePagedRequest
 */
export interface StatisticsApiTurnoverPerPosUserComparePagedRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserComparePaged
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserComparePaged
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserComparePaged
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserComparePaged
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerPosUserComparePaged
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerPosUserComparePaged
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserComparePaged
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserComparePaged
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerPosUserComparePaged
     */
    readonly search?: string
}

/**
 * Request parameters for turnoverPerPosUserPaged operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerPosUserPagedRequest
 */
export interface StatisticsApiTurnoverPerPosUserPagedRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserPaged
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserPaged
     */
    readonly endDate?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerPosUserPaged
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerPosUserPaged
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserPaged
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserPaged
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerPosUserPaged
     */
    readonly search?: string
}

/**
 * Request parameters for turnoverPerPosUserParent operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerPosUserParentRequest
 */
export interface StatisticsApiTurnoverPerPosUserParentRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserParent
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerPosUserParent
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerPosUserParent
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerProduct operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerProductRequest
 */
export interface StatisticsApiTurnoverPerProductRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProduct
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProduct
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerProduct
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerProductCompare operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerProductCompareRequest
 */
export interface StatisticsApiTurnoverPerProductCompareRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductCompare
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductCompare
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductCompare
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductCompare
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerProductCompare
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerProductComparePaged operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerProductComparePagedRequest
 */
export interface StatisticsApiTurnoverPerProductComparePagedRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductComparePaged
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductComparePaged
     */
    readonly endDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductComparePaged
     */
    readonly compareStartDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductComparePaged
     */
    readonly compareEndDate?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerProductComparePaged
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerProductComparePaged
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductComparePaged
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductComparePaged
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerProductComparePaged
     */
    readonly search?: string
}

/**
 * Request parameters for turnoverPerProductParent operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerProductParentRequest
 */
export interface StatisticsApiTurnoverPerProductParentRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductParent
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductParent
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerProductParent
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerProductUncapped operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerProductUncappedRequest
 */
export interface StatisticsApiTurnoverPerProductUncappedRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductUncapped
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductUncapped
     */
    readonly endDate?: number

    /**
     * 
     * @type {boolean}
     * @memberof StatisticsApiTurnoverPerProductUncapped
     */
    readonly HasGiftcardIncluded?: boolean
}

/**
 * Request parameters for turnoverPerProductUncappedPaged operation in StatisticsApi.
 * @export
 * @interface StatisticsApiTurnoverPerProductUncappedPagedRequest
 */
export interface StatisticsApiTurnoverPerProductUncappedPagedRequest {
    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductUncappedPaged
     */
    readonly startDate?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductUncappedPaged
     */
    readonly endDate?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerProductUncappedPaged
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerProductUncappedPaged
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductUncappedPaged
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof StatisticsApiTurnoverPerProductUncappedPaged
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof StatisticsApiTurnoverPerProductUncappedPaged
     */
    readonly search?: string
}

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * 
     * @param {StatisticsApiCurrentAndPreviousTurnoverRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public currentAndPreviousTurnover(requestParameters: StatisticsApiCurrentAndPreviousTurnoverRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).currentAndPreviousTurnover(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiCurrentAndPreviousTurnoverAverageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public currentAndPreviousTurnoverAverage(requestParameters: StatisticsApiCurrentAndPreviousTurnoverAverageRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).currentAndPreviousTurnoverAverage(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiCurrentAndPreviousTurnoverParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public currentAndPreviousTurnoverParent(requestParameters: StatisticsApiCurrentAndPreviousTurnoverParentRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).currentAndPreviousTurnoverParent(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiCurrentAndPreviousTurnoverParentAverageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public currentAndPreviousTurnoverParentAverage(requestParameters: StatisticsApiCurrentAndPreviousTurnoverParentAverageRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).currentAndPreviousTurnoverParentAverage(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiCustomBoxesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public customBoxes(requestParameters: StatisticsApiCustomBoxesRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).customBoxes(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.CustomData, requestParameters.currentDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public gainedAndLostByUid(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).gainedAndLostByUid(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public lastUpdated(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).lastUpdated(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTotalsAndAvgDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public totalsAndAvgData(requestParameters: StatisticsApiTotalsAndAvgDataRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).totalsAndAvgData(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.CustomData, requestParameters.currentDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverByDayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverByDay(requestParameters: StatisticsApiTurnoverByDayRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverByDay(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverByDayCompareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverByDayCompare(requestParameters: StatisticsApiTurnoverByDayCompareRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverByDayCompare(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverByHourRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverByHour(requestParameters: StatisticsApiTurnoverByHourRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverByHour(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverByHourCompareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverByHourCompare(requestParameters: StatisticsApiTurnoverByHourCompareRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverByHourCompare(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverByMonthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverByMonth(requestParameters: StatisticsApiTurnoverByMonthRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverByMonth(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverByMonthCompareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverByMonthCompare(requestParameters: StatisticsApiTurnoverByMonthCompareRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverByMonthCompare(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverByWeekRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverByWeek(requestParameters: StatisticsApiTurnoverByWeekRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverByWeek(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverByWeekCompareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverByWeekCompare(requestParameters: StatisticsApiTurnoverByWeekCompareRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverByWeekCompare(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverByWeekDayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverByWeekDay(requestParameters: StatisticsApiTurnoverByWeekDayRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverByWeekDay(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverByWeekDayCompareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverByWeekDayCompare(requestParameters: StatisticsApiTurnoverByWeekDayCompareRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverByWeekDayCompare(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerClientParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerClientParent(requestParameters: StatisticsApiTurnoverPerClientParentRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerClientParent(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerClientParentCompareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerClientParentCompare(requestParameters: StatisticsApiTurnoverPerClientParentCompareRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerClientParentCompare(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerClientParentComparePagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerClientParentComparePaged(requestParameters: StatisticsApiTurnoverPerClientParentComparePagedRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerClientParentComparePaged(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerClientParentPagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerClientParentPaged(requestParameters: StatisticsApiTurnoverPerClientParentPagedRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerClientParentPaged(requestParameters.startDate, requestParameters.endDate, requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerDayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerDay(requestParameters: StatisticsApiTurnoverPerDayRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerDay(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerDayParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerDayParent(requestParameters: StatisticsApiTurnoverPerDayParentRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerDayParent(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerDisplayGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerDisplayGroup(requestParameters: StatisticsApiTurnoverPerDisplayGroupRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerDisplayGroup(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerDisplayGroupParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerDisplayGroupParent(requestParameters: StatisticsApiTurnoverPerDisplayGroupParentRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerDisplayGroupParent(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerHourRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerHour(requestParameters: StatisticsApiTurnoverPerHourRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerHour(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerHourParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerHourParent(requestParameters: StatisticsApiTurnoverPerHourParentRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerHourParent(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerMonthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerMonth(requestParameters: StatisticsApiTurnoverPerMonthRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerMonth(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerMonthParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerMonthParent(requestParameters: StatisticsApiTurnoverPerMonthParentRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerMonthParent(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerPosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerPos(requestParameters: StatisticsApiTurnoverPerPosRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerPos(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerPosParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerPosParent(requestParameters: StatisticsApiTurnoverPerPosParentRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerPosParent(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerPosUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerPosUser(requestParameters: StatisticsApiTurnoverPerPosUserRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerPosUser(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerPosUserCompareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerPosUserCompare(requestParameters: StatisticsApiTurnoverPerPosUserCompareRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerPosUserCompare(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerPosUserComparePagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerPosUserComparePaged(requestParameters: StatisticsApiTurnoverPerPosUserComparePagedRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerPosUserComparePaged(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerPosUserPagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerPosUserPaged(requestParameters: StatisticsApiTurnoverPerPosUserPagedRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerPosUserPaged(requestParameters.startDate, requestParameters.endDate, requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerPosUserParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerPosUserParent(requestParameters: StatisticsApiTurnoverPerPosUserParentRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerPosUserParent(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerProduct(requestParameters: StatisticsApiTurnoverPerProductRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerProduct(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerProductCompareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerProductCompare(requestParameters: StatisticsApiTurnoverPerProductCompareRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerProductCompare(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerProductComparePagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerProductComparePaged(requestParameters: StatisticsApiTurnoverPerProductComparePagedRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerProductComparePaged(requestParameters.startDate, requestParameters.endDate, requestParameters.compareStartDate, requestParameters.compareEndDate, requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerProductParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerProductParent(requestParameters: StatisticsApiTurnoverPerProductParentRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerProductParent(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerProductUncappedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerProductUncapped(requestParameters: StatisticsApiTurnoverPerProductUncappedRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerProductUncapped(requestParameters.startDate, requestParameters.endDate, requestParameters.HasGiftcardIncluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsApiTurnoverPerProductUncappedPagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public turnoverPerProductUncappedPaged(requestParameters: StatisticsApiTurnoverPerProductUncappedPagedRequest = {}, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).turnoverPerProductUncappedPaged(requestParameters.startDate, requestParameters.endDate, requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }
}
