import _Vue from "vue";

export function VueResourceDateFormat<T>(Vue: typeof _Vue): void {
    // @ts-ignore
    Vue.Resource = window.Resource_DateFormat

    // @ts-ignore
    Object.defineProperties(Vue.prototype, {
        Resource_DateFormat: {
            get() {
                // @ts-ignore
                return window.Resource_DateFormat;
            }
        }
    });
}
