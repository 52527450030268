import {Module} from "vuex";

interface IUserTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const userTableIndexState: Module<IUserTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        Email(state, getters, rootState) { return getConfig("User.ColumnOptions.Email", rootState, true); },
        Name(state, getters, rootState) { return getConfig("User.ColumnOptions.Name", rootState, true); },
        Initials(state, getters, rootState) { return getConfig("User.ColumnOptions.Initials", rootState, true); },
        IsLocked(state, getters, rootState) { return getConfig("User.ColumnOptions.IsLocked", rootState, true); },
        IsActive(state, getters, rootState) { return getConfig("User.ColumnOptions.IsActive", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Email: getters.Email,
                Name: getters.Name,
                Initials: getters.Initials,
                IsLocked: getters.IsLocked,
                IsActive: getters.IsActive,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Email: true,
                Name: true,
                Initials: true,
                IsLocked: true,
                IsActive: true,
            };
        }
    }
}

export default userTableIndexState;