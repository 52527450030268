import {Module} from "vuex";

interface IVendorModalTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const vendorModalTableState: Module<IVendorModalTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        ID(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.ID", rootState, true); },
        Name(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.Name", rootState, true); },
        Address(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.Address", rootState, true); },
        City(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.City", rootState, true); },
        IsActive(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.IsActive", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                ID: getters.ID,
                Name: getters.Name,
                Address: getters.Address,
                City: getters.City,
                IsActive: getters.IsActive,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                ID: true,
                Name: true,
                Address: true,
                City: true,
                IsActive: true,
            };
        }
    }
}

export default vendorModalTableState;