/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesClientMinimal } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesClientTreeNode } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientClientMassEdit } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientClientStatisticsResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientClientStatisticsTableResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientClientViewModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientGetCreateClientRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientGetEditClientRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientGetSubClientsDicResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientPostCreateClientRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsPagedViewModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsResellerHealthCheck } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsResellerResellerClient } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsResellerResellerUnitVM } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsSelectListItemSelectListItem } from '../models';
/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatistics: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('clientStatistics', 'uid', uid)
            const localVarPath = `/api/v1.0/client/{uid}/client-statistics`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsTable: async (uid: string, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('clientStatisticsTable', 'uid', uid)
            const localVarPath = `/api/v1.0/client/{uid}/client-statistics-table`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsClientPostCreateClientRequestModel} [APIInternalFrontendAPIModelsClientPostCreateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createClient: async (APIInternalFrontendAPIModelsClientPostCreateClientRequestModel?: APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientModel: async (languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/create-client-model`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [releaseTypeUid] 
         * @param {Array<string>} [request_body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBulk: async (releaseTypeUid?: string, request_body?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/edit-bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (releaseTypeUid !== undefined) {
                localVarQueryParameter['releaseTypeUid'] = releaseTypeUid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request_body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editModel: async (uid: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('editModel', 'uid', uid)
            const localVarPath = `/api/v1.0/client/{uid}/edit-model`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientTypeUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByClientTypeUid: async (clientTypeUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-all-by-client-type-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientTypeUid !== undefined) {
                localVarQueryParameter['clientTypeUid'] = clientTypeUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientTypeUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByClientTypeUidSelect: async (clientTypeUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-all-by-client-type-uid-select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientTypeUid !== undefined) {
                localVarQueryParameter['clientTypeUid'] = clientTypeUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [searchString] 
         * @param {string} [xml] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildClients: async (uid?: string, searchString?: string, xml?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-child-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (xml !== undefined) {
                localVarQueryParameter['xml'] = xml;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [search] 
         * @param {string} [xml] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {boolean} [showDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildClientsSorting: async (uid?: string, search?: string, xml?: string, order?: string, sort?: string, showDeleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-child-clients-sorting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (xml !== undefined) {
                localVarQueryParameter['xml'] = xml;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenClientTypes: async (clientUID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-children-client-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUID !== undefined) {
                localVarQueryParameter['clientUID'] = clientUID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientHasBillableChildren: async (clientUID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-client-has-billable-children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUID !== undefined) {
                localVarQueryParameter['clientUID'] = clientUID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientHasChildren: async (clientUID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-client-has-children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUID !== undefined) {
                localVarQueryParameter['clientUID'] = clientUID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [request_body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditBulkClients: async (request_body?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-edit-bulk-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request_body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [resetCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseHealthCheck: async (resetCache?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-license-health-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resetCache !== undefined) {
                localVarQueryParameter['resetCache'] = resetCache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsPagedViewModel} [APIInternalFrontendAPIModelsPagedViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfUnitsWithSomethingWrong: async (APIInternalFrontendAPIModelsPagedViewModel?: APIInternalFrontendAPIModelsPagedViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-list-of-units-with-something-wrong`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsPagedViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsPagedViewModel} [APIInternalFrontendAPIModelsPagedViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLockedOrDueClients: async (APIInternalFrontendAPIModelsPagedViewModel?: APIInternalFrontendAPIModelsPagedViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-locked-or-due-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsPagedViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNameByUid: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getNameByUid', 'uid', uid)
            const localVarPath = `/api/v1.0/client/{uid}/name`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentClientTypeUid: async (clientUID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-parent-client-type-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUID !== undefined) {
                localVarQueryParameter['clientUID'] = clientUID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPuidByUid: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getPuidByUid', 'uid', uid)
            const localVarPath = `/api/v1.0/client/{uid}/parentuid`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResellerSubClients: async (clientUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/reseller-sub-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResellerUidByClientUid: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-reseller-uid-by-client-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelf: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-self`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleSearch: async (clientUid?: string, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/get-simple-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleByUid: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getSingleByUid', 'uid', uid)
            const localVarPath = `/api/v1.0/client/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialSubClients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/special-sub-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubClients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/sub-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [addonUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasModule: async (clientUid?: string, addonUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/client/has-module`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }

            if (addonUid !== undefined) {
                localVarQueryParameter['addonUid'] = addonUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} templateUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTemplate: async (uid: string, templateUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('setTemplate', 'uid', uid)
            // verify required parameter 'templateUid' is not null or undefined
            assertParamExists('setTemplate', 'templateUid', templateUid)
            const localVarPath = `/api/v1.0/client/{uid}/set-template/{templateUid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)))
                .replace(`{${"templateUid"}}`, encodeURIComponent(String(templateUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel} [APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient: async (uid: string, APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel?: APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('updateClient', 'uid', uid)
            const localVarPath = `/api/v1.0/client/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientStatistics(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsClientClientStatisticsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientStatistics(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientStatisticsTable(uid: string, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsClientClientStatisticsTableResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientStatisticsTable(uid, sort, order, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsClientPostCreateClientRequestModel} [APIInternalFrontendAPIModelsClientPostCreateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createClient(APIInternalFrontendAPIModelsClientPostCreateClientRequestModel?: APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClient(APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClientModel(languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsClientGetCreateClientRequestModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClientModel(languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [releaseTypeUid] 
         * @param {Array<string>} [request_body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editBulk(releaseTypeUid?: string, request_body?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editBulk(releaseTypeUid, request_body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editModel(uid: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsClientGetEditClientRequestModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editModel(uid, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientTypeUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllByClientTypeUid(clientTypeUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsClientClientViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllByClientTypeUid(clientTypeUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientTypeUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllByClientTypeUidSelect(clientTypeUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsSelectListItemSelectListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllByClientTypeUidSelect(clientTypeUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [searchString] 
         * @param {string} [xml] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildClients(uid?: string, searchString?: string, xml?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesClientTreeNode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildClients(uid, searchString, xml, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [search] 
         * @param {string} [xml] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {boolean} [showDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildClientsSorting(uid?: string, search?: string, xml?: string, order?: string, sort?: string, showDeleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesClientTreeNode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildClientsSorting(uid, search, xml, order, sort, showDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildrenClientTypes(clientUID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildrenClientTypes(clientUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientHasBillableChildren(clientUID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientHasBillableChildren(clientUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientHasChildren(clientUID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientHasChildren(clientUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [request_body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditBulkClients(request_body?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsClientClientMassEdit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditBulkClients(request_body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [resetCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseHealthCheck(resetCache?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsResellerHealthCheck>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseHealthCheck(resetCache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsPagedViewModel} [APIInternalFrontendAPIModelsPagedViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListOfUnitsWithSomethingWrong(APIInternalFrontendAPIModelsPagedViewModel?: APIInternalFrontendAPIModelsPagedViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsResellerResellerUnitVM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListOfUnitsWithSomethingWrong(APIInternalFrontendAPIModelsPagedViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsPagedViewModel} [APIInternalFrontendAPIModelsPagedViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLockedOrDueClients(APIInternalFrontendAPIModelsPagedViewModel?: APIInternalFrontendAPIModelsPagedViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsResellerResellerClient>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLockedOrDueClients(APIInternalFrontendAPIModelsPagedViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNameByUid(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNameByUid(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentClientTypeUid(clientUID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentClientTypeUid(clientUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPuidByUid(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPuidByUid(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResellerSubClients(clientUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsClientGetSubClientsDicResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResellerSubClients(clientUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResellerUidByClientUid(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResellerUidByClientUid(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelf(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsClientClientViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelf(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleSearch(clientUid?: string, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesClientMinimal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimpleSearch(clientUid, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleByUid(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsClientClientViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleByUid(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialSubClients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsClientGetSubClientsDicResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialSubClients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubClients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsClientGetSubClientsDicResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubClients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [addonUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasModule(clientUid?: string, addonUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasModule(clientUid, addonUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} templateUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTemplate(uid: string, templateUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTemplate(uid, templateUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel} [APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClient(uid: string, APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel?: APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClient(uid, APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatistics(uid: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsClientClientStatisticsResponseModel> {
            return localVarFp.clientStatistics(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsTable(uid: string, sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsClientClientStatisticsTableResponseModel> {
            return localVarFp.clientStatisticsTable(uid, sort, order, limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsClientPostCreateClientRequestModel} [APIInternalFrontendAPIModelsClientPostCreateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createClient(APIInternalFrontendAPIModelsClientPostCreateClientRequestModel?: APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.createClient(APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientModel(languageUid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsClientGetCreateClientRequestModel> {
            return localVarFp.createClientModel(languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [releaseTypeUid] 
         * @param {Array<string>} [request_body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBulk(releaseTypeUid?: string, request_body?: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.editBulk(releaseTypeUid, request_body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editModel(uid: string, languageUid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsClientGetEditClientRequestModel> {
            return localVarFp.editModel(uid, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientTypeUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByClientTypeUid(clientTypeUid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsClientClientViewModel>> {
            return localVarFp.getAllByClientTypeUid(clientTypeUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientTypeUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByClientTypeUidSelect(clientTypeUid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsSelectListItemSelectListItem>> {
            return localVarFp.getAllByClientTypeUidSelect(clientTypeUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [searchString] 
         * @param {string} [xml] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildClients(uid?: string, searchString?: string, xml?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesClientTreeNode>> {
            return localVarFp.getChildClients(uid, searchString, xml, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [search] 
         * @param {string} [xml] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {boolean} [showDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildClientsSorting(uid?: string, search?: string, xml?: string, order?: string, sort?: string, showDeleted?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesClientTreeNode>> {
            return localVarFp.getChildClientsSorting(uid, search, xml, order, sort, showDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenClientTypes(clientUID?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getChildrenClientTypes(clientUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientHasBillableChildren(clientUID?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.getClientHasBillableChildren(clientUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientHasChildren(clientUID?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.getClientHasChildren(clientUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [request_body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditBulkClients(request_body?: Array<string>, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsClientClientMassEdit>> {
            return localVarFp.getEditBulkClients(request_body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [resetCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseHealthCheck(resetCache?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsResellerHealthCheck>> {
            return localVarFp.getLicenseHealthCheck(resetCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsPagedViewModel} [APIInternalFrontendAPIModelsPagedViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfUnitsWithSomethingWrong(APIInternalFrontendAPIModelsPagedViewModel?: APIInternalFrontendAPIModelsPagedViewModel, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsResellerResellerUnitVM>> {
            return localVarFp.getListOfUnitsWithSomethingWrong(APIInternalFrontendAPIModelsPagedViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsPagedViewModel} [APIInternalFrontendAPIModelsPagedViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLockedOrDueClients(APIInternalFrontendAPIModelsPagedViewModel?: APIInternalFrontendAPIModelsPagedViewModel, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsResellerResellerClient>> {
            return localVarFp.getLockedOrDueClients(APIInternalFrontendAPIModelsPagedViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNameByUid(uid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getNameByUid(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentClientTypeUid(clientUID?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getParentClientTypeUid(clientUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPuidByUid(uid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPuidByUid(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResellerSubClients(clientUid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsClientGetSubClientsDicResponseModel> {
            return localVarFp.getResellerSubClients(clientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResellerUidByClientUid(options?: any): AxiosPromise<string> {
            return localVarFp.getResellerUidByClientUid(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelf(options?: any): AxiosPromise<APIInternalFrontendAPIModelsClientClientViewModel> {
            return localVarFp.getSelf(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleSearch(clientUid?: string, query?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesClientMinimal>> {
            return localVarFp.getSimpleSearch(clientUid, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleByUid(uid: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsClientClientViewModel> {
            return localVarFp.getSingleByUid(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialSubClients(options?: any): AxiosPromise<APIInternalFrontendAPIModelsClientGetSubClientsDicResponseModel> {
            return localVarFp.getSpecialSubClients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubClients(options?: any): AxiosPromise<APIInternalFrontendAPIModelsClientGetSubClientsDicResponseModel> {
            return localVarFp.getSubClients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [addonUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasModule(clientUid?: string, addonUid?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.hasModule(clientUid, addonUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} templateUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTemplate(uid: string, templateUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.setTemplate(uid, templateUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel} [APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient(uid: string, APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel?: APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.updateClient(uid, APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for clientStatistics operation in ClientApi.
 * @export
 * @interface ClientApiClientStatisticsRequest
 */
export interface ClientApiClientStatisticsRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiClientStatistics
     */
    readonly uid: string
}

/**
 * Request parameters for clientStatisticsTable operation in ClientApi.
 * @export
 * @interface ClientApiClientStatisticsTableRequest
 */
export interface ClientApiClientStatisticsTableRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiClientStatisticsTable
     */
    readonly uid: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiClientStatisticsTable
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiClientStatisticsTable
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof ClientApiClientStatisticsTable
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ClientApiClientStatisticsTable
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof ClientApiClientStatisticsTable
     */
    readonly search?: string
}

/**
 * Request parameters for createClient operation in ClientApi.
 * @export
 * @interface ClientApiCreateClientRequest
 */
export interface ClientApiCreateClientRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsClientPostCreateClientRequestModel}
     * @memberof ClientApiCreateClient
     */
    readonly APIInternalFrontendAPIModelsClientPostCreateClientRequestModel?: APIInternalFrontendAPIModelsClientPostCreateClientRequestModel
}

/**
 * Request parameters for createClientModel operation in ClientApi.
 * @export
 * @interface ClientApiCreateClientModelRequest
 */
export interface ClientApiCreateClientModelRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiCreateClientModel
     */
    readonly languageUid?: string
}

/**
 * Request parameters for editBulk operation in ClientApi.
 * @export
 * @interface ClientApiEditBulkRequest
 */
export interface ClientApiEditBulkRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiEditBulk
     */
    readonly releaseTypeUid?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ClientApiEditBulk
     */
    readonly request_body?: Array<string>
}

/**
 * Request parameters for editModel operation in ClientApi.
 * @export
 * @interface ClientApiEditModelRequest
 */
export interface ClientApiEditModelRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiEditModel
     */
    readonly uid: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiEditModel
     */
    readonly languageUid?: string
}

/**
 * Request parameters for getAllByClientTypeUid operation in ClientApi.
 * @export
 * @interface ClientApiGetAllByClientTypeUidRequest
 */
export interface ClientApiGetAllByClientTypeUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetAllByClientTypeUid
     */
    readonly clientTypeUid?: string
}

/**
 * Request parameters for getAllByClientTypeUidSelect operation in ClientApi.
 * @export
 * @interface ClientApiGetAllByClientTypeUidSelectRequest
 */
export interface ClientApiGetAllByClientTypeUidSelectRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetAllByClientTypeUidSelect
     */
    readonly clientTypeUid?: string
}

/**
 * Request parameters for getChildClients operation in ClientApi.
 * @export
 * @interface ClientApiGetChildClientsRequest
 */
export interface ClientApiGetChildClientsRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildClients
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildClients
     */
    readonly searchString?: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildClients
     */
    readonly xml?: string
}

/**
 * Request parameters for getChildClientsSorting operation in ClientApi.
 * @export
 * @interface ClientApiGetChildClientsSortingRequest
 */
export interface ClientApiGetChildClientsSortingRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildClientsSorting
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildClientsSorting
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildClientsSorting
     */
    readonly xml?: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildClientsSorting
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildClientsSorting
     */
    readonly sort?: string

    /**
     * 
     * @type {boolean}
     * @memberof ClientApiGetChildClientsSorting
     */
    readonly showDeleted?: boolean
}

/**
 * Request parameters for getChildrenClientTypes operation in ClientApi.
 * @export
 * @interface ClientApiGetChildrenClientTypesRequest
 */
export interface ClientApiGetChildrenClientTypesRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetChildrenClientTypes
     */
    readonly clientUID?: string
}

/**
 * Request parameters for getClientHasBillableChildren operation in ClientApi.
 * @export
 * @interface ClientApiGetClientHasBillableChildrenRequest
 */
export interface ClientApiGetClientHasBillableChildrenRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetClientHasBillableChildren
     */
    readonly clientUID?: string
}

/**
 * Request parameters for getClientHasChildren operation in ClientApi.
 * @export
 * @interface ClientApiGetClientHasChildrenRequest
 */
export interface ClientApiGetClientHasChildrenRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetClientHasChildren
     */
    readonly clientUID?: string
}

/**
 * Request parameters for getEditBulkClients operation in ClientApi.
 * @export
 * @interface ClientApiGetEditBulkClientsRequest
 */
export interface ClientApiGetEditBulkClientsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientApiGetEditBulkClients
     */
    readonly request_body?: Array<string>
}

/**
 * Request parameters for getLicenseHealthCheck operation in ClientApi.
 * @export
 * @interface ClientApiGetLicenseHealthCheckRequest
 */
export interface ClientApiGetLicenseHealthCheckRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClientApiGetLicenseHealthCheck
     */
    readonly resetCache?: boolean
}

/**
 * Request parameters for getListOfUnitsWithSomethingWrong operation in ClientApi.
 * @export
 * @interface ClientApiGetListOfUnitsWithSomethingWrongRequest
 */
export interface ClientApiGetListOfUnitsWithSomethingWrongRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsPagedViewModel}
     * @memberof ClientApiGetListOfUnitsWithSomethingWrong
     */
    readonly APIInternalFrontendAPIModelsPagedViewModel?: APIInternalFrontendAPIModelsPagedViewModel
}

/**
 * Request parameters for getLockedOrDueClients operation in ClientApi.
 * @export
 * @interface ClientApiGetLockedOrDueClientsRequest
 */
export interface ClientApiGetLockedOrDueClientsRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsPagedViewModel}
     * @memberof ClientApiGetLockedOrDueClients
     */
    readonly APIInternalFrontendAPIModelsPagedViewModel?: APIInternalFrontendAPIModelsPagedViewModel
}

/**
 * Request parameters for getNameByUid operation in ClientApi.
 * @export
 * @interface ClientApiGetNameByUidRequest
 */
export interface ClientApiGetNameByUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetNameByUid
     */
    readonly uid: string
}

/**
 * Request parameters for getParentClientTypeUid operation in ClientApi.
 * @export
 * @interface ClientApiGetParentClientTypeUidRequest
 */
export interface ClientApiGetParentClientTypeUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetParentClientTypeUid
     */
    readonly clientUID?: string
}

/**
 * Request parameters for getPuidByUid operation in ClientApi.
 * @export
 * @interface ClientApiGetPuidByUidRequest
 */
export interface ClientApiGetPuidByUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetPuidByUid
     */
    readonly uid: string
}

/**
 * Request parameters for getResellerSubClients operation in ClientApi.
 * @export
 * @interface ClientApiGetResellerSubClientsRequest
 */
export interface ClientApiGetResellerSubClientsRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetResellerSubClients
     */
    readonly clientUid?: string
}

/**
 * Request parameters for getSimpleSearch operation in ClientApi.
 * @export
 * @interface ClientApiGetSimpleSearchRequest
 */
export interface ClientApiGetSimpleSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetSimpleSearch
     */
    readonly clientUid?: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiGetSimpleSearch
     */
    readonly query?: string
}

/**
 * Request parameters for getSingleByUid operation in ClientApi.
 * @export
 * @interface ClientApiGetSingleByUidRequest
 */
export interface ClientApiGetSingleByUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiGetSingleByUid
     */
    readonly uid: string
}

/**
 * Request parameters for hasModule operation in ClientApi.
 * @export
 * @interface ClientApiHasModuleRequest
 */
export interface ClientApiHasModuleRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiHasModule
     */
    readonly clientUid?: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiHasModule
     */
    readonly addonUid?: string
}

/**
 * Request parameters for setTemplate operation in ClientApi.
 * @export
 * @interface ClientApiSetTemplateRequest
 */
export interface ClientApiSetTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiSetTemplate
     */
    readonly uid: string

    /**
     * 
     * @type {string}
     * @memberof ClientApiSetTemplate
     */
    readonly templateUid: string
}

/**
 * Request parameters for updateClient operation in ClientApi.
 * @export
 * @interface ClientApiUpdateClientRequest
 */
export interface ClientApiUpdateClientRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientApiUpdateClient
     */
    readonly uid: string

    /**
     * 
     * @type {APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel}
     * @memberof ClientApiUpdateClient
     */
    readonly APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel?: APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel
}

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * 
     * @param {ClientApiClientStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public clientStatistics(requestParameters: ClientApiClientStatisticsRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).clientStatistics(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiClientStatisticsTableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public clientStatisticsTable(requestParameters: ClientApiClientStatisticsTableRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).clientStatisticsTable(requestParameters.uid, requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiCreateClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public createClient(requestParameters: ClientApiCreateClientRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).createClient(requestParameters.APIInternalFrontendAPIModelsClientPostCreateClientRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiCreateClientModelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public createClientModel(requestParameters: ClientApiCreateClientModelRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).createClientModel(requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiEditBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public editBulk(requestParameters: ClientApiEditBulkRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).editBulk(requestParameters.releaseTypeUid, requestParameters.request_body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiEditModelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public editModel(requestParameters: ClientApiEditModelRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).editModel(requestParameters.uid, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetAllByClientTypeUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getAllByClientTypeUid(requestParameters: ClientApiGetAllByClientTypeUidRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getAllByClientTypeUid(requestParameters.clientTypeUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetAllByClientTypeUidSelectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getAllByClientTypeUidSelect(requestParameters: ClientApiGetAllByClientTypeUidSelectRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getAllByClientTypeUidSelect(requestParameters.clientTypeUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetChildClientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getChildClients(requestParameters: ClientApiGetChildClientsRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getChildClients(requestParameters.uid, requestParameters.searchString, requestParameters.xml, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetChildClientsSortingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getChildClientsSorting(requestParameters: ClientApiGetChildClientsSortingRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getChildClientsSorting(requestParameters.uid, requestParameters.search, requestParameters.xml, requestParameters.order, requestParameters.sort, requestParameters.showDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetChildrenClientTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getChildrenClientTypes(requestParameters: ClientApiGetChildrenClientTypesRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getChildrenClientTypes(requestParameters.clientUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetClientHasBillableChildrenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientHasBillableChildren(requestParameters: ClientApiGetClientHasBillableChildrenRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClientHasBillableChildren(requestParameters.clientUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetClientHasChildrenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientHasChildren(requestParameters: ClientApiGetClientHasChildrenRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClientHasChildren(requestParameters.clientUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetEditBulkClientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getEditBulkClients(requestParameters: ClientApiGetEditBulkClientsRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getEditBulkClients(requestParameters.request_body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetLicenseHealthCheckRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getLicenseHealthCheck(requestParameters: ClientApiGetLicenseHealthCheckRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getLicenseHealthCheck(requestParameters.resetCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetListOfUnitsWithSomethingWrongRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getListOfUnitsWithSomethingWrong(requestParameters: ClientApiGetListOfUnitsWithSomethingWrongRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getListOfUnitsWithSomethingWrong(requestParameters.APIInternalFrontendAPIModelsPagedViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetLockedOrDueClientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getLockedOrDueClients(requestParameters: ClientApiGetLockedOrDueClientsRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getLockedOrDueClients(requestParameters.APIInternalFrontendAPIModelsPagedViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetNameByUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getNameByUid(requestParameters: ClientApiGetNameByUidRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getNameByUid(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetParentClientTypeUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getParentClientTypeUid(requestParameters: ClientApiGetParentClientTypeUidRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getParentClientTypeUid(requestParameters.clientUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetPuidByUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getPuidByUid(requestParameters: ClientApiGetPuidByUidRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getPuidByUid(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetResellerSubClientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getResellerSubClients(requestParameters: ClientApiGetResellerSubClientsRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getResellerSubClients(requestParameters.clientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getResellerUidByClientUid(options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getResellerUidByClientUid(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getSelf(options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getSelf(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetSimpleSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getSimpleSearch(requestParameters: ClientApiGetSimpleSearchRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getSimpleSearch(requestParameters.clientUid, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiGetSingleByUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getSingleByUid(requestParameters: ClientApiGetSingleByUidRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getSingleByUid(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getSpecialSubClients(options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getSpecialSubClients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getSubClients(options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getSubClients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiHasModuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public hasModule(requestParameters: ClientApiHasModuleRequest = {}, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).hasModule(requestParameters.clientUid, requestParameters.addonUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiSetTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public setTemplate(requestParameters: ClientApiSetTemplateRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).setTemplate(requestParameters.uid, requestParameters.templateUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientApiUpdateClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClient(requestParameters: ClientApiUpdateClientRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClient(requestParameters.uid, requestParameters.APIInternalFrontendAPIModelsClientPostUpdateClientRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}
