

















import Spinner from "@components/vue-strap/Spinner.vue";

export default {
	name: "fp-card",
	components: {Spinner},
	props: {
		title: {default: "", type: String},
		noPrecalculatedHeight: {default: false, type: Boolean},
		isLoaded: {default: true, type: Boolean},
	}
};
