enum ClientType
{
    Inactive,
    Test,
    Vendor,
    Template,
    License_Light,
    License_Pro,
    Partners,
    ParentType,
    Closed,
    Reseller,
    MainClient,
    Subject
}

export default ClientType;