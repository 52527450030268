import {Module} from "vuex";

interface IFlexTicketTableIndexState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const flexTicketTableIndexState: Module<IFlexTicketTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        Description(state, getters, rootState) { return getConfig("FlexTickets.ColumnOptions.Description", rootState, true); },
        NumberOfUses(state, getters, rootState) { return getConfig("FlexTickets.ColumnOptions.NumberOfUses", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Description: getters.Description,
                NumberOfUses: getters.NumberOfUses,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Description: true,
                NumberOfUses: true,
            };
        }
    }
}

export default flexTicketTableIndexState;