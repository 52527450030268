import {Module} from "vuex";
import axios, {CancelTokenSource} from "axios";
import {StatisticsApi} from "../../_Services/Autogenerated_v4/V1/api/statistics-api";
import {
	APIInternalFrontendAPIDataAccessCubeEntityGetByDateResponse,
	APIInternalFrontendAPIDataAccessCubeEntityGetByHourResponse,
	APIInternalFrontendAPIDataAccessCubeEntityGetByMonthResponse,
	APIInternalFrontendAPIDataAccessCubeEntityGetByWeekdayResponse,
	APIInternalFrontendAPIDataAccessCubeEntityGetByWeekResponse
} from "../../_Services/Autogenerated_v4/V1/models";
import * as moment from "moment";
import isNullOrEmpty from "../isNullOrEmpty";

interface IStatisticsIndexState {
	savedSearchParams: object;
	cancelToken: CancelTokenSource;
	StatisticsService: StatisticsApi;
	lastUpdated: string;
	dates: {
		startDate: string;
		endDate: string;
		compareStartDate: string;
		compareEndDate: string;
	}
}

const statisticsIndexState: Module<IStatisticsIndexState, any> = {
	namespaced: true,

	state: () => ({
		savedSearchParams: null,
		cancelToken: null,
		StatisticsService: null,
		lastUpdated: null,
		dates: {
			startDate: null,
			endDate: null,
			compareStartDate: null,
			compareEndDate: null,
		}
	}),
	actions: {
		SET_DATES({state, commit}, args) {
			commit("SET_DATES", args);
		},
		SET_LAST_UPDATED({ commit }, args) {
			commit("SET_LAST_UPDATED", args)
		},
		SET_SERVICE({state, commit, rootGetters}) {
			if (state.StatisticsService) return;
			
			const config = rootGetters["getSwaggerAxiosOptions"];
			commit("SET_SERVICE", new StatisticsApi(config));
		},
		SET_SAVED_SEARCH_PARAMS(context, args) {
			context.commit("SET_SAVED_SEARCH_PARAMS", args);
		},

		async updateLastUpdated({ state, dispatch }) {
			await dispatch("SET_SERVICE");

			const response = await state.StatisticsService.lastUpdated({
				cancelToken: state.cancelToken.token
			});

			if (response) dispatch("SET_LAST_UPDATED", moment.utc(response.data).local().format("DD-MM-YYYY HH:mm:ss"));
		},
		async getTurnoverADay({ state, rootGetters, dispatch }): Promise<APIInternalFrontendAPIDataAccessCubeEntityGetByDateResponse> {
			await dispatch("SET_SERVICE");
			let response;

			const { startDate, endDate, compareStartDate, compareEndDate } = await dispatch("getDatesFormatted", true);
			
			if (compareEndDate == null) {
				response = await state.StatisticsService.turnoverByDay(
					{
						startDate,
						endDate,
					}, {
						cancelToken: state.cancelToken.token
					});
			}
			else {
				response = await state.StatisticsService.turnoverByDayCompare(
					{
						startDate,
						endDate,
						compareStartDate,
						compareEndDate,
					}, {
						cancelToken: state.cancelToken.token
					});
			}

			return response.data;
		},
		async getTurnoverAMonth({ state, rootGetters, dispatch }): Promise<APIInternalFrontendAPIDataAccessCubeEntityGetByMonthResponse> {
			await dispatch("SET_SERVICE");
			let response;

			const { startDate, endDate, compareStartDate, compareEndDate } = await dispatch("getDatesFormatted", true);
			
			if (compareEndDate == null) {
				response = await state.StatisticsService.turnoverByMonth(
					{
						startDate,
						endDate,
					}, {
						cancelToken: state.cancelToken.token
					});
			}
			else {
				response = await state.StatisticsService.turnoverByMonthCompare(
					{
						startDate,
						endDate,
						compareStartDate,
						compareEndDate,
					}, {
						cancelToken: state.cancelToken.token
					});
			}

			return response.data;
		},
		async getTurnoverAWeek({ state, rootGetters, dispatch }): Promise<APIInternalFrontendAPIDataAccessCubeEntityGetByWeekResponse> {
			await dispatch("SET_SERVICE");
			let response;

			const { startDate, endDate, compareStartDate, compareEndDate } = await dispatch("getDatesFormatted", true);

			if (compareEndDate == null) {
				response = await state.StatisticsService.turnoverByWeek(
					{
						startDate,
						endDate,
					}, {
						cancelToken: state.cancelToken.token
					});
			}
			else {
				response = await state.StatisticsService.turnoverByWeekCompare(
					{
						startDate,
						endDate,
						compareStartDate,
						compareEndDate,
					}, {
						cancelToken: state.cancelToken.token
					});
			}

			return response.data;
		},
		async getTurnoverAWeekday({ state, dispatch }): Promise<APIInternalFrontendAPIDataAccessCubeEntityGetByWeekdayResponse> {
			await dispatch("SET_SERVICE");
			let response;
			
			const { startDate, endDate, compareStartDate, compareEndDate } = await dispatch("getDatesFormatted", true);

			if (compareEndDate == null) {
				response = await state.StatisticsService.turnoverByWeekDay(
					{
						startDate,
						endDate,
					}, {
						cancelToken: state.cancelToken.token
					});
			}
			else {
				response = await state.StatisticsService.turnoverByWeekDayCompare(
					{
						startDate,
						endDate,
						compareStartDate,
						compareEndDate,
					}, {
						cancelToken: state.cancelToken.token
					});
			}

			return response.data;
		},
		async getTurnoverByHour({ state, dispatch }): Promise<APIInternalFrontendAPIDataAccessCubeEntityGetByHourResponse> {
			await dispatch("SET_SERVICE");
			let response;

			const { startDate, endDate, compareStartDate, compareEndDate } = await dispatch("getDatesFormatted", true);

			if (compareEndDate == null) {
				response = await state.StatisticsService.turnoverByHour(
					{
						startDate,
						endDate,
					}, {
						cancelToken: state.cancelToken.token
					});
			}
			else {
				response = await state.StatisticsService.turnoverByHourCompare(
					{
						startDate,
						endDate,
						compareStartDate,
						compareEndDate,
					}, {
						cancelToken: state.cancelToken.token
					});
			}

			return response.data;
		},
		getDatesFormatted({state}, defaultCompareToNull = false) {
			const startDate = Number(moment(state.dates.startDate, "DD-MM-YYYY").format("YYYYMMDD"));
			const endDate = Number(moment(state.dates.endDate, "DD-MM-YYYY").format("YYYYMMDD"));

			let compareStartDate;
			if (isNullOrEmpty(state.dates.compareStartDate))
				compareStartDate = defaultCompareToNull ? null : Number(moment(state.dates.startDate, "DD-MM-YYYY").add(-1, "year").format("YYYYMMDD"));
			else
				compareStartDate = Number(moment(state.dates.compareStartDate, "DD-MM-YYYY").format("YYYYMMDD"));

			let compareEndDate;
			if (isNullOrEmpty(state.dates.compareEndDate))
				compareEndDate = defaultCompareToNull ? null : Number(moment(state.dates.endDate, "DD-MM-YYYY").add(-1, "year").format("YYYYMMDD"));
			else
				compareEndDate = Number(moment(state.dates.compareEndDate, "DD-MM-YYYY").format("YYYYMMDD"));

			return {startDate, endDate, compareStartDate, compareEndDate};
		},
		async getCurrentAndPreviousTurnoverParentAverage({ state, dispatch }) {
			await dispatch("SET_SERVICE");

			const { startDate, endDate, compareStartDate, compareEndDate } = await dispatch("getDatesFormatted");
			
			const response = await state.StatisticsService.currentAndPreviousTurnoverParentAverage(
				{
					startDate,
					endDate,
					compareStartDate,
					compareEndDate,
				}, {
					cancelToken: state.cancelToken.token
				});

			return response;
		},

		async getCurrentAndPreviousTurnoverParent({ state, dispatch }) {
			await dispatch("SET_SERVICE");

			const { startDate, endDate, compareStartDate, compareEndDate } = await dispatch("getDatesFormatted");

			const response = await state.StatisticsService.currentAndPreviousTurnoverParent(
				{
					startDate,
					endDate,
					compareStartDate,
					compareEndDate,
				}, {
					cancelToken: state.cancelToken.token
				});

			return response;
		},
		async getTurnoverPerPosUserPaged({ state, rootGetters, dispatch }, args: any) {
			await dispatch("SET_SERVICE");

			let response;
			const { startDate, endDate, compareStartDate, compareEndDate } = await dispatch("getDatesFormatted", true);

			if (!compareEndDate) {
				response = await state.StatisticsService.turnoverPerPosUserPaged(
					{
						startDate,
						endDate,
						sort: args.sort,
						order: args.order,
						limit: args.limit,
						offset: args.offset,
						search: null,
					}, {
						cancelToken: state.cancelToken.token
					});
			}
			else {
				response = await state.StatisticsService.turnoverPerPosUserComparePaged(
					{
						startDate,
						endDate,
						compareStartDate,
						compareEndDate,
						sort: args.sort,
						order: args.order,
						limit: args.limit,
						offset: args.offset,
						search: null,
					}, {
						cancelToken: state.cancelToken.token
					});
			}

			return response;
		},
		async getTurnoverPerProductPaged({ state, rootGetters, dispatch }, args: any) {
			await dispatch("SET_SERVICE");

			let response;
			const { startDate, endDate, compareStartDate, compareEndDate } = await dispatch("getDatesFormatted", true);

			if (!compareEndDate) {
				response = await state.StatisticsService.turnoverPerProductUncappedPaged(
					{
						startDate,
						endDate,
						sort: args.sort,
						order: args.order,
						limit: args.limit,
						offset: args.offset,
						search: null,
					}, {
						cancelToken: state.cancelToken.token
					});
			}
			else {
				response = await state.StatisticsService.turnoverPerProductComparePaged(
					{
						startDate,
						endDate,
						compareStartDate,
						compareEndDate,
						sort: args.sort,
						order: args.order,
						limit: args.limit,
						offset: args.offset,
						search: null,
					}, {
						cancelToken: state.cancelToken.token
					});
			}

			return response.data;
		},
		async getTurnoverPerClientParentPaged({ state, rootGetters, dispatch }, args: any) {
			await dispatch("SET_SERVICE");

			let response;
			const { startDate, endDate, compareStartDate, compareEndDate } = await dispatch("getDatesFormatted", true);

			if (!compareEndDate) {
				response = await state.StatisticsService.turnoverPerClientParentPaged(
					{
						startDate,
						endDate,
						sort: args.sort,
						order: args.order,
						limit: args.limit,
						offset: args.offset,
						search: null,
					}, {
						cancelToken: state.cancelToken.token
					});
			}
			else {
				response = await state.StatisticsService.turnoverPerClientParentComparePaged(
					{
						startDate,
						endDate,
						compareStartDate,
						compareEndDate,
						sort: args.sort,
						order: args.order,
						limit: args.limit,
						offset: args.offset,
						search: null,
					}, {
						cancelToken: state.cancelToken.token
					});
			}

			return response;
		},
	},
	mutations: {
		SET_DATES(state, newState) {
			state.dates = newState;
		},
		SET_LAST_UPDATED(state, newState) {
			state.lastUpdated = newState;
		},
		SET_SERVICE(state, service) {
			state.StatisticsService = service;
		},
		SET_SAVED_SEARCH_PARAMS(state, newState) {
			state.savedSearchParams = newState;
		},
		CANCEL_ALL_REQUESTS(state, message) {
			console.log("New CancelToken");
			state.cancelToken?.cancel(message);
			state.cancelToken = axios.CancelToken.source();
		}
	},
	getters: {
		getSavedSearchParams(state, getters) {
			return state.savedSearchParams;
		},
		getCancelToken(state): CancelTokenSource {
			return state.cancelToken;
		},
		getDates(state) {
			return state.dates;
		},
		getLastUpdated(state) {
			return state.lastUpdated;
		},
		hasCompare(state, getters) {
			//@ts-ignore
			return state.savedSearchParams?.compareStartDate != null;
		},
	}
};

export default statisticsIndexState;