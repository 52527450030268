import _Vue from "vue";

export function VueResource<T>(Vue: typeof _Vue): void {
	// @ts-ignore
	Vue.Resource = window.Resource;

	// @ts-ignore
	Object.defineProperties(Vue.prototype, {
		Resource: {
			get() {
				// @ts-ignore
				return window.Resource;
			}
		}
	});
}
