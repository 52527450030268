import {Module} from "vuex";
import {AuthenticationApiApi} from "../../_Services/Autogenerated_v4/V1/api/authentication-api-api";
let AuthenticationService: AuthenticationApiApi;

interface IStringDictionary<V> {
	[index: string]: V;
}
interface IActionTableIndexState {
	cachedTooltipScope: IStringDictionary<object>;
}
const actionTableIndexState: Module<IActionTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
		cachedTooltipScope: {},
    }),
    actions: {
		async FETCH_SCOPES_TOOLTIP_BY_TOKEN(context, token) {
			if (!AuthenticationService) {
                AuthenticationService = new AuthenticationApiApi(context.rootGetters["getSwaggerAxiosOptions"]);
			}
			return (await AuthenticationService.getScopesTooltipByVoucherAuthenticationUid({voucherAuthenticationUid: token})).data;
		},

		async SET_SCOPES_TOOLTIP_BY_TOKEN(context, args: {token: string, obj: string[] }) {
			context.commit("SET_SCOPE_TOOLTIP", args);
		},
		async getCachedTooltipScopes(context, token) {
			let scopes = context.getters.getCachedTooltipScopes(token);
			if (!scopes) {
				scopes = await context.dispatch("FETCH_SCOPES_TOOLTIP_BY_TOKEN", token);
				await context.dispatch("SET_SCOPES_TOOLTIP_BY_TOKEN", { token: token, obj: scopes });
			}
			return scopes;
		},
    },
    mutations: {
		SET_SCOPE_TOOLTIP(state, args: {token: string, obj: string[] }) {
			state.cachedTooltipScope[args.token] = args.obj;
		},
		RESET_SCOPE_TOOLTIP(state) {
			state.cachedTooltipScope = {};
		},
    },
    getters: {
		getCachedTooltipScopes(state) {
			return (token: string) => {
				return state.cachedTooltipScope[token];
			};
		},
    }
}

export default actionTableIndexState;