import axios from "axios";

import Vue from 'vue';
import VuexPersistence from 'vuex-persist';
import * as accounting from "accounting";

import "/FlexSystem/styles/bootstrap-custom.scss";
import "vue-multiselect/dist/vue-multiselect.min.css";
import '/FlexSystem/styles/vue-multiselect-custom.scss';
import '/FlexSystem/Content/css/bootstrap-table.css';
import '/FlexSystem/Scripts/@simonwep/pickr/dist/themes/classic.min.css';
import 'select2/dist/css/select2.min.css';
import '/FlexSystem/Content/bootstrap-datepicker3.min.css';
import "c3/c3.min.css";

import "./Shared/Types/GUID.js";
import "./Shared/FrontendApi/FrontendApi.js";
import "./Shared/ColorPicker.js";
import "./Shared/vendors/jquery.unobtrusive-ajax";
import "./Shared/vendors/jquery.serializeToJSON";
import "./Shared/vendors/bootstrap-datepicker/bootstrap-datepicker";
import "./Shared/Defaults/JQuery";

import {IUserSession} from "../interfaces/IUserSession";
import {DateFormat_Resource} from "../Resources/DateFormat/Resource";
import {Resource} from "../Resources/Resource";
import {Configuration} from "../_Services/Autogenerated_v4/V1/configuration";
import {APIInternalFrontendAPIDataAccessEntitiesUser as API_Internal_FrontendAPI_DataAccess_Entities_User} from "../_Services/Autogenerated_v4/V1/models/apiinternal-frontend-apidata-access-entities-user";
import {APIInternalFrontendAPIModelsClientClientViewModel as API_Internal_FrontendAPI_Models_Client_ClientViewModel} from "../_Services/Autogenerated_v4/V1/models/apiinternal-frontend-apimodels-client-client-view-model";

import {ClientApi} from "../_Services/Autogenerated_v4/V1/api/client-api";
import {UserApi} from "../_Services/Autogenerated_v4/V1/api/user-api";
import {FlexPosConfigApi} from "../_Services/Autogenerated_v4/V1/api/flex-pos-config-api";
import {AddonApi} from "../_Services/Autogenerated_v4/V1/api/addon-api";


import VueCookies from 'vue-cookies';
import {Plugin as VueFragment} from "vue-fragment";
import Vuelidate from 'vuelidate';
// Load the Vue router
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import FpTable from "./_Components/tables/fp-table.vue";
import FpTableColumn from "./_Components/tables/fp-table-column.vue";
import FpAccordion from "./_Components/fp-accordion/fp-accordion.vue";
import FpAccordionItem from "./_Components/fp-accordion/fp-accordion-item.vue";
import FpPanel from "./_Components/fp-panel/fp-panel.vue";
import FpPanelTab from "./_Components/fp-panel/fp-panel-tab.vue";
import FpCard from "./_Components/fp-card/fp-card.vue";

import BackButton from "./_Components/back-button.vue";
import VueSwitches from "./_Components/vue-switches/src/switches.vue";
import {VueMoment} from "../vue-plugins/vue-moment";
import {VueAccounting} from "../vue-plugins/vue-accounting/index";
import {VueLog} from "../vue-plugins/vue-log";
import {VueResource} from "../vue-plugins/vue-resource";
import {VueResourceDateFormat} from "../vue-plugins/vue-resource-dateformat";
import {VueUserSession} from "../vue-plugins/vue-usersession";
import {VueToast} from "../vue-plugins/vue-toast";
import {VueBootstrap} from "../vue-plugins/vue-bootstrap";
import {VueSwaggerAxiosOptions} from "../vue-plugins/vue-swaggerAxiosOptions";
import {config, library} from '@fortawesome/fontawesome-svg-core';

import Vuex, {Store} from 'vuex';
import AspNetViewComponent from "./_Pages/asp-net-view.vue";
import SettingsIndexViewComponent from "./_Pages/settings/settings-index.vue";
import PortalVue from 'portal-vue';

import navigationGuardState from "./_StoreModules/navigationGuardState.ts";
import storeValidation from "./_StoreModules/storeValidation.ts";

/*#region Dashboard*/
/*Overview*/
import overviewIndexState from "./_StoreModules/overviewIndexState";
import unitsFailureTableIndexState from "./_StoreModules/unitsFailureTableIndexState";
import pipelineTableIndexState from "./_StoreModules/pipelineTableIndexState";
import obsListTableIndexState from "./_StoreModules/obsListTableIndexState";
/*Client*/
import clientBulkEditTableState from "./_StoreModules/clientBulkEditTableState";
/*Currency*/
import currencyTableIndexState from "./_StoreModules/currencyTableIndexState";
/*Customers*/
import customerTableIndexState from "./_StoreModules/customerTableIndexState";
import customerTransactionHistoryDetailsState from "./_StoreModules/customerTransactionHistoryDetailsState";
import customerBulkDeleteTableState from "./_StoreModules/customerBulkDeleteTableState";
/*Customer Groups*/
import customerGroupTableIndexState from "./_StoreModules/customerGroupTableIndexState";
/*Financial Product Groups*/
import financialProductGroupTableIndexState from "./_StoreModules/financialProductGroupTableIndexState";
/*Statistics*/
import statisticsIndexState from "./_StoreModules/statisticsIndexState";
/*Products*/
import productConfigState, {IProductConfigState} from "./_StoreModules/productConfigState";
import productTableIndexState, {IProductTableState} from "./_StoreModules/productTableIndex";
import productBulkEditTableState from "./_StoreModules/productBulkEditTableState";
import productBulkDeleteTableState from "./_StoreModules/productBulkDeleteTableState";
import productDeleteTableState from "./_StoreModules/productDeleteTableState";
import productStockTableIndexState from "./_StoreModules/productStockTableIndex";
import productStockDetailsTableState from "./_StoreModules/productStockDetailsTableState";
import productStockConfigState from "./_StoreModules/productStockConfigState";
import productStockEnumState from "./_StoreModules/productStockEnumState";
/*Product Groups*/
import productDisplayGroupTableIndexState from "./_StoreModules/productDisplayGroupTableIndexState";
/*Product Brands*/
import productBrandTableIndexState from "./_StoreModules/productBrandTableIndexState";
/*Reports*/
import dynamicReportState from "./_StoreModules/dynamicReportState";
import dynamicReportColumnsIndexState from "./_StoreModules/dynamicReportColumnsIndexState";
/*Cash management*/
import cashManagementTableIndexState from "./_StoreModules/cashManagementTableIndexState";
/*Campaign Module*/
import campaignTableIndexState from "./_StoreModules/campaignTableIndexState";
import campaignProductsTableState from "./_StoreModules/campaignProductsTableState";
/*Product List*/
import productListTableIndexState from "./_StoreModules/productListTableIndexState";
/*Users*/
import userTableIndexState from "./_StoreModules/userTableIndexState";
import posUserTableIndexState from "./_StoreModules/posUserTableIndexState";
/*Warranty*/
import warrantyTableIndexState from "./_StoreModules/warrantyTableIndexState";
/*#endregion*/
/*region ResellerModule*/
/*Billing*/
import billingTableIndexState from "./_StoreModules/billingTableIndexState";
/*Reseller*/
import resellerTableIndexState from "./_StoreModules/resellerTableIndexState";
import actionsTableIndexState from "./_StoreModules/actionsTableIndexState";
/*#endregion*/
/*#region Settings*/
/*Bon Layout*/
import bonLayoutModuleIndexState from "./_StoreModules/bonLayoutModuleIndexState";

/*Card Group*/
import cardGroupTableIndexState from "./_StoreModules/cardGroupTableIndexState";

/*Customer Statistics*/
import customerStatisticsIndexState from "./_StoreModules/customerStatisticsIndexState";

/*Design And Layout*/
import buttonFunctionsState from "./_StoreModules/buttonFunctionsState";
/*Sync Job*/
import syncJobQueueTableIndexState from "./_StoreModules/syncJobQueueTableIndexState";
import syncJobFailedTableIndexState from "./_StoreModules/syncJobFailedTableIndexState";
import syncJobDoneTableIndexState from "./_StoreModules/syncJobDoneTableIndexState";
/*Report Sync Job*/
import reportSyncJobTableIndexState from "./_StoreModules/reportSyncJobTableIndexState";
/*FlexPosConfig*/
import flexPosConfigTableIndexState from "./_StoreModules/flexPosConfigTableIndexState";
/*Payment Type*/
import paymentTypesState from "./_StoreModules/paymentTypesState";

/*Product Campaign*/
import productCampaignTableIndexState from "./_StoreModules/productCampaignTableIndexState";
import productCampaignTableAddProductsModalState from "./_StoreModules/productCampaignTableAddProductsModalState";

/*Product Import*/
import productImportIndexState from "./_StoreModules/productImportIndexState";
/*#endregion*/
/*Gift Card*/
import giftCardTableIndexState from "./_StoreModules/giftCardTableIndexState";
/*Flex Ticket*/
import flexTicketTableIndexState from "./_StoreModules/flexTicketTableIndexState";
/*Transaction history*/
import transactionHistoryTableIndexState from "./_StoreModules/transactionHistoryTableIndexState";
import transactionHistoryDetailsState from "./_StoreModules/transactionHistoryDetailsState";
/*#region Vendor Modules*/
/*Vendor*/
import vendorTableIndexState from "./_StoreModules/vendorTableIndexState";
/*Order*/
import orderTableIndexState from "./_StoreModules/orderTableIndexState";
import orderDetailsState from "./_StoreModules/orderDetailsState";
/*GoodsReception*/
import goodsReceptionTableIndexState from "./_StoreModules/goodsReceptionTableIndexState";
import goodsReceptionDetailsState from "./_StoreModules/goodsReceptionDetailsState";
import vendorModalTableState from "./_StoreModules/vendorModalTableState";
/*#endregion*/
import statusModuleNotApprovedTableIndex from "./_StoreModules/statusModuleNotApprovedTableIndex";
import statusModuleApprovedTableIndex from "./_StoreModules/statusModuleApprovedTableIndex";
import statusModuleFinalizedTableIndex from "./_StoreModules/statusModuleFinalizedTableIndex";
import statusModuleEditTableIndex from "./_StoreModules/statusModuleEditTableIndex";
import statusModuleIndex from "./_StoreModules/statusModuleIndex";

/*Other*/
import topMenuState from "./_StoreModules/topMenuState";
import notificationState from "./_StoreModules/notificationState";
import activeAddonsState from "./_StoreModules/activeAddonsState";
import backendConfigState from "./_StoreModules/backendConfigState";
import productAutocompleteState from "./_StoreModules/productAutocompleteState";

/*#region Admin*/
/*FlexDeploy*/
import flexDeployTableIndexState from "./_StoreModules/flexDeployTableIndexState";
import flexDeployBulkEditTableState from "./_StoreModules/flexDeployBulkEditTableState";
/*#endregion*/

/*EnumTypes*/
import enumTypesTableIndexState from "./_StoreModules/enumTypesTableIndexState";
import enumTableIndexState from "./_StoreModules/enumTableIndexState";

/*Language*/
import languageTableIndexState from "./_StoreModules/languageTableIndexState";

import ClientType from '../_Enums/ClientType';
import {CombinedVueInstance} from 'vue/types/vue';
import isNullOrEmpty from "./isNullOrEmpty";
import {Guid} from "../_Types/Guid";
import {
	Alert,
	Button,
	Carousel,
	Collapse,
	Dropdown,
	Modal,
	Offcanvas,
	Popover,
	ScrollSpy,
	Tab,
	Toast,
	Tooltip
} from "bootstrap";

config.autoAddCss = false;

import {faSearch as farSearch} from '@fortawesome/pro-regular-svg-icons/faSearch';
import {faPen as farPen} from '@fortawesome/pro-regular-svg-icons/faPen';
import {faTrashAlt as farTrashAlt} from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import {faChevronDoubleRight as farChevronDoubleRight} from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';
import {faChevronDoubleLeft as farChevronDoubleLeft} from '@fortawesome/pro-regular-svg-icons/faChevronDoubleLeft';
import {faChevronDown as farChevronDown} from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import {faSpinner as farSpinner} from '@fortawesome/pro-regular-svg-icons/faSpinner';
import {faLock as farLock} from '@fortawesome/pro-regular-svg-icons/faLock';
import {faArchive as farArchive} from '@fortawesome/pro-regular-svg-icons/faArchive';
import {faArrowsV as farArrowsV} from '@fortawesome/pro-regular-svg-icons/faArrowsV';
import {faArrowCircleLeft as farArrowCircleLeft} from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import {faMobile as farMobile} from "@fortawesome/pro-regular-svg-icons/faMobile";
import {faHistory as farHistory} from '@fortawesome/pro-regular-svg-icons/faHistory';
import {faBadgePercent as farBadgePercent} from "@fortawesome/pro-regular-svg-icons/faBadgePercent";
import {faSpinnerThird as farSpinnerThird} from "@fortawesome/pro-regular-svg-icons/faSpinnerThird";
import {faFloppyDisk as farFloppyDisk} from "@fortawesome/pro-regular-svg-icons/faFloppyDisk";
import {faSquareArrowUpRight as farSquareArrowUpRight} from "@fortawesome/pro-regular-svg-icons/faSquareArrowUpRight";
import {faEyeSlash as farEyeSlash} from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import {faEye as farEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faArrowDownToBracket as farArrowDownToBracket} from "@fortawesome/pro-regular-svg-icons/faArrowDownToBracket";
import {faCircleInfo as farCircleInfo} from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import {faDownload as farDownload} from "@fortawesome/pro-regular-svg-icons/faDownload";
import {faDisplayArrowDown as farDisplayArrowDown} from '@fortawesome/pro-regular-svg-icons/faDisplayArrowDown';
import {faAngleDown as farAngleDown} from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import {faAngleUp as farAngleUp} from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import {faMoneyCheckDollarPen as farMoneyCheckDollarPen} from '@fortawesome/pro-regular-svg-icons/faMoneyCheckDollarPen';
import {faSlash as farSlash} from '@fortawesome/pro-regular-svg-icons/faSlash';

library.add(
	farSearch, farPen, farTrashAlt, farChevronDoubleRight, farChevronDoubleLeft, 
	farChevronDown, farSpinner, farLock, farArchive, farArrowsV, farArrowCircleLeft, 
	farMobile, farHistory, farBadgePercent, farSpinnerThird, farFloppyDisk, farSlash,
	farSquareArrowUpRight, farEyeSlash, farEye, farArrowDownToBracket, farCircleInfo,
	farDownload, farDisplayArrowDown, farAngleDown, farAngleUp, farMoneyCheckDollarPen,
);

import {faHomeAlt as falHomeAlt} from '@fortawesome/pro-light-svg-icons/faHomeAlt';
import {faTags as falTags} from '@fortawesome/pro-light-svg-icons/faTags';
import {faTag as falTag} from '@fortawesome/pro-light-svg-icons/faTag';
import {faFilter as falFilter} from '@fortawesome/pro-light-svg-icons/faFilter';
import {faTools as falTools} from '@fortawesome/pro-light-svg-icons/faTools';
import {faUserAlt as falUserAlt} from '@fortawesome/pro-light-svg-icons/faUserAlt';
import {faCog as falCog} from '@fortawesome/pro-light-svg-icons/faCog';
import {faCopy as falCopy} from '@fortawesome/pro-light-svg-icons/faCopy';
import {faArrowAltToTop as falArrowAltToTop} from '@fortawesome/pro-light-svg-icons/faArrowAltToTop';
import {faArrowAltUp as falArrowAltUp} from '@fortawesome/pro-light-svg-icons/faArrowAltUp';
import {faBell as falBell} from '@fortawesome/pro-light-svg-icons/faBell';
import {faStore as falStore} from '@fortawesome/pro-light-svg-icons/faStore';
import {faExclamationCircle as falExclamationCircle} from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import {faCheckCircle as falCheckCircle} from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import {faServer as falServer} from "@fortawesome/pro-light-svg-icons/faServer";
import {faGraduationCap as falGraduationCap} from "@fortawesome/pro-light-svg-icons/faGraduationCap";
import {faBarcodeAlt as falBarcodeAlt} from "@fortawesome/pro-light-svg-icons/faBarcodeAlt";
import {faBoxOpen as falBoxOpen} from "@fortawesome/pro-light-svg-icons/faBoxOpen";
import {faExpandArrows as falExpandArrows} from "@fortawesome/pro-light-svg-icons/faExpandArrows";
import {faUserFriends as falUserFriends} from "@fortawesome/pro-light-svg-icons/faUserFriends";
import {faTruck as falTruck} from "@fortawesome/pro-light-svg-icons/faTruck";
import {faFileChartLine as falFileChartLine} from "@fortawesome/pro-light-svg-icons/faFileChartLine";
import {faAddressCard as falAddressCard} from "@fortawesome/pro-light-svg-icons/faAddressCard";
import {faGift as falGift} from "@fortawesome/pro-light-svg-icons/faGift";
import {faBadgePercent as falBadgePercent} from "@fortawesome/pro-light-svg-icons/faBadgePercent";
import {faCogs as falCogs} from "@fortawesome/pro-light-svg-icons/faCogs";
import {faUserLock as falUserLock} from "@fortawesome/pro-light-svg-icons/faUserLock";
import {faUndo as falUndo} from "@fortawesome/pro-light-svg-icons/faUndo";
import {faFileInvoiceDollar as falFileInvoiceDollar} from "@fortawesome/pro-light-svg-icons/faFileInvoiceDollar";
import {faFlag as falFlag} from "@fortawesome/pro-light-svg-icons/faFlag";
import {faExchange as falExchange} from "@fortawesome/pro-light-svg-icons/faExchange";
import {faPrint as falPrint} from "@fortawesome/pro-light-svg-icons/faPrint";
import {faTimes as falTimes} from "@fortawesome/pro-light-svg-icons/faTimes";
import {faDownload as falDownload} from "@fortawesome/pro-light-svg-icons/faDownload";
import {faArchive as falArchive} from "@fortawesome/pro-light-svg-icons/faArchive";
import {faList as falList} from "@fortawesome/pro-light-svg-icons/faList";
import {faPencilRuler as falPencilRuler} from "@fortawesome/pro-light-svg-icons/faPencilRuler";
import {faCubes as falCubes} from "@fortawesome/pro-light-svg-icons/faCubes";
import {faLayerGroup as falLayerGroup} from "@fortawesome/pro-light-svg-icons/faLayerGroup";
import {faCopyright as falCopyright} from "@fortawesome/pro-light-svg-icons/faCopyright";
import {faUsers as falUsers} from "@fortawesome/pro-light-svg-icons/faUsers";
import {faPersonDolly as falPersonDolly} from "@fortawesome/pro-light-svg-icons/faPersonDolly";
import {faPeopleCarry as falPeopleCarry} from "@fortawesome/pro-light-svg-icons/faPeopleCarry";
import {faParachuteBox as falParachuteBox} from "@fortawesome/pro-light-svg-icons/faParachuteBox";
import {faReceipt as falReceipt} from "@fortawesome/pro-light-svg-icons/faReceipt";
import {faChartPie as falChartPie} from "@fortawesome/pro-light-svg-icons/faChartPie";
import {faKey as falKey} from "@fortawesome/pro-light-svg-icons/faKey";
import {faWrench as falWrench} from "@fortawesome/pro-light-svg-icons/faWrench";
import {faLock as falLock} from "@fortawesome/pro-light-svg-icons/faLock";
import {faCreditCard as falCreditCard} from "@fortawesome/pro-light-svg-icons/faCreditCard";
import {faMoneyBillAlt as falMoneyBillAlt} from "@fortawesome/pro-light-svg-icons/faMoneyBillAlt";
import {faMobile as falMobile} from "@fortawesome/pro-light-svg-icons/faMobile";
import {faTv as falTv} from "@fortawesome/pro-light-svg-icons/faTv";
import {faCalendarAlt as falCalendarAlt} from "@fortawesome/pro-light-svg-icons/faCalendarAlt";
import {faClock as falClock} from "@fortawesome/pro-light-svg-icons/faClock";
import {faInfoCircle as falInfoCircle} from "@fortawesome/pro-light-svg-icons/faInfoCircle";
import {faUser as falUser} from "@fortawesome/pro-light-svg-icons/faUser";
import {faDatabase as falDatabase} from "@fortawesome/pro-light-svg-icons/faDatabase";
import {faCoins as falCoins} from "@fortawesome/pro-light-svg-icons/faCoins";
import {faListOl as falListOl} from "@fortawesome/pro-light-svg-icons/faListOl";
import {faEnvelope as falEnvelope} from "@fortawesome/pro-light-svg-icons/faEnvelope";
import {faLanguage as falLanguage} from "@fortawesome/pro-light-svg-icons/faLanguage";
import {faTicketAlt as falTicketAlt} from "@fortawesome/pro-light-svg-icons/faTicketAlt";
import {faBook as falBook} from "@fortawesome/pro-light-svg-icons/faBook";
import {faUsersCog as falUsersCog} from "@fortawesome/pro-light-svg-icons/faUsersCog";
import {faMoneyBill as falMoneyBill} from "@fortawesome/pro-light-svg-icons/faMoneyBill";
import {faPowerOff as falPowerOff} from '@fortawesome/pro-light-svg-icons/faPowerOff';
import {faHome as falHome} from "@fortawesome/pro-light-svg-icons/faHome";
import {faDesktop as falDesktop} from "@fortawesome/pro-light-svg-icons/faDesktop";
import {faHandsHeart as falHandsHeart} from "@fortawesome/pro-light-svg-icons/faHandsHeart";
import {faGlobe as falGlobe} from "@fortawesome/pro-light-svg-icons/faGlobe";
import {faEdit as falEdit} from "@fortawesome/pro-light-svg-icons/faEdit";
import {faTable as falTable} from "@fortawesome/pro-light-svg-icons/faTable";
import {faGridHorizontal as falGridHorizontal} from "@fortawesome/pro-light-svg-icons/faGridHorizontal";
import {faKeyboard as falKeyboard} from "@fortawesome/pro-light-svg-icons/faKeyboard";
import {faMoneyBillWave as falMoneyBillWave} from "@fortawesome/pro-light-svg-icons/faMoneyBillWave";
import {faFileImport as falFileImport} from "@fortawesome/pro-light-svg-icons/faFileImport";
import {faUserChart as falUserChart} from "@fortawesome/pro-light-svg-icons/faUserChart";
import {faChartNetwork as falChartNetwork} from "@fortawesome/pro-light-svg-icons/faChartNetwork";
import {faNewspaper as falNewspaper} from "@fortawesome/pro-light-svg-icons/faNewspaper";
import {faExclamationTriangle as falExclamationTriangle} from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";
import {faDotCircle as falDotCircle} from "@fortawesome/pro-light-svg-icons/faDotCircle";
import {faPen as falPen} from '@fortawesome/pro-light-svg-icons/faPen';
import {faKitchenSet as falKitchenSet} from '@fortawesome/pro-light-svg-icons/faKitchenSet';
import {faWeightScale as falWeightScale} from '@fortawesome/pro-light-svg-icons/faWeightScale';
import {faCashRegister as falCashRegister} from '@fortawesome/pro-light-svg-icons/faCashRegister';
import {faComputerClassic as falComputerClassic} from '@fortawesome/pro-light-svg-icons/faComputerClassic';
import {faArrowDownToLine as falArrowDownToLine} from '@fortawesome/pro-light-svg-icons/faArrowDownToLine';
import {faImageUser as falImageUser} from '@fortawesome/pro-light-svg-icons/faImageUser';
import {faGiftCard as falGiftCard} from '@fortawesome/pro-light-svg-icons/faGiftCard';
import {faPuzzlePiece as falPuzzlePiece} from '@fortawesome/pro-light-svg-icons/faPuzzlePiece';
import {faForkKnife as falForkKnife} from '@fortawesome/pro-light-svg-icons/faForkKnife';
import {faTableLayout as falTableLayout} from '@fortawesome/pro-light-svg-icons/faTableLayout';
import {faDisplay as falDisplay} from '@fortawesome/pro-light-svg-icons/faDisplay';
import {faTicket as falTicket} from '@fortawesome/pro-light-svg-icons/faTicket';
import {faBarcode as falBarcode} from '@fortawesome/pro-light-svg-icons/faBarcode';
import {faSquareMinus as falSquareMinus} from '@fortawesome/pro-light-svg-icons/faSquareMinus';
import {faSquarePlus as falSquarePlus} from '@fortawesome/pro-light-svg-icons/faSquarePlus';
import {faArrowCircleRight as falArrowCircleRight} from '@fortawesome/pro-light-svg-icons/faArrowCircleRight';
import {faBug as falBug} from '@fortawesome/pro-light-svg-icons/faBug';
import {faScannerGun as falScannerGun} from '@fortawesome/pro-light-svg-icons/faScannerGun';
import {faMap as falMap} from '@fortawesome/pro-light-svg-icons/faMap';
import {faToolbox as falToolbox} from '@fortawesome/pro-light-svg-icons/faToolbox';
import {faMugSaucer as falMugSaucer} from '@fortawesome/pro-light-svg-icons/faMugSaucer';
import {faTowerObservation as falTowerObservation} from '@fortawesome/pro-light-svg-icons/faTowerObservation';
import {faTruckMoving as falTruckMoving} from '@fortawesome/pro-light-svg-icons/faTruckMoving';
import {faFerrisWheel as falFerrisWheel} from '@fortawesome/pro-light-svg-icons/faFerrisWheel';
import {faHandHoldingDollar as falHandHoldingDollar} from '@fortawesome/pro-light-svg-icons/faHandHoldingDollar';
import {faChartSimple as falChartSimple} from '@fortawesome/pro-light-svg-icons/faChartSimple';
import {faCommentsQuestion as falCommentsQuestion} from '@fortawesome/pro-light-svg-icons/faCommentsQuestion';
import {faEllipsisStrokeVertical as falEllipsisStrokeVertical} from '@fortawesome/pro-light-svg-icons/faEllipsisStrokeVertical';
import {faFileExport as falFileExport} from '@fortawesome/pro-light-svg-icons/faFileExport';
import {faBellConcierge as falBellConcierge} from '@fortawesome/pro-light-svg-icons/faBellConcierge';
import {faClipboard as falClipboard} from '@fortawesome/pro-light-svg-icons/faClipboard';
import {faRepeat as falRepeat} from '@fortawesome/pro-light-svg-icons/faRepeat';
import {faPlus as falPlus} from '@fortawesome/pro-light-svg-icons/faPlus';
import {faSackDollar as falStackDollar} from '@fortawesome/pro-light-svg-icons/faSackDollar';

library.add(
	falHomeAlt, falTags, falTag, falFilter, falTools, falHandHoldingDollar,
	falUserAlt, falCog, falCopy, falArrowAltToTop, falArrowAltUp,
	falBell, falStore, falExclamationCircle, falCheckCircle, falChartSimple,
	falServer, falGraduationCap, falBarcodeAlt, falBoxOpen, falCommentsQuestion,
	falExpandArrows, falUserFriends, falTruck, falFileChartLine, falFileExport,
	falAddressCard, falGift, falBadgePercent, falCogs, falUserLock,
	falUndo, falFileInvoiceDollar, falFlag, falExchange, falPrint, falStackDollar,
	falTimes, falDownload, falArchive, falList, falPencilRuler,
	falCubes, falLayerGroup, falCopyright, falUsers, falPersonDolly,
	falPeopleCarry, falParachuteBox, falReceipt, falChartPie, falKey,
	falWrench, falLock, falCreditCard, falMoneyBillAlt, falMobile,
	falTv, falCalendarAlt, falClock, falInfoCircle, falUser, falDatabase,
	falCoins, falListOl, falEnvelope, falLanguage, falTicketAlt, falBook,
	falUsersCog, falMoneyBill, falPowerOff, falHome, falDesktop, falToolbox,
	falHandsHeart, falGlobe, falEdit, falTable, falGridHorizontal, falTruckMoving,
	falKeyboard, falMoneyBillWave, falFileImport, falUserChart, falMugSaucer,
	falChartNetwork, falNewspaper, falExclamationTriangle, falDotCircle,
	falPen, falKitchenSet, falWeightScale, falCashRegister, falComputerClassic,
	falArrowDownToLine, falImageUser, falGiftCard, falPuzzlePiece, falTowerObservation,
	falForkKnife, falTableLayout, falDisplay, falTicket, falBarcode, falFerrisWheel,
	falSquareMinus, falSquarePlus, falArrowCircleRight, falBug, falScannerGun, falMap,
	falEllipsisStrokeVertical, falBellConcierge, falClipboard, falRepeat, falPlus,
);

import {faArrowRightToCity} from '@fortawesome/free-solid-svg-icons/faArrowRightToCity';
import {faStore} from "@fortawesome/free-solid-svg-icons/faStore";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons/faCaretUp";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons/faCaretDown";
import {faCaretLeft} from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";
import {faUserLock} from "@fortawesome/free-solid-svg-icons/faUserLock";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import {faCamera} from "@fortawesome/free-solid-svg-icons/faCamera";
import {faVideo} from "@fortawesome/free-solid-svg-icons/faVideo";
import {faCircleQuestion} from "@fortawesome/free-solid-svg-icons/faCircleQuestion";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faBuilding} from "@fortawesome/free-solid-svg-icons/faBuilding";
import {faTasks} from "@fortawesome/free-solid-svg-icons/faTasks";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faGlobe} from "@fortawesome/free-solid-svg-icons/faGlobe";
import {faWrench} from "@fortawesome/free-solid-svg-icons/faWrench";
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {faPause} from "@fortawesome/free-solid-svg-icons/faPause";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";
import {faExpand} from "@fortawesome/free-solid-svg-icons/faExpand";
import {faCompress} from "@fortawesome/free-solid-svg-icons/faCompress";
import {faBan} from "@fortawesome/free-solid-svg-icons/faBan";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {faEyeSlash} from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import {faTags} from '@fortawesome/free-solid-svg-icons/faTags';
import {faPrint} from "@fortawesome/free-solid-svg-icons/faPrint";
import {faGift} from "@fortawesome/free-solid-svg-icons/faGift";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faBarcode} from "@fortawesome/free-solid-svg-icons/faBarcode";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import {faExclamation} from "@fortawesome/free-solid-svg-icons/faExclamation";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import {faQuestion} from "@fortawesome/free-solid-svg-icons/faQuestion";
import {faSquarePlus} from '@fortawesome/free-solid-svg-icons/faSquarePlus';
import {faSquareMinus} from '@fortawesome/free-solid-svg-icons/faSquareMinus';


library.add(
	faArrowRightToCity, faStore, faUser, faChevronDown, faChevronUp,
	faChevronLeft, faChevronRight, faCaretUp, faCaretDown, faCaretLeft,
	faExclamationTriangle, faInfoCircle, faLock, faUserLock, faPlus,
	faMinus, faCamera, faVideo, faCircleQuestion, faTimesCircle, faTimes,
	faBuilding, faTasks, faCheck, faGlobe, faWrench, faKey, faEdit,
	faArrowLeft, faArrowRight, faPause, faPlay, faExpand, faCompress,
	faBan, faEye, faEyeSlash, faCheckCircle, faTags, faPrint, faGift,
	faUsers, faBarcode, faTriangleExclamation, faExclamation, faCircle,
	faQuestion, faPlus, faSquarePlus, faSquareMinus,
);

import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

import {StockType} from "../_Enums/StockType";
import {FlexPOSConfigProperty} from "./_Types/FlexPOSConfigProperty";
import {findIndex} from "underscore";
import {_RouteConfigBase, Component, NavigationGuard} from "vue-router/types/router";
import AppView from "./app.vue";
//@ts-ignore
import $ from "jquery";

Vue.use(PortalVue);


//import FingerprintJS from '@fingerprintjs/fingerprintjs';

declare global {
	export interface Window {
		// @ts-ignore
		Vue: typeof Vue;
		bootstrap: {
			Alert: Alert,
			Button: Button,
			Carousel: Carousel,
			Collapse: Collapse,
			Dropdown: Dropdown,
			Modal: Modal,
			Offcanvas: Offcanvas,
			Popover: Popover,
			ScrollSpy: ScrollSpy,
			Tab: Tab,
			Toast: Toast,
			Tooltip: Tooltip
		};
		store: Store<IState>;
		route: VueRouter;
		vueApp: CombinedVueInstance<Vue, object, object, object, Record<never, unknown>>;
		enableDarkTheme(): void
		disableDarkTheme(): void
		accounting: accounting.Static;
		UserSession: IUserSession;
		Resource_DateFormat: DateFormat_Resource;
		Resource: Resource;
	}
	export let UserSession: IUserSession;
	export let Resource_DateFormat: DateFormat_Resource;
	export let Resource: Resource;
	export let momentLocale: string;
	// @ts-ignore
	export let moment: VueMoment;
	export let accounting: accounting.Static;
	// @ts-ignore
	export let bootstrap: {
		Alert: typeof Alert,
		Button: typeof Button,
		Carousel: typeof Carousel,
		Collapse: typeof Collapse,
		Dropdown: typeof Dropdown,
		Modal: typeof Modal,
		Offcanvas: typeof Offcanvas,
		Popover: typeof Popover,
		ScrollSpy: typeof ScrollSpy,
		Tab: typeof Tab,
		Toast: typeof Toast,
		Tooltip: typeof Tooltip
	};
}

// @ts-ignore
window.Vue = Vue;



let validateTokenPromise: Promise<string> | null = null;
async function validateToken(): Promise<string> {

	const token = () => { return UserSession.FrontendAPIToken; };
	if (validateTokenPromise === null) {
		// @ts-ignore
		validateTokenPromise = new Promise((resolve, reject) => {
			const tmpToken = token()?.toString();
			if (isNullOrEmpty(tmpToken)) {
				reject(new Error("No token"));
				return;
			}

			axios.post(UserSession.FrontendApiUri + "api/v1.0/auth/validate-token", {}, {
				headers: { "Authorization": "Bearer " + tmpToken }
			}).then(() => {
				resolve(UserSession.FrontendAPIToken);
				validateTokenPromise = null;
			}).catch((e) => {
				reject(e);
				validateTokenPromise = null;
			});
		});

	}

	return validateTokenPromise;
}
let getNewTokenPromise: Promise<string> | null = null;
async function getNewToken(): Promise<string> {

	if (getNewTokenPromise === null) {
		// @ts-ignore
		getNewTokenPromise = new Promise((resolve, reject) => {
			axios.get("/Dashboard/Client/GetFrontendApiToken").then((response) => {
				if (response.request.responseURL.indexOf("/Dashboard/Client/GetFrontendApiToken") === -1) reject(new Error("Redirected"));

				UserSession.FrontendAPIToken = response.data;
				axios.defaults.headers.common["Authorization"] = "Bearer " + response.data;
				// @ts-ignore
				//const fingerprint = window.store?.getters["getFingerprint"];
				//if (!isNullOrEmpty(fingerprint))
				//	axios.defaults.headers.common["X-Fingerprint"] = fingerprint;

				resolve(UserSession.FrontendAPIToken);
				getNewTokenPromise = null;
			}).catch((e) => {
				reject(e);
				getNewTokenPromise = null;
			});
		});

	}

	return getNewTokenPromise;
}

function getSwaggerAxiosOptions(): Configuration {
	//const fingerprint = window.store?.getters["getFingerprint"];
	return {
		basePath: new URL(UserSession.FrontendApiUri).origin,
		accessToken: async () => {
			let token;
			if (isNullOrEmpty(UserSession.FrontendAPIToken)) {
				token = await getNewToken();
			} else {
				try {
					token = await validateToken();
				} catch (e) {
					token = await getNewToken();
				}
			}

			return token;
		},
		baseOptions: {
			headers: {
				//"X-Fingerprint": fingerprint
			}
		},
		isJsonMime(mime: string): boolean {
			const jsonMime = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
			return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
		}
	};
}

const getSwaggerAxiosOptionsObj = getSwaggerAxiosOptions();
const ClientService: ClientApi = new ClientApi(getSwaggerAxiosOptionsObj);

const UserService = new UserApi(getSwaggerAxiosOptionsObj);

const FlexPosConfigService = new FlexPosConfigApi(getSwaggerAxiosOptionsObj);

const AddonService = new AddonApi(getSwaggerAxiosOptionsObj);

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.response.use(async function (response) {
	const appVersion = response.headers["X-AppVersion"];
	if (appVersion)
		await store.dispatch("SET_APP_VERSION_LAST_REQUEST", appVersion);

	return response;
}, function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	return Promise.reject(error);
});

//@ts-ignore
window.Vue = Vue;

Vue.use(VueCookies);

Vue.use(VueFragment);

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);
// @ts-ignore
Vue.use(Vuelidate);

Vue.use(VueRouter);

Vue.use(Meta);

//@ts-ignore
Vue.component("fp-table", FpTable);

Vue.component("fp-table-column", FpTableColumn);

Vue.component("fp-accordion", FpAccordion);

Vue.component("fp-accordion-item", FpAccordionItem);

Vue.component("fp-panel", FpPanel);

Vue.component("fp-panel-tab", FpPanelTab);

Vue.component("fp-card", FpCard);

Vue.component("back-button", BackButton);

Vue.component("vue-switches", VueSwitches);

Vue.use(VueMoment);

Vue.use(VueAccounting);

Vue.use(VueLog);

Vue.use(VueResource);

Vue.use(VueResourceDateFormat);

Vue.use(VueUserSession);

Vue.use(VueToast);

Vue.use(VueBootstrap);

Vue.use(VueSwaggerAxiosOptions);

Vue.directive('bootstrap-tooltip', {
	// When the bound element is inserted into the DOM...
	inserted: function (el, binding) {
		const validPositions = ["right", "left", "bottom"];
		if (validPositions.includes(binding.arg)) {
			try {
				if (el.title || binding.value)
					bootstrap.Tooltip.getOrCreateInstance(el, {title: el.title || binding.value, placement: binding.arg});
			} catch (e) {
			}
		} else {
			try {
				if (el.title || binding.value)
					bootstrap.Tooltip.getOrCreateInstance(el, {title: el.title || binding.value});
			} catch (e) {
			}
		}
	},
	update(el, binding, vnode) {
		try {
			vnode.context.$nextTick(() => {
				const tooltip = bootstrap.Tooltip.getInstance(el);
				if (tooltip) {
					tooltip.hide();
					if (el.title) {
						tooltip.dispose();
						bootstrap.Tooltip.getOrCreateInstance(el, {title: el.title || binding.expression});
					}
				}
				else if (el.title)
					bootstrap.Tooltip.getOrCreateInstance(el, {title: el.title || binding.expression });
			});	
		}
		catch(e) {}
	},
	componentUpdated(el, binding, vnode) {
		try {
			vnode.context.$nextTick(() => {
				const tooltip = bootstrap.Tooltip.getInstance(el);
				if (tooltip) {
					tooltip.hide();

					if (el.title)
						bootstrap.Tooltip.getOrCreateInstance(el, {title: el.title || binding.expression });
				}
				else if (el.title)
					bootstrap.Tooltip.getOrCreateInstance(el, {title: el.title || binding.expression });
			});
		}
		catch(e) {}
	},
	unbind(el) {
		try	{
			const tooltip = bootstrap.Tooltip.getInstance(el);
			if (tooltip) {
				try {
					tooltip.hide();
				}
				catch(e) {}
				try {
					tooltip.dispose();
				}
				catch(e) {}
			}
		}
		catch(e) {}
	}
});

//@ts-ignore
Date.prototype.toJSON = function(){ return window.moment(this).format(); };

Vue.use(Vuex);
/* eslint-disable vue/max-len */

const LoginComponent = () => import(/* webpackChunkName: "login" */ "./_Pages/login.vue");
const EconomicAppComponent = () => import(/* webpackChunkName: "economic-app" */ "./_Pages/economicApp/economic-index.vue");
const ChangePasswordComponent = () => import(/* webpackChunkName: "change-password" */ "./_Pages/change-password.vue");
const TestComponent = () => import(/* webpackChunkName: "test" */ "./_Pages/test-components.vue");
/*#region Dashboard*/
/*CashManagement*/
const CashManagementIndexComponent = () => import(/* webpackChunkName: "dashboard-cash-management-index" */ "./_Pages/dashboard/cash-management/dashboard-cash-management-index.vue");

/*Currency*/
const CurrencyIndexComponent = () => import(/* webpackChunkName: "dashboard-currency-index" */ "./_Pages/dashboard/currency/dashboard-currency-index.vue");
const CurrencyCreateComponent = () => import(/* webpackChunkName: "dashboard-currency-create" */ "./_Pages/dashboard/currency/dashboard-currency-create.vue");
const CurrencyEditComponent = () => import(/* webpackChunkName: "dashboard-currency-edit" */ "./_Pages/dashboard/currency/dashboard-currency-edit.vue");

/*Customers*/
const CustomerIndexComponent = () => import(/* webpackChunkName: "dashboard-customer-index" */ "./_Pages/dashboard/customer/dashboard-customer-index.vue");
const CustomerCreateComponent = () => import(/* webpackChunkName: "dashboard-customer-create" */ "./_Pages/dashboard/customer/dashboard-customer-create.vue");
const CustomerEditComponent = () => import(/* webpackChunkName: "dashboard-customer-edit" */ "./_Pages/dashboard/customer/dashboard-customer-edit.vue");
const CustomerDetailsComponent = () => import(/* webpackChunkName: "dashboard-customer-details" */ "./_Pages/dashboard/customer/dashboard-customer-details.vue");

/*Customer Groups*/
const CustomerGroupIndexComponent = () => import(/* webpackChunkName: "dashboard-customer-group-index" */ "./_Pages/dashboard/customer-group/dashboard-customer-group-index.vue");
const CustomerGroupCreateComponent = () => import(/* webpackChunkName: "dashboard-customer-group-create" */ "./_Pages/dashboard/customer-group/dashboard-customer-group-create.vue");
const CustomerGroupEditComponent = () => import(/* webpackChunkName: "dashboard-customer-group-edit" */ "./_Pages/dashboard/customer-group/dashboard-customer-group-edit.vue");
const CustomerGroupDetailsComponent = () => import(/* webpackChunkName: "dashboard-customer-group-details" */ "./_Pages/dashboard/customer-group/dashboard-customer-group-details.vue");

/*External Page*/
const ExternalPageComponent = () => import(/* webpackChunkName: "dashboard-external-page" */ "./_Pages/dashboard/external-page/dashboard-external-page.vue");

/*Users*/
const UserIndexComponent = () => import(/* webpackChunkName: "dashboard-user-index" */ "./_Pages/dashboard/user/dashboard-user-index.vue");
const UserEditComponent = () => import(/* webpackChunkName: "dashboard-user-edit" */ "./_Pages/dashboard/user/dashboard-user-edit.vue");
const UserCreateComponent = () => import(/* webpackChunkName: "dashboard-user-create" */ "./_Pages/dashboard/user/dashboard-user-create.vue");
const UserDetailsComponent = () => import(/* webpackChunkName: "dashboard-user-details" */ "./_Pages/dashboard/user/dashboard-user-details.vue");
const UserUnlockComponent = () => import(/* webpackChunkName: "dashboard-user-unlock" */ "./_Pages/dashboard/user/dashboard-user-unlock.vue");
/*Pos Users*/
const PosUserIndexComponent = () => import(/* webpackChunkName: "dashboard-pos-user-index" */ "./_Pages/dashboard/pos-user/dashboard-pos-user-index.vue");
const PosUserCreateComponent = () => import(/* webpackChunkName: "dashboard-pos-user-create" */ "./_Pages/dashboard/pos-user/dashboard-pos-user-create.vue");
const PosUserEditComponent = () => import(/* webpackChunkName: "dashboard-pos-user-edit" */ "./_Pages/dashboard/pos-user/dashboard-pos-user-edit.vue");
const PosUserResetPasswordComponent = () => import(/* webpackChunkName: "dashboard-pos-user-reset-password" */ "./_Pages/dashboard/pos-user/dashboard-pos-user-reset-password.vue");
/*Overview*/
const MainOverviewIndexComponent = () => import(/* webpackChunkName: "main-overview" */ "./_Pages/dashboard/overview/main-overview.vue");
/*Statistics*/
const StatisticsIndexComponent = () => import(/* webpackChunkName: "dashboard-statistics-index" */ "./_Pages/dashboard/statistics/dashboard-statistics-index.vue");

/*Reports */
const ReportsIndexComponent = () => import(/* webpackChunkName: "dashboard-reports" */ "./_Pages/dashboard/reports/dashboard-reports-index.vue");

/*Dynamic Report*/
const OrderReportComponent = () => import(/* webpackChunkName: "dashboard-reports-order-report" */ "./_Pages/dashboard/reports/order-report/dashboard-reports-order-report.vue");
const DynamicReportIndexComponent = () => import(/* webpackChunkName: "dashboard-dynamic-report-index" */ "./_Pages/dashboard/reports/dashboard-dynamic-report-index.vue");
const DynamicReportCreateComponent = () => import(/* webpackChunkName: "dashboard-dynamic-report-create" */ "./_Pages/dashboard/reports/dashboard-dynamic-report-create.vue");
const DynamicReportEditComponent = () => import(/* webpackChunkName: "dashboard-dynamic-report-edit" */ "./_Pages/dashboard/reports/dashboard-dynamic-report-edit.vue");
const DynamicReportColumnsIndexComponent = () => import(/* webpackChunkName: "dashboard-dynamic-report-columns-index" */ "./_Pages/dashboard/reports/dashboard-dynamic-report-columns-index.vue");
const DynamicReportColumnCreateComponent = () => import(/* webpackChunkName: "dashboard-dynamic-report-column-create" */ "./_Pages/dashboard/reports/dashboard-dynamic-report-column-create.vue");
const DynamicReportColumnEditComponent = () => import(/* webpackChunkName: "dashboard-dynamic-report-column-edit" */ "./_Pages/dashboard/reports/dashboard-dynamic-report-column-edit.vue");

/*Products*/
const ProductIndexComponent = () => import(/* webpackChunkName: "product" */ "./_Pages/dashboard/product/product-index.vue");
const ProductDetailsComponent = () => import(/* webpackChunkName: "product-details" */ "./_Pages/dashboard/product/product-details.vue");
const ProductCreateComponent = () => import(/* webpackChunkName: "product-create" */ "./_Pages/dashboard/product/product-create.vue");
const ProductCreateStockComponent = () => import(/* webpackChunkName: "product-create-stock" */ "./_Pages/dashboard/product/product-create-stock.vue");
const ProductEditComponent = () => import(/* webpackChunkName: "product-edit" */ "./_Pages/dashboard/product/product-edit.vue");
const ProductEditPriceComponent = () => import(/* webpackChunkName: "product-edit-price" */ "./_Pages/dashboard/product/product-edit-price.vue");
const ProductCreateCombinationComponent = () => import(/* webpackChunkName: "product-create-combination" */ "./_Pages/dashboard/product/product-create-combination.vue");
const ProductEditCombinationComponent = () => import(/* webpackChunkName: "product-edit-combination" */ "./_Pages/dashboard/product/product-edit-combination.vue");

const ProductStockIndexComponent = () => import(/* webpackChunkName: "product-stock" */ "./_Pages/dashboard/product-stock/product-stock-index.vue");
const ProductStockDetailsComponent = () => import(/* webpackChunkName: "product-stock-details" */ "./_Pages/dashboard/product-stock/product-stock-details.vue");
const ProductStockEditComponent = () => import(/* webpackChunkName: "product-stock-edit" */ "./_Pages/dashboard/product-stock/product-stock-edit.vue");

/*Product Display Groups*/
const ProductDisplayGroupTableIndexComponent = () => import(/* webpackChunkName: "product-display-group-index" */ "./_Pages/dashboard/product-display-group/product-display-group-index.vue");
const ProductDisplayGroupEditComponent = () => import(/* webpackChunkName: "product-display-group-edit" */ "./_Pages/dashboard/product-display-group/product-display-group-edit.vue");
const ProductDisplayGroupCreateComponent = () => import(/* webpackChunkName: "product-display-group-create" */ "./_Pages/dashboard/product-display-group/product-display-group-create.vue");
const ProductDisplayGroupDetailsComponent = () => import(/* webpackChunkName: "product-display-group-details" */ "./_Pages/dashboard/product-display-group/product-display-group-details.vue");

/*Financial Product Groups*/
const FinancialProductGroupTableIndexComponent = () => import(/* webpackChunkName: "financial-product-group-index" */ "./_Pages/dashboard/financial-product-group/financial-product-group-index.vue");
const FinancialProductGroupEditComponent = () => import(/* webpackChunkName: "financial-product-group-edit" */ "./_Pages/dashboard/financial-product-group/financial-product-group-edit.vue");
const FinancialProductGroupCreateComponent = () => import(/* webpackChunkName: "financial-product-group-create" */ "./_Pages/dashboard/financial-product-group/financial-product-group-create.vue");
const FinancialProductGroupDetailsComponent = () => import(/* webpackChunkName: "financial-product-group-details" */ "./_Pages/dashboard/financial-product-group/financial-product-group-details.vue");

/*Module*/
const ModuleIndexComponent = () => import(/* webpackChunkName: "dashboard-module-index" */ "./_Pages/dashboard/module/module-index.vue");
const ModuleEditAllComponent = () => import(/* webpackChunkName: "dashboard-module-edit-all" */ "./_Pages/dashboard/module/module-edit-all.vue");
const ModuleEditComponent = () => import(/* webpackChunkName: "dashboard-module-edit" */ "./_Pages/dashboard/module/module-edit.vue");
const EditModuleComponent = () => import(/* webpackChunkName: "dashboard-edit-module" */ "./_Pages/dashboard/module/edit-module.vue");
const CreateModuleComponent = () => import(/* webpackChunkName: "dashboard-create-module" */ "./_Pages/dashboard/module/create-module.vue");
const ModuleDetailsComponent = () => import(/* webpackChunkName: "dashboard-module-details" */ "./_Pages/dashboard/module/module-details.vue");

/*Product Brands*/
const ProductBrandTableIndexComponent = () => import(/* webpackChunkName: "product-brand-index" */ "./_Pages/dashboard/brand/product-brand-index.vue");
const ProductBrandCreateComponent = () => import(/* webpackChunkName: "product-brand-create" */ "./_Pages/dashboard/brand/product-brand-create.vue");
const ProductBrandEditComponent = () => import(/* webpackChunkName: "product-brand-edit" */ "./_Pages/dashboard/brand/product-brand-edit.vue");
const ProductBrandDetailsComponent = () => import(/* webpackChunkName: "product-brand-details" */ "./_pages/dashboard/brand/product-brand-details.vue");
/*Warranty*/
const WarrantyTableIndexComponent = () => import(/* webpackChunkName: "dashboard-warranty-index" */ "./_Pages/dashboard/warranty/dashboard-warranty-index.vue");
const WarrantyEditComponent = () => import(/* webpackChunkName: "dashboard-warranty-edit" */ "./_Pages/dashboard/warranty/dashboard-warranty-edit.vue");
const WarrantyCreateComponent = () => import(/* webpackChunkName: "dashboard-warranty-create" */ "./_Pages/dashboard/warranty/dashboard-warranty-create.vue");
/*#endregion*/
/*#region Reseller Modules*/
/*Billing*/
const BillingTableIndexComponent = () => import(/* webpackChunkName: "reseller-billing-index" */ "./_Pages/resellerModule/billing/reseller-billing-index.vue");
const BillingDetailsComponent = () => import(/* webpackChunkName: "reseller-billing-details" */ "./_Pages/resellerModule/billing/reseller-billing-details.vue");
/*Reseller*/
const ResellerTableIndexComponent = () => import(/* webpackChunkName: "reseller-index" */ "./_Pages/resellerModule/reseller/reseller-index.vue");
const ResellerCreateStoreComponent = () => import(/* webpackChunkName: "reseller-create-store" */ "./_Pages/resellerModule/reseller/reseller-create-store.vue");
const ResellerEditStoreComponent = () => import(/* webpackChunkName: "reseller-edit-store" */ "./_Pages/resellerModule/reseller/reseller-edit-store.vue");
/*#endregion*/
/*#region Settings*/

/*Bon Layout*/
const BonLayoutIndexComponent = () => import(/* webpackChunkName: "settings-bon-layout-index" */ "./_Pages/settings/bonLayout/settings-bon-layout-index.vue");
/*Breece*/
const BreeceIndexComponent = () => import(/* webpackChunkName: "settings-breece-index" */ "./_Pages/settings/breece/settings-breece-index.vue");

/*Card Fee Analytics*/
const CardFeeAnalyticsIndexComponent = () => import(/* webpackChunkName: "settings-card-fee-analytics-index" */ "./_Pages/settings/card-fee-analytics/card-fee-analytics-index.vue");

/*Customer Statistics*/
const CustomerStatisticsIndexComponent = () => import(/* webpackChunkName: "settings-customer-statistics-index" */ "./_Pages/settings/customerStatistics/settings-customer-statistics-index.vue");
/*Design And Layout*/
const DesignAndLayoutIndexComponent = () => import(/* webpackChunkName: "settings-design-and-layout-index" */ "./_Pages/settings/designAndLayout/settings-design-and-layout-index.vue");
/*Card Group*/
const CardGroupTableIndexComponent = () => import(/* webpackChunkName: "settings-card-group-index" */ "./_Pages/settings/cardGroup/settings-card-group-index.vue");
const CardGroupEditComponent = () => import(/* webpackChunkName: "settings-card-group-edit" */ "./_Pages/settings/cardGroup/settings-card-group-edit.vue");
const CardGroupCreateComponent = () => import(/* webpackChunkName: "settings-card-group-create" */ "./_Pages/settings/cardGroup/settings-card-group-create.vue");

/*SAF-T*/
const SAFTIndexComponent = () => import(/* webpackChunkName: "settings-saft-index" */ "./_Pages/settings/saft/settings-saft-index.vue");

/*Exchange Labels*/
const ExchangeLabelsIndexComponent = () => import(/* webpackChunkName: "settings-exchange-labels-index" */ "./_Pages/settings/exchangeLabels/settings-exchange-labels-index.vue");
/*ED Account*/
const EDAccountIndexComponent = () => import(/* webpackChunkName: "ed-account-index" */ "./_Pages/settings/edAccount/ed-account-index.vue");
/*Plecto*/
const PlectoIndexComponent = () => import(/* webpackChunkName: "ed-account-index" */ "./_Pages/settings/edAccount/plecto-index.vue");
/*Mobile pay*/
const MobilePayIndexComponent = () => import(/* webpackChunkName: "mobile-pay-index" */ "./_Pages/settings/mobilePay/mobile-pay-index.vue");
/*Sync Job*/
const SyncJobTableIndexComponent = () => import(/* webpackChunkName: "settings-sync-job-index" */ "./_Pages/settings/syncJob/settings-sync-job-index.vue");
const SyncJobCreateIndexComponent = () => import(/* webpackChunkName: "settings-sync-job-create" */ "./_Pages/settings/syncJob/settings-sync-job-create.vue");
const SyncJobEditIndexComponent = () => import(/* webpackChunkName: "settings-sync-job-edit" */ "./_Pages/settings/syncJob/settings-sync-job-edit.vue");
/*Same System*/
const SameSystemIndexComponent = () => import(/* webpackChunkName: "settings-same-system-index" */ "./_Pages/settings/sameSystem/settings-same-system-index.vue");
/*Customer Import*/
const CustomerImportIndexComponent = () => import(/* webpackChunkName: "settings-customer-import-index" */ "./_Pages/settings/customerImport/settings-customer-import-index.vue");
/*Product Import*/
const ProductImportIndexComponent = () => import(/* webpackChunkName: "settings-product-import-index" */ "./_Pages/settings/productImport/settings-product-import-index.vue");
/*FlexPos Config*/
const FlexPosConfigTableIndexComponent = () => import(/* webpackChunkName: "settings-flex-pos-config-index" */ "./_Pages/settings/flexPosConfig/settings-flex-pos-config-index.vue");
const FlexPosConfigCreateComponent = () => import(/* webpackChunkName: "settings-flex-pos-config-create" */ "./_Pages/settings/flexPosConfig/settings-flex-pos-config-create.vue");
const FlexPosConfigEditComponent = () => import(/* webpackChunkName: "settings-flex-pos-config-edit" */ "./_Pages/settings/flexPosConfig/settings-flex-pos-config-edit.vue");
/*FlexPos Config Dynamic*/
const FlexPosConfigDynamicIndexComponent = () => import(/* webpackChunkName: "settings-flex-pos-config-dynamic-index" */ "./_Pages/settings/flexPosConfigDynamic/settings-flex-pos-config-dynamic-index.vue");
const FlexPosConfigDynamicItemComponent = () => import(/* webpackChunkName: "settings-flex-pos-config-dynamic-item" */ "./_Pages/settings/flexPosConfigDynamic/settings-flex-pos-config-dynamic-item.vue");
/*FlexVoucher*/
const FlexVoucherIndexComponent = () => import(/* webpackChunkName: "settings-flex-voucher-index" */ "./_Pages/settings/flexVoucher/settings-flex-voucher-index.vue");
/*Payment Type*/
const PaymentTypeIndexComponent = () => import(/* webpackChunkName: "settings-payment-type-index" */ "./_Pages/settings/paymentType/settings-payment-type-index.vue");

/*Loyalty*/
const LoyaltyIndexComponent = () => import(/* webpackChunkName: "settings-loyalty-index" */ "./_Pages/settings/loyalty/settings-loyalty-index.vue");
/*#endregion*/

/*GiftCard*/
const GiftCardTableIndexComponent = () => import(/* webpackChunkName: "gift-card-index" */ "./_Pages/gift-card/gift-card-index.vue");
/*Campaign Module*/
const CampaignTableIndexComponent = () => import(/* webpackChunkName: "campaign-index" */ "./_Pages/campaignModule/campaign/campaign-index.vue");
const CampaignEditComponent = () => import(/* webpackChunkName: "campaign-edit" */ "./_Pages/campaignModule/campaign/campaign-edit.vue");
const CampaignCreateComponent = () => import(/* webpackChunkName: "campaign-create" */ "./_Pages/campaignModule/campaign/campaign-create.vue");
/*Product List*/
const ProductListTableIndexComponent = () => import(/* webpackChunkName: "product-list-index" */ "./_Pages/dashboard/product-list/product-list-index.vue");
const ProductListEditComponent = () => import(/* webpackChunkName: "product-list-edit" */ "./_Pages/dashboard/product-list/product-list-edit.vue");
const ProductListCreateComponent = () => import(/* webpackChunkName: "product-list-create" */ "./_Pages/dashboard/product-list/product-list-create.vue");

const InterventionsIndexComponent = () => import(/* webpackChunkName: "interventions-index" */ "./_Pages/settings/interventions/interventions-index.vue");

/*FlexTicket*/
const FlexTicketIndexComponent = () => import(/* webpackChunkName: "flex-ticket-index" */ "./_Pages/flexTicketModule/flexticket/flexticket-index.vue");
/*Transaction History*/
const TransactionHistoryIndexComponent = () => import(/* webpackChunkName: "transaction-history-index" */ "./_Pages/dashboard/transaction-history/transaction-history-index.vue");
/*#region Vendor Modules*/
/*Vendor*/
const VendorModuleTableIndexComponent = () => import(/* webpackChunkName: "vendor-module-index" */ "./_Pages/vendorModule/vendor/vendor-module-index.vue");
const VendorModuleCreateComponent = () => import(/* webpackChunkName: "vendor-module-create" */ "./_Pages/vendorModule/vendor/vendor-module-create.vue");
const VendorModuleEditComponent = () => import(/* webpackChunkName: "vendor-module-edit" */ "./_Pages/vendorModule/vendor/vendor-module-edit.vue");
/*Order*/
const OrderTableIndexComponent = () => import(/* webpackChunkName: "vendor-module-order-index" */ "./_Pages/vendorModule/orders/vendor-module-orders-index.vue");
const OrderEditComponent = () => import(/* webpackChunkName: "vendor-module-order-edit" */ "./_Pages/vendorModule/orders/vendor-module-orders-edit.vue");
const OrderDetailsComponent = () => import(/* webpackChunkName: "vendor-module-order-details" */ "./_Pages/vendorModule/orders/vendor-module-orders-details.vue");
/*GoodsReception*/
const GoodsReceptionTableIndexComponent = () => import(/* webpackChunkName: "vendor-module-goods-reception-index" */ "./_Pages/vendorModule/goodsReception/vendor-module-goods-reception-index.vue");
const GoodsReceptionEditComponent = () => import(/* webpackChunkName: "vendor-module-goods-reception-edit" */ "./_Pages/vendorModule/goodsReception/vendor-module-goods-reception-edit.vue");
const GoodsReceptionCreateComponent = () => import(/* webpackChunkName: "vendor-module-goods-reception-create" */ "./_Pages/vendorModule/goodsReception/vendor-module-goods-reception-create.vue");
const GoodsReceptionDetailsComponent = () => import(/* webpackChunkName: "vendor-module-goods-reception-details" */ "./_Pages/vendorModule/goodsReception/vendor-module-goods-reception-details.vue");
/*#endregion*/

const FlexAdConfigComponent = () => import(/* webpackChunkName: "flexad-config" */ "./_Pages/flexad/flexad-config.vue");
const FlexAdIndexComponent = () => import(/* webpackChunkName: "flexad-index" */ "./_Pages/flexad/flexad-index.vue");
const FlexAdCreateComponent = () => import(/* webpackChunkName: "flexad-create" */ "./_Pages/flexad/flexad-create.vue");
const FlexAdEditComponent = () => import(/* webpackChunkName: "flexad-edit" */ "./_Pages/flexad/flexad-edit.vue");

const StatusIndexComponent = () => import(/* webpackChunkName: "status-module-index" */ "./_Pages/status-module/status-module-index.vue");
const StatusEditComponent = () => import(/* webpackChunkName: "status-module-edit" */ "./_Pages/status-module/status-module-edit.vue");
const StatusDetailsComponent = () => import(/* webpackChunkName: "status-module-details" */ "./_Pages/status-module/status-module-details.vue");

const FlexVoucherTotalsComponent = () => import(/* webpackChunkName: "flex-voucher-totals" */ "./_Pages/flex-voucher/totals-overview.vue");
const FlexVoucherTotalsPerMonthComponent = () => import(/* webpackChunkName: "flex-voucher-totals-per-month" */ "./_Pages/flex-voucher/totals-per-month.vue");
const FlexVoucherTransactionHistoryComponent = () => import(/* webpackChunkName: "flex-voucher-transaction-history" */ "./_Pages/flex-voucher/transaction-history.vue");
const FlexVoucherInfoComponent = () => import(/* webpackChunkName: "flex-voucher-info" */ "./_Pages/flex-voucher/flex-voucher-info.vue");
const FlexVoucherBatchComponent = () => import(/* webpackChunkName: "flex-voucher-batch" */ "./_Pages/flex-voucher/flex-voucher-batch.vue");
const FlexVoucherBatchEditComponent = () => import(/* webpackChunkName: "flex-voucher-batch-edit" */ "./_Pages/flex-voucher/flex-voucher-batch-edit.vue");
const FlexVoucherBatchCreateComponent = () => import(/* webpackChunkName: "flex-voucher-batch-create" */ "./_Pages/flex-voucher/flex-voucher-batch-create.vue");
const FlexVoucherOverviewComponent = () => import(/* webpackChunkName: "flex-voucher-overview" */ "./_Pages/flex-voucher/flex-voucher-overview.vue");
const FlexVoucherDownloadFileComponent = () => import(/* webpackChunkName: "flex-voucher-download-file" */ "./_Pages/flex-voucher/download-file.vue");

/*Flex Deploy*/
const FlexDeployComponent = () => import(/* webpackChunkName: "flex-deploy-index" */ "./_Pages/admin/flexDeploy/flex-deploy-index.vue");
const FlexDeployDetailsComponent = () => import(/* webpackChunkName: "flex-deploy-index" */ "./_Pages/admin/flexDeploy/flex-deploy-index.vue");
const FlexDeployEditComponent = () => import(/* webpackChunkName: "flex-deploy-edit" */ "./_Pages/admin/flexDeploy/flex-deploy-edit.vue");
const FlexDeployCreateComponent = () => import(/* webpackChunkName: "flex-deploy-create" */ "./_Pages/admin/flexDeploy/flex-deploy-create.vue");

const EnumComponent = () => import(/* webpackChunkName: "enum-index" */ "./_Pages/admin/enum/enum-index.vue");
const EnumChildrenComponent = () => import(/* webpackChunkName: "enum-children" */ "./_Pages/admin/enum/enum-index.vue");
const EnumViewComponent = () => import(/* webpackChunkName: "enum-view" */ "./_Pages/admin/enum/enum-view.vue");

const LanguageIndexComponent = () => import(/* webpackChunkName: "language-index" */ "./_Pages/admin/language/language-index.vue");

const NotificationShowAllComponent = () => import(/* webpackChunkName: "notifications-showall" */ "./_Pages/notification/notifications-showall.vue");
const NotificationsIndexComponent = () => import(/* webpackChunkName: "notifications-index" */ "./_Pages/admin/notifications/notifications-index.vue");
const NotificationsEditComponent = () => import(/* webpackChunkName: "notifications-edit" */ "./_Pages/admin/notifications/notifications-edit.vue");
const NotificationsCreateComponent = () => import(/* webpackChunkName: "notifications-create" */ "./_Pages/admin/notifications/notifications-create.vue");
/* eslint-enable vue/max-len */

async function reloadConfiguration() {
	await Promise.all([store.dispatch("setUserSession"), store.dispatch("setBackendConfigs")]);
	await Promise.all([store.dispatch("setFlexPosConfigs"), store.dispatch("setActiveAddons")]);
}

interface RouteConfig extends _RouteConfigBase {
	component?: Component | unknown
}

const routes: RouteConfig[] = [
	{path: '/', redirect: {name: "login"}},
	{path: '/login', name: "login", component: LoginComponent},
	{path: '/economicApp/', name: "economic-app", component: EconomicAppComponent},
	{path: '/economicApp/Login', name: "economic-app-login", component: EconomicAppComponent},
	{path: '/account/changePassword', name: "change-password", component: ChangePasswordComponent},
	/*region Dashboard*/
	{path: '/dashboard', redirect: {name: "overview"}},
	{path: '/dashboard/', redirect: {name: "overview"}},
	/*CashManagement*/
	{path: '/dashboard/cashManagement', name:"dashboard-cash-management-index", component: CashManagementIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCashManagementAllowed"])}}, 
	/*Currency*/
	{path: '/dashboard/currency', name:"dashboard-currency-index", component: CurrencyIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCurrencyAllowed"])}},
	{path: '/dashboard/currency/Edit/:id', name:"dashboard-currency-edit", component: CurrencyEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCurrencyAllowed"])}},
	{path: '/dashboard/currency/Create', component: CurrencyCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCurrencyAllowed"])}},
	
	/*Customers*/
	{path: '/dashboard/customer', name:"dashboard-customer-index", component: CustomerIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCustomersAllowed"])}},
	{path: '/dashboard/customer/Edit/:id', name:"dashboard-customer-edit", component: CustomerEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCustomersAllowed"])}},
	{path: '/dashboard/customer/Create', component: CustomerCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCustomersAllowed"])}},
	{path: '/dashboard/customer/Details/:id', component: CustomerDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCustomersAllowed"])}},
	
	/*Customer Groups*/
	{path: '/dashboard/customerGroups', name:"dashboard-customer-group-index", component: CustomerGroupIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCustomersAllowed"])}},
	{path: '/dashboard/customerGroup/Edit/:id', name:"dashboard-customer-group-edit", component: CustomerGroupEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCustomersAllowed"])}},
	{path: '/dashboard/customerGroup/Create', component: CustomerGroupCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCustomersAllowed"])}},
	{path: '/dashboard/customerGroup/Details/:id', component: CustomerGroupDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCustomersAllowed"])}},

	/*External Page*/
	{path: '/dashboard/externalPage/:id', name: "external-page", component: ExternalPageComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsExternalPageAllowed"])}},
	
	/*Financial Product Groups*/
	{path: '/dashboard/financialProductGroups', name:"dashboard-financial-group-index", component: FinancialProductGroupTableIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFinanceGroupsAllowed"])}},
	{path: '/dashboard/financialProductGroup/Edit/:id', name:"dashboard-financial-group-edit", component: FinancialProductGroupEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFinanceGroupsAllowed"])}},
	{path: '/dashboard/financialProductGroup/Create', component: FinancialProductGroupCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFinanceGroupsAllowed"])}},
	{path: '/dashboard/financialProductGroup/Details/:id', component: FinancialProductGroupDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFinanceGroupsAllowed"])}},

	/*Modules*/
	{path: '/dashboard/module', name: "dashboard-module-index", component: ModuleIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsModulesAllowed"])}},
	{path: '/dashboard/module/editall', name: "dashboard-module-edit-all", component: ModuleEditAllComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsModulesAllowed"])}},
	{path: '/dashboard/module/edit', name: "dashboard-module-edit", component: ModuleEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsModulesAllowed"])}},
	{path: '/dashboard/module/details/:id', name: "dashboard-module-details", component: ModuleDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsModulesAllowed"])}},
	{path: '/dashboard/module/editmodule/:id', name: "dashboard-edit-module", component: EditModuleComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsModulesAllowed"])}},
	{path: '/dashboard/module/createmodule', name: "dashboard-create-module", component: CreateModuleComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsModulesAllowed"])}},

	/*Overview*/
	{path: '/dashboard/overview', name: "overview", component: MainOverviewIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFrontpageAllowed"])}},
	
	/*Users*/
	{path: '/dashboard/user', name:"dashboard-user-index", component: UserIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsUsersAllowed"])}},
	{path: '/dashboard/user/edit/:id', name:"dashboard-user-edit", component: UserEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsUsersAllowed"])}},
	{path: '/dashboard/user/create', component: UserCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsUsersAllowed"])}},
	{path: '/dashboard/user/details/:id', component: UserDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsUsersAllowed"])}},
	{path: '/dashboard/user/unlock/:id', component: UserUnlockComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsUsersAllowed"])}},
	/*Pos Users*/
	{path: '/dashboard/posuser', name:"dashboard-pos-user-index", component: PosUserIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsPosUsersAllowed"])}},
	{path: '/dashboard/posuser/create', component: PosUserCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsPosUsersAllowed"])}},
	{path: '/dashboard/posuser/edit/:id', component: PosUserEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsPosUsersAllowed"])}},
	{path: '/dashboard/posuser/resetPassword/:id', component: PosUserResetPasswordComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsPosUsersAllowed"])}},
	/*Reports*/
	
	{path: '/dashboard/reports', name:"dashboard-reports-index", component: ReportsIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsReportsAllowed"])}},
	{path: '/dashboard/reports/order-report', name:"dashboard-reports-order-report", component: OrderReportComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsReportsAllowed"])}},

	{path: '/dashboard/dynamicReport', name:"dashboard-dynamic-report-index", component: DynamicReportIndexComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/dashboard/dynamicReport/create', name:"dashboard-dynamic-report-create", component: DynamicReportCreateComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/dashboard/dynamicReport/edit/:id', name:"dashboard-dynamic-report-edit", component: DynamicReportEditComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/dashboard/dynamicReport/columnsIndex/:id', name:"dashboard-dynamic-report-columns-index", component: DynamicReportColumnsIndexComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/dashboard/dynamicReport/createColumn/:id', name:"dashboard-dynamic-report-column-create", component: DynamicReportColumnCreateComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/dashboard/dynamicReport/editColumn/:id', name:"dashboard-dynamic-report-column-edit", component: DynamicReportColumnEditComponent, beforeEnter: navigationIsFlexPosAdmin},
	
	/*Products*/
	{path: '/dashboard/product', name: "product-index", component: ProductIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsProductsAllowed"])}},
	{path: '/Dashboard/Product/IndexWithClear', redirect: {name: "product-index"},
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsProductsAllowed"])}},
	{path: '/dashboard/product/index', redirect: {name: "product-index"},
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsProductsAllowed"])}},
	{path: '/dashboard/product/search', redirect: {name: "product-index"},
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsProductsAllowed"])}},
	{path: '/dashboard/product/details/:id', name: "product-details", component: ProductDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsProductsAllowed"])}},
	{path: '/dashboard/product/create/', name: "product-create", component: ProductCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCreateProductAllowed"])}},
	{path: '/dashboard/product/create-stock/:id', name: "product-create-stock", component: ProductCreateStockComponent},
	{path: '/dashboard/product/create-combination/:id', name: "product-create-combination", component: ProductCreateCombinationComponent},
	{path: '/dashboard/product/edit-combination/:id', name: "product-edit-combination", component: ProductEditCombinationComponent},
	{path: '/dashboard/product/edit/:id', name: "product-edit", component: ProductEditComponent},
	{path: '/dashboard/product/edit-price/:id', name: "product-edit-price", component: ProductEditPriceComponent},
	
	/*Product Stock*/
	{path: '/dashboard/product-stock', name: "product-stock-index", component: ProductStockIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsStockAllowed"])}},
	{path: '/dashboard/productstock/index', redirect: {name: "product-stock-index"},
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsStockAllowed"])}},
	{path: '/dashboard/productstock/search', redirect: {name: "product-stock-index"},
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsStockAllowed"])}},

	{path: '/dashboard/product-stock/details/:id', name: "product-stock-details", component: ProductStockDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsStockAllowed"])}},
	{path: '/dashboard/product-stock/edit/:id', name: "product-stock-edit", component: ProductStockEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsStockAllowed"])}},

	/*Product Display Groups*/
	{path: '/dashboard/displayProductGroups', name:"product-display-group-index", component: ProductDisplayGroupTableIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsDisplayGroupsAllowed"])}},
	{path: '/dashboard/displayProductGroup/Edit/:id', name:"product-display-group-edit", component: ProductDisplayGroupEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsDisplayGroupsAllowed"])}},
	{path: '/dashboard/displayProductGroup/Create', component: ProductDisplayGroupCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsDisplayGroupsAllowed"])}},
	{path: '/dashboard/displayProductGroup/Details/:id', component: ProductDisplayGroupDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsDisplayGroupsAllowed"])}},
	
	/*Product List*/
	{path: '/dashboard/productList', name: "product-list", component: ProductListTableIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsProductListsAllowed"])}},
	{path: '/dashboard/productList/Index', component: ProductListTableIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsProductListsAllowed"])}},
	{path: '/dashboard/productList/edit/:id', name: "product-list-edit", component: ProductListEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsProductListsAllowed"])}},
	{path: '/dashboard/productList/create', name: "product-list-create", component: ProductListCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsProductListsAllowed"])}},

	/*Product Brands*/
	{path: '/dashboard/brand', name:"product-brand-index", component: ProductBrandTableIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsBrandsAllowed"])}},
	{path: '/dashboard/brand/Edit/:id', name:"product-brand-edit", component: ProductBrandEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsBrandsAllowed"])}},
	{path: '/dashboard/brand/Create', component: ProductBrandCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsBrandsAllowed"])}},
	{path: '/dashboard/brand/Details/:id', component: ProductBrandDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsBrandsAllowed"])}},

	/*Statistik*/
	{path: '/dashboard/statistics*', component: StatisticsIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsStatisticsAllowed"])}},

	/*Transaction History*/
	{path: '/dashboard/transactionHistory', component: TransactionHistoryIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsTransactionHistoryAllowed"])}},
	
	/*Warranty*/
	{path: '/dashboard/warranty', component: WarrantyTableIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsWarrantyProfilesAllowed"])}},
	{path: '/dashboard/warranty/edit/:id', component: WarrantyEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsWarrantyProfilesAllowed"])}},
	{path: '/dashboard/warranty/create', component: WarrantyCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsWarrantyProfilesAllowed"])}},
	/*#endregion*/
	/*#region Reseller Module*/
	/*Billing*/
	{path: '/resellerModule/billing', component: BillingTableIndexComponent, meta: { sideMenuType: "reseller" }, beforeEnter: navigationIsFlexPosAdminOrIsReseller},
	{path: '/resellerModule/billing/details/:id', component: BillingDetailsComponent, meta: { sideMenuType: "reseller" }, beforeEnter: navigationIsFlexPosAdminOrIsReseller},
	/*Reseller*/
	{path: '/resellerModule/reseller', name:"reseller-module-index", component: ResellerTableIndexComponent, meta: { sideMenuType: "reseller" }, beforeEnter: navigationIsFlexPosAdminOrIsReseller},
	{path: '/resellerModule/reseller/create-store', name:"reseller-module-create-store", component: ResellerCreateStoreComponent, meta: { sideMenuType: "reseller" }, beforeEnter: navigationIsFlexPosAdminOrIsReseller},
	{path: '/resellerModule/reseller/edit-store/:id', name:"reseller-module-edit-store", component: ResellerEditStoreComponent, meta: { sideMenuType: "reseller" }, beforeEnter: navigationIsFlexPosAdminOrIsReseller},
    
	/*#endregion*/
	/*#region Settings*/
	{path: '/settings/', name: "settings-index", component: SettingsIndexViewComponent, meta: { sideMenuType: "settings" }},

	/*Bon Layout*/
	{path: '/settings/BonLayout', name: "settings-bon-layout-index", component: BonLayoutIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsBonLayoutAllowed"])}},
	
	/*Breece*/
	{path: '/settings/Breece', name: "settings-breece-index", component: BreeceIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsDelfiBreeceAllowed"])}},

	/*Card Fee Analytics*/
	{path: '/settings/cardFeeAnalytics', name: "settings-card-fee-analytics-index", component: CardFeeAnalyticsIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCardFeeAnalyticsAllowed"])}},
	
	/*Customer Statistics*/
	{path: '/settings/customerStatistics', name: "settings-customer-statistics-index", component: CustomerStatisticsIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCustomerStatisticsAllowed"])}},
	
	/*Design And Layout*/
	{path: '/settings/designAndLayout', name: "settings-design-and-layout-index", component: DesignAndLayoutIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsDesignAndLayoutAllowed"])}},

	/*SAF-T*/
	{path: '/settings/SAF-T', component: SAFTIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsSAFTAllowedSideMenu"])}},
	
	/*Loyalty*/
	{path: '/settings/loyalty', name: "settings-loyalty-index", component: LoyaltyIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsLoyaltyAllowed"])}},
	
	/*Besøgsstatistik*/
	{path: '/settings/customerStatistics*', component: AspNetViewComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCustomerStatisticsAllowed"])}},
    
	/*BonLayout*/
	{path: '/settings/bonLayout*', component: AspNetViewComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsBonLayoutAllowed"])}},

	/*GDPR*/
	{path: '/settings/GDPR*', component: AspNetViewComponent, meta: { sideMenuType: "settings" }},

	/*FlexVoucher*/
	{path: '/settings/flexVoucher', component: FlexVoucherIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexVoucherAllowed"])}},
	
	/*PaymentType*/
	{path: '/settings/PaymentType', name:"payment-type-index", component: PaymentTypeIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsPaymentTypeAllowed"])}},

	/*CardGroup*/
	{path: '/settings/CardGroup', name: "settings-card-group-index", component: CardGroupTableIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCardGroupAllowed"])}},
	{path: '/settings/CardGroup/Edit/:id', name:"settings-card-group-edit", component: CardGroupEditComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCardGroupAllowed"])}},
	{path: '/settings/CardGroup/Create', name:"settings-card-group-create", component: CardGroupCreateComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCardGroupAllowed"])}},

	/*Exchange Labels*/
	{path: '/settings/ExchangeLabels', component: ExchangeLabelsIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsExchangeLabelsAllowed"])}},
	
	/*EDAccount*/
	{path: '/settings/EDAccount', name: "ed-account-index", component: EDAccountIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsEDAccountAllowed"])}},
	{path: '/EDAccount/VismaNet', name: "ed-account-visma", component: EDAccountIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVismaNetAccountAllowed"])}},
	{path: '/EDAccount/VismaNetNew', name: "ed-account-visma-new", component: EDAccountIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVismaNetAccountAllowed"])}},
	
	{path: '/EDAccount/Dinero', name: "ed-account-dinero", component: EDAccountIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsDineroAccountAllowed"])}},
	{path: '/Settings/Plecto/', component: PlectoIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsEDAccountAllowed"])}},
	
	
	/*Mobile Pay*/
	{path: '/settings/mobilePay', name: "mobile-pay-index", component: MobilePayIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsMobilePayAllowed"])}},

	/*NordiskeDatabase*/
	{path: '/settings/nordiskeDatabase', component: AspNetViewComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsNordiskeDatabaseAllowed"])}},
	
	/*SyncJobs*/
	{path: '/settings/SyncJob', name: "settings-sync-job-index", component: SyncJobTableIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsSyncJobAllowed"])}},
	{path: '/settings/SyncJob/create', component: SyncJobCreateIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsSyncJobAllowed"])}},
	{path: '/settings/SyncJob/create/:id', name: "settings-sync-job-copy", component: SyncJobCreateIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsSyncJobAllowed"])}},
	{path: '/settings/SyncJob/edit/:id', component: SyncJobEditIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsSyncJobAllowed"])}},
	{path: '/settings/SyncJob*', component: AspNetViewComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsSyncJobAllowed"])}},
    
	/*SameSystem*/
	{path: '/settings/SameSystem', name: "settings-same-system-index", component: SameSystemIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsSameSystemAllowed"])}},

	/*Customer Import*/
	{path: '/settings/CustomerImport', name: "settings-customer-import-index", component: CustomerImportIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsImportAllowed"])}},
	
	/*Product Import*/
	{path: '/settings/ProductImport', name: "settings-product-import-index", component: ProductImportIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsImportAllowed"])}},

	{path: '/settings/interventions', component: InterventionsIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsInterventionsAllowed"])}},
	
	/*FlexPos Config*/
	{path: '/settings/flexPosConfig', name: "settings-flex-pos-config-index", component: FlexPosConfigTableIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexPosConfigAllowed"])}},
	{path: '/settings/flexPosConfig/create', component: FlexPosConfigCreateComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexPosConfigAllowed"])}},
	{path: '/settings/flexPosConfig/edit/:id', component: FlexPosConfigEditComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexPosConfigAllowed"])}},
	
	/*FlexPos Config Dynamic*/
	{path: '/settings/flexPosConfigDynamic', name:"flexpos-config-front", component: FlexPosConfigDynamicIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexPosConfigDynamicAllowed"])}},
	{path: '/settings/flexPosConfigDynamic/:id', component: FlexPosConfigDynamicItemComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexPosConfigDynamicAllowed"])}},
	
	/*FlexAD*/
	{path: '/flexad/config', name: "flexad-config", component: FlexAdConfigComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexAdAdvancedAllowed"])}},
	{path: '/flexad/index', name:"flexad-index", component: FlexAdIndexComponent , meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexAdAllowed"])}},
	{path: '/flexad/create', name:"flexad-create", component: FlexAdCreateComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexAdAllowed"])}},
	{path: '/flexad/edit/:id', name:"flexad-edit", component: FlexAdEditComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexAdAllowed"])}},
    
	/*FlexTicket*/
	{path: '/flexTicket/type', component: FlexTicketIndexComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexTicketConfigAllowed"])}},
	{path: '/flexTicket/*', component: AspNetViewComponent, meta: { sideMenuType: "settings" },
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexTicketConfigAllowed"])}},

	/*#endregion*/
	/*Gift Card*/
	{path: '/giftCard/giftCard', component: GiftCardTableIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsGiftcardsAllowed"])}},
	/*Campaign Module*/
	{path: '/campaignModule/campaign', component: CampaignTableIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCampaignAllowed"])}},
	{path: '/campaignModule/campaign/edit/:id', component: CampaignEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCampaignAllowed"])}},
	{path: '/campaignModule/campaign/copy/:id', component: CampaignEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCampaignAllowed"])}},
	{path: '/campaignModule/campaign/create', component: CampaignCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsCampaignAllowed"])}},
	/*#region VendorModules*/
	/*Vendor*/
	{path: '/vendorModule/vendor', name:"vendor-index", component: VendorModuleTableIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVendorModuleAllowed"])}},
	{path: '/vendorModule/vendor/create', component: VendorModuleCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVendorModuleAllowed"])}},
	{path: '/vendorModule/vendor/edit/:id', component: VendorModuleEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVendorModuleAllowed"])}},

	/*Order*/
	{path: '/vendorModule/orders', name:"vendor-module-order-index", component: OrderTableIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVendorOrdersEnabledAllowed"])}},
	{path: '/vendorModule/orders/edit/:id', name:"vendor-module-order-edit", component: OrderEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVendorOrdersEnabledAllowed"])}},
	{path: '/vendorModule/orders/details/:id', component: OrderDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVendorOrdersEnabledAllowed"])}},
	/*GoodsReception*/
	{path: '/vendorModule/goodsReception', name:"vendor-module-goods-reception-index", component: GoodsReceptionTableIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVendorModuleAllowed"])}},
	{path: '/vendorModule/goodsReception/edit/:id', name:"vendor-module-goods-reception-edit", component: GoodsReceptionEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVendorModuleAllowed"])}},
	{path: '/vendorModule/goodsReception/create', component: GoodsReceptionCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVendorModuleAllowed"])}},
	{path: '/vendorModule/goodsReception/details/:id', component: GoodsReceptionDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsVendorModuleAllowed"])}},
	/*#endregion*/
	{path: '/status-module/', name:"status-module-index", component: StatusIndexComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsStockModuleAllowed"])}},
	{path: '/status-module/:id', name:"status-module-edit", component: StatusEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsStockModuleAllowed"])}},
	{path: '/status-module/details/:id', name:"status-module-details", component: StatusDetailsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsStockModuleAllowed"])}},

	{path: '/flex-voucher/totals-overview', name:"flex-voucher-totals", component: FlexVoucherTotalsComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexVoucherModuleAllowed"])}},
	{path: '/flex-voucher/totals-per-month', name:"flex-voucher-totals-per-month", component: FlexVoucherTotalsPerMonthComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexVoucherModuleAllowed"])}},
	{path: '/flex-voucher/transaction-history', name:"flex-voucher-transaction-history", component: FlexVoucherTransactionHistoryComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexVoucherModuleAllowed"])}},
	{path: '/flex-voucher/details/:id', name:"flex-voucher-info", component: FlexVoucherInfoComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexVoucherModuleAllowed"])}},
	{path: '/flex-voucher/batch', name:"flex-voucher-batch", component: FlexVoucherBatchComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexVoucherModuleAllowed"])}},
	{path: '/flex-voucher/batch/create', name:"flex-voucher-batch-create", component: FlexVoucherBatchCreateComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexVoucherModuleAllowed"])}},
	{path: '/flex-voucher/batch/:id', name:"flex-voucher-batch-edit", component: FlexVoucherBatchEditComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexVoucherModuleAllowed"])}},
	{path: '/flex-voucher/voucher-overview', name:"flex-voucher-overview", component: FlexVoucherOverviewComponent,
		beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsFlexVoucherModuleAllowed"])}},
	/*Do not add routing protection on this, one should beable to download it without being logged in.*/
	{path: '/flex-voucher/download-file/:id', name:"flex-voucher-download-file", component: FlexVoucherDownloadFileComponent},

	/*#region Admin*/	
	/*FlexDeploy*/
	{path: '/Admin/FlexDeploy', name:"flex-deploy-index", component: FlexDeployComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/Admin/FlexDeploy/FlexPos/:id', name:"flex-deploy-details", component: FlexDeployDetailsComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/Admin/FlexDeploy/Edit/:id', name:"flex-deploy-edit", component: FlexDeployEditComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/Admin/FlexDeploy/Create/', name:"flex-deploy-create", component: FlexDeployCreateComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/Admin/FlexDeploy/Create/:id', name:"flex-deploy-details-create", component: FlexDeployCreateComponent, beforeEnter: navigationIsFlexPosAdmin},

	/*Enum*/
	{path: '/Admin/Enum', name:"enum-index", component: EnumComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/Admin/Enum/:id', name:"enum-children", component: EnumChildrenComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/Admin/Enum/View/:id', name:"enum-view", component: EnumViewComponent, beforeEnter: navigationIsFlexPosAdmin},

	/*Language*/
	{path: '/LanguageModule/Language', name:"language-index", component: LanguageIndexComponent, beforeEnter: (to, from, next) => {navigationAllowed(to, from, next, store.getters["navigationGuardState/getIsLanguageManagementAllowed2"])}},


	/*Notifications*/
	{path: '/Admin/Notifications', name:"notifications-index", component: NotificationsIndexComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/Admin/Notifications/Edit/:id', name:"notifications-edit", component: NotificationsEditComponent, beforeEnter: navigationIsFlexPosAdmin},
	{path: '/Admin/Notifications/Create', name:"notifications-create", component: NotificationsCreateComponent, beforeEnter: navigationIsFlexPosAdmin},
	/*#endregion*/
	
	/*Notifications*/
	{path: '/Notification/showAll', name:"notifications-show-all", component: NotificationShowAllComponent},
	
	{path: '/FlexVoucher/FlexVoucherCsv/GetCsvFile',
		//@ts-ignore
		redirect: to =>  {
			return { name: "flex-voucher-download-file", params: { id: to.query.fileUid }, query: null };
		}
	},

	/*Other*/
	{path: '/dashboard/client/edit-current-client', component: AspNetViewComponent},
	{path: '/dashboard/client/go-one-up', component: AspNetViewComponent},
	{path: '/dashboard/client/go-to-top', component: AspNetViewComponent},
	{path: '/dashboard/client/go-to-client/:id', component: AspNetViewComponent},
	{path: '/logout', component: AspNetViewComponent},
	{path: '*', component: AspNetViewComponent},
];

function navigationAllowed(to, from, next, allowed): any {
	if(allowed) {
		next();
		return;
	}
	return next(from);
}

function navigationIsFlexPosAdminOrIsReseller(to, from, next): any {
	if(window.store.getters.getIsFlexPosAdmin || window.store.getters.getIsAmeroAdmin || window.store.getters.getIsReseller) {
		next();
		return;
	}
	return next(from);
}

function navigationIsFlexPosAdmin(to, from, next): any {
	if(window.store.getters.getIsFlexPosAdmin) {
		next();
		return;
	}
	return next(from);
}

if (process.env.NODE_ENV === "development") {
	routes.unshift({path: '/test', name: "test-components", component: TestComponent});
}
const router = new VueRouter({
	mode: 'history',
	routes: routes
});


const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	reducer: (state: IState) => {
		return {
			isLoggedIn: state.isLoggedIn,
			clientUid: state.clientUid,
			userUid: state.userUid,
			parentClientUid: state.parentClientUid,
			parentUserUid: state.parentUserUid,
			parentUserClientUid: state.parentUserClientUid,
			cultureId: state.cultureId,
			client: state.client,
			user: state.user,
			isReseller: state.isReseller,
			isFlexPosAdmin: state.isFlexPosAdmin,
			isAmeroAdmin: state.isAmeroAdmin,
			isFlexPosReseller: state.isFlexPosReseller,
			isFranchise: state.isFranchise,
			isParent: state.isParent,
			isSwitchClientParent: state.isSwitchClientParent,
			isExternalPageActive: state.isExternalPageActive,
			isFlexVoucherOwner: state.isFlexVoucherOwner,
			isDegraded: state.isDegraded,
			getDataFromParent: state.getDataFromParent,
			sideMenuIn: state.sideMenuIn,
			isDarkTheme: state.isDarkTheme,
			productConfigState: {
				unitTypeEnum: state.productConfigState.unitTypeEnum
			},
			productTableIndexState: {
				savedSearchParams: state.productTableIndexState.savedSearchParams,
				productUids: state.productTableIndexState.productUids
			},
			inlineLanguageTextEditor: state.inlineLanguageTextEditor,
		};
	}
});
type KeyValueStringBoolean = { [key: string]: boolean };
type KeyValueStringString = { [key: string]: string };
type FlexPOSConfigPropertyKey = FlexPOSConfigProperty | string;
type KeyValueFlexPOSConfigAny = { [key: FlexPOSConfigPropertyKey]: unknown };

interface IState {
	languageUid: string;
	appLoaded: boolean;
	appLoadingFailed: boolean;
	isLoggedIn: boolean;
	clientUid: string;
	userUid: string;
	parentClientUid: string;
	parentUserUid: string;
	parentUserClientUid: string;
	cultureId: number;
	client: API_Internal_FrontendAPI_Models_Client_ClientViewModel;
	user: API_Internal_FrontendAPI_DataAccess_Entities_User;
	isReseller: boolean;
	isFlexPos: boolean;
	isFlexPosAdmin: boolean;
	isAmero: boolean;
	isAmeroAdmin: boolean;
	isFlexPosReseller: boolean;
	isFranchise: boolean,
	appVersion: string;
	appVersionLastRequest: string;
	sideMenuIn: boolean;
	tableAction: { type: string, argument: object };
	toasts: { type: "danger" | "success" | "primary" | "info" | "secondary", text: string | object, header: string, until: number, time: number, Id: string }[];
	backendConfigs: KeyValueStringString;
	flexPosConfigsDic: KeyValueFlexPOSConfigAny;
	activeAddons: KeyValueStringBoolean;
	// eslint-disable-next-line @typescript-eslint/ban-types
	axiosOptions: Function;
	tabVisible: boolean;
	environment: string;
	getDataFromParent: boolean;
	isParent: boolean;
	isSwitchClientParent: boolean;
	isExternalPageActive: boolean;
	isFlexVoucherOwner: boolean;
	isDegraded: boolean;
	isDarkTheme: boolean;
	modals: Vue[];
	validationPanelUpdated: number;
	validationPanelHeights: object;
	fingerprint: string;
	transactionHistoryGuids: Guid[];
	productConfigState: IProductConfigState;
	productTableIndexState: IProductTableState;
	inlineLanguageTextEditor: boolean;
}

const store = new Vuex.Store<IState>({
	modules: {
		storeValidation,
		navigationGuardState,
		/*#region Dashboard*/
		/*Overview*/
		overviewIndexState,
		unitsFailureTableIndexState,
		pipelineTableIndexState,
		obsListTableIndexState,
		/*Client*/
		clientBulkEditTableState,
		/*Currency*/
		currencyTableIndexState,
		/*Customers*/
		customerTableIndexState,
		customerTransactionHistoryDetailsState,
		customerBulkDeleteTableState,
		/*Customer Groups*/
		customerGroupTableIndexState,
		/*Dynamic Report*/
		dynamicReportState,
		dynamicReportColumnsIndexState,
		/*Financial Product Groups*/
		financialProductGroupTableIndexState,
		/*Products*/
		productConfigState,
		productStockConfigState,
		productTableIndexState,
		productBulkEditTableState,
		productBulkDeleteTableState,
		productDeleteTableState,
		productStockTableIndexState,
		productStockDetailsTableState,
		productStockEnumState,
		/*Product Groups*/
		productDisplayGroupTableIndexState,
		/*Product Brands*/
		productBrandTableIndexState,
		/*Statistics*/
		statisticsIndexState,
		/*Warranty*/
		warrantyTableIndexState,
		/*#endregion*/
		/*#region ResellerModule*/
		billingTableIndexState,
		resellerTableIndexState,
		actionsTableIndexState,
		/*#endregion*/
		/*#region Settings*/
		/*Bon Layout*/
		bonLayoutModuleIndexState,
		/*Card Group*/
		cardGroupTableIndexState,
		/*Customer Statistics*/
		customerStatisticsIndexState,
		/*Design And Layout*/
		buttonFunctionsState,
		/*Sync Job*/
		syncJobQueueTableIndexState,
		syncJobFailedTableIndexState,
		syncJobDoneTableIndexState,
		/*Report Sync Job*/
		reportSyncJobTableIndexState,
		/*FlexPosConfig*/
		flexPosConfigTableIndexState,
		/*PaymentType*/
		paymentTypesState,
		/*PaymentImport*/
		productImportIndexState,
		/*Product Campaign*/
		productCampaignTableIndexState,
		productCampaignTableAddProductsModalState,
		/*#endregion*/
		/*Gift Card*/
		giftCardTableIndexState,
		/*Cash Management*/
		cashManagementTableIndexState,
		/*Campaign Module*/
		campaignTableIndexState,
		campaignProductsTableState,
		/*Product List*/
		productListTableIndexState,
		/*Users*/
		userTableIndexState,
		posUserTableIndexState,
		/*FlexTicket*/
		flexTicketTableIndexState,
		/*Transaction History*/
		transactionHistoryTableIndexState,
		transactionHistoryDetailsState,
		/*#region Vendor Modules*/
		/*Vendor*/
		vendorTableIndexState,
		/*Order*/
		orderTableIndexState,
		orderDetailsState,
		/*GoodsReception*/
		goodsReceptionTableIndexState,
		goodsReceptionDetailsState,
		vendorModalTableState,
		/*#endregion*/
		/*Other*/
		topMenuState,
		notificationState,
		activeAddonsState,
		backendConfigState,
		productAutocompleteState,
		statusModuleNotApprovedTableIndex,
		statusModuleApprovedTableIndex,
		statusModuleFinalizedTableIndex,
		statusModuleEditTableIndex,
		statusModuleIndex,
		/*#region Admin*/
		/*FlexDeploy*/
		flexDeployTableIndexState,
		flexDeployBulkEditTableState,
		/*#endregion*/

		/*EnumTypes*/
		enumTypesTableIndexState,
		enumTableIndexState,
		
		/*Language*/
		languageTableIndexState,
	},
	//@ts-ignore
	state: {
		languageUid: null,
		appLoaded: false,
		appLoadingFailed: false,
		isLoggedIn: null,
		clientUid: null,
		userUid: null,
		parentClientUid: null,
		parentUserUid: null,
		parentUserClientUid: null,
		cultureId: null,
		client: null,
		user: null,
		isReseller: null,
		isFlexPos: null,
		isFlexPosAdmin: null,
		isAmero: null,
		isAmeroAdmin: null,
		isFlexPosReseller: null,
		isFranchise: null,
		appVersion: null,
		appVersionLastRequest: null,
		sideMenuIn: null,
		tableAction: null,
		toasts: [],
		backendConfigs: {},
		flexPosConfigsDic: {},
		activeAddons: {},
		axiosOptions: null,
		tabVisible: true,
		environment: 'development',
		getDataFromParent: false,
		isParent: false,
		isDarkTheme: false,
		modals: [],
		validationPanelUpdated: 0,
		validationPanelHeights: {},
		fingerprint: null,
		transactionHistoryGuids: [],
		productConfigState: null,
		productTableIndexState: null,
		inlineLanguageTextEditor: false,
	},
	getters: {
		getActiveModal: state => () => {
			if (state.modals.length === 0) {
				return null;
			}

			if (state.modals.length === 1) {
				const modal = state.modals[0];
				// @ts-ignore
				if (modal.backdrop != null && modal.backdrop.classList.contains("show"))
					return modal;
			}

			let activeModal = null;
			for (let i = 0; i < state.modals.length; i++) {

				const modal = state.modals[i];
				// @ts-ignore
				if (!(modal.backdrop != null && modal.backdrop.classList.contains("show"))) continue;

				if (!activeModal?.$el?.style?.zIndex
					//@ts-ignore
					&& !isNaN(Number(modal.$el.style.zIndex))
					//@ts-ignore
					&& Number(modal.$el.style.zIndex) > 0)
					activeModal = modal;

				//@ts-ignore
				if (activeModal != null
					&& !isNaN(Number(activeModal.$el.style.zIndex))
					//@ts-ignore
					&& Number(modal.$el.style.zIndex) > 0)
					continue;

				activeModal = modal;
			}
			return activeModal;
		},
		getRequestVerificationToken() {
			const token: HTMLInputElement = document.querySelector('#token > input');
			return token.value;
		},
		getModals(state): Vue[] {
			return state.modals;
		},
		getIsDarkTheme(state) {
			return state.isDarkTheme;
		},
		getEnvironment(state) {
			return state.environment;
		},
		getIsFlexPosAdmin(state) {
			return state.isFlexPosAdmin;
		},
		getIsAmeroAdmin(state) {
			return state.isAmeroAdmin;
		},
		getIsFlexPosReseller(state) {
			return state.isFlexPosReseller;
		},
		getIsFranchise(state) {
			return state.client.IsFranchise;
		},
		getIsReseller(state) {
			return state.isReseller;
		},
		getIsFlexPos(state) {
			return state.isFlexPos;
		},
		getIsAmero(state) {
			return state.isAmero;
		},
		getFlexPosConfigGetter(state) {
			return function (prop) {
				prop = (prop + "").toLowerCase();
				for (const p in state.flexPosConfigsDic) {
					if (state.flexPosConfigsDic.hasOwnProperty(p) && prop == (p + "").toLowerCase()) {
						return state.flexPosConfigsDic[p];
					}
				}
			};
		},
		getFlexPosConfigBool(state, getters): (key: FlexPOSConfigPropertyKey, defaultValue: boolean) => boolean {
			return (key: FlexPOSConfigPropertyKey, defaultValue = false) => {
				if (key === null || key === undefined) return defaultValue;

				const value = getters.getFlexPosConfigGetter(key);
				if (typeof value === "boolean") return value;

				return defaultValue;
			};
		},
		getFlexPosConfigString(state, getters): (key: FlexPOSConfigPropertyKey, defaultValue: string | null) => string | null {
			return (key: FlexPOSConfigPropertyKey, defaultValue: string | null = null) => {
				if (key === null || key === undefined) return defaultValue;

				const value = getters.getFlexPosConfigGetter(key);
				if (value) return value;

				return defaultValue;
			};
		},
		getFlexPosConfigInt(state, getters): (key: FlexPOSConfigPropertyKey, defaultValue: number | undefined | null) => number | undefined {
			return (key: FlexPOSConfigPropertyKey, defaultValue: number | undefined | null = null): number | undefined => {
				if (key === null || key === undefined) return defaultValue || undefined;

				const value = getters.getFlexPosConfigGetter(key);
				if (value != null) return value;

				return defaultValue || undefined;
			};
		},
		getBackendConfigGetter(state) {
			return function (prop) {
				prop = (prop + "").toLowerCase();
				for (const p in state.backendConfigs) {
					if (state.backendConfigs.hasOwnProperty(p) && prop == (p + "").toLowerCase()) {
						return state.backendConfigs[p];
					}
				}
			};
		},
		getBackendConfigExists(state, getters): (key: string) => boolean {
			return (key: string): boolean => {
				const keyValue = getters.getBackendConfigGetter(key);
				return keyValue != null;
			}
		},
		getBackendConfigBool(state, getters): (key: string, defaultValue: boolean) => boolean {
			return (key: string, defaultValue = false): boolean => {
				if (key === null || key === undefined) return defaultValue;
				if (state.backendConfigs === null || state.backendConfigs === undefined) return defaultValue;

				const keyValue = getters.getBackendConfigGetter(key);
				if (keyValue !== null && keyValue !== undefined) {
					return keyValue.toLowerCase() === "true";
				}

				return defaultValue;
			};
		},
		getBackendConfigString(state, getters): (key: string, defaultValue: string | null) => string | null {
			return (key: string, defaultValue: string | null = null): string | null => {
				if (key === null || key === undefined) return defaultValue;

				const keyValue = getters.getBackendConfigGetter(key);
				if (keyValue !== null && keyValue !== undefined) {
					return keyValue;
				}

				return defaultValue;
			};
		},
		getState(state) {
			return state;
		},
		getTableAction(state) {
			return state.tableAction;
		},
		getDataFromParent(state) {
			return state.getDataFromParent;
		},
		getToasts(state) {
			const arr = [];
			for (let i = 0; i < state.toasts.length; i++) {
				const item = state.toasts[i];
				if ((item.until !== null && item.until !== undefined) && item.until > (item.until - Date.now())) continue;

				//Vue.$cookies
				arr.push({
					Id: item.Id,
					type: item.type,
					header: item.header,
					text: item.text,
					//@ts-ignore
					timeText: window.moment(item.time).fromNow()
				});
			}
			return arr;
		},
		getActiveAddons(state) {
			return (key: string, defaultValue = false): boolean => {
				key = key.toLowerCase();
				if (key === null || key === undefined) return defaultValue;
				if (state.activeAddons === null || state.activeAddons === undefined) return defaultValue;
				const keyValue = state.activeAddons[key];
				if (keyValue !== null && keyValue !== undefined) {
					return keyValue.toString().toLowerCase() === "true";
				}

				return defaultValue;
			};
		},
		getSideMenu(state) {
			return state.sideMenuIn;
		},
		getIsLoggedIn(state) {
			return state.isLoggedIn;
		},
		getUserName(state) {
			if (state.user)
				return state.user.Name;

			return "N/A";
		},
		getUserUID(state) {
			if (state.user)
				return state.user.Uid;

			return Guid.Empty;
		},
		getUserCellPhone(state) {
			if (state.user)
				return state.user.CellPhone;

			return "";
		},
		getUserPhone(state) {
			if (state.user)
				return state.user.Phone;

			return "";
		},
		getUserEmail(state) {
			if (state.user)
				return state.user.Email;

			return "";
		},
		getUserIsAdmin(state) {
			if (state.user)
				return state.user.IsAdmin;

			return "";
		},
		getUserInitials(state) {
			if (state.user)
				return state.user.Initials;

			return "N/A";
		},
		getClientCountryUid(state) {
			if (state.client)
				return state.client.CountryUid;

			return null;
		},
		getClientName(state) {
			if (state.client)
				return state.client.Name;

			return null;
		},
		getClientId(state) {
			return state.client?.ID || null;
		},
		getClientUid(state) {
			return state.clientUid;
		},
		getClientIsParent(state) {
			return state.client.IsParent;
		},
		getClientHasChildren(state) {
			return state.client.HasChildren;
		},
		getParentClientUid(state) {
			return state.parentClientUid;
		},
		getParentUserClientUid(state) {
			return state.parentUserClientUid;
		},
		getParentUserUid(state) {
			return state.parentUserUid;
		},
		getClientTypeUid(state) {
			if (!state.client) return null;
			if (!state.client.ClientTypeUid) return null;
			return state.client.ClientTypeUid;
		},
		getClientType(state) {
			if (!state.client) return null;
			if (!state.client.ClientTypeUid) return null;

			switch (state.client.ClientTypeUid) {
				case "d71498ba-001c-4163-80bb-54a5b3f301c8":
					return ClientType.Test;
				case "4625a2c9-ecc9-408b-b147-7785671019a9":
					return ClientType.Vendor;
				case "1eb5c1a6-e79a-4c0f-a588-788215e7c566":
					return ClientType.Template;
				case "9c8b7fde-d122-47e5-a6eb-83bf1c31ca1a":
					return ClientType.License_Light;
				case "e163a1ad-f04f-449d-b1ac-d29101b7909e":
					return ClientType.Partners;
				case "c6b2d2c8-e2a7-4e60-9c83-d636720a0972":
					return ClientType.ParentType;
				case "26e4afbc-c7a8-4655-9f3e-dc9d332ee44a":
					return ClientType.License_Pro;
				case "b7738ac4-b22d-43ac-b738-e5b51702fbf1":
					return ClientType.Closed;
				case "e3452869-fce6-433f-a3b3-27f225044ba0":
					return ClientType.Inactive;
				case "1683bd85-8d31-4fb4-98fe-e742486d97c5":
					return ClientType.Reseller;
				case "8160515d-70fb-4027-ac2d-ea6414e5bf7e":
					return ClientType.MainClient;
				case "49b31970-8441-43e7-9821-ef93aa027228":
					return ClientType.Subject;
				default:
					return null;
			}
		},
		getIsParentType(state) {
			return (clientTypeUid: string): boolean => {
				if(clientTypeUid == null) {
					if (!state.client) return null;
					if (!state.client.ClientTypeUid) return null;
					clientTypeUid = state.client.ClientTypeUid;
				}
				
				switch (clientTypeUid.toLowerCase()) {
					case "c6b2d2c8-e2a7-4e60-9c83-d636720a0972":
					case "49b31970-8441-43e7-9821-ef93aa027228":
					case "8160515d-70fb-4027-ac2d-ea6414e5bf7e":
					case "1683bd85-8d31-4fb4-98fe-e742486d97c5":
						return true
				}
				
				return false;
			};
		},
		getIsOtherClientTypes(state) {
			return (clientTypeUid: string): boolean => {
				if(clientTypeUid == null) {
					if (!state.client) return null;
					if (!state.client.ClientTypeUid) return null;
					clientTypeUid = state.client.ClientTypeUid;
				}

				switch (clientTypeUid.toLowerCase()) {
					case "d71498ba-001c-4163-80bb-54a5b3f301c8":
					case "e3452869-fce6-433f-a3b3-27f225044ba0":
					case "b7738ac4-b22d-43ac-b738-e5b51702fbf1":
						return true
				}

				return false;
			};
		},
		getLanguageUid(state) {
			return state.languageUid;
		},
		getAppVersion(state) {
			return state.appVersion;
		},
		getAxiosOptions(state) {
			return state.axiosOptions;
		},
		getSwaggerAxiosOptions() { return getSwaggerAxiosOptionsObj; },
		getUserIsDegraded(state) {
			return (state.user && state.user.IsDegraded) || false;
		},
		getTabIsVisible(state) {
			return state.tabVisible;
		},
		getAppLoadingFailed(state) {
			return state.appLoadingFailed;
		},
		getValidationPanelHeights(state) {
			return state.validationPanelHeights;
		},
		getValidationPanelUpdated(state) {
			return state.validationPanelUpdated;
		},
		getFingerprint(state) {
			return state.fingerprint;
		},
		getIsSwitchClientParent(state) {
			return state.isSwitchClientParent;
		},
		getInlineLanguageTextEditor(state) {
			return state.inlineLanguageTextEditor;
		}
	},
	actions: {
		allowedStockAddons() {
			return (...types: StockType[]) => {
				let allowed = false;

				if (types.indexOf(StockType.SimpleStock) > -1)
					allowed = store.getters["getActiveAddons"]("8E818B29-74D9-4DC8-A13B-B0F99739A1D5");
				if (types.indexOf(StockType.StatusAndScan) > -1)
					allowed = store.getters["getActiveAddons"]("42FCD9AC-2034-46A9-8438-3B5078AF23D1");
				if (types.indexOf(StockType.Logistics) > -1)
					allowed = store.getters["getActiveAddons"]("1205C5D0-A88A-4549-BA54-E761938DC03B");

				return allowed;
			};
		},
		changeSideMenu(context, val) {
			context.commit("SET_SIDE_MENU", val);
		},
		ADD_MODAL(context, modal) {
			context.commit("ADD_MODAL", modal);
		},
		REMOVE_MODAL(context, modal) {
			context.commit("REMOVE_MODAL", modal);
		},
		RESET_STATE(context) {
			context.commit("RESET_STATE");
		},
		SET_APP_VERSION_LAST_REQUEST(context, appVersion) {
			context.commit("SET_APP_VERSION_LAST_REQUEST", appVersion);
		},
		SET_ENVIRONMENT(context, environment) {
			context.commit("SET_ENVIRONMENT", environment);
		},
		setTabVisible(context, args) {
			context.commit("SET_TAB_VISIBLE", args);
		},
		async FETCH_PARENT_USER(context) {
			return (await UserService.userGET({uid: context.state.parentUserUid})).data;
		},
		async FETCH_CLIENT_SELF() {
			return await ClientService.getSelf();
		},
		async setActiveAddons(context) {
			const addons = await AddonService.getActiveDictionary();

			context.commit("SET_ACTIVE_ADDONS", addons.data);
		},
		async setAppLoaded(context) {
			context.commit("SET_APP_LOADED");
		},
		async setAppLoadingFailed(context) {
			context.commit("SET_APP_LOADING_FAILED");
		},
		async setUserSession(context) {
			const response = await axios("/backend-service/Account/GetUserSession");
			const userSession = response.data;
			context.commit("SET_USER_STATE", userSession);

			await app.$nextTick(async () => {
				if (userSession.IsLoggedIn) {
					if (store.state.client == null || store.state.client.Uid != store.state.clientUid) {
						const client = await ClientService.getSelf();
						context.commit("SET_CLIENT", client.data);
					}
					if (store.state.user == null || store.state.user.Uid != store.state.parentClientUid) {
						await context.dispatch("setUser");
					}
				}
			});
		},
		async setClient(context) {
			context.commit("SET_CLIENT", await context.dispatch("FETCH_CLIENT_SELF"));
		},
		async setUser(context) {
			context.commit("SET_USER", await context.dispatch("FETCH_PARENT_USER"));
		},
		async setBackendConfigs(context) {
			const response = await axios("/backend-service/Account/GetBackendConfigs");
			const obj = {};
			for (let i = 0; i < response.data.length; i++) {
				const item = response.data[i];
				obj[item.key] = item.value;
			}

			context.commit("SET_BACKEND_CONFIG", obj);
		},
		async setBackendConfig(context, args: { key: string, value: string | number | boolean }) {
			const response = await axios("/backend-service/Account/SetBackendConfig", {
				params: {
					key: args.key,
					value: args.value
				}
			});
			const obj = {};
			for (let i = 0; i < response.data.length; i++) {
				const item = response.data[i];
				obj[item.key] = item.value;
			}

			context.commit("SET_BACKEND_CONFIG", obj);
		},
		async setFlexPosConfigs(context) {
			const configs = await FlexPosConfigService.getDictionary();

			context.commit("SET_FLEX_POS_CONFIG", configs.data);
		},
		addToast(context, toast) {
			context.commit("ADD_TOAST", toast);
		},
		removeToast(context, toastId) {
			context.commit("REMOVE_TOAST", toastId);
		},
		setTableAction(context, args: { type: string, argument: unknown }) {
			if (args != null) {
				if (args.type == null) {
					console.error("setTableAction(): args.type not set");
				}
				if (args.argument == null) {
					console.error("setTableAction(): args.argument not set");
				}
			}
			context.commit("SET_TABLE_ACTION", args);
		},
		SET_VALIDATION_PANEL_UPDATED(context) {
			context.commit("SET_VALIDATION_PANEL_UPDATED");
		},
		SET_VALIDATION_PANEL_HEIGHT(context, args) {
			context.commit("SET_VALIDATION_PANEL_HEIGHT", args);
		},
		SET_FINGERPRINT(context, args) {
			context.commit("SET_FINGERPRINT", args);
		},
		SET_INLINE_LANGUAGE_TEXT_EDITOR(context, args) {
			context.commit("SET_INLINE_LANGUAGE_TEXT_EDITOR", args);
		},
	},
	mutations: {
		SET_SIDE_MENU(state, val) {
			if (typeof val !== "undefined")
				state.sideMenuIn = val;
			else
				state.sideMenuIn = !state.sideMenuIn;

		},
		ADD_MODAL(state, modal) {
			state.modals.push(modal);
		},
		SET_VALIDATION_PANEL_UPDATED(state) {
			state.validationPanelUpdated = new Date().getTime();
		},
		SET_VALIDATION_PANEL_HEIGHT(state, args) {
			state.validationPanelHeights[args.name] = args.height;
		},
		REMOVE_MODAL(state, modal) {
			const index = state.modals.indexOf(modal);
			if (index > -1)
				state.modals.slice(index, 1);
		},
		RESET_STATE(state) {
			state.appLoaded = false;
			state.isLoggedIn = null;
			state.languageUid = null;
			state.clientUid = null;
			state.userUid = null;
			state.parentClientUid = null;
			state.parentUserUid = null;
			state.parentUserClientUid = null;
			state.cultureId = null;
			state.client = null;
			state.user = null;
			state.isReseller = null;
			state.isFlexPos = null;
			state.isFlexPosAdmin = null;
			state.isAmero = null;
			state.isAmeroAdmin = null;
			state.isFlexPosReseller = null;
			state.appVersion = null;
			state.appVersionLastRequest = null;
			state.sideMenuIn = true;
			state.tableAction = null;
			state.toasts = [];
			state.backendConfigs = {};
			state.activeAddons = {};
			state.tabVisible = true;
			state.environment = 'development';
			state.isParent = false;
			state.isSwitchClientParent = false;
			state.isFlexVoucherOwner = false;
			state.isExternalPageActive = false;
			state.isDegraded = false;
			state.getDataFromParent = false;
			state.inlineLanguageTextEditor = false;
		},
		SET_APP_VERSION_LAST_REQUEST(state, appVersion) {
			state.appVersionLastRequest = appVersion;
		},
		SET_ENVIRONMENT(state, environment) {
			state.environment = environment;
		},
		SET_TAB_VISIBLE(state, isVisible) {
			state.tabVisible = isVisible;
		},
		SET_ACTIVE_ADDONS(state, activeAddons) {
			state.activeAddons = activeAddons;
		},
		SET_USER_STATE(state: IState, session) {
			state.isLoggedIn = session.IsLoggedIn;
			state.clientUid = session.ClientUid;
			state.userUid = session.UserUid;
			state.parentClientUid = session.ParentClientUid;
			state.parentUserClientUid = session.ParentUserClientUid;
			state.parentUserUid = session.ParentUserUid;
			state.cultureId = session.CultureId;
			state.appVersion = session.AppVersion;
			state.appVersionLastRequest = session.AppVersion;
			state.languageUid = session.LanguageUid;

			state.isFlexPos = session.IsFlexPOS;
			state.isFlexPosAdmin = session.IsFlexPosAdmin;
			state.isAmero = session.IsAmero;
			state.isAmeroAdmin = session.IsAmeroAdmin;
			state.isFlexPosReseller = session.IsFlexPosReseller;
			state.isReseller = session.IsReseller;
			state.isParent = session.IsParent;
			state.isSwitchClientParent = session.IsSwitchClientParent;
			state.isExternalPageActive = session.IsExternalPageActive;
			state.isFlexVoucherOwner = session.IsFlexVoucherOwner;
			state.isDegraded = session.IsDegraded;
			state.getDataFromParent = session.GetDataFromParent;
		},
		SET_BACKEND_CONFIG(state, backendConfigs) {
			state.backendConfigs = backendConfigs;
		},
		SET_FLEX_POS_CONFIG(state, configs) {
			state.flexPosConfigsDic = configs;
		},
		SET_CLIENT(state: IState, client: API_Internal_FrontendAPI_Models_Client_ClientViewModel) {
			state.client = client;
		},
		SET_USER(state: IState, user: API_Internal_FrontendAPI_DataAccess_Entities_User) {
			state.user = user;
		},
		SET_APP_LOADED(state, appLoaded = true) {
			state.appLoaded = appLoaded;
		},
		SET_APP_LOADING_FAILED(state) {
			state.appLoadingFailed = true;
		},
		SET_TABLE_ACTION(state, args) {
			state.tableAction = args;
		},
		ADD_TOAST(state, toast) {
			toast.Id = Guid.create().toString();
			toast.time = Date.now();
			state.toasts.push(toast);
		},
		REMOVE_TOAST(state, toastId) {
			const index = findIndex(state.toasts, function (toast) {
				return toast.Id === toastId;
			});
			if (index > -1) {
				state.toasts.splice(index, 1);
			}
		},
		SET_FINGERPRINT(state, args) {
			state.fingerprint = args;
		},
		SET_INLINE_LANGUAGE_TEXT_EDITOR(state, args) {
			state.inlineLanguageTextEditor = args;
		},
	},
	plugins: [vuexLocal.plugin]
});

window.store = store;

router.beforeEach(async (to, from, next) => {
	async function doAction() {
		if (to.name === "flex-voucher-download-file") {
			next();

			return;
		}

		if (to.name === "economic-app" || to.name === "economic-app-login") {
			next();

			return;
		}

		if (!store.state.isLoggedIn && !store.state.appLoadingFailed) {
			if (to.name !== 'login')
				next({path: '/login'});
			else next();

			return;
		}

		if (to.name === 'login') {
			window.store.commit("SET_APP_LOADED", false);
			next({path: store.getters["backendConfigState/getBackendConfigDefaultPage"]});

			await reloadConfiguration();

			window.vueApp.$nextTick(() =>
				window.location.href = store.getters["backendConfigState/getBackendConfigDefaultPage"]
			);

			return;
		} else if (to.path === '/') {
			window.store.commit("SET_APP_LOADED", false);
			next({path: store.getters["backendConfigState/getBackendConfigDefaultPage"]});

			await reloadConfiguration();

			window.vueApp.$nextTick(() =>
				window.location.href = store.getters["backendConfigState/getBackendConfigDefaultPage"]
			);

			return;
		}

		if (to.path === '/dashboard/client/go-one-up') {
			window.store.commit("SET_APP_LOADED", false);
			await axios(`/backend-service/dashboard/client/changeclient?clientUid=${store.getters.getParentClientUid}&parentUid=null`);

			await reloadConfiguration();

			window.vueApp.$nextTick(() =>
				window.location.href = store.getters["backendConfigState/getBackendConfigDefaultPage"]
			);

			return;
		}

		if (to.path === '/dashboard/client/go-to-top') {
			window.store.commit("SET_APP_LOADED", false);
			await axios(`/backend-service/dashboard/client/changeclient?clientUid=${store.getters.getParentUserClientUid}&parentUid=null`);

			await reloadConfiguration();

			window.vueApp.$nextTick(() =>
				window.location.href = store.getters["backendConfigState/getBackendConfigDefaultPage"]
			);

			return;
		}

		if (to.path === '/dashboard/client/edit-current-client') {
			const clientUid = store.getters.getClientUid;
			window.store.commit("SET_APP_LOADED", false);
			await axios(`/backend-service/dashboard/client/changeclient?clientUid=${store.getters.getParentUserClientUid}&parentUid=null`);

			await reloadConfiguration();

			window.vueApp.$nextTick(() => {
				if(to.query['edit-module'] === '1') window.location.href = `/resellerModule/reseller/edit-store/${clientUid}#?modules`;
				else window.location.href = `/resellerModule/reseller/edit-store/${clientUid}`;
			});

			return;
		}

		if (to.path.startsWith('/dashboard/client/go-to-client/')) {
			window.store.commit("SET_APP_LOADED", false);
			await axios(`/backend-service/dashboard/client/changeclient?clientUid=${to.params.id}&parentUid=null`);

			await reloadConfiguration();

			window.vueApp.$nextTick(() => {
				if (store.getters.getIsReseller || store.getters.getIsFlexPos || store.getters.getIsAmero)
					window.location.href = window.location.origin + "/ResellerModule/Reseller";
				else window.location.href = store.getters["backendConfigState/getBackendConfigDefaultPage"];
			});

			return;
		}
		if (to.path === '/logout') {
			window.store.commit("SET_APP_LOADED", false);
			await axios(`/backend-service/account/logout`);

			window.vueApp.$nextTick(() => window.location.href = "/login");

			return;
		}

		next();
	}

	if (store.state.appLoaded === false) {
		store.watch(state => state.appLoaded, async () => {
			await doAction();
		});
	} else {
		await doAction();
	}
});

window.route = router;

import {ApmVuePlugin} from "@elastic/apm-rum-vue";

try {
	// @ts-ignore
	//if (window.store.getters.getIsFlexPosAdmin) {
	let env = process.env.NODE_ENV;
	if (env.toLowerCase() === "development") env = "Development";
	else if (env.toLowerCase() === "staging") env = "Staging";
	else if (env.toLowerCase() === "production") env = "Production";

	//@ts-ignore
	window.APP_VERSION = process.env.APP_VERSION;

	Vue.use(ApmVuePlugin, {
		// @ts-ignore
		router: window.route,
		"config": {
			"apiVersion": 3,
			"serviceName": 'BackOffice',
			//@ts-ignore
			"serviceVersion": window.APP_VERSION,
			"serverUrl": "https://apm2.flexpos.com",
			"environment": env,
			"ignoreTransactions": [/login*/],
			"distributedTracingOrigins": ['http://localhost:5000', 'https://localhost:5001', "https://frontend-api.flexpos.com", "https://frontend-api-rc.flexpos.com"],
			"propagateTracestate": true,
			"breakdownMetrics": true
			// agent configuration
		}
	});
	//}
}
catch(e) {
	console.log("Tried enabling Elastic RUM, but the PC is outside dev environment");
}

const app = new Vue({
	components: {app: AppView},
	template: `<app></app>`,
	store,
	router
}).$mount('#app');

window.vueApp = app;

async function setMomentLocale() {
	const momentDa = () => import(/* webpackChunkName: "moment-da" */ "../../../node_modules/moment/locale/da");
	const momentEn = () => import(/* webpackChunkName: "moment-en" */ "../../../node_modules/moment/locale/en-gb");
	const momentDe = () => import(/* webpackChunkName: "moment-de" */ "../../../node_modules/moment/locale/de");
	const momentSv = () => import(/* webpackChunkName: "moment-sv" */ "../../../node_modules/moment/locale/sv");
	const momentNo = () => import(/* webpackChunkName: "moment-nn" */ "../../../node_modules/moment/locale/nn");
	const momentFo = () => import(/* webpackChunkName: "moment-fo" */ "../../../node_modules/moment/locale/fo");

	switch (store.getters.getLanguageUid.toUpperCase()) {
		case "3F44DF11-7AE3-4406-AF1B-8AF2970C1BF0":
			await momentDa();
			break;
		case "0040F0C9-B449-467D-8F6D-E48AD1776C57":
			await momentEn();
			break;
		case "45CCD89E-639F-400D-9328-DA7ABBBD27C5":
			await momentDe();
			break;
		case "B3D1A4CC-3032-4DA6-BC41-12D17F96B36E":
			await momentSv();
			break;
		case "3FD0F190-6ABB-4028-A034-F6B18495E3BE":
			await momentNo();
			break;
		case "D60B65A6-BAE9-4443-ADFF-A1A1A747E083":
			await momentFo();
			break;
	}

	moment.locale(momentLocale);
}

(async function () {
	await store.dispatch("SET_ENVIRONMENT", process.env.NODE_ENV);
	if ("hidden" in document) {
		document.addEventListener('visibilitychange', async () => {
			await store.dispatch("setTabVisible", !document.hidden);
		}, false);
		await store.dispatch("setTabVisible", !document.hidden);
	}


	try {

		await Promise.all([store.dispatch("setUserSession"), store.dispatch("setBackendConfigs")]);
		if (UserSession.FrontendAPIToken != null) {
			await Promise.all([
				store.dispatch("setFlexPosConfigs"),
				store.dispatch("setActiveAddons"),
				await setMomentLocale()]);

			try {
				//@ts-ignore
				app.$apm.setUserContext({
					id: window.store.getters.getClientUid,
					username: window.store.getters.getUserUID,
					email: window.store.getters.getParentUserClientUid
				});
			}
			catch (e) {
				
			}
			
		}
	} catch (e) {
		console.warn("Loading Session failed: FrontendAPIToken: ", e);
		await store.dispatch("setAppLoadingFailed");
	}


	try {
		let notificationInterval: NodeJS.Timer = null;
		store.dispatch("notificationState/setNotifications").finally();

		store.watch((state) => state.tabVisible, (value) => {
			if (value) {
				store.dispatch("notificationState/setNotifications");

				notificationInterval = setInterval(() => {
					store.dispatch("notificationState/setNotifications");
				}, 1000 * 30);
			} else {
				clearInterval(notificationInterval);
			}
		});
	} catch (e) {

	}
	await app.$nextTick(async () => {
		await store.dispatch("setAppLoaded");
	});
	// try {
	//     const fpPromise = FingerprintJS.load()
	//
	//     ;(async () => {
	// 		debugger;
	//         const fp = await fpPromise;
	//         const result = await fp.get();
	//
	//         await store.dispatch("SET_FINGERPRINT", result.visitorId);
	//     })().then(r => {})
	// }
	// catch(e) {
	//
	// }

	if (store.getters.getIsDarkTheme) {
		document.body.classList.add("dark-theme");
	}
	store.watch((state) => state.isDarkTheme, (value) => {
		if (value) {
			document.body.classList.add("dark-theme");
		} else {
			document.body.classList.remove("dark-theme");
		}
	});
})();

window.enableDarkTheme = () => store.state.isDarkTheme = true;
window.disableDarkTheme = () => store.state.isDarkTheme = false;

$(document).on('select2:open', () => {
	const allFound = document.querySelectorAll('.select2-container--open .select2-search__field');
	if (allFound[allFound.length - 1])
		//@ts-ignore
		allFound[allFound.length - 1].focus();
});


// console.log(
// 	`Some people say dark theme is not needed. 
// 	I disagree, use: enableDarkTheme() to enable for your current session.
// 	Use disableDarkTheme() to disable again :)`);
