<template>
	<tr :key="row[rowKey]+'_details'">
		<td v-if="hasSelectColumn">&nbsp;</td>
		<td :colspan="columns.length + (hasSelectColumn ? 0 : 1)">
			<slot :row="row"/>
		</td>
	</tr>
</template>

<script>
export default {
	name: "fp-table-detail-row",
	props: {
		row: {
			required: true
		},
		rowKey: {
			required: true
		},
		hasSelectColumn: {
			required: true
		},
		columns: {
			required: true
		},
	}
}
</script>