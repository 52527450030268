


































































































































































export default {
	name: "validation-custom-field",
	props: {
		validation: {required: true, type: Object},
		id: {required: true, type: String},
		text: {required: false, type: String, default: ""},
		helpText: {required: false, type: String, default: ""},
		labelShown: {required: false, default: true, type: Boolean},
		optionsGroup: {required: false, default: false, type: Boolean},
		optionsGroupNoTop: {required: false, default: false, type: Boolean},
		optionsGroupNoBottom: {required: false, default: false, type: Boolean},
		labelNoBreak: {required: false, default: false, type: Boolean},
		sameAsOtherResource: {required: false, default: null, type: String},
		requiredText: {
			default() {
				return this.Resource.ErrorFieldRequired;
			},
			type: String
		},
		betweenText: {
			default() {
				return this.Resource.Error_RangeMinimumAndMaximum;
			},
			type: String
		},
		maxValueText: {
			default() {
				return this.Resource.ErrorFieldRequired;
			},
			type: String
		},
		minValueText: {
			default() {
				return this.Resource.ErrorFieldRequired;
			},
			type: String
		},
		fpMaxValueText: {
			default() {
				return this.Resource.TheFieldHasMaxSizeOfOrLess;
			},
			type: String
		},
		fpMinValueText: {
			default() {
				return this.Resource.TheFieldHasMinimumSizeOfOrLarger;
			},
			type: String
		},
		minLengthText: {
			default() {
				return this.Resource.Error_MinLength;
			},
			type: String
		},
		maxLengthText: {
			default() {
				return this.Resource.Error_MaxLength;
			},
			type: String
		},
		numberText: {
			default() {
				return this.Resource.ErrorFieldCanOnlyContainNumbers;
			},
			type: String
		},
		cannotContainLeadingZeroAndZeroText: {
			default() {
				return this.Resource.ErrorFieldNotOnlyZeros;
			},
			type: String
		},
		urlText: {
			default() {
				return this.Resource.Error_URL;
			},
			type: String
		},
		emailText: {
			default() {
				return this.Resource.Error_Email;
			},
			type: String
		},
		sameAsText: {
			default() {
				return this.Resource.HasToBeSameAs;
			},
			type: String
		},
		guidText: {
			default() {
				return this.Resource.Error_Guid;
			},
			type: String
		},
		cannotContainText: {
			default() {
				return this.Resource.CannotContain;
			},
			type: String
		},
		cannotOnlyContainText: {
			default() {
				return this.Resource.CannotOnlyContain;
			},
			type: String
		},
		productNumberValidatorText: {
			default() {
				return this.Resource.ProductNumberIsNotValid;
			},
			type: String
		},
		positionUnder: {
			default() {
				return false;
			}
		},
		heightAuto: {
			default() {
				return false;
			}
		},
		maxInt: {
			default() {
				return this.Resource.TheFieldHasMaxSizeOfOrLess;
			},
			type: String
		},
		minInt: {
			default() {
				return this.Resource.TheFieldHasMinimumSizeOfOrLarger;
			},
			type: String
		},
	},
	computed: {
		showHelpText() {
			return this.helpText !== this.Resource.TranslateMe;
		},
		slotProps() {
			return {
				valid: !this.validation.$invalid && this.validation.$dirty,
				invalid: this.validation.$invalid && this.validation.$dirty && !this.validation.$pending
			};
		},
		isRequired() {
			return this.validation && this.validation.required !== undefined;
		},
		customValidators(): { key: string, errorText: string }[] {
			const ignoredFields = ["$anyDirty", "$anyError", "$dirty", "$error", "$invalid", "$model",
				"$params", "$pending", "required", "minLength", "maxLength", "minValue", "maxValue", "sameAs", "between",
				"number", "isNumberAndDecimals", "guid", "email", "url", "cannotContain", "cannotOnlyContain", "productNumberValidator", "cannotContainLeadingZeroAndZero",
				"isInt32", "isInt64", "fpMinValue", "fpMaxValue"];

			let result = [];
			let keys = Object.keys(this.validation);

			for (let i = 0; i < keys.length; i++) {
				let key = keys[i];
				if (ignoredFields.indexOf(key) === -1) {
					if (this.validation.$params[key] && this.validation.$params[key].errorText) {
						let errorText = this.validation.$params[key].errorText;
						if (errorText.indexOf("{0}") > -1) {
							errorText = errorText.replace("{0}", this.validation.$model);
						}

						result.push({key: key, errorText: errorText});
					}
				}
			}

			return result;
		}
	},
	methods: {
		setErrorTextInObject(validation, field, errorText) {
			let text = errorText;
			if (!validation[field]) validation[field] = {};
			validation[field].errorText = text;

			return text;
		},
		renderRequiredText() {
			let text = this.requiredText;
			text = text.replace("{0}", this.text);

			return this.setErrorTextInObject(this.validation.$params, "required", text);
		},
		renderBetweenText() {
			if (this.validation.minLength === undefined) return "";
			const min = this.validation.$params.minLength.min;
			const max = this.validation.$params.minLength.max;
			let text = this.betweenText;
			text = text.replace("{0}", this.text);
			text = text.replace("{1}", min);
			text = text.replace("{2}", max);

			return this.setErrorTextInObject(this.validation.$params, "minLength", text);
		},
		renderMinLengthText() {
			if (this.validation.minLength === undefined) return "";
			const min = this.validation.$params.minLength.min;
			let text = this.minLengthText;
			text = text.replace("{0}", this.text);
			text = text.replace("{1}", min);

			return this.setErrorTextInObject(this.validation.$params, "minLength", text);
		},
		renderMaxLengthText() {
			if (this.validation.maxLength === undefined) return "";
			const max = this.validation.$params.maxLength.max;
			let text = this.maxLengthText;
			text = text.replace("{0}", this.text);
			text = text.replace("{1}", max);

			return this.setErrorTextInObject(this.validation.$params, "maxLength", text);
		},
		renderMinValueText() {
			let text = this.minValueText;
			text = text.replace("{0}", this.text);

			return this.setErrorTextInObject(this.validation.$params, "minValue", text);
		},
		renderNumberText() {
			let text = this.numberText;
			text = text.replace("{0}", `'${this.text}'`);

			return this.setErrorTextInObject(this.validation.$params, "number", text);
		},
		renderIsNumberAndDecimalsText() {
			let text = this.numberText;
			text = text.replace("{0}", `'${this.text}'`);

			return this.setErrorTextInObject(this.validation.$params, "isNumberAndDecimals", text);
		},
		renderCannotContainLeadingZeroAndZeroText() {
			let text = this.cannotContainLeadingZeroAndZeroText;
			text = text.replace("{0}", `'${this.text}'`);

			return this.setErrorTextInObject(this.validation.$params, "cannotContainLeadingZeroAndZero", text);
		},
		urlValidatorText() {
			let text = this.urlText;
			text = text.replace("{0}", `'${this.text}'`);

			return this.setErrorTextInObject(this.validation.$params, "url", text);
		},
		emailValidatorText() {
			let text = this.emailText;
			text = text.replace("{0}", `'${this.text}'`);

			return this.setErrorTextInObject(this.validation.$params, "email", text);
		},
		renderSameAsText() {
			let text = this.sameAsText;
			text = text.replace("{0}", `'${this.text}'`);
			text = text.replace("{1}", `'${this.sameAsOtherResource}'`);

			return this.setErrorTextInObject(this.validation.$params, "sameAsPassword", text);
		},
		renderGuidText() {
			let text = this.guidText;
			text = text.replace("{0}", `'${this.text}'`);

			return this.setErrorTextInObject(this.validation.$params, "guid", text);
		},
		renderCannotOnlyContainText() {
			let contains = [];
			let items = this.validation.$params.cannotOnlyContain.items;
			for (let i = 0; i < items.length; i++) {
				if (this.validation.$model.includes(items[i])) {
					contains.push(`'${items[i]}'`);
				}
			}

			let text = this.cannotOnlyContainText;
			text = text.replace("{0}", `'${this.text}'`);
			text = text.replace("{1}", `${contains.join(', ')}`);

			return this.setErrorTextInObject(this.validation.$params, "cannotOnlyContain", text);
		},
		renderCannotContainText() {
			let contains = [];
			let items = this.validation.$params.cannotContain.items;
			for (let i = 0; i < items.length; i++) {
				if (this.validation.$model.includes(items[i])) {
					contains.push(`'${items[i]}'`);
				}
			}

			let text = this.cannotContainText;
			text = text.replace("{0}", contains.join(', '));
			text = text.replace("{1}", `'${this.text}'`);

			return this.setErrorTextInObject(this.validation.$params, "cannotContain", text);
		},
		renderProductNumberValidatorText() {
			let text = this.productNumberValidatorText;
			return this.setErrorTextInObject(this.validation.$params, "productNumberValidator", text);
		},
		int32ValidatorText() {
			const int32 = 2147483647;
			let text = "";
			if (Number(this.text) < 0) {
				text = this.minInt.replace("{0}", `'${this.text}'`).replace("{1}", -int32);
			} else {
				text = this.maxInt.replace("{0}", `'${this.text}'`).replace("{1}", int32);
			}

			return this.setErrorTextInObject(this.validation.$params, "isInt32", text);
		},
		int64ValidatorText() {
			const int32 = 9223372036854775807;
			let text = "";
			if (Number(this.text) < 0) {
				text = this.minInt.replace("{0}", `'${this.text}'`).replace("{1}", -int32);
			} else {
				text = this.maxInt.replace("{0}", `'${this.text}'`).replace("{1}", int32);
			}

			return this.setErrorTextInObject(this.validation.$params, "isInt64", text);
		},
		renderFPMaxValueText() {
			let text = this.fpMaxValueText;
			text = text.replace("{0}", this.text);
			text = text.replace("{1}", this.validation.$params.fpMaxValue.max);

			return this.setErrorTextInObject(this.validation.$params, "fpMaxValue", text);
		},
		renderFPMinValueText() {
			let text = this.fpMinValueText;
			text = text.replace("{0}", this.text);
			text = text.replace("{1}", this.validation.$params.fpMinValue.min);

			return this.setErrorTextInObject(this.validation.$params, "fpMinValue", text);
		},
	}
};
