import {Module} from "vuex";

const storeValidation: Module<any, any> = {
	namespaced: true,

	state: () => ({}),
	actions: {},
	mutations: {},
	getters: {
		checkMainStoreChildrenTypes(state, getters): (hasChildren: boolean, childrenClientTypes: any[], clientTypeUid: string, parentClientTypeUid: string) => boolean {
			return (hasChildren: boolean, childrenClientTypes: any[], clientTypeUid: string, parentClientTypeUid: string): boolean => {
				if (hasChildren) {
					for (let i = 0; i < childrenClientTypes.length; i++) {
						switch (childrenClientTypes[i]) {
							case "d71498ba-001c-4163-80bb-54a5b3f301c8":
							case "1eb5c1a6-e79a-4c0f-a588-788215e7c566":
							case "b7738ac4-b22d-43ac-b738-e5b51702fbf1":
							case "49692d86-d67f-4156-6164-cc59ae8620e4":
							case "8160515d-70fb-4027-ac2d-ea6414e5bf7e":
							case "9c8b7fde-d122-47e5-a6eb-83bf1c31ca1a":
							case "49b31970-8441-43e7-9821-ef93aa027228":
							case "26e4afbc-c7a8-4655-9f3e-dc9d332ee44a":
								continue;
						}

						if (!window.store.getters.getIsParentType(clientTypeUid)) {
							return false;
						}
					}
				} else {
					switch (clientTypeUid) {
						case "d71498ba-001c-4163-80bb-54a5b3f301c8":
						case "1eb5c1a6-e79a-4c0f-a588-788215e7c566":
						case "b7738ac4-b22d-43ac-b738-e5b51702fbf1":
						case "49692d86-d67f-4156-6164-cc59ae8620e4":
						case "8160515d-70fb-4027-ac2d-ea6414e5bf7e":
						case "9c8b7fde-d122-47e5-a6eb-83bf1c31ca1a":
						case "49b31970-8441-43e7-9821-ef93aa027228":
						case "26e4afbc-c7a8-4655-9f3e-dc9d332ee44a":
							if (!window.store.getters.getIsParentType(parentClientTypeUid) && !window.store.getters.getIsOtherClientTypes(parentClientTypeUid)) {
								return false;
							}
					}
				}

				return true;
			};
		},
		checkTestStoreChildrenTypes(state, getters): (hasChildren: boolean, childrenClientTypes: any[], clientTypeUid: string, parentClientTypeUid: string) => boolean {
			return (hasChildren: boolean, childrenClientTypes: any[], clientTypeUid: string, parentClientTypeUid: string): boolean => {
				if(window.store.getters.getIsParentType(clientTypeUid)) return false;
				if (hasChildren) {
					for (let i = 0; i < childrenClientTypes.length; i++) {
						switch (childrenClientTypes[i]) {
							case "26e4afbc-c7a8-4655-9f3e-dc9d332ee44a":
							case "9c8b7fde-d122-47e5-a6eb-83bf1c31ca1a":
							case "49692d86-d67f-4156-6164-cc59ae8620e4":
								if(window.store.getters.getIsOtherClientTypes(clientTypeUid)) {
									return true;
									;
								}
						}
					}
				} else {
					switch (clientTypeUid) {
						case "26e4afbc-c7a8-4655-9f3e-dc9d332ee44a":
						case "9c8b7fde-d122-47e5-a6eb-83bf1c31ca1a":
						case "49692d86-d67f-4156-6164-cc59ae8620e4":
							if(window.store.getters.getIsOtherClientTypes(parentClientTypeUid)) {
								return true;
							}
					}
				}

				return false;
			}
		}
	}
}

export default storeValidation;