<template>
	<div class="row main-panel">
		<div class="col-12">
			<h4>{{Resource.YouHaveAccessTo}}:</h4>
		</div>
		<div class="col-6">
			<ul class="list-group mb-4">
				<li class="text-center fw-bold list-group-item d-flex justify-content-center">{{Resource.MainMenu}}</li>
				<template v-for="item in userFirstAccessArray">
					<li v-if="!item.isDivider && (item.check === undefined || item.check === true)"
						class="list-group-item d-flex justify-content-between">
						<div class="ms-2">
							{{item.resource}}
						</div>
						<div class="me-2">
							<input type="checkbox" onclick="return false;" v-model="item.access" class="form-check-input" />
						</div>
					</li>
					<li v-else-if="item.isVisible" class="text-center fw-bold list-group-item d-flex justify-content-center">{{item.resource}}</li>
				</template>
			</ul>
			<ul class="list-group mb-4">
				<li class="text-center fw-bold list-group-item d-flex justify-content-center">{{Resource.ShopSetUp}}</li>
				<template v-for="item in userSecondAccessArray">
					<li v-if="!item.isDivider && (item.check === undefined || item.check === true)" class="list-group-item d-flex justify-content-between">
						<div class="ms-2">
							{{item.resource}}
						</div>
						<div class="me-2">
							<input type="checkbox" onclick="return false;" v-model="item.access" class="form-check-input" />
						</div>
					</li>
					<li v-else-if="item.isVisible" class="text-center fw-bold list-group-item d-flex justify-content-center text-muted">{{item.resource}}</li>
				</template>
			</ul>
			<ul class="list-group">
				<li class="text-center fw-bold list-group-item d-flex justify-content-center">{{Resource.Settings}}</li>
				<template v-for="item in userThirdAccessArray">
					<li v-if="!item.isDivider && (item.check === undefined || item.check === true)" class="list-group-item d-flex justify-content-between">
						<div class="ms-2">
							{{item.resource}}
						</div>
						<div class="me-2">
							<input type="checkbox" onclick="return false;" v-model="item.access" class="form-check-input" />
						</div>
					</li>
					<li v-else-if="item.isVisible" class="text-center fw-bold list-group-item d-flex justify-content-center">{{item.resource}}</li>
				</template>
			</ul>
		</div>
		<div class="col-6">
			<ul class="list-group">
				<li class="text-center fw-bold list-group-item d-flex justify-content-center">{{Resource.ReportSettings}}</li>
				<template v-for="report in reports">
					<li v-if="report.Uid !== emptyGuid" class="list-group-item d-flex justify-content-between">
						<div class="ms-2">
							{{ Resource[report.NameLocale] || report.Name}}
						</div>
						<div class="me-2">
							<input v-if="getBackendConfigBool('UserAccess.ReportSettings.' + report.Uid, false)" type="checkbox" onclick="return false;" v-model="trueBool" class="form-check-input" />
							<input v-else type="checkbox" onclick="return false;" v-model="falseBool" class="form-check-input" />
						</div>
					</li>
					<li v-else class="text-center fw-bold list-group-item d-flex justify-content-center text-muted">{{Resource[report.NameLocale] || report.Name}}</li>
				</template>
			</ul>
		</div>
		<div class="clearfix"></div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {DynamicReportApi} from "@services/Autogenerated_v4/V1/api/dynamic-report-api";

export default {
	name: "user-profile-access",
	computed: {
		...mapGetters({
			getUserUID: "getUserUID",
			getState: "getState",
			getBackendConfigBool: "getBackendConfigBool",
			getActiveAddons: "getActiveAddons",
			getLanguageUid: "getLanguageUid",
		}),
	},
	data() {
		return {
			emptyGuid: new GUID().Empty,
			userFirstAccessArray: [],
			userSecondAccessArray: [],
			userThirdAccessArray: [],
			reports: [],
			trueBool: true,
			falseBool: false,

			DynamicReportService: new DynamicReportApi(this.$swaggerAxiosOptions),
		}
	},
	methods: {
		async getFirst() {
			this.userFirstAccessArray.push({resource: this.Resource.FronPage, access: this.getBackendConfigBool("UserAccess.Frontpage", true)});
			this.userFirstAccessArray.push({resource: this.Resource.ProductCatalog, access: this.getBackendConfigBool("UserAccess.Products", true)});
			this.userFirstAccessArray.push({resource: this.Resource.Customers, access: this.getBackendConfigBool("UserAccess.Customers", true)});
			this.userFirstAccessArray.push({resource: this.Resource.Vendor, check: this.getActiveAddons('8E818B29-74D9-4DC8-A13B-B0F99739A1D5', false), access: this.getBackendConfigBool("UserAccess.VendorModule", true)});
			this.userFirstAccessArray.push({resource: this.Resource.Reports, access: this.getBackendConfigBool("UserAccess.Reports", true)});
			this.userFirstAccessArray.push({resource: this.Resource.Status, check: this.getActiveAddons('8E818B29-74D9-4DC8-A13B-B0F99739A1D5', false), access: this.getBackendConfigBool("UserAccess.StockModule", true)});
			this.userFirstAccessArray.push({resource: this.Resource.LanguageManagement, check: this.getActiveAddons('22237FB5-6FA0-48AA-9F3A-9C6E70A6B9D4', false), access: this.getBackendConfigBool("UserAccess.LanguageManagementModule", true)});
			this.userFirstAccessArray.push({resource: this.Resource.TransactionHistory , access: this.getBackendConfigBool("UserAccess.TransactionHistory", true)});
			this.userFirstAccessArray.push({resource: this.Resource.StatisticsAndMore, access: this.getBackendConfigBool("UserAccess.Statistics", true)});
			this.userFirstAccessArray.push({resource: this.Resource.FlexVoucher, check: this.getActiveAddons('52D9E242-924A-4AC9-A277-F3C4BC88128B', false), access: this.getBackendConfigBool("UserAccess.FlexVoucherModule", true)});
			this.userFirstAccessArray.push({resource: this.Resource.Campaigns, access: this.getBackendConfigBool("UserAccess.Campaign", true)});
			this.userFirstAccessArray.push({resource: this.Resource.GiftCard, access: this.getBackendConfigBool("UserAccess.Giftcards", true)});
			this.userFirstAccessArray.push({resource: this.Resource.CashManagement, check: this.getActiveAddons('4edf6d2a-df6d-41c2-f68d-16a1b3f18a99', false), access: this.getBackendConfigBool("UserAccess.CashManagement", true)});
			
			this.userFirstAccessArray.push({resource: this.Resource.ExternalPages, isDivider: true, isVisible: this.getActiveAddons('06f84aa6-99af-4d55-167e-32d01f0e4f38', false) || this.getActiveAddons('11c89cd0-c608-4b36-4575-caa43f3a4a81', false)});
			this.userFirstAccessArray.push({resource: this.Resource.Config_Feature_ServiceModule, access: this.getBackendConfigBool("UserAccess.ServiceModule", true), check: this.getActiveAddons('06f84aa6-99af-4d55-167e-32d01f0e4f38', false)});
			this.userFirstAccessArray.push({resource: this.Resource.Config_Feature_EventOrderModule, access: this.getBackendConfigBool("UserAccess.EventOrders", true), check: this.getActiveAddons('11c89cd0-c608-4b36-4575-caa43f3a4a81', false)});
		},
		async getSecond() {
			this.userSecondAccessArray.push({resource: this.Resource.PosUsers, access: this.getBackendConfigBool("UserAccess.PosUsers", true)});
			this.userSecondAccessArray.push({resource: this.Resource.FlexAd, access: this.getBackendConfigBool("UserAccess.FlexAd", true)});
			this.userSecondAccessArray.push({resource: this.Resource.Users, access: this.getBackendConfigBool("UserAccess.Users", true)});
			this.userSecondAccessArray.push({resource: this.Resource.Modules, access: this.getBackendConfigBool("UserAccess.Modules", true)});
			this.userSecondAccessArray.push({resource: this.Resource.Currency, access: this.getBackendConfigBool("UserAccess.Currency", true)});
			this.userSecondAccessArray.push({resource: this.Resource.ExternalPage, access: this.getBackendConfigBool("UserAccess.ExternalPage", true)});
			this.userSecondAccessArray.push({resource: this.Resource.AllowDeleteProducts, access: this.getBackendConfigBool("UserAccess.AllowDeleteProducts", true)});
		},
		async getThird() {
			this.userThirdAccessArray.push({resource: this.Resource.ERPSystem, check: this.hasERPModule(), access: this.getBackendConfigBool("UserAccess.Settings.EDAccount", true)});
			this.userThirdAccessArray.push({resource: this.Resource.Breece, check: this.getActiveAddons('B2BB7D77-4063-496C-91E2-66CC3EFC9DC8', false), access: this.getBackendConfigBool("UserAccess.Settings.DelfiBreece", true)});
			this.userThirdAccessArray.push({resource: this.Resource.NordiskeDatabase, check: this.getActiveAddons('39D59B64-F21B-4D47-BF37-6C3354B9CA86', false), access: this.getBackendConfigBool("UserAccess.Settings.NordiskeDatabase", true)});
			this.userThirdAccessArray.push({resource: this.Resource.SameSystem, check: this.getActiveAddons('3657f1e9-df13-40f1-b830-9e610281c8d0', false), access: this.getBackendConfigBool("UserAccess.Settings.SameSystem", true)});
			
			this.userThirdAccessArray.push({resource: this.Resource.Loyalty, check: this.getActiveAddons('BF57A16A-0AC0-4794-B36E-05643C3AE2BC', false), access: this.getBackendConfigBool("UserAccess.Settings.Loyalty", true)});
			this.userThirdAccessArray.push({resource: this.Resource.CustomerStatistics, check: this.getActiveAddons('D9644011-D747-49D0-9674-9918B5AD9B4C', false), access: this.getBackendConfigBool("UserAccess.Settings.CustomerStatistics", true)});
			this.userThirdAccessArray.push({resource: this.Resource.Plecto, check: this.getActiveAddons('3F4938D3-08A0-4E5B-AC0B-8216FC2E7085', false), access: this.getBackendConfigBool("UserAccess.Settings.Plecto", true)});
			this.userThirdAccessArray.push({resource: this.Resource.FlexTicket, check: this.getActiveAddons('29be91a6-89e0-463f-8a54-07ea06939743', false), access: this.getBackendConfigBool("UserAccess.Settings.FlexTicketConfig", true)});
			this.userThirdAccessArray.push({resource: this.Resource.FlexAd, check: this.getActiveAddons('21103EB7-2E4E-44E3-8879-61A3D872F3B5', false), access: this.getBackendConfigBool("UserAccess.Settings.FlexAdConfig", true)});
			this.userThirdAccessArray.push({resource: this.Resource.MobilePay, check: this.getActiveAddons('72D413C4-AAA7-4938-AEAA-0E3554F8C5FB', false), access: this.getBackendConfigBool("UserAccess.Settings.MobilePay", false)});
			this.userThirdAccessArray.push({resource: this.Resource.Import, check: this.getActiveAddons('B22BC0FC-0028-4C68-A52E-0010C398A7A9', false), access: this.getBackendConfigBool("UserAccess.Settings.ProductImport", true)});
			this.userThirdAccessArray.push({resource: this.Resource.PaymentType, access: this.getBackendConfigBool("UserAccess.Settings.PaymentType", true)});
			this.userThirdAccessArray.push({resource: this.Resource.DesignAndLayout, access: this.getBackendConfigBool("UserAccess.Settings.ButtonFunctions", true)});
			this.userThirdAccessArray.push({resource: this.Resource.DesignLayout, access: this.getBackendConfigBool("UserAccess.Settings.BonLayout", true)});
			this.userThirdAccessArray.push({resource: this.Resource.ExchangeLabels, access: this.getBackendConfigBool("UserAccess.Settings.ExchangeLabels", true)});
			this.userThirdAccessArray.push({resource: this.Resource.FlexVoucher  + ' ' + this.Resource.Setup, check: this.getActiveAddons('52D9E242-924A-4AC9-A277-F3C4BC88128B', false),access: this.getBackendConfigBool("UserAccess.Settings.FlexVoucher", true)});
            this.userThirdAccessArray.push({resource: this.Resource.SAFT, access: this.getBackendConfigBool("UserAccess.Settings.Saft", true)});
            this.userThirdAccessArray.push({resource: this.Resource.Configuration, access: this.getBackendConfigBool("UserAccess.Settings.FlexPosConfig", true)});
		},
		async getFourth() {
			let data = (await this.DynamicReportService.getReportsAccessForUserAccess({
				languageUid: this.getLanguageUid
			})).data;
			this.reports = data;
		},
		hasERPModule() {
			if (this.getActiveAddons('EB7902BF-3D8E-406D-8860-11ACEA0179E1', false)) {
				return true;	
			} else if (this.getActiveAddons('9CB2485F-2943-4A5F-A199-DBEAB2EC14F0', false)) {
				return true;
			} else if (this.getActiveAddons('C6FB0C97-F205-4062-AB85-319FDCDD22D6', false)) {
				return true;
			} else if (this.getActiveAddons('EC120F25-5BD2-407D-AAC3-EEC77195631E', false)) {
				return true;
			} else if (this.getActiveAddons('59e39a38-1534-48ac-b1ba-61e07793d813', false)) {
				return true;
			}
			
			return false;
		}
	},
	async mounted() {
		await this.getFirst();
		await this.getSecond();
		await this.getThird();
		await this.getFourth();
	}
}
</script>

<style scoped>

</style>