import {Module} from "vuex";

interface IReportSyncQJobTableIndexState {
	savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
	const keyValue = rootState.backendConfigs[key]
	if (keyValue !== null && keyValue !== undefined)
		return keyValue.toLowerCase() === "true";

	return defaultValue;
}
const reportSyncQJobTableIndexState: Module<IReportSyncQJobTableIndexState, any> = {
	namespaced: true,

	state: () => ({
		savedSearchParams: null
	}),
	actions: {
		SET_SAVED_SEARCH_PARAMS(context, args) {
			context.commit("SET_SAVED_SEARCH_PARAMS", args)
		},
	},
	mutations: {
		SET_SAVED_SEARCH_PARAMS(state, newState) {
			state.savedSearchParams = newState;
		},
	},
	getters: {
		getSavedSearchParams(state, getters) {
			return state.savedSearchParams
		},
		StatusTxt(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.StatusTxt", rootState, true); },
		JobTypeName(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.JobTypeName", rootState, true); },
		Email(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.Email", rootState, true); },
		RepeatIntervalType(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.RepeatIntervalType", rootState, true); },
		RunTime(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.RunTime", rootState, true); },
		getColumnVisibility(state, getters, rootState, rootGetters) {
			return {
				StatusTxt: getters.StatusTxt,
				JobTypeName: getters.JobTypeName,
				Email: getters.Email,
				RepeatIntervalType: getters.RepeatIntervalType,
				RunTime: getters.RunTime,
			};
		},
		getConfigVisibility(state, getters, rootState, rootGetters) {
			return {
				FullStatusText: true,
				JobTypeName: true,
				Email: true,
				RepeatIntervalType: true,
				RunTime: true,
			};
		}
	}
}

export default reportSyncQJobTableIndexState;