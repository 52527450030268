import {Module} from "vuex";

interface IFlexDeployTableIndexState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const flexDeployTableIndexState: Module<IFlexDeployTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
		ApplicationTypeUID(state, getters, rootState) { return getConfig("FlexDeploy.ColumnOptions.ApplicationTypeUID", rootState, true); },
		VersionNumber(state, getters, rootState) { return getConfig("FlexDeploy.ColumnOptions.VersionNumber", rootState, true); },
		ReleaseTypeUID(state, getters, rootState) { return getConfig("FlexDeploy.ColumnOptions.ReleaseTypeUID", rootState, true); },
		DBScript(state, getters, rootState) { return getConfig("FlexDeploy.ColumnOptions.DBScript", rootState, true); },
		APPFileUID(state, getters, rootState) { return getConfig("FlexDeploy.ColumnOptions.APPFileUID", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				ApplicationTypeUID: getters.ApplicationTypeUID,
                VersionNumber: getters.VersionNumber,
                ReleaseTypeUID: getters.ReleaseTypeUID,
                DBScript: getters.DBScript,
				APPFileUID: getters.APPFileUID,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				ApplicationTypeUID: true,
                VersionNumber: true,
                ReleaseTypeUID: true,
                DBScript: true,
				APPFileUID: true,
            };
        }
    }
}

export default flexDeployTableIndexState;