<template>
	<label :class="classObject" @click="val = !val">
		<span class="vue-switcher__label" v-if="shouldShowLabel">
			<span v-if="label" v-text="label" />
			<span v-if="!label && value" v-text="textEnabled" />
			<span v-if="!label && !value" v-text="textDisabled" />
		</span>

		<input :id="id" type="checkbox" :disabled="disabled" v-model="val">
    	<div></div>
	</label>
</template>

<script>

export default {
	name: 'switches',
	props: {
		id: {
			default: null,
			type: String
		},

		typeBold: {
			default: false,
			validator(val) {
				return typeof val === "boolean" || val === "false" || val === "true"; 
			}
		},

		value: {
			default: false,
			type: Boolean
		},

		disabled: {
			default: false,
			type: Boolean
		},

		label: {
			default: '',
			type: String
		},

		textEnabled: {
			default: '',
			type: String
		},

		textDisabled: {
			default: '',
			type: String
		},

		color: {
			default: 'default',
			type: String
		},

		theme: {
			default: 'default',
			type: String
		},

		emitOnMount: {
			default: true,
			type: Boolean
		},

   		small: {
   		  	default: false,
   		  	type: Boolean
   		},
	},
	data() {
		return {
			val: this.value
		};
	},
	computed: {
		classObject() {

			const {color, value, theme, typeBold, disabled, small} = this;

			return {
				'vue-switcher': true,
        		['vue-switcher-small']: small,
        		['vue-switcher-theme--fp-small']: small,
				['vue-switcher--unchecked']: !value,
				['vue-switcher--disabled']: disabled,
				['vue-switcher--bold']: typeBold,
				['vue-switcher--bold--unchecked']: typeBold && !value,
				[`vue-switcher-theme--${theme}`]: color,
				[`vue-switcher-color--${color}`]: color,
			};

		},

		shouldShowLabel() {
			return this.label !== '' || this.textEnabled !== '' || this.textDisabled !== '';
		}
	},
	watch: {
		val(val) {
			this.$emit('input', val);
		},
		value(val) {
			this.val = val;
		}
	},
	mounted() {
		if (this.emitOnMount) {
			this.$emit('input', this.val);
		}
	},
};
</script>

<style src="./../dist/switches.css"></style>
<style lang="scss" scoped>
.vue-switcher-theme--fp {
	&.vue-switcher-theme--fp-small div:after {
		content:"" !important;
		margin-left: -12px;
	}
	&.vue-switcher-theme--fp-small.vue-switcher--unchecked div:after {
		margin-left: -26px;
	}
	&.vue-switcher-theme--fp-small.vue-switcher-theme--fp div {
		width: 26px;
		height: 14px;
	}
	&.vue-switcher-small.vue-switcher div:after {
		height: 10px;
		width: 10px;
	}
}
</style>
