import {ActionContext, Module} from "vuex";

interface IProductImportIndexState {
	extendedProductInfoActive: boolean;
	extendedProductInfoText1: string;
	extendedProductInfoText2: string;
	extendedProductInfoText3: string;
	extendedProductInfoText4: string;
	extendedProductInfoText5: string,
	extendedProductInfoText6: string,
	extendedProductInfoText7: string,
	extendedProductInfoText8: string,
	extendedProductInfoNumber1: string;
	extendedProductInfoNumber2: string;
	extendedProductInfoDate1: string;
	extendedProductInfoMemo1: string;
	extendedProdInfoUrl1: string,
	extendedProdInfoUrl2: string,
	extendedProdInfoBoolean1: string,
	extendedProdInfoBoolean2: string,
}

const productImportTableState: Module<IProductImportIndexState, any> = {
    namespaced: true,
	
	state: () => ({
		extendedProductInfoActive: null,
		extendedProductInfoText1: null,
		extendedProductInfoText2: null,
		extendedProductInfoText3: null,
		extendedProductInfoText4: null,
		extendedProductInfoText5: null,
		extendedProductInfoText6: null,
		extendedProductInfoText7: null,
		extendedProductInfoText8: null,
		extendedProductInfoNumber1: null,
		extendedProductInfoNumber2: null,
		extendedProductInfoDate1: null,
		extendedProductInfoMemo1: null,
		extendedProdInfoUrl1: null,
		extendedProdInfoUrl2: null,
		extendedProdInfoBoolean1: null,
		extendedProdInfoBoolean2: null,
	}),
    actions: {
    },
    mutations: {
    },
    getters: {
		getExtendedProductInfo(state: IProductImportIndexState, getters, rootState, rootGetters) {
			return {
				extendedProductInfoActive: rootGetters.getFlexPosConfigBool("ExtendedProdInfoActive", false),
				extendedProductInfoText1: rootGetters.getFlexPosConfigString("ExtendedProdInfoText1", Resource.ExtendedProdInfoText1),
				extendedProductInfoText2: rootGetters.getFlexPosConfigString("ExtendedProdInfoText2", Resource.ExtendedProdInfoText2),
				extendedProductInfoText3: rootGetters.getFlexPosConfigString("ExtendedProdInfoText3", Resource.ExtendedProdInfoText3),
				extendedProductInfoText4: rootGetters.getFlexPosConfigString("ExtendedProdInfoText4", Resource.ExtendedProdInfoText4),
				extendedProductInfoText5: rootGetters.getFlexPosConfigString("ExtendedProdInfoText5", Resource.ExtendedProdInfoText5),
				extendedProductInfoText6: rootGetters.getFlexPosConfigString("ExtendedProdInfoText6", Resource.ExtendedProdInfoText6),
				extendedProductInfoText7: rootGetters.getFlexPosConfigString("ExtendedProdInfoText7", Resource.ExtendedProdInfoText7),
				extendedProductInfoText8: rootGetters.getFlexPosConfigString("ExtendedProdInfoText8", Resource.ExtendedProdInfoText8),
				extendedProductInfoNumber1: rootGetters.getFlexPosConfigString("ExtendedProdInfoNumber1", Resource.ExtendedProdInfoNumber1),
				extendedProductInfoNumber2: rootGetters.getFlexPosConfigString("ExtendedProdInfoNumber2", Resource.ExtendedProdInfoNumber2),
				extendedProductInfoDate1: rootGetters.getFlexPosConfigString("ExtendedProdInfoDate1", Resource.ExtendedProdInfoDate1),
				extendedProductInfoMemo1: rootGetters.getFlexPosConfigString("ExtendedProdInfoMemo1", Resource.ExtendedProdInfoMemo1),
				extendedProductInfoUrl1: rootGetters.getFlexPosConfigString("ExtendedProdInfoUrl1", Resource.ExtendedProdInfoUrl1),
				extendedProductInfoUrl2: rootGetters.getFlexPosConfigString("ExtendedProdInfoUrl2", Resource.ExtendedProdInfoUrl2),
				extendedProductInfoBoolean1: rootGetters.getFlexPosConfigString("ExtendedProdInfoBoolean1", Resource.ExtendedProdInfoBoolean1),
				extendedProductInfoBoolean2: rootGetters.getFlexPosConfigString("ExtendedProdInfoBoolean2", Resource.ExtendedProdInfoBoolean2),
			};
		},
		getCombinations(state, getters, rootState, rootGetters) {
			return {
				combination1: { Active: rootGetters.getFlexPosConfigBool("Combination1FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination1FieldName", Resource.Combination1) },
				combination2: { Active: rootGetters.getFlexPosConfigBool("Combination2FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination2FieldName", Resource.Combination2) },
				combination3: { Active: rootGetters.getFlexPosConfigBool("Combination3FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination3FieldName", Resource.Combination3) },
				combination4: { Active: rootGetters.getFlexPosConfigBool("Combination4FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination4FieldName", Resource.Combination4) },
			};
		},
    }
}

export default productImportTableState;