import {Module} from "vuex";

interface IUnitsFailureTableIndexState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const unitsFailureTableIndexState: Module<IUnitsFailureTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        Name(state, getters, rootState) { return getConfig("UnitsOfFailure.ColumnOptions.Name", rootState, true); },
		Info(state, getters, rootState) { return getConfig("UnitsOfFailure.ColumnOptions.Info", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Name: getters.Name,
                Info: getters.Info,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Name: true,
				Info: true,
            };
        }
    }
}

export default unitsFailureTableIndexState;