import {ActionContext, Module} from "vuex";

interface IProductStockConfigState {
}

const productStockConfigState: Module<IProductStockConfigState, any> = {
    namespaced: true,

    state: () => ({
    }),
    actions: {
        setConfigs({ commit,rootGetters }: ActionContext<IProductStockConfigState, any>) {
            let configs = {
                stockActive: rootGetters.getActiveAddons("8E818B29-74D9-4DC8-A13B-B0F99739A1D5", false),
            }
        },
    },
    mutations: {
    },
    getters: {
        getConfigs(state, getters, rootState, rootGetters) {
            return {
                stockActive: rootGetters.getActiveAddons("8E818B29-74D9-4DC8-A13B-B0F99739A1D5", false),
                hideProductStockButtons: rootGetters.getFlexPosConfigBool("HideProductStockButtons", false),
            }
        }
    }
}

export default productStockConfigState;