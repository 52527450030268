import {Module} from "vuex";

interface IProductBulkDeleteTableState {
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const productBulkDeleteTableState: Module<IProductBulkDeleteTableState, any> = {
    namespaced: true,
    
    state: () => ({
    }),
    actions: {
    },
    mutations: {
    },
    getters: {
        ProductNumber(state, getters, rootState) { return getConfig("ProductBulkDelete.ColumnOptions.ProductNumber", rootState, true); },
        ProductName(state, getters, rootState) { return getConfig("ProductBulkDelete.ColumnOptions.ProductName", rootState, true); },
        StockQuantity(state, getters, rootState) { return getConfig("ProductBulkDelete.ColumnOptions.StockQuantity", rootState, true); },
        HasSyncToWeb(state, getters, rootState) { return getConfig("ProductBulkDelete.ColumnOptions.HasSyncToWeb", rootState, true); },
        IsFollowProduct(state, getters, rootState) { return getConfig("ProductBulkDelete.ColumnOptions.IsFollowProduct", rootState, true); },
        HasVirtualProduct(state, getters, rootState) { return getConfig("ProductBulkDelete.ColumnOptions.HasVirtualProduct", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            let stockConfigs = rootGetters["productStockConfigState/getConfigs"];
            
            return {
                ProductNumber: getters.ProductNumber,
                ProductName: getters.ProductName,
                StockQuantity: getters.StockQuantity && stockConfigs.stockActive,
                HasSyncToWeb: getters.HasSyncToWeb,
                IsFollowProduct: getters.IsFollowProduct,
                HasVirtualProduct: getters.HasVirtualProduct,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            let stockConfigs = rootGetters["productStockConfigState/getConfigs"];

            return {
                ProductNumber: true,
                ProductName: true,
                StockQuantity: stockConfigs.stockActive,
                HasSyncToWeb: true,
                IsFollowProduct: true,
                HasVirtualProduct: true,
            };
        }
    }
}

export default productBulkDeleteTableState;