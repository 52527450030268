import {Module} from "vuex";

interface IProductTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const productListTableIndexState: Module<IProductTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
		ClientUid(state, getters, rootState) { return getConfig("ProductList.ColumnOptions.ClientUid", rootState, true); },
        Number(state, getters, rootState) { return getConfig("ProductList.ColumnOptions.Number", rootState, true); },
        Name(state, getters, rootState) { return getConfig("ProductList.ColumnOptions.Name", rootState, true); },
        VpCount(state, getters, rootState) { return getConfig("ProductList.ColumnOptions.VpCount", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUid: getters.ClientUid,
                Number: getters.Number,
                Name: getters.Name,
                VpCount: getters.VpCount,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUid: true,
                Number: true,
                Name: true,
                VpCount: true
            };
        }
    }
}

export default productListTableIndexState;