import {Module} from "vuex";

interface IProductCampaignTableAddProductsModalState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const productCampaignTableAddProductsModalState: Module<IProductCampaignTableAddProductsModalState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
		Number(state, getters, rootState) { return getConfig("ProductCampaign.ColumnOptions.Number", rootState, true); },
		Name(state, getters, rootState) { return getConfig("ProductCampaign.ColumnOptions.Name", rootState, true); },
		SalesPrice(state, getters, rootState) { return getConfig("ProductCampaign.ColumnOptions.SalesPrice", rootState, true); },
		Size(state, getters, rootState) { return getConfig("ProductCampaign.ColumnOptions.Size", rootState, true); },
		Color(state, getters, rootState) { return getConfig("ProductCampaign.ColumnOptions.Color", rootState, true); },
		Combination1(state, getters, rootState) { return getConfig("ProductCampaign.ColumnOptions.Combination1", rootState, true); },
		Combination2(state, getters, rootState) { return getConfig("ProductCampaign.ColumnOptions.Combination2", rootState, true); },
		Combination3(state, getters, rootState) { return getConfig("ProductCampaign.ColumnOptions.Combination3", rootState, true); },
		Combination4(state, getters, rootState) { return getConfig("ProductCampaign.ColumnOptions.Combination4", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Number: getters.Number,
                Name: getters.Name,
				SalesPrice: getters.SalesPrice,
                Size: getters.Size,
                Color: getters.Color,
				Combination1: getters.Combination1,
				Combination2: getters.Combination2,
				Combination3: getters.Combination3,
				Combination4: getters.Combination4,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Number: true,
                Name: true,
				SalesPrice: true,
                Size: true,
                Color: true,
				Combination1: true,
				Combination2: true,
				Combination3: true,
				Combination4: true,
			};
        },
		getCombinations(state, getters, rootState, rootGetters) {
			return {
				combination1: { Active: rootGetters.getFlexPosConfigBool("Combination1FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination1FieldName", Resource.Combination1) },
				combination2: { Active: rootGetters.getFlexPosConfigBool("Combination2FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination2FieldName", Resource.Combination2) },
				combination3: { Active: rootGetters.getFlexPosConfigBool("Combination3FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination3FieldName", Resource.Combination3) },
				combination4: { Active: rootGetters.getFlexPosConfigBool("Combination4FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination4FieldName", Resource.Combination4) },
			};
		},
    }
}

export default productCampaignTableAddProductsModalState;