import { Alert, Button, Carousel, Collapse, Dropdown, Modal, Offcanvas, Popover, ScrollSpy, Tab, Toast, Tooltip } from "bootstrap";
import _Vue from "vue";

export function VueBootstrap<T>(Vue: typeof _Vue): void {
    // @ts-ignore
    window.bootstrap = {Alert: Alert, Button: Button, Carousel: Carousel, Collapse: Collapse, Dropdown: Dropdown, Modal: Modal, Offcanvas: Offcanvas, Popover: Popover, ScrollSpy: ScrollSpy, Tab: Tab, Toast: Toast, Tooltip: Tooltip};
    // @ts-ignore
    Vue.prototype.$bootstrap = window.bootstrap;
    // @ts-ignore
    Vue.bootstrap = window.bootstrap
    // @ts-ignore
    Vue.$bootstrap = window.bootstrap
}