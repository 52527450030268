<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 101 27">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_185" data-name="Rectangle 185" width="101" height="27"
                      transform="translate(815.438 320.143)" fill="#fff" :stroke="flexColor" stroke-width="1"/>
            </clipPath>
        </defs>
        <g id="Mask_Group_111" data-name="Mask Group 111" transform="translate(-815.438 -320.143)"
           clip-path="url(#clip-path)">
            <g id="FlexPOS_logo_sort" data-name="FlexPOS_logo sort" transform="translate(815.19 322.661)">
                <path id="Path_182" data-name="Path 182"
                      d="M27.871,49.193V28.145H40.288v1.683H29.748v8.705h8.965V40.2H29.748v8.995Z"
                      transform="translate(-27.871 -27.32)" :fill="flexColor" />
                <path id="Path_183" data-name="Path 183" d="M73.55,47.622V25.749h1.787V47.622Z"
                      transform="translate(-57.831 -25.749)" :fill="flexColor" />
                <path id="Path_184" data-name="Path 184"
                      d="M101.227,58.352l.909-.091.061,1.484a48.368,48.368,0,0,1-5.8.424q-3.271-.03-4.573-1.938t-1.289-5.879q0-7.935,6.239-7.935a5.612,5.612,0,0,1,4.482,1.713q1.484,1.713,1.484,5.465l-.061,1.454h-10.3a7.223,7.223,0,0,0,.952,4.136,3.719,3.719,0,0,0,3.271,1.346Q98.925,58.534,101.227,58.352Zm-8.843-6.814h8.541a7.1,7.1,0,0,0-.986-4.27A3.737,3.737,0,0,0,96.788,46,4.069,4.069,0,0,0,93.5,47.315,6.446,6.446,0,0,0,92.383,51.538Z"
                      transform="translate(-68.972 -37.993)" :fill="flexColor" />
                <path id="Path_185" data-name="Path 185"
                      d="M131.9,45.307h2.019l4,6.239,4-6.239h2.019l-4.926,7.541,4.906,7.6h-2l-3.988-6.209-4,6.209h-2.019l4.876-7.571Z"
                      transform="translate(-96.104 -38.577)" :fill="flexColor" />
                <path id="Path_186" data-name="Path 186"
                      d="M183.121,44.063h-3.385v6h-4.179V29.465h7.565q7.329,0,7.329,7.147a7.629,7.629,0,0,1-1.864,5.529A7.257,7.257,0,0,1,183.121,44.063Zm-3.385-3.574H183.1q3.119,0,3.119-3.877a4.038,4.038,0,0,0-.757-2.739,3.033,3.033,0,0,0-2.356-.835h-3.365Z"
                      transform="translate(-124.737 -28.186)" fill="#ed691c"/>
                <path id="Path_187" data-name="Path 187"
                      d="M239.906,47.172q-1.925,2.557-6.619,2.557t-6.616-2.557q-1.921-2.561-1.925-8.012t1.925-8.1q1.925-2.648,6.616-2.652t6.619,2.652q1.925,2.652,1.921,8.1T239.906,47.172Zm-9.964-2.665a4.32,4.32,0,0,0,6.693,0,11.57,11.57,0,0,0,.9-5.33,12.146,12.146,0,0,0-.909-5.438,4.139,4.139,0,0,0-6.663,0,11.957,11.957,0,0,0-.909,5.438A11.665,11.665,0,0,0,229.942,44.507Z"
                      transform="translate(-157 -27.493)" fill="#ed691c"/>
                <path id="Path_188" data-name="Path 188"
                      d="M288.961,32.049q-3.3,0-3.3,2.181a1.652,1.652,0,0,0,.818,1.484,21.242,21.242,0,0,0,3.833,1.484,10.532,10.532,0,0,1,4.223,2.2,5.176,5.176,0,0,1,1.211,3.769,6.067,6.067,0,0,1-1.969,4.906,7.761,7.761,0,0,1-5.145,1.683,28.651,28.651,0,0,1-5.788-.727l-1.09-.212.427-3.254a50.417,50.417,0,0,0,6.209.545q3.21,0,3.21-2.665a1.773,1.773,0,0,0-.74-1.514,10.472,10.472,0,0,0-2.955-1.181,12.213,12.213,0,0,1-4.95-2.376,5.046,5.046,0,0,1-1.44-3.833,5.561,5.561,0,0,1,1.878-4.6,7.98,7.98,0,0,1,5.149-1.514,34.21,34.21,0,0,1,5.694.606l1.09.212-.337,3.3Q290.721,32.049,288.961,32.049Z"
                      transform="translate(-194.234 -27.5)" fill="#ed691c"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "flexpos-logo",
    props: {
        flexColor: {
            default: "#262624"
        }
    }
}
</script>