/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2 } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessFlexPosConfigExportAsText } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfig } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2 } from '../models';
/**
 * FlexPosConfigApi - axios parameter creator
 * @export
 */
export const FlexPosConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (key?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByUid: async (uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/delete-by-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAsText: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/export-as-text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/get-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllForTable: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/get-all-for-table`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByKey: async (key?: string, unitUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/get-by-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (unitUid !== undefined) {
                localVarQueryParameter['unitUid'] = unitUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByUid: async (uid?: string, unitUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/get-by-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (unitUid !== undefined) {
                localVarQueryParameter['unitUid'] = unitUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/get-dictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaged: async (sort?: string, order?: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/get-paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [keyValue] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredefinedConfigs: async (keyValue?: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/get-predefined-configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyValue !== undefined) {
                localVarQueryParameter['keyValue'] = keyValue;
            }

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [overrideValue] 
         * @param {APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2} [APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveKey: async (overrideValue?: string, APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2?: APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/save-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (overrideValue !== undefined) {
                localVarQueryParameter['overrideValue'] = overrideValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit>} [APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveKeys: async (APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit?: Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/flexposconfig/save-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlexPosConfigApi - functional programming interface
 * @export
 */
export const FlexPosConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlexPosConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(key?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByUid(uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByUid(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAsText(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessFlexPosConfigExportAsText>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAsText(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfig>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllForTable(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllForTable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByKey(key?: string, unitUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByKey(key, unitUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByUid(uid?: string, unitUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByUid(uid, unitUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any | undefined; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaged(sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaged(sort, order, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [keyValue] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPredefinedConfigs(keyValue?: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string | undefined; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPredefinedConfigs(keyValue, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [overrideValue] 
         * @param {APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2} [APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveKey(overrideValue?: string, APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2?: APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveKey(overrideValue, APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit>} [APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveKeys(APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit?: Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveKeys(APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlexPosConfigApi - factory interface
 * @export
 */
export const FlexPosConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlexPosConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(key?: string, options?: any): AxiosPromise<void> {
            return localVarFp._delete(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByUid(uid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteByUid(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAsText(options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessFlexPosConfigExportAsText>> {
            return localVarFp.exportAsText(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfig>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllForTable(options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModel>> {
            return localVarFp.getAllForTable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByKey(key?: string, unitUid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfig> {
            return localVarFp.getByKey(key, unitUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByUid(uid?: string, unitUid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfig> {
            return localVarFp.getByUid(uid, unitUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionary(options?: any): AxiosPromise<{ [key: string]: any | undefined; }> {
            return localVarFp.getDictionary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaged(sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesPagedListOfAPIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2> {
            return localVarFp.getPaged(sort, order, limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [keyValue] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredefinedConfigs(keyValue?: string, languageUid?: string, options?: any): AxiosPromise<{ [key: string]: string | undefined; }> {
            return localVarFp.getPredefinedConfigs(keyValue, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [overrideValue] 
         * @param {APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2} [APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveKey(overrideValue?: string, APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2?: APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2, options?: any): AxiosPromise<void> {
            return localVarFp.saveKey(overrideValue, APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit>} [APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveKeys(APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit?: Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit>, options?: any): AxiosPromise<void> {
            return localVarFp.saveKeys(APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in FlexPosConfigApi.
 * @export
 * @interface FlexPosConfigApiDeleteRequest
 */
export interface FlexPosConfigApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiDelete
     */
    readonly key?: string
}

/**
 * Request parameters for deleteByUid operation in FlexPosConfigApi.
 * @export
 * @interface FlexPosConfigApiDeleteByUidRequest
 */
export interface FlexPosConfigApiDeleteByUidRequest {
    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiDeleteByUid
     */
    readonly uid?: string
}

/**
 * Request parameters for getByKey operation in FlexPosConfigApi.
 * @export
 * @interface FlexPosConfigApiGetByKeyRequest
 */
export interface FlexPosConfigApiGetByKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiGetByKey
     */
    readonly key?: string

    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiGetByKey
     */
    readonly unitUid?: string
}

/**
 * Request parameters for getByUid operation in FlexPosConfigApi.
 * @export
 * @interface FlexPosConfigApiGetByUidRequest
 */
export interface FlexPosConfigApiGetByUidRequest {
    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiGetByUid
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiGetByUid
     */
    readonly unitUid?: string
}

/**
 * Request parameters for getPaged operation in FlexPosConfigApi.
 * @export
 * @interface FlexPosConfigApiGetPagedRequest
 */
export interface FlexPosConfigApiGetPagedRequest {
    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiGetPaged
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiGetPaged
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof FlexPosConfigApiGetPaged
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof FlexPosConfigApiGetPaged
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiGetPaged
     */
    readonly search?: string
}

/**
 * Request parameters for getPredefinedConfigs operation in FlexPosConfigApi.
 * @export
 * @interface FlexPosConfigApiGetPredefinedConfigsRequest
 */
export interface FlexPosConfigApiGetPredefinedConfigsRequest {
    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiGetPredefinedConfigs
     */
    readonly keyValue?: string

    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiGetPredefinedConfigs
     */
    readonly languageUid?: string
}

/**
 * Request parameters for saveKey operation in FlexPosConfigApi.
 * @export
 * @interface FlexPosConfigApiSaveKeyRequest
 */
export interface FlexPosConfigApiSaveKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof FlexPosConfigApiSaveKey
     */
    readonly overrideValue?: string

    /**
     * 
     * @type {APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2}
     * @memberof FlexPosConfigApiSaveKey
     */
    readonly APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2?: APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2
}

/**
 * Request parameters for saveKeys operation in FlexPosConfigApi.
 * @export
 * @interface FlexPosConfigApiSaveKeysRequest
 */
export interface FlexPosConfigApiSaveKeysRequest {
    /**
     * 
     * @type {Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit>}
     * @memberof FlexPosConfigApiSaveKeys
     */
    readonly APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit?: Array<APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit>
}

/**
 * FlexPosConfigApi - object-oriented interface
 * @export
 * @class FlexPosConfigApi
 * @extends {BaseAPI}
 */
export class FlexPosConfigApi extends BaseAPI {
    /**
     * 
     * @param {FlexPosConfigApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public _delete(requestParameters: FlexPosConfigApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration)._delete(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FlexPosConfigApiDeleteByUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public deleteByUid(requestParameters: FlexPosConfigApiDeleteByUidRequest = {}, options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration).deleteByUid(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public exportAsText(options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration).exportAsText(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public getAllForTable(options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration).getAllForTable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FlexPosConfigApiGetByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public getByKey(requestParameters: FlexPosConfigApiGetByKeyRequest = {}, options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration).getByKey(requestParameters.key, requestParameters.unitUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FlexPosConfigApiGetByUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public getByUid(requestParameters: FlexPosConfigApiGetByUidRequest = {}, options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration).getByUid(requestParameters.uid, requestParameters.unitUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public getDictionary(options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration).getDictionary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FlexPosConfigApiGetPagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public getPaged(requestParameters: FlexPosConfigApiGetPagedRequest = {}, options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration).getPaged(requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FlexPosConfigApiGetPredefinedConfigsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public getPredefinedConfigs(requestParameters: FlexPosConfigApiGetPredefinedConfigsRequest = {}, options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration).getPredefinedConfigs(requestParameters.keyValue, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FlexPosConfigApiSaveKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public saveKey(requestParameters: FlexPosConfigApiSaveKeyRequest = {}, options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration).saveKey(requestParameters.overrideValue, requestParameters.APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigTableModelV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FlexPosConfigApiSaveKeysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexPosConfigApi
     */
    public saveKeys(requestParameters: FlexPosConfigApiSaveKeysRequest = {}, options?: AxiosRequestConfig) {
        return FlexPosConfigApiFp(this.configuration).saveKeys(requestParameters.APIInternalFrontendAPIModelsFlexPosConfigFlexPosConfigSubmit, options).then((request) => request(this.axios, this.basePath));
    }
}
