import {Module} from "vuex";

interface IWarrantyTableIndexState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const warrantyTableIndexState: Module<IWarrantyTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
		clientUid(state, getters, rootState) { return getConfig("Warranties.ColumnOptions.clientUid", rootState, true); },
        name(state, getters, rootState) { return getConfig("Warranties.ColumnOptions.name", rootState, true); },
        createdBy(state, getters, rootState) { return getConfig("Warranties.ColumnOptions.createdBy", rootState, true); },
        LineCount(state, getters, rootState) { return getConfig("Warranties.ColumnOptions.LineCount", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				clientUid: getters.clientUid,
                name: getters.name,
                createdBy: getters.createdBy,
                LineCount: getters.LineCount,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				clientUid: true,
                name: true,
                createdBy: true,
                LineCount: true,
            };
        }
    }
}

export default warrantyTableIndexState;