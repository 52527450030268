/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIModelsEnumEnumPostModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsEnumEnumPreviewViewModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsEnumEnumViewModel } from '../models';
// @ts-ignore
import { SystemCollectionsGenericKeyValuePairOfSystemInt32SystemString } from '../models';
/**
 * EnumApi - axios parameter creator
 * @export
 */
export const EnumApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsEnumEnumViewModel} [APIInternalFrontendAPIModelsEnumEnumViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (APIInternalFrontendAPIModelsEnumEnumViewModel?: APIInternalFrontendAPIModelsEnumEnumViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsEnumEnumViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentUid] 
         * @param {string} [typeUid] 
         * @param {Array<APIInternalFrontendAPIModelsEnumEnumPostModel>} [APIInternalFrontendAPIModelsEnumEnumPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUpdateBulk: async (parentUid?: string, typeUid?: string, APIInternalFrontendAPIModelsEnumEnumPostModel?: Array<APIInternalFrontendAPIModelsEnumEnumPostModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/create-update-bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentUid !== undefined) {
                localVarQueryParameter['parentUid'] = parentUid;
            }

            if (typeUid !== undefined) {
                localVarQueryParameter['typeUid'] = typeUid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsEnumEnumPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsEnumEnumViewModel} [APIInternalFrontendAPIModelsEnumEnumViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit: async (APIInternalFrontendAPIModelsEnumEnumViewModel?: APIInternalFrontendAPIModelsEnumEnumViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsEnumEnumViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByParentUid: async (uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/get-all-by-parent-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPreview: async (uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/get-all-preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnumParent: async (uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/get-enum-parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuidEnum: async (uid?: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/get-guid-enum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuidEnumSingleValue: async (key?: string, uid?: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/get-guid-enum-single-value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntEnum: async (uid?: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/get-int-enum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntEnumSingleValue: async (key?: string, uid?: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/get-int-enum-single-value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntEnumSorted: async (uid?: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/get-int-enum-sorted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStringEnum: async (uid?: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/get-string-enum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStringEnumSingleValue: async (key?: string, uid?: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/enum/get-string-enum-single-value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnumApi - functional programming interface
 * @export
 */
export const EnumApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnumApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsEnumEnumViewModel} [APIInternalFrontendAPIModelsEnumEnumViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(APIInternalFrontendAPIModelsEnumEnumViewModel?: APIInternalFrontendAPIModelsEnumEnumViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(APIInternalFrontendAPIModelsEnumEnumViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [parentUid] 
         * @param {string} [typeUid] 
         * @param {Array<APIInternalFrontendAPIModelsEnumEnumPostModel>} [APIInternalFrontendAPIModelsEnumEnumPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUpdateBulk(parentUid?: string, typeUid?: string, APIInternalFrontendAPIModelsEnumEnumPostModel?: Array<APIInternalFrontendAPIModelsEnumEnumPostModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUpdateBulk(parentUid, typeUid, APIInternalFrontendAPIModelsEnumEnumPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsEnumEnumViewModel} [APIInternalFrontendAPIModelsEnumEnumViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edit(APIInternalFrontendAPIModelsEnumEnumViewModel?: APIInternalFrontendAPIModelsEnumEnumViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edit(APIInternalFrontendAPIModelsEnumEnumViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllByParentUid(uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsEnumEnumViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllByParentUid(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPreview(uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsEnumEnumPreviewViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPreview(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnumParent(uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsEnumEnumViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnumParent(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuidEnum(uid?: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string | undefined; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuidEnum(uid, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuidEnumSingleValue(key?: string, uid?: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuidEnumSingleValue(key, uid, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntEnum(uid?: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string | undefined; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntEnum(uid, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntEnumSingleValue(key?: string, uid?: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntEnumSingleValue(key, uid, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntEnumSorted(uid?: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemCollectionsGenericKeyValuePairOfSystemInt32SystemString>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntEnumSorted(uid, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStringEnum(uid?: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string | undefined; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStringEnum(uid, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStringEnumSingleValue(key?: string, uid?: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStringEnumSingleValue(key, uid, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnumApi - factory interface
 * @export
 */
export const EnumApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnumApiFp(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsEnumEnumViewModel} [APIInternalFrontendAPIModelsEnumEnumViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(APIInternalFrontendAPIModelsEnumEnumViewModel?: APIInternalFrontendAPIModelsEnumEnumViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.create(APIInternalFrontendAPIModelsEnumEnumViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [parentUid] 
         * @param {string} [typeUid] 
         * @param {Array<APIInternalFrontendAPIModelsEnumEnumPostModel>} [APIInternalFrontendAPIModelsEnumEnumPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUpdateBulk(parentUid?: string, typeUid?: string, APIInternalFrontendAPIModelsEnumEnumPostModel?: Array<APIInternalFrontendAPIModelsEnumEnumPostModel>, options?: any): AxiosPromise<boolean> {
            return localVarFp.createUpdateBulk(parentUid, typeUid, APIInternalFrontendAPIModelsEnumEnumPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsEnumEnumViewModel} [APIInternalFrontendAPIModelsEnumEnumViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit(APIInternalFrontendAPIModelsEnumEnumViewModel?: APIInternalFrontendAPIModelsEnumEnumViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.edit(APIInternalFrontendAPIModelsEnumEnumViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByParentUid(uid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsEnumEnumViewModel>> {
            return localVarFp.getAllByParentUid(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPreview(uid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsEnumEnumPreviewViewModel>> {
            return localVarFp.getAllPreview(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnumParent(uid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsEnumEnumViewModel> {
            return localVarFp.getEnumParent(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuidEnum(uid?: string, languageUid?: string, options?: any): AxiosPromise<{ [key: string]: string | undefined; }> {
            return localVarFp.getGuidEnum(uid, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuidEnumSingleValue(key?: string, uid?: string, languageUid?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getGuidEnumSingleValue(key, uid, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntEnum(uid?: string, languageUid?: string, options?: any): AxiosPromise<{ [key: string]: string | undefined; }> {
            return localVarFp.getIntEnum(uid, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntEnumSingleValue(key?: string, uid?: string, languageUid?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getIntEnumSingleValue(key, uid, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntEnumSorted(uid?: string, languageUid?: string, options?: any): AxiosPromise<Array<SystemCollectionsGenericKeyValuePairOfSystemInt32SystemString>> {
            return localVarFp.getIntEnumSorted(uid, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStringEnum(uid?: string, languageUid?: string, options?: any): AxiosPromise<{ [key: string]: string | undefined; }> {
            return localVarFp.getStringEnum(uid, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [uid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStringEnumSingleValue(key?: string, uid?: string, languageUid?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getStringEnumSingleValue(key, uid, languageUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for create operation in EnumApi.
 * @export
 * @interface EnumApiCreateRequest
 */
export interface EnumApiCreateRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsEnumEnumViewModel}
     * @memberof EnumApiCreate
     */
    readonly APIInternalFrontendAPIModelsEnumEnumViewModel?: APIInternalFrontendAPIModelsEnumEnumViewModel
}

/**
 * Request parameters for createUpdateBulk operation in EnumApi.
 * @export
 * @interface EnumApiCreateUpdateBulkRequest
 */
export interface EnumApiCreateUpdateBulkRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumApiCreateUpdateBulk
     */
    readonly parentUid?: string

    /**
     * 
     * @type {string}
     * @memberof EnumApiCreateUpdateBulk
     */
    readonly typeUid?: string

    /**
     * 
     * @type {Array<APIInternalFrontendAPIModelsEnumEnumPostModel>}
     * @memberof EnumApiCreateUpdateBulk
     */
    readonly APIInternalFrontendAPIModelsEnumEnumPostModel?: Array<APIInternalFrontendAPIModelsEnumEnumPostModel>
}

/**
 * Request parameters for edit operation in EnumApi.
 * @export
 * @interface EnumApiEditRequest
 */
export interface EnumApiEditRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsEnumEnumViewModel}
     * @memberof EnumApiEdit
     */
    readonly APIInternalFrontendAPIModelsEnumEnumViewModel?: APIInternalFrontendAPIModelsEnumEnumViewModel
}

/**
 * Request parameters for getAllByParentUid operation in EnumApi.
 * @export
 * @interface EnumApiGetAllByParentUidRequest
 */
export interface EnumApiGetAllByParentUidRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumApiGetAllByParentUid
     */
    readonly uid?: string
}

/**
 * Request parameters for getAllPreview operation in EnumApi.
 * @export
 * @interface EnumApiGetAllPreviewRequest
 */
export interface EnumApiGetAllPreviewRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumApiGetAllPreview
     */
    readonly uid?: string
}

/**
 * Request parameters for getEnumParent operation in EnumApi.
 * @export
 * @interface EnumApiGetEnumParentRequest
 */
export interface EnumApiGetEnumParentRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumApiGetEnumParent
     */
    readonly uid?: string
}

/**
 * Request parameters for getGuidEnum operation in EnumApi.
 * @export
 * @interface EnumApiGetGuidEnumRequest
 */
export interface EnumApiGetGuidEnumRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumApiGetGuidEnum
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof EnumApiGetGuidEnum
     */
    readonly languageUid?: string
}

/**
 * Request parameters for getGuidEnumSingleValue operation in EnumApi.
 * @export
 * @interface EnumApiGetGuidEnumSingleValueRequest
 */
export interface EnumApiGetGuidEnumSingleValueRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumApiGetGuidEnumSingleValue
     */
    readonly key?: string

    /**
     * 
     * @type {string}
     * @memberof EnumApiGetGuidEnumSingleValue
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof EnumApiGetGuidEnumSingleValue
     */
    readonly languageUid?: string
}

/**
 * Request parameters for getIntEnum operation in EnumApi.
 * @export
 * @interface EnumApiGetIntEnumRequest
 */
export interface EnumApiGetIntEnumRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumApiGetIntEnum
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof EnumApiGetIntEnum
     */
    readonly languageUid?: string
}

/**
 * Request parameters for getIntEnumSingleValue operation in EnumApi.
 * @export
 * @interface EnumApiGetIntEnumSingleValueRequest
 */
export interface EnumApiGetIntEnumSingleValueRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumApiGetIntEnumSingleValue
     */
    readonly key?: string

    /**
     * 
     * @type {string}
     * @memberof EnumApiGetIntEnumSingleValue
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof EnumApiGetIntEnumSingleValue
     */
    readonly languageUid?: string
}

/**
 * Request parameters for getIntEnumSorted operation in EnumApi.
 * @export
 * @interface EnumApiGetIntEnumSortedRequest
 */
export interface EnumApiGetIntEnumSortedRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumApiGetIntEnumSorted
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof EnumApiGetIntEnumSorted
     */
    readonly languageUid?: string
}

/**
 * Request parameters for getStringEnum operation in EnumApi.
 * @export
 * @interface EnumApiGetStringEnumRequest
 */
export interface EnumApiGetStringEnumRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumApiGetStringEnum
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof EnumApiGetStringEnum
     */
    readonly languageUid?: string
}

/**
 * Request parameters for getStringEnumSingleValue operation in EnumApi.
 * @export
 * @interface EnumApiGetStringEnumSingleValueRequest
 */
export interface EnumApiGetStringEnumSingleValueRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumApiGetStringEnumSingleValue
     */
    readonly key?: string

    /**
     * 
     * @type {string}
     * @memberof EnumApiGetStringEnumSingleValue
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof EnumApiGetStringEnumSingleValue
     */
    readonly languageUid?: string
}

/**
 * EnumApi - object-oriented interface
 * @export
 * @class EnumApi
 * @extends {BaseAPI}
 */
export class EnumApi extends BaseAPI {
    /**
     * 
     * @param {EnumApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public create(requestParameters: EnumApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).create(requestParameters.APIInternalFrontendAPIModelsEnumEnumViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiCreateUpdateBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public createUpdateBulk(requestParameters: EnumApiCreateUpdateBulkRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).createUpdateBulk(requestParameters.parentUid, requestParameters.typeUid, requestParameters.APIInternalFrontendAPIModelsEnumEnumPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiEditRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public edit(requestParameters: EnumApiEditRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).edit(requestParameters.APIInternalFrontendAPIModelsEnumEnumViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiGetAllByParentUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public getAllByParentUid(requestParameters: EnumApiGetAllByParentUidRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).getAllByParentUid(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiGetAllPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public getAllPreview(requestParameters: EnumApiGetAllPreviewRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).getAllPreview(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiGetEnumParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public getEnumParent(requestParameters: EnumApiGetEnumParentRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).getEnumParent(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiGetGuidEnumRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public getGuidEnum(requestParameters: EnumApiGetGuidEnumRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).getGuidEnum(requestParameters.uid, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiGetGuidEnumSingleValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public getGuidEnumSingleValue(requestParameters: EnumApiGetGuidEnumSingleValueRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).getGuidEnumSingleValue(requestParameters.key, requestParameters.uid, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiGetIntEnumRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public getIntEnum(requestParameters: EnumApiGetIntEnumRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).getIntEnum(requestParameters.uid, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiGetIntEnumSingleValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public getIntEnumSingleValue(requestParameters: EnumApiGetIntEnumSingleValueRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).getIntEnumSingleValue(requestParameters.key, requestParameters.uid, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiGetIntEnumSortedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public getIntEnumSorted(requestParameters: EnumApiGetIntEnumSortedRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).getIntEnumSorted(requestParameters.uid, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiGetStringEnumRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public getStringEnum(requestParameters: EnumApiGetStringEnumRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).getStringEnum(requestParameters.uid, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumApiGetStringEnumSingleValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumApi
     */
    public getStringEnumSingleValue(requestParameters: EnumApiGetStringEnumSingleValueRequest = {}, options?: AxiosRequestConfig) {
        return EnumApiFp(this.configuration).getStringEnumSingleValue(requestParameters.key, requestParameters.uid, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }
}
