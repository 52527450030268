import { throttle } from "underscore";
import {ActionContext, Module} from "vuex";
import {EnumApi} from "../../_Services/Autogenerated_v4/V1/api/enum-api";

let EnumService;



export interface IProductStockEnumState {
	stockTransactionType: Record<string, unknown>;
	stockTransactionStatus: Record<string, unknown>;
	stockTransactionReason: Record<string, unknown>;
}

let stockTransactionTypeThrottle: ((() => Promise<void>) & _.Cancelable) | null = null;
let stockTransactionStatusThrottle: ((() => Promise<void>) & _.Cancelable) | null = null;
let stockTransactionReasonThrottle: ((() => Promise<void>) & _.Cancelable) | null = null;

const productStockEnumState: Module<IProductStockEnumState, any> = {
	namespaced: true,

	state: () => ({
		stockTransactionType: null,
		stockTransactionStatus: null,
		stockTransactionReason: null,
	}),
	actions: {
		async setStockTransactionTypeIfNotSet({ commit, state, rootGetters, rootState }: ActionContext<IProductStockEnumState, any>) {
			if (!EnumService) EnumService = new EnumApi(rootGetters["getSwaggerAxiosOptions"]);
			if (!stockTransactionTypeThrottle)
				stockTransactionTypeThrottle = throttle(async () => {
					const languageUid = rootState.languageUid;
					const enumValue = (await EnumService.getIntEnum({uid: "E9B51D07-C82C-4BBE-8519-D6A86BFACFFB", languageUid: languageUid})).data;
					commit("SET_STOCK_TRANSACTION_TYPE", enumValue);
				}, 1000 * 60 * 5);

			await stockTransactionTypeThrottle();
		},
		async setStockTransactionStatusIfNotSet({ commit, state, rootGetters, rootState }: ActionContext<IProductStockEnumState, any>) {
			if (!EnumService) EnumService = new EnumApi(rootGetters["getSwaggerAxiosOptions"]);
			if (!stockTransactionStatusThrottle)
				stockTransactionStatusThrottle = throttle(async () => {
					const languageUid = rootState.languageUid;
					const enumValue = (await EnumService.getIntEnum({uid: "D97E3CDA-1673-4DF6-6536-8931C27CADF5", languageUid: languageUid})).data;
					commit("SET_STOCK_TRANSACTION_STATUS", enumValue);
				}, 1000 * 60 * 5);

			await stockTransactionStatusThrottle();
		},
		async setStockTransactionReasonIfNotSet({ commit, state, rootGetters, rootState }: ActionContext<IProductStockEnumState, any>) {
			if (!EnumService) EnumService = new EnumApi(rootGetters["getSwaggerAxiosOptions"]);
			if (!stockTransactionReasonThrottle)
				stockTransactionReasonThrottle = throttle(async () => {
					const languageUid = rootState.languageUid;
					const enumValue = (await EnumService.getIntEnumSorted({uid: "753B87D9-2CE9-4EF1-2387-A9375EE12829", languageUid: languageUid})).data;
					const map = new Map();
					for (let i = 0; i < enumValue.length; i++) {
						const item = enumValue[i];
						map.set(item.Key, item.Value);
					}
					
					commit("SET_STOCK_TRANSACTION_REASON", map);
				}, 1000 * 60 * 5);

			await stockTransactionReasonThrottle();
		}
	},
	mutations: {
		SET_STOCK_TRANSACTION_TYPE(state: IProductStockEnumState, newState) {
			state.stockTransactionType = newState;
		},
		SET_STOCK_TRANSACTION_STATUS(state: IProductStockEnumState, newState) {
			state.stockTransactionStatus = newState;
		},
		SET_STOCK_TRANSACTION_REASON(state: IProductStockEnumState, newState) {
			state.stockTransactionReason = newState;
		}
	},
	getters: {
		getStockTransactionType(state: IProductStockEnumState) {
			return state.stockTransactionType;
		},
		getStockTransactionStatus(state: IProductStockEnumState) {
			return state.stockTransactionStatus;
		},
		getStockTransactionReason(state: IProductStockEnumState) {
			return state.stockTransactionReason;
		},
	}
};

export default productStockEnumState;