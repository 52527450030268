import {Module} from "vuex";

interface IObsListTableIndexState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const obsListTableIndexState: Module<IObsListTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
		Store(state, getters, rootState) { return getConfig("ObsList.ColumnOptions.Store", rootState, true); },
		Unit(state, getters, rootState) { return getConfig("ObsList.ColumnOptions.Unit", rootState, true); },
		CDrive(state, getters, rootState) { return getConfig("ObsList.ColumnOptions.CDrive", rootState, true); },
		CurrentVersion(state, getters, rootState) { return getConfig("ObsList.ColumnOptions.CurrentVersion", rootState, true); },
		ExpectedVersion(state, getters, rootState) { return getConfig("ObsList.ColumnOptions.ExpectedVersion", rootState, true); },
		LastSoftwareUpdate(state, getters, rootState) { return getConfig("ObsList.ColumnOptions.LastSoftwareUpdate", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Store: getters.Store,
                Unit: getters.Unit,
				CDrive: getters.CDrive,
				CurrentVersion: getters.CurrentVersion,
				ExpectedVersion: getters.ExpectedVersion,
				LastSoftwareUpdate: getters.LastSoftwareUpdate,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Store: true,
				Unit: true,
				CDrive: true,
				CurrentVersion: true,
				ExpectedVersion: true,
				LastSoftwareUpdate: true,
            };
        }
    }
}

export default obsListTableIndexState;