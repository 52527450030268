<template>
	<div class="p-3 h-100 position-relative">
		<div class="d-flex justify-content-center align-items-center h-100" v-if="loading">
			<font-awesome-icon icon="fa-regular fa-spinner-third" class="text-primary" :spin="true" size="5x" />
		</div>
		<div ref="page" v-if="statusCode === null || environment === 'development'" />
		<div v-else-if="statusCode === 404">
			<h1>
				{{ Resource.nNotFound.replace('{0}', Resource.Page) }}
			</h1>
		</div>    
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "asp-net-view",
	data() {
		return {
			ajax: null,
			statusCode: null,
			loading: false
		};
	},
	computed: {
		url() {
			return "/backend-service" + this.$route.fullPath;
		},
		...mapGetters({
			environment: "getEnvironment"
		})
	},
	watch: {
		url: {
			immediate: true,
			handler: function (value, oldValue) {
				if (value === oldValue) return;
				const url = value ? new URL(value, location.origin) : {};
				const oldUrl = oldValue ? new URL(oldValue, location.origin) : {};
				if (url.pathname === oldUrl.pathname) return;
                
				if (this.ajax) {
					this.ajax.abort();
					this.ajax = null;
				}

				this.loading = true;
				this.ajax = $.ajax({
					url: value
				}).done((response) => {
					this.loading = false;
					this.statusCode = null;
					$(this.$refs.page).html(response);
				}).fail((jqXHR) => {
					this.loading = false;
					this.statusCode = jqXHR.status;
					if (this.environment === "development") {
						$(this.$refs.page).html(jqXHR.responseText);
					}
					this.$store.dispatch("addToast", { 
						type: "danger", 
						header: Resource.Error, 
						text: Resource.nNotFound.replace('{0}', Resource.Page) 
					});
				});
			}
		}
	},
	beforeDestroy() {
		if (this.ajax) {
			this.ajax.abort();
			this.ajax = null;
		}
	},
	destroyed() {
		if (this.ajax) {
			this.ajax.abort();
			this.ajax = null;
		}
	}
};
</script>

<style scoped>

</style>