import {Module} from "vuex";

interface IClientBulkEditTableState {
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const clientBulkEditTableState: Module<IClientBulkEditTableState, any> = {
    namespaced: true,
    
    state: () => ({
    }),
    actions: {
    },
    mutations: {
    },
    getters: {
        Name(state, getters, rootState) { return getConfig("ClientBulkEdit.ColumnOptions.Name", rootState, true); },
		getColumnVisibility(state, getters, rootState, rootGetters) {
			return {
				Name: getters.Name,
			};
		},
		getConfigVisibility(state, getters, rootState, rootGetters) {
			return {
				Name: true,
			};
		}
    }
}

export default clientBulkEditTableState;