export default function modelStateFormatter(modelState: any, returnAsObject: boolean = false): string[] | {field: string, error: string} {
    const errors = [];
    if (Array.isArray(modelState)) {
        const modelStateArray: {Value: string[], Key: string}[] = modelState;
        for (let i = 0; i < modelStateArray.length; i++) {
            for (let j = 0; j < modelStateArray[i].Value.length; j++) {
                let error;
                let field = modelStateArray[i].Key;
                
                if (modelStateArray[i].Value[j].indexOf("|") > -1) {
                    let errorSplit = modelStateArray[i].Value[j].split("|")
    
                    let resource = Resource[errorSplit[0]];
                    if (resource) {
                        for (let k = 1; k < errorSplit.length; k++) {
							resource = resource.replace(`{${k-1}}`, errorSplit[k]);
                        }
                        error = resource;
                    }
                    else {
                        let resource = Resource[modelStateArray[i].Value[j]];
                        if (resource)
                            error = resource;
                        else
                            error = modelStateArray[i].Value[j];
                    }
                }
                else {
                    let resource = Resource[modelStateArray[i].Value[j]];
                    if (resource)
                        error = resource;
                    else
                        error = modelStateArray[i].Value[j];
                }
    
                
                if (returnAsObject === true)
                    errors.push({field: field, error: error});
                else                
                    errors.push(error);
            }
        }    
    }
    else {
        if (typeof modelState === "string") return [modelState];
        const obj = Object.keys(modelState);
        for (let i = 0; i < obj.length; i++) {
            const field = obj[i];

            for (let j = 0; j < modelState[field].length; j++) {
                let error = modelState[field][j];

				if (error.indexOf("|") > -1) {
					let errorSplit = error.split("|")

					let resource = Resource[errorSplit[0]];
					if (resource) {
						for (let k = 1; k < errorSplit.length; k++) {
							resource = resource.replace(`{${k-1}}`, errorSplit[k]);
						}
						error = resource;
					}
					else {
						let resource = Resource[modelState[field][j]];
						if (resource)
							error = resource;
						else
							error = modelState[field][j];
					}
				}
				else {
					let resource = Resource[modelState[field][j]];
					if (resource)
						error = resource;
					else
						error = modelState[field][j];
				}
				
                if (returnAsObject === true)
                    errors.push({field: field, error: error});
                else
                    errors.push(error);    
            }
        }
    }
    return errors
}