import {Module} from "vuex";

interface IBillingTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const billingTableState: Module<IBillingTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        SentDate(state, getters, rootState) { return getConfig("Billing.ColumnOptions.SentDate", rootState, true); },
        Header(state, getters, rootState) { return getConfig("Billing.ColumnOptions.Header", rootState, true); },
        InvoiceNetAmount(state, getters, rootState) { return getConfig("Billing.ColumnOptions.InvoiceNetAmount", rootState, true); },
        InvoiceGrossAmount(state, getters, rootState) { return getConfig("Billing.ColumnOptions.InvoiceGrossAmount", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                SentDate: getters.SentDate,
                Header: getters.Header,
                InvoiceNetAmount: getters.InvoiceNetAmount,
                InvoiceGrossAmount: getters.InvoiceGrossAmount,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                SentDate: true,
                Header: true,
                InvoiceNetAmount: true,
                InvoiceGrossAmount: true,
            };
        }
    }
}

export default billingTableState;