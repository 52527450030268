/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesNews } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesNotifications } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsNewsNewsModel } from '../models';
/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsNewsNewsModel} [APIInternalFrontendAPIModelsNewsNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (APIInternalFrontendAPIModelsNewsNewsModel?: APIInternalFrontendAPIModelsNewsNewsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/notification/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsNewsNewsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsNewsNewsModel} [APIInternalFrontendAPIModelsNewsNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit: async (APIInternalFrontendAPIModelsNewsNewsModel?: APIInternalFrontendAPIModelsNewsNewsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/notification/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsNewsNewsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/notification/get-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [Area] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllForLocation: async (Area?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/notification/get-all-for-location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (Area !== undefined) {
                localVarQueryParameter['Area'] = Area;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [ShowInactive] 
         * @param {string} [Areas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllForLocations: async (ShowInactive?: boolean, Areas?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/notification/get-all-for-locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ShowInactive !== undefined) {
                localVarQueryParameter['ShowInactive'] = ShowInactive;
            }

            if (Areas !== undefined) {
                localVarQueryParameter['Areas'] = Areas;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [showVendor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDots: async (showVendor?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/notification/get-dots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (showVendor !== undefined) {
                localVarQueryParameter['showVendor'] = showVendor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationDELETE: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('notificationDELETE', 'uid', uid)
            const localVarPath = `/api/v1.0/notification/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationGET: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('notificationGET', 'uid', uid)
            const localVarPath = `/api/v1.0/notification/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAllHidden: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/notification/set-all-hidden`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAllRead: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/notification/set-all-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHidden: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('setHidden', 'uid', uid)
            const localVarPath = `/api/v1.0/notification/set-hidden/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRead: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('setRead', 'uid', uid)
            const localVarPath = `/api/v1.0/notification/set-read/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setShown: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('setShown', 'uid', uid)
            const localVarPath = `/api/v1.0/notification/set-shown/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUnread: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('setUnread', 'uid', uid)
            const localVarPath = `/api/v1.0/notification/set-unread/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsNewsNewsModel} [APIInternalFrontendAPIModelsNewsNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(APIInternalFrontendAPIModelsNewsNewsModel?: APIInternalFrontendAPIModelsNewsNewsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(APIInternalFrontendAPIModelsNewsNewsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsNewsNewsModel} [APIInternalFrontendAPIModelsNewsNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edit(APIInternalFrontendAPIModelsNewsNewsModel?: APIInternalFrontendAPIModelsNewsNewsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edit(APIInternalFrontendAPIModelsNewsNewsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesNews>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [Area] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllForLocation(Area?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesNews>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllForLocation(Area, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [ShowInactive] 
         * @param {string} [Areas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllForLocations(ShowInactive?: boolean, Areas?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesNews>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllForLocations(ShowInactive, Areas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [showVendor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDots(showVendor?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesNotifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDots(showVendor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationDELETE(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationDELETE(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationGET(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsNewsNewsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationGET(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAllHidden(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAllHidden(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAllRead(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAllRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setHidden(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setHidden(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRead(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setRead(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setShown(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setShown(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUnread(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUnread(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsNewsNewsModel} [APIInternalFrontendAPIModelsNewsNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(APIInternalFrontendAPIModelsNewsNewsModel?: APIInternalFrontendAPIModelsNewsNewsModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.create(APIInternalFrontendAPIModelsNewsNewsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsNewsNewsModel} [APIInternalFrontendAPIModelsNewsNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit(APIInternalFrontendAPIModelsNewsNewsModel?: APIInternalFrontendAPIModelsNewsNewsModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.edit(APIInternalFrontendAPIModelsNewsNewsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesNews>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [Area] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllForLocation(Area?: number, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesNews>> {
            return localVarFp.getAllForLocation(Area, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [ShowInactive] 
         * @param {string} [Areas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllForLocations(ShowInactive?: boolean, Areas?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesNews>> {
            return localVarFp.getAllForLocations(ShowInactive, Areas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [showVendor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDots(showVendor?: boolean, options?: any): AxiosPromise<APIInternalFrontendAPIDataAccessEntitiesNotifications> {
            return localVarFp.getDots(showVendor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationDELETE(uid: string, options?: any): AxiosPromise<void> {
            return localVarFp.notificationDELETE(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationGET(uid: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsNewsNewsModel> {
            return localVarFp.notificationGET(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAllHidden(options?: any): AxiosPromise<void> {
            return localVarFp.setAllHidden(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAllRead(options?: any): AxiosPromise<void> {
            return localVarFp.setAllRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHidden(uid: string, options?: any): AxiosPromise<void> {
            return localVarFp.setHidden(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRead(uid: string, options?: any): AxiosPromise<void> {
            return localVarFp.setRead(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setShown(uid: string, options?: any): AxiosPromise<void> {
            return localVarFp.setShown(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUnread(uid: string, options?: any): AxiosPromise<void> {
            return localVarFp.setUnread(uid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for create operation in NotificationApi.
 * @export
 * @interface NotificationApiCreateRequest
 */
export interface NotificationApiCreateRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsNewsNewsModel}
     * @memberof NotificationApiCreate
     */
    readonly APIInternalFrontendAPIModelsNewsNewsModel?: APIInternalFrontendAPIModelsNewsNewsModel
}

/**
 * Request parameters for edit operation in NotificationApi.
 * @export
 * @interface NotificationApiEditRequest
 */
export interface NotificationApiEditRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsNewsNewsModel}
     * @memberof NotificationApiEdit
     */
    readonly APIInternalFrontendAPIModelsNewsNewsModel?: APIInternalFrontendAPIModelsNewsNewsModel
}

/**
 * Request parameters for getAllForLocation operation in NotificationApi.
 * @export
 * @interface NotificationApiGetAllForLocationRequest
 */
export interface NotificationApiGetAllForLocationRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationApiGetAllForLocation
     */
    readonly Area?: number
}

/**
 * Request parameters for getAllForLocations operation in NotificationApi.
 * @export
 * @interface NotificationApiGetAllForLocationsRequest
 */
export interface NotificationApiGetAllForLocationsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationApiGetAllForLocations
     */
    readonly ShowInactive?: boolean

    /**
     * 
     * @type {string}
     * @memberof NotificationApiGetAllForLocations
     */
    readonly Areas?: string
}

/**
 * Request parameters for getDots operation in NotificationApi.
 * @export
 * @interface NotificationApiGetDotsRequest
 */
export interface NotificationApiGetDotsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationApiGetDots
     */
    readonly showVendor?: boolean
}

/**
 * Request parameters for notificationDELETE operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationDELETERequest
 */
export interface NotificationApiNotificationDELETERequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationDELETE
     */
    readonly uid: string
}

/**
 * Request parameters for notificationGET operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationGETRequest
 */
export interface NotificationApiNotificationGETRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationGET
     */
    readonly uid: string
}

/**
 * Request parameters for setHidden operation in NotificationApi.
 * @export
 * @interface NotificationApiSetHiddenRequest
 */
export interface NotificationApiSetHiddenRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationApiSetHidden
     */
    readonly uid: string
}

/**
 * Request parameters for setRead operation in NotificationApi.
 * @export
 * @interface NotificationApiSetReadRequest
 */
export interface NotificationApiSetReadRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationApiSetRead
     */
    readonly uid: string
}

/**
 * Request parameters for setShown operation in NotificationApi.
 * @export
 * @interface NotificationApiSetShownRequest
 */
export interface NotificationApiSetShownRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationApiSetShown
     */
    readonly uid: string
}

/**
 * Request parameters for setUnread operation in NotificationApi.
 * @export
 * @interface NotificationApiSetUnreadRequest
 */
export interface NotificationApiSetUnreadRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationApiSetUnread
     */
    readonly uid: string
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {NotificationApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public create(requestParameters: NotificationApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).create(requestParameters.APIInternalFrontendAPIModelsNewsNewsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiEditRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public edit(requestParameters: NotificationApiEditRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).edit(requestParameters.APIInternalFrontendAPIModelsNewsNewsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiGetAllForLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getAllForLocation(requestParameters: NotificationApiGetAllForLocationRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getAllForLocation(requestParameters.Area, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiGetAllForLocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getAllForLocations(requestParameters: NotificationApiGetAllForLocationsRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getAllForLocations(requestParameters.ShowInactive, requestParameters.Areas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiGetDotsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getDots(requestParameters: NotificationApiGetDotsRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getDots(requestParameters.showVendor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiNotificationDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationDELETE(requestParameters: NotificationApiNotificationDELETERequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationDELETE(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiNotificationGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationGET(requestParameters: NotificationApiNotificationGETRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationGET(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public setAllHidden(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).setAllHidden(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public setAllRead(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).setAllRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiSetHiddenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public setHidden(requestParameters: NotificationApiSetHiddenRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).setHidden(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiSetReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public setRead(requestParameters: NotificationApiSetReadRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).setRead(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiSetShownRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public setShown(requestParameters: NotificationApiSetShownRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).setShown(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiSetUnreadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public setUnread(requestParameters: NotificationApiSetUnreadRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).setUnread(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }
}
