


















































import Spinner from "@components/vue-strap/Spinner.vue";
import {v4 as newGuid} from "uuid";

export default {
	name: "fp-accordion",
	components: {Spinner},
	props: {
		hasHeadHeight:
		{
			type: Boolean,
			default: false,
		},
		hasLoadingIcon: {
			type: Boolean,
			default: false
		},
		
		isLoading: {
			type: Boolean,
			default: false
		},
		loadError: {
			type: Boolean,
			default: false
		},
		loadErrorText: {
			type: String,
			default() {
				return this.Resource.ServerError
			}
		},
		idField: {
			default() {
				return newGuid();
			}
		},
	},
	data() {
		return {
			tabs: [],
		}
	}
}
