<template>
	<mounting-portal mount-to="body" append>
		<div class="modal fade" :class="{'modal-top': placeInTop}" tabindex="-1" @transitionend="trans = false"
			 :style="{'z-index': zIndex}" ref="modal" :id="id">
			<div class="modal-dialog printModal" :class="modalClass" aria-modal="true"
				 aria-live="assertive"
				 @click.stop="">
				<div class="modal-content">
					<slot name="header">
						<div class="position-relative modal-header">
							<div class="col" v-if="showLeftRightHeaders">
								<slot name="header-left"/>
							</div>
							<h5 class="col modal-title" v-html="title"></h5>
							<div class="col" v-if="showLeftRightHeaders">
								<slot name="header-right"/>
							</div>
						</div>
					</slot>
					<div class="modal-body">
						<slot/>
					</div>
					<slot name="footer" class="modal-footer"/>
				</div>
			</div>
		</div>
	</mounting-portal>
</template>

<script>
import {getScrollBarWidth} from "../vue-strap/utils/utils";

export default {
	name: "fp-modal",
	props: {
		value: {
			required: true,
			validator: function (value) {
				return typeof value === "boolean";
			}
		},
		title: {
			default: "[Title]",
			validator: function (value) {
				return typeof value === "string";
			}
		},
		transition: {
			required: true,
			validator: function (value) {
				return typeof value === "boolean";
			}
		},
		size: {
			default: "590",
			validator: function (value) {
				return ["sm", "590", "lg", "xl", "xxl"].indexOf(value) > -1;
			}
		},
		hideOnBackdropClick: {
			default: true,
			validator: function (value) {
				return typeof value === "boolean";
			}
		},
		lockedTop: {
			default: false,
			validator: function (value) {
				return typeof value === "boolean";
			}
		},
		placeInTop: {
			default: false,
			validator: function (value) {
				return typeof value === "boolean";
			}
		},
		showLeftRightHeaders: {
			default: false,
			validator: function (value) {
				return typeof value === "boolean";
			}
		},
		id: {
			default: null,
		}
	},
	data() {
		return {
			trans: false,
			val: null,
			backdrop: null,
			backdropZIndex: 1040,
			zIndex: 1041,
		}
	},
	computed: {
		modalClass() {
			const modalClass =  `modal-${this.size}`;
			let result = {'modal-dialog-centered': this.lockedTop };
			result[modalClass] = true;
			return result;
		}
	},
	watch: {
		trans(val, old) {
			if (val === old) return;

			this.$emit('trans', val);
			const el = this.$refs.modal;
			const body = document.body;
			if (this.backdrop == null) {
				const modals = this.$store.getters["getModals"];

				if (modals && modals.length) {
					this.zIndex += modals.length * 10;
					this.backdropZIndex += modals.length * 10;
				}
				
				this.backdrop = document.createElement("div");
				this.backdrop.classList.add("modal-backdrop");
                this.backdrop.classList.add("printWhite");
				this.backdrop.style.zIndex = this.backdropZIndex; 
				this.backdrop.classList.add("fade");
				this.backdrop.style.display = "none";
				body.append(this.backdrop);
			}

			const backdrop = this.backdrop;
			if (val) {//starting
				if (this.val) {
					this.$store.dispatch("ADD_MODAL", this);
					this.$emit("opening");
					el.querySelector('.modal-content').focus();
					el.style.display = 'block';
					setTimeout(() => el.classList.add('show'), 0);
					body.classList.add('modal-open');
					backdrop.classList.add('show');
					backdrop.classList.add("printWhite");
					backdrop.style.display = "block";

					if (getScrollBarWidth() !== 0) {
						body.style.paddingRight = getScrollBarWidth() + 'px';
					}
				} else {
					this.$emit("closing");

					el.classList.remove('show');
				}
			} else {//ending
				if (this.val) {
					if (this.hideOnBackdropClick) {
						this.$refs.modal.addEventListener("mousedown", e => {
							let path = e.path || (e.composedPath && e.composedPath());
							for (let i = 0; i < path.length; i++) {
								if (path[i] === null || path[i] === undefined || path[i].classList === null || path[i].classList === undefined) continue;
								if (path[i].classList.contains("select2-container") || path[i].classList.contains("modal-content")) return;
								if (path[i].classList.contains("modal")) {
									if (document.body.clientWidth >= path[i].clientWidth) {
										if (e.x >= path[i].clientWidth && e.x <= document.body.clientWidth) {
											return;
										}
									}
								}
							}
							
							this.$emit('outside-close');
							this.val = false;
						});
					}
				} else {
					if (this.hideOnBackdropClick) {
						this.$refs.modal.removeEventListener("mousedown", e => {
							this.val = false;
						});
					}

					this.$store.dispatch("REMOVE_MODAL", this);
				}
				
				this.$emit(this.val ? 'opened' : 'closed');
				if (!this.val) {
					el.style.display = 'none';

					backdrop.style.display = "none";
					backdrop.classList.remove('show');

					body.style.paddingRight = 0;
					body.classList.remove('modal-open');
				}
			}
			setTimeout(() => { this.trans = false; }, 150);

		},
		val(val, old) {
			this.$emit('input', val);
			if (old === null ? val === true : val !== old) this.trans = true;
		},
		value(val, old) {
			if (val !== old) this.val = val;
		},
		transition(val, old) {
			if (val !== old) this.trans = val;
		}
	},
	mounted() {
		this.val = this.value;
		this.trans = this.transition;
	},
	beforeDestroy() {
		const backdrop = this.backdrop;
		if (!backdrop) return;

		if (this.hideOnBackdropClick){
			backdrop.removeEventListener("mousedown", () => {
				this.val = false;
			});
		}
		document.body.removeChild(backdrop);
	}
}
</script>

<style lang="scss" scoped>
@import "FlexSystem/styles/variables";
@import "FlexSystem/styles/modal";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.modal::v-deep .margin-buttons {
	margin: 30px 30px 40px;
}

.modal-top {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
}
</style>

