import {ActionContext, Module} from "vuex";
import parseInternationalFloat from "./../parseInternationalFloat";
import {
	ProductStockApi
} from "../../_Services/Autogenerated_v4/V1/api/product-stock-api";
import {APIInternalFrontendAPIModelsProductStockProductStockTooltipModel} from "../../_Services/Autogenerated_v4/V1/models";
import {APIInternalFrontendAPIModelsProductPagedProductViewModel} from "../../_Services/Autogenerated_v4/V2/models";
let ProductStockService: ProductStockApi;

interface IStringDictionary<V> {
	[index: string]: V;
}
export interface IProductTableState {
	savedSearchParams: object;
	cachedTooltipStockQuantity: IStringDictionary<object>;
	stockAndPrices: any;
	stockAndPricesUpdated: number;
	productUids: string[];
}
function getConfig(key, rootState, defaultValue) {
	const keyValue = rootState.backendConfigs[key];
	if (keyValue !== null && keyValue !== undefined)
		return keyValue.toLowerCase() === "true";

	return defaultValue;
}
const productTableIndexState: Module<IProductTableState, unknown> = {
	namespaced: true,
    
	state: () => ({
		savedSearchParams: null,
		cachedTooltipStockQuantity: {},
		stockAndPrices: {},
		stockAndPricesUpdated: 0,
		productUids: []
	}),
	actions: {
		SET_PRODUCT_UIDS(context, uids) {
			context.commit("SET_PRODUCT_UIDS", uids);
		},
		async FETCH_STOCK_TOOLTIP_BY_PRODUCT_UID(context, productUid) {
			if (!ProductStockService) {
				ProductStockService = new ProductStockApi(context.rootGetters["getSwaggerAxiosOptions"]);
			}
			return (await ProductStockService.getStockTooltipByProductUid({productUid: productUid})).data;
		},
		async SET_STOCK_TOOLTIP_BY_PRODUCT_UID(context, args: {uid: string, obj: APIInternalFrontendAPIModelsProductStockProductStockTooltipModel[] }) {
			context.commit("SET_STOCK_TOOLTIP", args);
		},
		async getCachedTooltipStockQuantity(context, uid) {
			let stocks = context.getters.getCachedTooltipStockQuantity(uid);
			if (!stocks) {
				stocks = await context.dispatch("FETCH_STOCK_TOOLTIP_BY_PRODUCT_UID", uid);
				await context.dispatch("SET_STOCK_TOOLTIP_BY_PRODUCT_UID", { uid: uid, obj: stocks });
			}
			return stocks;
		},
		SET_SAVED_SEARCH_PARAMS(context, args) {
			context.commit("SET_SAVED_SEARCH_PARAMS", args);
		},
		async saveStockAndPrices(context, args: {rows: APIInternalFrontendAPIModelsProductPagedProductViewModel[], getStock: boolean}) {
			if (args.rows == undefined || args.rows.length == 0) return;
			const swaggerAxiosOptions = window.store.getters["getSwaggerAxiosOptions"];
            
			let last_response_len;
			// @ts-ignore
			$.ajax(UserSession.FrontendApiUri + "api/v1.0/product/get-price-and-stock-for-list", {
				method: "POST",
				dataType: "json",
				async: true,
				cache: false,
				crossDomain: true,
				contentType: "application/json; charset=utf-8",
				headers: { "Authorization": "Bearer " + await swaggerAxiosOptions.accessToken() },
				data: JSON.stringify({ getStock: args.getStock, pricesAndStockModels: args.rows.map((item, index) => { return {ProductUid: item.Uid, IsCombinationMaster: item.IsCombinationMaster}; }) }),
				xhrFields: {
					onprogress: function(e: ProgressEvent)
					{
						// @ts-ignore
						if (e.target.status !== 200) return;

						// @ts-ignore
						let this_response, response = e.currentTarget.response;
						if(last_response_len === undefined)
						{
							this_response = response;
							last_response_len = response.length;
						}
						else
						{
							this_response = response.substring(last_response_len);
							last_response_len = response.length;
						}
						const this_response_split = this_response.split("\n");

						for (let i = 0; i < this_response_split.length; i++)
						{
							const this_response_row_split = this_response_split[i].split("|");
							const item = {uid: "", costPrice: 0, salesPrice: 0, stockQuantity: 0};
							if (this_response_row_split.length > 0) {
								item.uid = this_response_row_split[0];
							}
							if (this_response_row_split.length > 1) {
								item.costPrice = parseInternationalFloat(this_response_row_split[1]);
							}
							if (this_response_row_split.length > 2) {
								item.salesPrice = parseInternationalFloat(this_response_row_split[2]);
							}
							if (this_response_row_split.length > 3) {
								item.stockQuantity = parseInternationalFloat(this_response_row_split[3]);
							}

							if (item.uid === undefined || item.uid === null || item.uid === "") continue;
							context.commit("SET_STOCK_AND_PRICE", item);
						}
					}
				}
			}).fail(function(data) {
				if (data.status !== 200) {
					context.commit("ADD_TOAST", { type: "danger", text: null, header: Resource.CouldNotRetrievePricesAndStock, until: 3000 }, {root: true});
				}
			})
				.done(function(data)
				{
					const this_response_split = data.split("\n");

					for (let i = 0; i < this_response_split.length; i++)
					{
						const this_response_row_split = this_response_split[i].split("|");
						const item = {uid: "", costPrice: 0, salesPrice: 0, stockQuantity: 0};
						if (this_response_row_split.length > 0) {
							item.uid = this_response_row_split[0];
						}
						if (this_response_row_split.length > 1) {
							item.costPrice = parseInternationalFloat(this_response_row_split[1]);
						}
						if (this_response_row_split.length > 2) {
							item.salesPrice = parseInternationalFloat(this_response_row_split[2]);
						}
						if (this_response_row_split.length > 3) {
							item.stockQuantity = parseInternationalFloat(this_response_row_split[3]);
						}
						if (item.uid === undefined || item.uid === null || item.uid === "") continue;
						context.commit("SET_STOCK_AND_PRICE", item);
					}
				});
		},
		resetStockAndPrices(context) {
			context.commit("RESET_STOCK_AND_PRICE");
		}
	},
	mutations: {
		SET_PRODUCT_UIDS(state, args) {
			state.productUids = args;
		},
		SET_SAVED_SEARCH_PARAMS(state, newState) {
			state.savedSearchParams = newState;
		},
		SET_STOCK_AND_PRICE(state, newState) {
			state.stockAndPrices[`_${newState.uid}_`] = {
				stockQuantity: newState.stockQuantity,
				costPrice: newState.costPrice,
				salesPrice: newState.salesPrice
			};
			state.stockAndPricesUpdated = Date.now();
		},
		RESET_STOCK_AND_PRICE(state) {
			state.stockAndPrices = {};
		},
		SET_STOCK_TOOLTIP(state, args: {uid: string, obj: APIInternalFrontendAPIModelsProductStockProductStockTooltipModel[] }) {
			state.cachedTooltipStockQuantity[args.uid] = args.obj;
		},
		RESET_STOCK_TOOLTIP(state) {
			state.cachedTooltipStockQuantity = {};
		},
	},

	getters: {
		getCachedTooltipStockQuantity(state) {
			return (uid: string) => {
				return state.cachedTooltipStockQuantity[uid];
			};
		},
		getSavedSearchParams(state) {
			return state.savedSearchParams;
		},
		getProductUids(state) {
			return state.productUids;
		},
		ClientUid(state, getters, rootState) { return getConfig("Product.ColumnOptions.ClientUid", rootState, true); },
		Number(state, getters, rootState) { return getConfig("Product.ColumnOptions.Number", rootState, true); },
		Name(state, getters, rootState) { return getConfig("Product.ColumnOptions.Name", rootState, true); },
		SupplierNumber(state, getters, rootState) { return getConfig("Product.ColumnOptions.SupplierNumber", rootState, false); },
		SupplierName(state, getters, rootState) { return getConfig("Product.ColumnOptions.SupplierName", rootState, false); },
		BrandName(state, getters, rootState) { return getConfig("Product.ColumnOptions.BrandName", rootState, false); },
		DisplayGroupName(state, getters, rootState) { return getConfig("Product.ColumnOptions.DisplayGroupName", rootState, false); },
		FinancialGroupName(state, getters, rootState) { return getConfig("Product.ColumnOptions.FinancialGroupName", rootState, false); },
		Size(state, getters, rootState) { return getConfig("Product.ColumnOptions.Size", rootState, true); },
		Color(state, getters, rootState) { return getConfig("Product.ColumnOptions.Color", rootState, true); },
		Combination1(state, getters, rootState) { return getConfig("Product.ColumnOptions.Combination1", rootState, true); },
		Combination2(state, getters, rootState) { return getConfig("Product.ColumnOptions.Combination2", rootState, true); },
		Combination3(state, getters, rootState) { return getConfig("Product.ColumnOptions.Combination3", rootState, true); },
		Combination4(state, getters, rootState) { return getConfig("Product.ColumnOptions.Combination4", rootState, true); },
		CombinationAction(state, getters, rootState) { return getConfig("Product.ColumnOptions.CombinationAction", rootState, true); },
		Text1(state, getters, rootState) { return getConfig("Product.ColumnOptions.Text1", rootState, false); },
		Text2(state, getters, rootState) { return getConfig("Product.ColumnOptions.Text2", rootState, false); },
		Text3(state, getters, rootState) { return getConfig("Product.ColumnOptions.Text3", rootState, false); },
		Text4(state, getters, rootState) { return getConfig("Product.ColumnOptions.Text4", rootState, false); },
		Number1(state, getters, rootState) { return getConfig("Product.ColumnOptions.Number1", rootState, false); },
		Number2(state, getters, rootState) { return getConfig("Product.ColumnOptions.Number2", rootState, false); },
		Date1(state, getters, rootState) { return getConfig("Product.ColumnOptions.Date1", rootState, false); },
		Memo1(state, getters, rootState) { return getConfig("Product.ColumnOptions.Memo1", rootState, false); },
		SyncToWeb(state, getters, rootState) { return getConfig("Product.ColumnOptions.SyncToWeb", rootState, false); },
		IsActive(state, getters, rootState) { return getConfig("Product.ColumnOptions.IsActive", rootState, true); },
		Variant(state, getters, rootState) { return getConfig("Product.ColumnOptions.Variant", rootState, true); },
		SalesPrice(state, getters, rootState) { return getConfig("Product.ColumnOptions.SalesPrice", rootState, true); },
		CostPrice(state, getters, rootState) { return getConfig("Product.ColumnOptions.CostPrice", rootState, true); },
		IsVatFree(state, getters, rootState) { return getConfig("Product.ColumnOptions.IsVatFree", rootState, false); },
		StockQuantity(state, getters, rootState) { return getConfig("Product.ColumnOptions.StockQuantity", rootState, true); },
		MinStock(state, getters, rootState) { return getConfig("Product.ColumnOptions.MinStock", rootState, false); },
		MaxStock(state, getters, rootState) { return getConfig("Product.ColumnOptions.MaxStock", rootState, false); },
		UnitType(state, getters, rootState) { return getConfig("Product.ColumnOptions.UnitType", rootState, false); },
		getColumnVisibility(state, getters, rootState, rootGetters) {
			const combinations = rootGetters["productConfigState/getCombinations"];
			const extendedProductInfo = rootGetters["productConfigState/getExtendedProductInfo"];
			const stockConfigs = rootGetters["productStockConfigState/getConfigs"];
            
			return {
				ClientUid: getters.ClientUid,
				Number: getters.Number,
				Name: getters.Name,
				SupplierNumber: getters.SupplierNumber,
				SupplierName: getters.SupplierName,
				BrandName: getters.BrandName,
				DisplayGroupName: getters.DisplayGroupName,
				FinancialGroupName: getters.FinancialGroupName,
				Size: getters.Size,
				Color: getters.Color,
				Combination1: getters.Combination1 && combinations.combination1 && combinations.combination1.Active,
				Combination2: getters.Combination2 && combinations.combination2 && combinations.combination2.Active,
				Combination3: getters.Combination3 && combinations.combination3 && combinations.combination3.Active,
				Combination4: getters.Combination4 && combinations.combination4 && combinations.combination4.Active,
				Text1: getters.Text1 && extendedProductInfo.extendedProductInfoActive,
				Text2: getters.Text2 && extendedProductInfo.extendedProductInfoActive,
				Text3: getters.Text3 && extendedProductInfo.extendedProductInfoActive,
				Text4: getters.Text4 && extendedProductInfo.extendedProductInfoActive,
				Number1: getters.Number1 && extendedProductInfo.extendedProductInfoActive,
				Number2: getters.Number2 && extendedProductInfo.extendedProductInfoActive,
				Date1: getters.Date1 && extendedProductInfo.extendedProductInfoActive,
				Memo1: getters.Memo1 && extendedProductInfo.extendedProductInfoActive,
				SyncToWeb: getters.SyncToWeb,
				IsActive: getters.IsActive,
				Variant: getters.Variant,
				SalesPrice: getters.SalesPrice,
				CostPrice: getters.CostPrice,
				IsVatFree: getters.IsVatFree,
				StockQuantity: getters.StockQuantity && stockConfigs.stockActive,
				MinStock: getters.MinStock && stockConfigs.stockActive,
				MaxStock: getters.MaxStock && stockConfigs.stockActive,
				UnitType: getters.UnitType,
			};
		},
		getConfigVisibility(state, getters, rootState, rootGetters) {
			const combinations = rootGetters["productConfigState/getCombinations"];
			const extendedProductInfo = rootGetters["productConfigState/getExtendedProductInfo"];
			const stockConfigs = rootGetters["productStockConfigState/getConfigs"];

			return {
				ClientUid: true,
				Number: true,
				Name: true,
				SupplierNumber: true,
				SupplierName: true,
				BrandName: true,
				DisplayGroupName: true,
				FinancialGroupName: true,
				Size: true,
				Color: true,
				Combination1: combinations.combination1 && combinations.combination1.Active,
				Combination2: combinations.combination2 && combinations.combination2.Active,
				Combination3: combinations.combination3 && combinations.combination3.Active,
				Combination4: combinations.combination4 && combinations.combination4.Active,
				Text1: extendedProductInfo.extendedProductInfoActive,
				Text2: extendedProductInfo.extendedProductInfoActive,
				Text3: extendedProductInfo.extendedProductInfoActive,
				Text4: extendedProductInfo.extendedProductInfoActive,
				Number1: extendedProductInfo.extendedProductInfoActive,
				Number2: extendedProductInfo.extendedProductInfoActive,
				Date1: extendedProductInfo.extendedProductInfoActive,
				Memo1: extendedProductInfo.extendedProductInfoActive,
				SyncToWeb: true,
				IsActive: true,
				Variant: true,
				SalesPrice: true,
				CostPrice: true,
				IsVatFree: true,
				StockQuantity: stockConfigs.stockActive,
				MinStock: stockConfigs.stockActive,
				MaxStock: stockConfigs.stockActive,
				UnitType: true,
			};
		},
		getStockAndPricesUpdated(state) {
			return state.stockAndPricesUpdated;
		},
		getStockQuantity(state) {
			return (uid) => {
				if (!state.stockAndPrices[`_${uid}_`]) return null;
				return state.stockAndPrices[`_${uid}_`].stockQuantity;
			};
		},
		getCostPrice(state) {
			return (uid) => {
				if (!state.stockAndPrices[`_${uid}_`]) return null;
				return state.stockAndPrices[`_${uid}_`].costPrice;
			};
		},
		getSalesPrice(state) {
			return (uid) => {
				if (!state.stockAndPrices[`_${uid}_`]) return null;
				return state.stockAndPrices[`_${uid}_`].salesPrice;
			};
		}
	}
};

export default productTableIndexState;