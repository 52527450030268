import {Module} from "vuex";

const activeAddonsState: Module<any, any> = {
    namespaced: true,

    state: () => ({}),
    actions: {
    },
    mutations: {
    },
    getters: {
        getIsSimpleStockActive(state, getters, rootState, rootGetters) {
            return rootState.activeAddons["8e818b29-74d9-4dc8-a13b-b0f99739a1d5"] || false;
        },
        getIsStatusAndScanActive(state, getters, rootState, rootGetters) {
            return rootState.activeAddons["42fcd9ac-2034-46a9-8438-3b5078af23d1"] || false;
        },
        getIsLogisticsActive(state, getters, rootState, rootGetters) {
            return rootState.activeAddons["1205c5d0-a88a-4549-ba54-e761938dc03b"] || false;
        },
        getIsProductListActive(state, getters, rootState, rootGetters) {
            return rootState.activeAddons["d3b0a40f-8c18-4146-b4bf-fd8dfac28ad0"] || false;
        },
        getIsWarrantyActive(state, getters, rootState, rootGetters) {
            return rootState.activeAddons["8552bb2b-6210-4548-9343-0d61e3c24269"] || false;
        },
        getIsResourceActive(state, getters, rootState, rootGetters) {
            return rootState.activeAddons["22237fb5-6fa0-48aa-9f3a-9c6e70a6b9d4"] || false;
        },
        getIsFlexVoucherActive(state, getters, rootState, rootGetters) {
            return rootState.activeAddons["52d9e242-924a-4ac9-a277-f3c4bc88128b"] || false;
        },
        getIsCampaignActive(state, getters, rootState, rootGetters) {
            return rootState.activeAddons["50af6a06-02f3-4a79-9b03-b616fea518f4"] || false;
        },
    }
}

export default activeAddonsState;