import {Module} from "vuex";

const backendConfigState: Module<any, any> = {
    namespaced: true,

    state: () => ({}),
    actions: {
    },
    mutations: {
    },
    getters: {
        getBackendConfigDefaultPage(state, getters, rootState, rootGetters) {
            return rootGetters.getBackendConfigString("UserAccess.DefaultPage", "/Dashboard/Overview") || "/Dashboard/Overview";
        },
    }
}

export default backendConfigState;