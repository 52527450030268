import {Module} from "vuex";

interface IFlexDeployBulkEditTableState {
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const flexDeployBulkEditTableState: Module<IFlexDeployBulkEditTableState, any> = {
    namespaced: true,
    
    state: () => ({
    }),
    actions: {
    },
    mutations: {
    },
    getters: {
		ApplicationTypeUID(state, getters, rootState) { return getConfig("DeploymentBulkEdit.ColumnOptions.ApplicationTypeUID", rootState, true); },
		VersionNumber(state, getters, rootState) { return getConfig("DeploymentBulkEdit.ColumnOptions.VersionNumber", rootState, true); },
		ReleaseTypeUID(state, getters, rootState) { return getConfig("DeploymentBulkEdit.ColumnOptions.ReleaseTypeUID", rootState, true); },
		DBScript(state, getters, rootState) { return getConfig("DeploymentBulkEdit.ColumnOptions.DBScript", rootState, true); },
		APPFileUID(state, getters, rootState) { return getConfig("DeploymentBulkEdit.ColumnOptions.APPFileUID", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                ApplicationTypeUID: getters.ApplicationTypeUID,
                VersionNumber: getters.VersionNumber,
                ReleaseTypeUID: getters.ReleaseTypeUID,
                DBScript: getters.DBScript,
                APPFileUID: getters.APPFileUID,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                ApplicationTypeUID: true,
                VersionNumber: true,
                ReleaseTypeUID: true,
                DBScript: true,
                APPFileUID: true,
            };
        }
    }
}

export default flexDeployBulkEditTableState;