














import {UserApi} from "@services/Autogenerated_v4/V1/api/user-api";

export default {
	name: "fp-input-password",
	props: {
		id: {
			default: null,
		},
		value: {
			default: null,
		},
		disabled: {
			default: false,
		},
		hasPasswordGenerator: {
			default: false,
		},
	},
	data() {
		return {
			hidden: true,
			val: this.value,
			UserService: new UserApi(this.$swaggerAxiosOptions),
		}
	},
	watch: {
		val: function(val, oldVal) {
			this.$emit("input", val)
		},
		value: function(val, oldVal) {
			this.val = val;
		}
	},
	methods: {
		async generatePassword() {
			let result = await this.UserService.generatePassword();
			this.validation.$model = result.data;
		},
		blur() {
			this.$emit("blur");
		},
		hiddenToggle() {
			this.hidden = !this.hidden;
		}
	}
}
