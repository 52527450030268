import {Module} from "vuex";

interface IOrderTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const orderTableIndexState: Module<IOrderTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        ID(state, getters, rootState) { return getConfig("Order.ColumnOptions.ID", rootState, true); },
        DateCreated(state, getters, rootState) { return getConfig("Order.ColumnOptions.DateCreated", rootState, true); },
        VendorName(state, getters, rootState) { return getConfig("Order.ColumnOptions.VendorName", rootState, true); },
        ExternalInvoiceNumber(state, getters, rootState) { return getConfig("Order.ColumnOptions.ExternalInvoiceNumber", rootState, true); },
        Memo(state, getters, rootState) { return getConfig("Order.ColumnOptions.Memo", rootState, true); },
        TotalAmount(state, getters, rootState) { return getConfig("Order.ColumnOptions.TotalAmount", rootState, true); },
        UserName(state, getters, rootState) { return getConfig("Order.ColumnOptions.UserName", rootState, true); },
        Status(state, getters, rootState) { return getConfig("Order.ColumnOptions.Status", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                ID: getters.ID,
                DateCreated: getters.DateCreated,
                VendorName: getters.VendorName,
                ExternalInvoiceNumber: getters.ExternalInvoiceNumber,
                Memo: getters.Memo,
                TotalAmount: getters.TotalAmount,
                UserName: getters.UserName,
                Status: getters.Status,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                ID: true,
                DateCreated: true,
                VendorName: true,
                ExternalInvoiceNumber: true,
                Memo: true,
                TotalAmount: true,
                UserName: true,
                Status: true,
            };
        }
    }
}

export default orderTableIndexState;