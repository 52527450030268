import {Module} from "vuex";

interface ICustomerGroupTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const customerGroupTableIndexState: Module<ICustomerGroupTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
		ClientUid(state, getters, rootState) { return getConfig("CustomerGroup.ColumnOptions.ClientUid", rootState, true); },
        Id(state, getters, rootState) { return getConfig("CustomerGroup.ColumnOptions.Id", rootState, true); },
        Name(state, getters, rootState) { return getConfig("CustomerGroup.ColumnOptions.Name", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUid: getters.ClientUid,
                Id: getters.Id,
                Name: getters.Name,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUid: true,
                Id: true,
                Name: true,
            };
        }
    }
}

export default customerGroupTableIndexState;