import {Module} from "vuex";

interface ICampaignTableIndexState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const campaignTableIndexState: Module<ICampaignTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
		ClientUid(state, getters, rootState) { return getConfig("Campaigns.ColumnOptions.ClientUid", rootState, true); },
        Name(state, getters, rootState) { return getConfig("Campaigns.ColumnOptions.Name", rootState, true); },
        FromDate(state, getters, rootState) { return getConfig("Campaigns.ColumnOptions.FromDate", rootState, true); },
        ToDate(state, getters, rootState) { return getConfig("Campaigns.ColumnOptions.ToDate", rootState, true); },
        ProductCount(state, getters, rootState) { return getConfig("Campaigns.ColumnOptions.ProductCount", rootState, true); },
		IsRunning(state, getters, rootState) { return getConfig("Campaigns.ColumnOptions.IsActive", rootState, true); },
		IsActive(state, getters, rootState) { return getConfig("Campaigns.ColumnOptions.IsActive", rootState, true); },
        CreatedBy(state, getters, rootState) { return getConfig("Campaigns.ColumnOptions.CreatedBy", rootState, true); },
        CreatedByType(state, getters, rootState) { return getConfig("Campaigns.ColumnOptions.CreatedByType", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUid: getters.ClientUid,
                Name: getters.Name,
                FromDate: getters.FromDate,
                ToDate: getters.ToDate,
                ProductCount: getters.ProductCount,
				IsRunning: getters.IsRunning,
                IsActive: getters.IsActive,
                CreatedBy: getters.CreatedBy,
                CreatedByType: getters.CreatedByType,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUid: true,
                Name: true,
                FromDate: true,
                ToDate: true,
                ProductCount: true,
				IsRunning: true,
                IsActive: true,
                CreatedBy: true,
                CreatedByType: true,
            };
        }
    }
}

export default campaignTableIndexState;