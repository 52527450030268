const checkIsNumberArray = ["1","2","3","4","5","6","7","8","9","0",',','.','-'];

function parseInternationalFloat(input)
{
	if (typeof input == "number") return input;
	if (input === undefined || input === null) return NaN; 
	
	while ((input.lastIndexOf(".") === (input.length - 1)) && input.length > 0)
	{
		input = input.substring(0, input.length - 1);
	}
	
	
	const inputArray = input.split("");
	for (let i = 0; i < inputArray.length; i++) {
		if (checkIsNumberArray.indexOf(inputArray[i].toString()) === -1)
			return NaN;
	}

	if (input) {
		const lastIndexOfDecimal = input.lastIndexOf(",");
		const lastIndexOfComma = input.lastIndexOf(".");
		const correctDecimal = (lastIndexOfComma > lastIndexOfDecimal) ? "." : ",";
		const correctDecimalLastIndex = (lastIndexOfComma > lastIndexOfDecimal) ? lastIndexOfComma : lastIndexOfDecimal;
		for (let i = 0; i < inputArray.length; i++) {
			if (i !== correctDecimalLastIndex) {
				if (inputArray[i] === correctDecimal) {
					inputArray[i] = "";
				}
			}
		}
		input = inputArray.join("");

		return accounting.unformat(input, correctDecimal);
	}

	return NaN;
}

export default parseInternationalFloat;