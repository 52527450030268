/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIModelsUnitCreateUnitModelResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsUnitEditUnitModelResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsUnitUnitTableViewModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsUnitUnitVersionTooltipModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsUnitUnitViewModel } from '../models';
// @ts-ignore
import { MicrosoftAspNetCoreMvcProblemDetails } from '../models';
/**
 * UnitApi - axios parameter creator
 * @export
 */
export const UnitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel} [APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnit: async (APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel?: APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/unit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (clientUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/unit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageUid] 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll_1: async (languageUid?: string, clientUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/unit/get-all-for-table`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {number} [license] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreateUnitModel: async (clientUid?: string, license?: number, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/unit/create-unit-model`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }

            if (license !== undefined) {
                localVarQueryParameter['license'] = license;
            }

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionary: async (clientUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/unit/get-dictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [type] 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryByType: async (type?: number, clientUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/unit/get-dictionary-by-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [clientUid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditUnitModel: async (uid: string, clientUid?: string, languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getEditUnitModel', 'uid', uid)
            const localVarPath = `/api/v1.0/unit/{uid}/edit-model`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingle: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getSingle', 'uid', uid)
            const localVarPath = `/api/v1.0/unit/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitName: async (uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/unit/get-unit-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitTooltipByClientUid: async (clientUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/unit/get-unit-tooltip-by-client-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {number} installCode 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installUnit: async (uid: string, installCode: number, clientUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('installUnit', 'uid', uid)
            // verify required parameter 'installCode' is not null or undefined
            assertParamExists('installUnit', 'installCode', installCode)
            const localVarPath = `/api/v1.0/unit/{uid}/install/{installCode}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)))
                .replace(`{${"installCode"}}`, encodeURIComponent(String(installCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel} [APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUnit: async (uid: string, APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel?: APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('updateUnit', 'uid', uid)
            const localVarPath = `/api/v1.0/unit/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitApi - functional programming interface
 * @export
 */
export const UnitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel} [APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUnit(APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel?: APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUnit(APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(clientUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsUnitUnitViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(clientUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [languageUid] 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll_1(languageUid?: string, clientUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsUnitUnitTableViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll_1(languageUid, clientUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {number} [license] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreateUnitModel(clientUid?: string, license?: number, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsUnitCreateUnitModelResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreateUnitModel(clientUid, license, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionary(clientUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string | undefined; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionary(clientUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [type] 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionaryByType(type?: number, clientUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string | undefined; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionaryByType(type, clientUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [clientUid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditUnitModel(uid: string, clientUid?: string, languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsUnitEditUnitModelResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditUnitModel(uid, clientUid, languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingle(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsUnitUnitViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingle(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnitName(uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnitName(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnitTooltipByClientUid(clientUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsUnitUnitVersionTooltipModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnitTooltipByClientUid(clientUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {number} installCode 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installUnit(uid: string, installCode: number, clientUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installUnit(uid, installCode, clientUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel} [APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUnit(uid: string, APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel?: APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUnit(uid, APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitApi - factory interface
 * @export
 */
export const UnitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitApiFp(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel} [APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnit(APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel?: APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.createUnit(APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(clientUid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsUnitUnitViewModel>> {
            return localVarFp.getAll(clientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageUid] 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll_1(languageUid?: string, clientUid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsUnitUnitTableViewModel>> {
            return localVarFp.getAll_1(languageUid, clientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {number} [license] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreateUnitModel(clientUid?: string, license?: number, languageUid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsUnitCreateUnitModelResponseModel> {
            return localVarFp.getCreateUnitModel(clientUid, license, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionary(clientUid?: string, options?: any): AxiosPromise<{ [key: string]: string | undefined; }> {
            return localVarFp.getDictionary(clientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [type] 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryByType(type?: number, clientUid?: string, options?: any): AxiosPromise<{ [key: string]: string | undefined; }> {
            return localVarFp.getDictionaryByType(type, clientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {string} [clientUid] 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditUnitModel(uid: string, clientUid?: string, languageUid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsUnitEditUnitModelResponseModel> {
            return localVarFp.getEditUnitModel(uid, clientUid, languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingle(uid: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsUnitUnitViewModel> {
            return localVarFp.getSingle(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitName(uid?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getUnitName(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitTooltipByClientUid(clientUid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsUnitUnitVersionTooltipModel>> {
            return localVarFp.getUnitTooltipByClientUid(clientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {number} installCode 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installUnit(uid: string, installCode: number, clientUid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.installUnit(uid, installCode, clientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel} [APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUnit(uid: string, APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel?: APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.updateUnit(uid, APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createUnit operation in UnitApi.
 * @export
 * @interface UnitApiCreateUnitRequest
 */
export interface UnitApiCreateUnitRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel}
     * @memberof UnitApiCreateUnit
     */
    readonly APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel?: APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel
}

/**
 * Request parameters for getAll operation in UnitApi.
 * @export
 * @interface UnitApiGetAllRequest
 */
export interface UnitApiGetAllRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitApiGetAll
     */
    readonly clientUid?: string
}

/**
 * Request parameters for getAll_1 operation in UnitApi.
 * @export
 * @interface UnitApiGetAll0Request
 */
export interface UnitApiGetAll0Request {
    /**
     * 
     * @type {string}
     * @memberof UnitApiGetAll0
     */
    readonly languageUid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitApiGetAll0
     */
    readonly clientUid?: string
}

/**
 * Request parameters for getCreateUnitModel operation in UnitApi.
 * @export
 * @interface UnitApiGetCreateUnitModelRequest
 */
export interface UnitApiGetCreateUnitModelRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitApiGetCreateUnitModel
     */
    readonly clientUid?: string

    /**
     * 
     * @type {number}
     * @memberof UnitApiGetCreateUnitModel
     */
    readonly license?: number

    /**
     * 
     * @type {string}
     * @memberof UnitApiGetCreateUnitModel
     */
    readonly languageUid?: string
}

/**
 * Request parameters for getDictionary operation in UnitApi.
 * @export
 * @interface UnitApiGetDictionaryRequest
 */
export interface UnitApiGetDictionaryRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitApiGetDictionary
     */
    readonly clientUid?: string
}

/**
 * Request parameters for getDictionaryByType operation in UnitApi.
 * @export
 * @interface UnitApiGetDictionaryByTypeRequest
 */
export interface UnitApiGetDictionaryByTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitApiGetDictionaryByType
     */
    readonly type?: number

    /**
     * 
     * @type {string}
     * @memberof UnitApiGetDictionaryByType
     */
    readonly clientUid?: string
}

/**
 * Request parameters for getEditUnitModel operation in UnitApi.
 * @export
 * @interface UnitApiGetEditUnitModelRequest
 */
export interface UnitApiGetEditUnitModelRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitApiGetEditUnitModel
     */
    readonly uid: string

    /**
     * 
     * @type {string}
     * @memberof UnitApiGetEditUnitModel
     */
    readonly clientUid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitApiGetEditUnitModel
     */
    readonly languageUid?: string
}

/**
 * Request parameters for getSingle operation in UnitApi.
 * @export
 * @interface UnitApiGetSingleRequest
 */
export interface UnitApiGetSingleRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitApiGetSingle
     */
    readonly uid: string
}

/**
 * Request parameters for getUnitName operation in UnitApi.
 * @export
 * @interface UnitApiGetUnitNameRequest
 */
export interface UnitApiGetUnitNameRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitApiGetUnitName
     */
    readonly uid?: string
}

/**
 * Request parameters for getUnitTooltipByClientUid operation in UnitApi.
 * @export
 * @interface UnitApiGetUnitTooltipByClientUidRequest
 */
export interface UnitApiGetUnitTooltipByClientUidRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitApiGetUnitTooltipByClientUid
     */
    readonly clientUid?: string
}

/**
 * Request parameters for installUnit operation in UnitApi.
 * @export
 * @interface UnitApiInstallUnitRequest
 */
export interface UnitApiInstallUnitRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitApiInstallUnit
     */
    readonly uid: string

    /**
     * 
     * @type {number}
     * @memberof UnitApiInstallUnit
     */
    readonly installCode: number

    /**
     * 
     * @type {string}
     * @memberof UnitApiInstallUnit
     */
    readonly clientUid?: string
}

/**
 * Request parameters for updateUnit operation in UnitApi.
 * @export
 * @interface UnitApiUpdateUnitRequest
 */
export interface UnitApiUpdateUnitRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitApiUpdateUnit
     */
    readonly uid: string

    /**
     * 
     * @type {APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel}
     * @memberof UnitApiUpdateUnit
     */
    readonly APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel?: APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel
}

/**
 * UnitApi - object-oriented interface
 * @export
 * @class UnitApi
 * @extends {BaseAPI}
 */
export class UnitApi extends BaseAPI {
    /**
     * 
     * @param {UnitApiCreateUnitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public createUnit(requestParameters: UnitApiCreateUnitRequest = {}, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).createUnit(requestParameters.APIInternalFrontendAPIModelsUnitPostCreateUnitRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitApiGetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public getAll(requestParameters: UnitApiGetAllRequest = {}, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).getAll(requestParameters.clientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitApiGetAll0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public getAll_1(requestParameters: UnitApiGetAll0Request = {}, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).getAll_1(requestParameters.languageUid, requestParameters.clientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitApiGetCreateUnitModelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public getCreateUnitModel(requestParameters: UnitApiGetCreateUnitModelRequest = {}, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).getCreateUnitModel(requestParameters.clientUid, requestParameters.license, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitApiGetDictionaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public getDictionary(requestParameters: UnitApiGetDictionaryRequest = {}, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).getDictionary(requestParameters.clientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitApiGetDictionaryByTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public getDictionaryByType(requestParameters: UnitApiGetDictionaryByTypeRequest = {}, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).getDictionaryByType(requestParameters.type, requestParameters.clientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitApiGetEditUnitModelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public getEditUnitModel(requestParameters: UnitApiGetEditUnitModelRequest, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).getEditUnitModel(requestParameters.uid, requestParameters.clientUid, requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitApiGetSingleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public getSingle(requestParameters: UnitApiGetSingleRequest, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).getSingle(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitApiGetUnitNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public getUnitName(requestParameters: UnitApiGetUnitNameRequest = {}, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).getUnitName(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitApiGetUnitTooltipByClientUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public getUnitTooltipByClientUid(requestParameters: UnitApiGetUnitTooltipByClientUidRequest = {}, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).getUnitTooltipByClientUid(requestParameters.clientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitApiInstallUnitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public installUnit(requestParameters: UnitApiInstallUnitRequest, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).installUnit(requestParameters.uid, requestParameters.installCode, requestParameters.clientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitApiUpdateUnitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public updateUnit(requestParameters: UnitApiUpdateUnitRequest, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).updateUnit(requestParameters.uid, requestParameters.APIInternalFrontendAPIModelsUnitPostUpdateUnitRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}
