import {Validation} from "vuelidate/vuelidate";
import Vue from "vue";

interface ValidationEvaluation {
    [ruleName: string]: boolean
}
interface ValidationGroups {
    [groupName: string]: Validation & ValidationProperties<any>
}
type ValidationProperties<V> = {
    [P in Exclude<keyof V, '$v'>]?: Validation & ValidationProperties<V[P]> & ValidationEvaluation
}


export default function vuelidateToErrorList(validation: ValidationProperties<Vue> & ValidationGroups & Validation | any, returnAsObject: boolean = false): string[] | {field: string, error: string} {
    const errors = [];
    if (validation != null) {
        if (validation.$anyError) {
            let keys = Object.keys(validation)
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                if (!key.startsWith("$") || key === "$each") {
                    let value = validation[key];

                    let errorTypes = value && value.$params ? Object.keys(value.$params) : [];

                    for (let i = 0; i < errorTypes.length; i++) {
                        let errorType = errorTypes[i];
                        let errorParams = value.$params[errorType];
                        let error = value[errorType];
                        if (!error && errorParams && errorParams.errorText) {
                            let errorText = errorParams.errorText
                            if (errorText.indexOf("{0}") > -1) {
                                errorText = errorText.replace("{0}", value.$model)
                            }
                            errors.push(errorText);
                        }
                    }

                    errors.push(...vuelidateToErrorList(value as any, returnAsObject) as Array<any>);
                }
            }
        }
    }
    
    return errors
}