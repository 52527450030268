import {ActionContext, Module} from "vuex";
import ClientType from "../../_Enums/ClientType";


const overviewIndexState: Module<any, any> = {
    namespaced: true,
    getters: {
        dateRange(state, getters, rootState, rootGetters) {
            return rootGetters.getBackendConfigString("Overview.DateRange", "0d");
        },
        sideTable(state, getters, rootState, rootGetters) {
            let sideTableDefault;
            if (rootGetters.getClientType === ClientType.MainClient) {
                sideTableDefault = "Stores";
            }
            else {
                sideTableDefault = "Units";
            }
            return rootGetters.getBackendConfigString("Overview.SideTable", sideTableDefault)
        },
        box1(state, getters, rootState, rootGetters) {
            return rootGetters.getBackendConfigString("Overview.Custombox.1", "1");
        },
        box2(state, getters, rootState, rootGetters) {
            return rootGetters.getBackendConfigString("Overview.Custombox.2", "2");
        },
        box3(state, getters, rootState, rootGetters) {
            return rootGetters.getBackendConfigString("Overview.Custombox.3", "6");
        },
        box4(state, getters, rootState, rootGetters) {
            return rootGetters.getBackendConfigString("Overview.Custombox.4", "13");
        },
        getState(state, getters) {
            function replaceOldDefaults(value) {
                if (!value) return null;
                return value
                    .replace("Today", "1")
                    .replace("1d", "1")
                    .replace("Yesterday", "2")
                    .replace("FromStartOfMonth", "6")
                    .replace("FromStartOfYear", "13")
                    .replace("7d", "8")
                    .replace("1m", "9")
                    .replace("6m", "10")
                    .replace("3y", "12")
                    .replace("12", "11")

            }
            
            return {
                range: replaceOldDefaults(getters.dateRange),
                sideTable: replaceOldDefaults(getters.sideTable),
                box1: replaceOldDefaults(getters.box1),
                box2: replaceOldDefaults(getters.box2),
                box3: replaceOldDefaults(getters.box3),
                box4: replaceOldDefaults(getters.box4),
            };
        }
    }
}

export default overviewIndexState;