/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesAddonLink } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsAddonAddonGetAllResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsAddonAddonPostListViewModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsAddonAddonViewModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsAddonLinkAddonLinkViewModel } from '../models';
// @ts-ignore
import { MicrosoftAspNetCoreMvcProblemDetails } from '../models';
/**
 * AddonApi - axios parameter creator
 * @export
 */
export const AddonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/addon/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {{ [key: string]: boolean; }} [units] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateOnClient: async (uid: string, units?: { [key: string]: boolean; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('activateOnClient', 'uid', uid)
            const localVarPath = `/api/v1.0/addon/{uid}/activate-on-client`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (units !== undefined) {
                localVarQueryParameter['units'] = units;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {{ [key: string]: boolean | undefined; }} [request_body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateOnClientByClientUid: async (clientUid?: string, uid?: string, request_body?: { [key: string]: boolean | undefined; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/addon/activate-on-client_by_client_uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request_body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel} [APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateStoreboxOnClient: async (uid: string, APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel?: APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('activateStoreboxOnClient', 'uid', uid)
            const localVarPath = `/api/v1.0/addon/{uid}/activate-storebox-on-client`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUnitLink: async (clientUid?: string, uid?: string, unitUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/addon/activate_unit_link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (unitUid !== undefined) {
                localVarQueryParameter['unitUid'] = unitUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addonGET: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('addonGET', 'uid', uid)
            const localVarPath = `/api/v1.0/addon/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAddonAddonPostListViewModel} [APIInternalFrontendAPIModelsAddonAddonPostListViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (APIInternalFrontendAPIModelsAddonAddonPostListViewModel?: APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/addon/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsAddonAddonPostListViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateOnClient: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('deactivateOnClient', 'uid', uid)
            const localVarPath = `/api/v1.0/addon/{uid}/deactivate-on-client`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateOnClientByClientUid: async (clientUid?: string, uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/addon/deactivate-on-client_by_client_uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUnitLink: async (clientUid?: string, uid?: string, unitUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/addon/deactivate_unit_link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (unitUid !== undefined) {
                localVarQueryParameter['unitUid'] = unitUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [forceNewCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveDictionary: async (forceNewCache?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/addon/get-active-dictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (forceNewCache !== undefined) {
                localVarQueryParameter['forceNewCache'] = forceNewCache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (clientUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/addon/get-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLinks: async (clientUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/addon/get-all-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientUid !== undefined) {
                localVarQueryParameter['clientUid'] = clientUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        links: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('links', 'uid', uid)
            const localVarPath = `/api/v1.0/addon/{uid}/links`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAddonAddonPostListViewModel} [APIInternalFrontendAPIModelsAddonAddonPostListViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (APIInternalFrontendAPIModelsAddonAddonPostListViewModel?: APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/addon/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsAddonAddonPostListViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [addonUid] 
         * @param {string} [imageLink] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImageLink: async (addonUid?: string, imageLink?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/addon/update-image-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (addonUid !== undefined) {
                localVarQueryParameter['addonUid'] = addonUid;
            }

            if (imageLink !== undefined) {
                localVarQueryParameter['imageLink'] = imageLink;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddonApi - functional programming interface
 * @export
 */
export const AddonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {{ [key: string]: boolean; }} [units] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateOnClient(uid: string, units?: { [key: string]: boolean; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateOnClient(uid, units, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {{ [key: string]: boolean | undefined; }} [request_body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateOnClientByClientUid(clientUid?: string, uid?: string, request_body?: { [key: string]: boolean | undefined; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateOnClientByClientUid(clientUid, uid, request_body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel} [APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateStoreboxOnClient(uid: string, APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel?: APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateStoreboxOnClient(uid, APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateUnitLink(clientUid?: string, uid?: string, unitUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateUnitLink(clientUid, uid, unitUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addonGET(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsAddonAddonViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addonGET(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAddonAddonPostListViewModel} [APIInternalFrontendAPIModelsAddonAddonPostListViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(APIInternalFrontendAPIModelsAddonAddonPostListViewModel?: APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateOnClient(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateOnClient(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateOnClientByClientUid(clientUid?: string, uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateOnClientByClientUid(clientUid, uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateUnitLink(clientUid?: string, uid?: string, unitUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateUnitLink(clientUid, uid, unitUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [forceNewCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveDictionary(forceNewCache?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean | undefined; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveDictionary(forceNewCache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(clientUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsAddonAddonGetAllResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(clientUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLinks(clientUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesAddonLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLinks(clientUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async links(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsAddonLinkAddonLinkViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.links(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAddonAddonPostListViewModel} [APIInternalFrontendAPIModelsAddonAddonPostListViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(APIInternalFrontendAPIModelsAddonAddonPostListViewModel?: APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [addonUid] 
         * @param {string} [imageLink] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateImageLink(addonUid?: string, imageLink?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateImageLink(addonUid, imageLink, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddonApi - factory interface
 * @export
 */
export const AddonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddonApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(uid?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp._delete(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {{ [key: string]: boolean; }} [units] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateOnClient(uid: string, units?: { [key: string]: boolean; }, options?: any): AxiosPromise<void> {
            return localVarFp.activateOnClient(uid, units, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {{ [key: string]: boolean | undefined; }} [request_body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateOnClientByClientUid(clientUid?: string, uid?: string, request_body?: { [key: string]: boolean | undefined; }, options?: any): AxiosPromise<void> {
            return localVarFp.activateOnClientByClientUid(clientUid, uid, request_body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel} [APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateStoreboxOnClient(uid: string, APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel?: APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.activateStoreboxOnClient(uid, APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUnitLink(clientUid?: string, uid?: string, unitUid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.activateUnitLink(clientUid, uid, unitUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addonGET(uid: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsAddonAddonViewModel> {
            return localVarFp.addonGET(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAddonAddonPostListViewModel} [APIInternalFrontendAPIModelsAddonAddonPostListViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(APIInternalFrontendAPIModelsAddonAddonPostListViewModel?: APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.create(APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateOnClient(uid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateOnClient(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateOnClientByClientUid(clientUid?: string, uid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateOnClientByClientUid(clientUid, uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {string} [uid] 
         * @param {string} [unitUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUnitLink(clientUid?: string, uid?: string, unitUid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateUnitLink(clientUid, uid, unitUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [forceNewCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveDictionary(forceNewCache?: boolean, options?: any): AxiosPromise<{ [key: string]: boolean | undefined; }> {
            return localVarFp.getActiveDictionary(forceNewCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(clientUid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsAddonAddonGetAllResponseModel> {
            return localVarFp.getAll(clientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLinks(clientUid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesAddonLink>> {
            return localVarFp.getAllLinks(clientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        links(uid: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsAddonLinkAddonLinkViewModel>> {
            return localVarFp.links(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAddonAddonPostListViewModel} [APIInternalFrontendAPIModelsAddonAddonPostListViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(APIInternalFrontendAPIModelsAddonAddonPostListViewModel?: APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.update(APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [addonUid] 
         * @param {string} [imageLink] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImageLink(addonUid?: string, imageLink?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.updateImageLink(addonUid, imageLink, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in AddonApi.
 * @export
 * @interface AddonApiDeleteRequest
 */
export interface AddonApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiDelete
     */
    readonly uid?: string
}

/**
 * Request parameters for activateOnClient operation in AddonApi.
 * @export
 * @interface AddonApiActivateOnClientRequest
 */
export interface AddonApiActivateOnClientRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiActivateOnClient
     */
    readonly uid: string

    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AddonApiActivateOnClient
     */
    readonly units?: { [key: string]: boolean; }
}

/**
 * Request parameters for activateOnClientByClientUid operation in AddonApi.
 * @export
 * @interface AddonApiActivateOnClientByClientUidRequest
 */
export interface AddonApiActivateOnClientByClientUidRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiActivateOnClientByClientUid
     */
    readonly clientUid?: string

    /**
     * 
     * @type {string}
     * @memberof AddonApiActivateOnClientByClientUid
     */
    readonly uid?: string

    /**
     * 
     * @type {{ [key: string]: boolean | undefined; }}
     * @memberof AddonApiActivateOnClientByClientUid
     */
    readonly request_body?: { [key: string]: boolean | undefined; }
}

/**
 * Request parameters for activateStoreboxOnClient operation in AddonApi.
 * @export
 * @interface AddonApiActivateStoreboxOnClientRequest
 */
export interface AddonApiActivateStoreboxOnClientRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiActivateStoreboxOnClient
     */
    readonly uid: string

    /**
     * 
     * @type {APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel}
     * @memberof AddonApiActivateStoreboxOnClient
     */
    readonly APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel?: APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel
}

/**
 * Request parameters for activateUnitLink operation in AddonApi.
 * @export
 * @interface AddonApiActivateUnitLinkRequest
 */
export interface AddonApiActivateUnitLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiActivateUnitLink
     */
    readonly clientUid?: string

    /**
     * 
     * @type {string}
     * @memberof AddonApiActivateUnitLink
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof AddonApiActivateUnitLink
     */
    readonly unitUid?: string
}

/**
 * Request parameters for addonGET operation in AddonApi.
 * @export
 * @interface AddonApiAddonGETRequest
 */
export interface AddonApiAddonGETRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiAddonGET
     */
    readonly uid: string
}

/**
 * Request parameters for create operation in AddonApi.
 * @export
 * @interface AddonApiCreateRequest
 */
export interface AddonApiCreateRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsAddonAddonPostListViewModel}
     * @memberof AddonApiCreate
     */
    readonly APIInternalFrontendAPIModelsAddonAddonPostListViewModel?: APIInternalFrontendAPIModelsAddonAddonPostListViewModel
}

/**
 * Request parameters for deactivateOnClient operation in AddonApi.
 * @export
 * @interface AddonApiDeactivateOnClientRequest
 */
export interface AddonApiDeactivateOnClientRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiDeactivateOnClient
     */
    readonly uid: string
}

/**
 * Request parameters for deactivateOnClientByClientUid operation in AddonApi.
 * @export
 * @interface AddonApiDeactivateOnClientByClientUidRequest
 */
export interface AddonApiDeactivateOnClientByClientUidRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiDeactivateOnClientByClientUid
     */
    readonly clientUid?: string

    /**
     * 
     * @type {string}
     * @memberof AddonApiDeactivateOnClientByClientUid
     */
    readonly uid?: string
}

/**
 * Request parameters for deactivateUnitLink operation in AddonApi.
 * @export
 * @interface AddonApiDeactivateUnitLinkRequest
 */
export interface AddonApiDeactivateUnitLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiDeactivateUnitLink
     */
    readonly clientUid?: string

    /**
     * 
     * @type {string}
     * @memberof AddonApiDeactivateUnitLink
     */
    readonly uid?: string

    /**
     * 
     * @type {string}
     * @memberof AddonApiDeactivateUnitLink
     */
    readonly unitUid?: string
}

/**
 * Request parameters for getActiveDictionary operation in AddonApi.
 * @export
 * @interface AddonApiGetActiveDictionaryRequest
 */
export interface AddonApiGetActiveDictionaryRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AddonApiGetActiveDictionary
     */
    readonly forceNewCache?: boolean
}

/**
 * Request parameters for getAll operation in AddonApi.
 * @export
 * @interface AddonApiGetAllRequest
 */
export interface AddonApiGetAllRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiGetAll
     */
    readonly clientUid?: string
}

/**
 * Request parameters for getAllLinks operation in AddonApi.
 * @export
 * @interface AddonApiGetAllLinksRequest
 */
export interface AddonApiGetAllLinksRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiGetAllLinks
     */
    readonly clientUid?: string
}

/**
 * Request parameters for links operation in AddonApi.
 * @export
 * @interface AddonApiLinksRequest
 */
export interface AddonApiLinksRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiLinks
     */
    readonly uid: string
}

/**
 * Request parameters for update operation in AddonApi.
 * @export
 * @interface AddonApiUpdateRequest
 */
export interface AddonApiUpdateRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsAddonAddonPostListViewModel}
     * @memberof AddonApiUpdate
     */
    readonly APIInternalFrontendAPIModelsAddonAddonPostListViewModel?: APIInternalFrontendAPIModelsAddonAddonPostListViewModel
}

/**
 * Request parameters for updateImageLink operation in AddonApi.
 * @export
 * @interface AddonApiUpdateImageLinkRequest
 */
export interface AddonApiUpdateImageLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof AddonApiUpdateImageLink
     */
    readonly addonUid?: string

    /**
     * 
     * @type {string}
     * @memberof AddonApiUpdateImageLink
     */
    readonly imageLink?: string
}

/**
 * AddonApi - object-oriented interface
 * @export
 * @class AddonApi
 * @extends {BaseAPI}
 */
export class AddonApi extends BaseAPI {
    /**
     * 
     * @param {AddonApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public _delete(requestParameters: AddonApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration)._delete(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiActivateOnClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public activateOnClient(requestParameters: AddonApiActivateOnClientRequest, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).activateOnClient(requestParameters.uid, requestParameters.units, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiActivateOnClientByClientUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public activateOnClientByClientUid(requestParameters: AddonApiActivateOnClientByClientUidRequest = {}, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).activateOnClientByClientUid(requestParameters.clientUid, requestParameters.uid, requestParameters.request_body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiActivateStoreboxOnClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public activateStoreboxOnClient(requestParameters: AddonApiActivateStoreboxOnClientRequest, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).activateStoreboxOnClient(requestParameters.uid, requestParameters.APIInternalFrontendAPIModelsAddonAddonPostActivateStoreBoxRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiActivateUnitLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public activateUnitLink(requestParameters: AddonApiActivateUnitLinkRequest = {}, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).activateUnitLink(requestParameters.clientUid, requestParameters.uid, requestParameters.unitUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiAddonGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public addonGET(requestParameters: AddonApiAddonGETRequest, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).addonGET(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public create(requestParameters: AddonApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).create(requestParameters.APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiDeactivateOnClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public deactivateOnClient(requestParameters: AddonApiDeactivateOnClientRequest, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).deactivateOnClient(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiDeactivateOnClientByClientUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public deactivateOnClientByClientUid(requestParameters: AddonApiDeactivateOnClientByClientUidRequest = {}, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).deactivateOnClientByClientUid(requestParameters.clientUid, requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiDeactivateUnitLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public deactivateUnitLink(requestParameters: AddonApiDeactivateUnitLinkRequest = {}, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).deactivateUnitLink(requestParameters.clientUid, requestParameters.uid, requestParameters.unitUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiGetActiveDictionaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public getActiveDictionary(requestParameters: AddonApiGetActiveDictionaryRequest = {}, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).getActiveDictionary(requestParameters.forceNewCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiGetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public getAll(requestParameters: AddonApiGetAllRequest = {}, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).getAll(requestParameters.clientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiGetAllLinksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public getAllLinks(requestParameters: AddonApiGetAllLinksRequest = {}, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).getAllLinks(requestParameters.clientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiLinksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public links(requestParameters: AddonApiLinksRequest, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).links(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public update(requestParameters: AddonApiUpdateRequest = {}, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).update(requestParameters.APIInternalFrontendAPIModelsAddonAddonPostListViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddonApiUpdateImageLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public updateImageLink(requestParameters: AddonApiUpdateImageLinkRequest = {}, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).updateImageLink(requestParameters.addonUid, requestParameters.imageLink, options).then((request) => request(this.axios, this.basePath));
    }
}
