import { throttle } from "underscore";
import {ActionContext, Module} from "vuex";
import {EnumApi} from "../../_Services/Autogenerated_v4/V1/api/enum-api";

let EnumService;

export interface IProductConfigState {
	extendedProductInfoActive: boolean;
	extendedProductInfoText1: string;
	extendedProductInfoText2: string;
	extendedProductInfoText3: string;
	extendedProductInfoText4: string;
	extendedProductInfoNumber1: string;
	extendedProductInfoNumber2: string;
	extendedProductInfoDate1: string;
	extendedProductInfoMemo1: string;
    
	unitTypeEnum: {};
}

let unitTypeThrottle: ((() => Promise<void>) & _.Cancelable) | null = null;

const productConfigState: Module<IProductConfigState, any> = {
	namespaced: true,

	state: () => ({
		extendedProductInfoActive: null,
		extendedProductInfoText1: null,
		extendedProductInfoText2: null,
		extendedProductInfoText3: null,
		extendedProductInfoText4: null,
		extendedProductInfoNumber1: null,
		extendedProductInfoNumber2: null,
		extendedProductInfoDate1: null,
		extendedProductInfoMemo1: null,
        
		unitTypeEnum: null,
	}),
	actions: {
		async setUnitTypeIfNotSet({ commit, state, rootGetters, rootState }: ActionContext<IProductConfigState, any>) {
			if (!EnumService) EnumService = new EnumApi(rootGetters["getSwaggerAxiosOptions"]);
			if (!unitTypeThrottle)
				unitTypeThrottle = throttle(async () => {
					const languageUid = rootState.languageUid;
					const enumValue = (await EnumService.getIntEnum({uid: "E3559BC4-5C91-4624-93F3-7DB346543AC1", languageUid: languageUid})).data;
					commit("SET_UNIT_TYPE", enumValue);
				}, 1000 * 60 * 5);

			await unitTypeThrottle();
		}
	},
	mutations: {
		SET_UNIT_TYPE(state: IProductConfigState, newState) {
			state.unitTypeEnum = newState;
		}
	},
	getters: {
		getExtendedProductInfo(state: IProductConfigState, getters, rootState, rootGetters) {
			return {
				extendedProductInfoActive: rootGetters.getFlexPosConfigBool("ExtendedProdInfoActive", false),
				extendedProductInfoText1: rootGetters.getFlexPosConfigString("ExtendedProdInfoText1", Resource.ExtendedProdInfoText1),
				extendedProductInfoText2: rootGetters.getFlexPosConfigString("ExtendedProdInfoText2", Resource.ExtendedProdInfoText2),
				extendedProductInfoText3: rootGetters.getFlexPosConfigString("ExtendedProdInfoText3", Resource.ExtendedProdInfoText3),
				extendedProductInfoText4: rootGetters.getFlexPosConfigString("ExtendedProdInfoText4", Resource.ExtendedProdInfoText4),
				extendedProductInfoText5: rootGetters.getFlexPosConfigString("ExtendedProdInfoText5", Resource.ExtendedProdInfoText5),
				extendedProductInfoText6: rootGetters.getFlexPosConfigString("ExtendedProdInfoText6", Resource.ExtendedProdInfoText6),
				extendedProductInfoText7: rootGetters.getFlexPosConfigString("ExtendedProdInfoText7", Resource.ExtendedProdInfoText7),
				extendedProductInfoText8: rootGetters.getFlexPosConfigString("ExtendedProdInfoText8", Resource.ExtendedProdInfoText8),
				extendedProductInfoNumber1: rootGetters.getFlexPosConfigString("ExtendedProdInfoNumber1", Resource.ExtendedProdInfoNumber1),
				extendedProductInfoNumber2: rootGetters.getFlexPosConfigString("ExtendedProdInfoNumber2", Resource.ExtendedProdInfoNumber2),
				extendedProductInfoDate1: rootGetters.getFlexPosConfigString("ExtendedProdInfoDate1", Resource.ExtendedProdInfoDate1),
				extendedProductInfoMemo1: rootGetters.getFlexPosConfigString("ExtendedProdInfoMemo1", Resource.ExtendedProdInfoMemo1),
				extendedProductInfoUrl1: rootGetters.getFlexPosConfigString("ExtendedProdInfoUrl1", Resource.ExtendedProdInfoUrl1),
				extendedProductInfoUrl2: rootGetters.getFlexPosConfigString("ExtendedProdInfoUrl2", Resource.ExtendedProdInfoUrl2),
				extendedProductInfoBoolean1: rootGetters.getFlexPosConfigString("ExtendedProdInfoBoolean1", Resource.ExtendedProdInfoBoolean1),
				extendedProductInfoBoolean2: rootGetters.getFlexPosConfigString("ExtendedProdInfoBoolean2", Resource.ExtendedProdInfoBoolean2),
			};
		},
		getCombinations(state, getters, rootState, rootGetters) {
			return {
				combination1: { Active: rootGetters.getFlexPosConfigBool("Combination1FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination1FieldName", Resource.Combination1) },
				combination2: { Active: rootGetters.getFlexPosConfigBool("Combination2FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination2FieldName", Resource.Combination2) },
				combination3: { Active: rootGetters.getFlexPosConfigBool("Combination3FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination3FieldName", Resource.Combination3) },
				combination4: { Active: rootGetters.getFlexPosConfigBool("Combination4FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination4FieldName", Resource.Combination4) },
			};
		},
		getUnitTypeEnum(state: IProductConfigState) {
			return state.unitTypeEnum;
		},
		getShowCreateProductOnChildClient(state, getters, rootState, rootGetters) {
			return rootGetters["getFlexPosConfigBool"]("ShowCreateProductOnChildClient", false);
		},
		getAllowUncheckedBarcodes(state, getters, rootState, rootGetters) {
			return rootGetters["getFlexPosConfigBool"]("AllowUncheckedBarcodes", false);
		},
		getAllowIdenticalBarcodes(state, getters, rootState, rootGetters) {
			return rootGetters["getFlexPosConfigBool"]("AllowIdenticalBarcodes", false);
		},
		getDefaultVat(state, getters, rootState, rootGetters) {
			const val = rootGetters["getFlexPosConfigInt"]("Vat", 25);
			return val >= 0 || val <= 100 ? val : 25;
		},
		allowConvertProductToCombinationMaster(state, getters, rootState, rootGetters) {
			return rootGetters["getFlexPosConfigBool"]("AllowConvertProductToCombinationMaster", false);
		},
		getDefaultProductActive(state, getters, rootState, rootGetters) {
			return rootGetters["getFlexPosConfigBool"]("DefaultProductActive", true);
		},
		getDefaultProductSendToWeb(state, getters, rootState, rootGetters) {
			return rootGetters["getFlexPosConfigBool"]("DefaultProductSendToWeb", true);
		},
		getIsCampaignActive(state, getters, rootState, rootGetters) {
			return rootState.activeAddons["50af6a06-02f3-4a79-9b03-b616fea518f4"] || false;
		},
	}
};

export default productConfigState;