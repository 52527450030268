
















































































































































































































































































































































































































































































































































































































































































































































































































































































import {mapActions, mapGetters} from 'vuex';
import autocomplete from "autocomplete.js";
import copyText from "../../_Pages/copyText";
import UserProfileModal from "@components/top-menu/user-profile-modal.vue";
import isNullOrWhitespace from "@app/isNullOrWhitespace";

export default {
	name: "top-menu",
	components: {UserProfileModal},
	data() {
		return {
			autocompleteInstance: null,
			clientNameInput: this.clientName,
			searching: false,
			languages: [],
			language: "1030",
			languageShown: false,
			userProfileShown: false,
			userDropdown: null,
			focused: false,
		};
	},
	computed: {
		...mapGetters({
			showMenu: "getIsLoggedIn",
			username: "getUserName",
			clientName: "getClientName",
			clientId: "getClientId",
			clientUid: "getClientUid",
			getLanguageUid: "getLanguageUid",
			parentUserClientUid: "getParentUserClientUid",
			getIsFlexPosAdmin: "getIsFlexPosAdmin",
			getIsFlexPosReseller: "getIsFlexPosReseller",
			notifications: "notificationState/getNotifications",
			getBackendConfigBool: "getBackendConfigBool",
			getActiveAddons: "getActiveAddons",
			getIsPosUsersAllowed: "navigationGuardState/getIsPosUsersAllowed",
			getIsFlexAdAllowed: "navigationGuardState/getIsFlexAdAllowed",
			getIsUsersAllowed: "navigationGuardState/getIsUsersAllowed",
			getIsModulesAllowed: "navigationGuardState/getIsModulesAllowed",
			getIsCurrencyAllowed: "navigationGuardState/getIsCurrencyAllowed",
			getIsSettingsAllowed: "navigationGuardState/getIsSettingsAllowed",
		}),
		notificationCount() {
			let count = 0;
			for (let i = 0; i < this.notifications.length; i++) {
				if(this.notifications[i].Uid === "3c65b7ba-c68b-4441-995a-a2c43cb740f4" || this.notifications[i].Uid === "1ec740c7-32a7-4b4a-bda3-309961b54d01") continue;
				if(!this.notifications[i].Read) count++;
			}
			return count;
		},
		forceHideSideMenu() {
			let value = this.$route.fullPath;
			if (value.indexOf("Account/ChangePassword") > -1) {
				return true;
			}
			return this.$route.name === "flex-voucher-download-file" || this.$route.name === "economic-app" || this.$route.name === "economic-app-login";
		},
	},
	watch: {
		language(value, oldValue) {
			if (oldValue === "") return;

			let selectedLanguages = this.languages.filter(item => item.CultureId === value);
			if (selectedLanguages.length === 0) return;
			let selectedLanguage = selectedLanguages[0];

			$.ajax({
				url: "/Account/SwitchLanguage",
				method: "POST",
				data: {
					"cultureId": value,
					"langUid": selectedLanguage.Uid
				},
				success: () => {
					this.$router.go(0);
				}
			});
		}
	},
	async mounted() {
		let currentQuery = null;
		const clients = async (query, callback) => {
			currentQuery = this.$refs.clientNameInput?.value || this.$refs.clientNameInput1?.value;
			callback(await this.getClient(query));
		};

		await this.getLanguages();

		if (this.$refs.clientNameSpan) this.$refs.clientNameSpan.style.display = "block";
		if (this.$refs.clientNameInput) this.$refs.clientNameInput.style.minWidth = (this.$refs.clientNameSpan.getBoundingClientRect().width) + "px";
		if (this.$refs.clientNameSpan) this.$refs.clientNameSpan.style.display = "none";
		if (this.$refs.clientNameSpan1) this.$refs.clientNameSpan1.style.display = "block";
		if (this.$refs.clientNameInput1) this.$refs.clientNameInput1.style.minWidth = (this.$refs.clientNameSpan1.getBoundingClientRect().width) + "px";
		if (this.$refs.clientNameSpan1) this.$refs.clientNameSpan1.style.display = "none";
		
		//@ts-ignore
		String.prototype.replaceAll = function(strReplace, strWith) {
			// See http://stackoverflow.com/a/3561711/556609
			var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
			var reg = new RegExp(esc, 'ig');
			return this.replace(reg, strWith);
		};
		this.autocompleteInstance = autocomplete(this.$refs.clientNameInput1, {
			autoselect: true,
			openOnFocus: true,
			panelPlacement: "end",
			placeholder: this.clientName,
			minLength: 0
		}, [
			{
				source: clients,
				displayKey: 'Name',
				templates: {
					suggestion: function (data) {
						let name = data.Name;
						if (!isNullOrWhitespace(currentQuery)) {
							name = name.replaceAll(currentQuery, function(match) { return `<span class="fw-bold">${match}</span>`; });
						}

						return `<td style='padding-left: ${10 + (data.lvl * 14)}px;'>${name}</td>`;
					}
				}
			}
		]).on('autocomplete:selected', (event, suggestion) => {
			this.$router.push("/dashboard/client/go-to-client/" + suggestion.Uid);
		}).on('autocomplete:closed', () => {
			this.autocompleteInstance.autocomplete.setVal(null);
		});
		
		this.autocompleteInstance = autocomplete(this.$refs.clientNameInput, {
			autoselect: true,
			openOnFocus: true,
			panelPlacement: "end",
			placeholder: this.clientName,
			minLength: 0
		}, [
			{
				source: clients,
				displayKey: 'Name',
				templates: {
					suggestion: function (data) {
						let name = data.Name;
						if (!isNullOrWhitespace(currentQuery)) {
							name = name.replaceAll(currentQuery, function(match) { return `<span class="fw-bold">${match}</span>`; });
						}
						
						return `<td style='padding-left: ${10 + (data.lvl * 14)}px;'>${name}</td>`;
					}
				}
			}
		]).on('autocomplete:selected', (event, suggestion) => {
			this.$router.push("/dashboard/client/go-to-client/" + suggestion.Uid);
		}).on('autocomplete:closed', () => {
			this.autocompleteInstance.autocomplete.setVal(null);
		})
	},
	methods: {
		...mapActions({
			markAllNotificationsRead: "notificationState/markAllNotificationsRead",
			markNotificationRead: "notificationState/markNotificationRead",
			markNotificationHidden: "notificationState/markNotificationHidden"
		}),
		async getClient(query) {
			return await this.$store.dispatch("topMenuState/setClient", query);
		},
		copyText(text) {
			copyText(text);
			this.$toast({type: "info", text: `${this.Resource.Copied} ${text}`, header: Resource.Info});
		},
		formatNotificationDate(notification) {
			let date = (notification.UpdatedDate !== null) ? notification.UpdatedDate : notification.CreatedDate;
			return moment(date).fromNow();
		},
		closeSettingsDropdown() {
			let dropdown = this.$bootstrap.Dropdown.getInstance(this.$refs.navBarShopSetup);
			if (dropdown) dropdown.hide();
		},
		closeUserDropdown() {
			if (!this.userDropdown)
				this.userDropdown = this.$bootstrap.Dropdown.getInstance(this.$refs.navbarUserDropdown);

			if (this.userDropdown) this.userDropdown.hide();
		},
		async getLanguages() {
			this.languages = await $.ajax({
				url: "/Account/GetLanguages",
			});

			if (this.$store.state.cultureId !== null) {
				if (this.checkArray(this.$store.state.cultureId, this.languages)) {
					this.language = this.$store.state.cultureId;
					return;
				}
			}

			this.language = this.$store.state.cultureId = "1030";
		},
		changeLanguage(value) {
			this.language = value;
		},
		checkArray(value, array) {
			let result = false;

			for (let i = 0; i < array.length; i++) {
				let name = array[i].value;
				if (name === value) {
					result = true;
					break;
				}
			}
			return result;
		},
	},
};
