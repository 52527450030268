<template>
	<div class="row" ref="panel">
		<div class="col-12">
			<div class="card validation-panel" :class="{'validation-summary-valid': errorsFormatted.length === 0, 'validation-summary-errors mb-3': errorsFormatted.length > 0}">
				<div class="card-header bg-danger text-white">
					{{ title }}
				</div>
				<div class="card-body">
					<div>
						<div data-valmsg-summary="true">
							<ul v-if="errors.length === 0" class="mb-0">
								<li style="display:none;" />
							</ul>
							<template v-else>
								<slot name="error-section">
									<ul class="mb-0">
										<template v-for="error in errorsFormatted">
											<li v-html="error" />
										</template>
									</ul>
								</slot>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions} from "vuex";
import modelStateFormatter from "../modelStateFormatter";
    
export default {
	name: "validation-panel",
	props: {
		title: {
			type: String,
			default() {
				return Resource.Error;
			}
		},
		errors: {
			type: Array | Object,
			default: function() { return [] }
		},
		name: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			observer: ResizeObserver
		};
	},
	computed: {
		errorsFormatted() {
			const IsAlreadyFormatted = this.errors.length > 0 ? typeof this.errors[0] === "string" : false;
			if (IsAlreadyFormatted) return this.errors;
				
			return modelStateFormatter(this.errors);
		}
	},
	methods: {
		...mapActions({ 
			updated: "SET_VALIDATION_PANEL_UPDATED",
			setHeight: "SET_VALIDATION_PANEL_HEIGHT"
		})
	},
	mounted() {
		const __this = this;
		this.observer = new ResizeObserver(function() {
			__this.setHeight({name: __this.name, height: __this.$refs.panel.offsetHeight});
			__this.updated();
		});

		this.observer.observe(this.$refs.panel);
	},
	beforeDestroy() {
		this.observer.disconnect();
	}
};
</script>

<style scoped>

</style>