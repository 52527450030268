




















export default {
	name: "load-error",
	props: {
		loadErrorText: {
			default: null,
			type: String
		}
	}
};
