import {Module} from "vuex";

interface IEnumTypesTableIndexState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const enumTypesTableIndexState: Module<IEnumTypesTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null,
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args);
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams;
        },
		UID(state, getters, rootState) { return getConfig("EnumTypes.ColumnOptions.UID", rootState, true); },
		Name(state, getters, rootState) { return getConfig("EnumTypes.ColumnOptions.Name", rootState, true); },
        Type(state, getters, rootState) { return getConfig("EnumTypes.ColumnOptions.Type", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				UID: getters.UID,
				Name: getters.Name,
                Type: getters.Type,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				UID: true,
                Name: true,
				Type: true,
            };
        }
    }
}

export default enumTypesTableIndexState;