import _Vue from "vue";

export function VueUserSession<VueUserSessOptions>(Vue: typeof _Vue, options?: VueUserSessionOptions): void {
    // @ts-ignore
    Object.defineProperties(Vue.prototype, {
        UserSession: {
            get() {
                // @ts-ignore
                return window.UserSession;
            }
        }
    });
}

export class VueUserSessionOptions {
    
}