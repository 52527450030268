import {Module} from "vuex";

interface IProductBulkEditTableState {
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const productBulkEditTableState: Module<IProductBulkEditTableState, any> = {
    namespaced: true,
    
    state: () => ({
    }),
    actions: {
    },
    mutations: {
    },
    getters: {
        Number(state, getters, rootState) { return getConfig("ProductBulkEdit.ColumnOptions.Number", rootState, true); },
        Name(state, getters, rootState) { return getConfig("ProductBulkEdit.ColumnOptions.Name", rootState, true); },
        SyncToWeb(state, getters, rootState) { return getConfig("ProductBulkEdit.ColumnOptions.SyncToWeb", rootState, true); },
        IsActive(state, getters, rootState) { return getConfig("ProductBulkEdit.ColumnOptions.IsActive", rootState, true); },
        IsVatFree(state, getters, rootState) { return getConfig("ProductBulkEdit.ColumnOptions.IsVatFree", rootState, true); },
        BrandName(state, getters, rootState) { return getConfig("ProductBulkEdit.ColumnOptions.BrandName", rootState, true); },
        DisplayProductGroupName(state, getters, rootState) { return getConfig("ProductBulkEdit.ColumnOptions.DisplayProductGroupName", rootState, true); },
        FinancialProductGroupName(state, getters, rootState) { return getConfig("ProductBulkEdit.ColumnOptions.FinancialProductGroupName", rootState, true); },
        SupplierName(state, getters, rootState) { return getConfig("ProductBulkEdit.ColumnOptions.SupplierName", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Number: getters.Number,
                Name: getters.Name,
                SyncToWeb: getters.SyncToWeb,
                IsActive: getters.IsActive,
                IsVatFree: getters.IsVatFree,
                BrandName: getters.BrandName,
                DisplayProductGroupName: getters.DisplayProductGroupName,
                FinancialProductGroupName: getters.FinancialProductGroupName,
                SupplierName: getters.SupplierName,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Number: true,
                Name: true,
                SyncToWeb: true,
                IsActive: true,
                IsVatFree: true,
                BrandName: true,
                DisplayProductGroupName: true,
                FinancialProductGroupName: true,
                SupplierName: true,
            };
        }
    }
}

export default productBulkEditTableState;