﻿// try {
//     function onErrors(validator) {  // 'this' is the form element
//         var container = $(document).find("[data-valmsg-summary=true]"),
//             list = container.find("ul");
//
//         if (list && list.length && validator.length) {
//             list.empty();
//             container.addClass("validation-summary-errors").removeClass("validation-summary-valid");
//
//             $.each(validator, function () {
//
//                 var parentTab = $(this.element).parents(".tab-pane")
//                 var parentTabTitle = $("[href='#" + parentTab.attr("id") + "']").attr("aria-controls")
//
//                 var message = JSON.parse(JSON.stringify(this.message));
//                 message = (parentTabTitle ? "<span class='text-bold'>" +parentTabTitle + ":</span> " : "") + message 
//                 $("<li />").html(message).appendTo(list);
//             });
//         }
//     }
//     var defaultOptions = {
//         validClass: 'has-success',
//         warningClass: 'has-warning',
//         errorClass: 'has-error',
//         highlight: function (element, errorClass, validClass) {
//             $(element).closest(".form-group")
//                 .removeClass(validClass)
//                 .addClass('has-error');
//         },
//         unhighlight: function (element, errorClass, validClass) {
//             $(element).closest(".form-group")
//                 .removeClass('has-error')
//                 .addClass(validClass);
//         },
//         showErrors: function(errorMap, errorList) {
//             try {
//                 if (this.numberOfInvalids()) {
//                     onErrors(errorList);
//
//                 }
//             }
//             catch(e) {
//                
//             }
//             this.defaultShowErrors();
//         },
//     };
//
//     $.validator.setDefaults(defaultOptions);
//
//     $.validator.unobtrusive.options = {
//         errorClass: defaultOptions.errorClass,
//         validClass: defaultOptions.validClass,
//     };
//     $.validator.setDefaults({
//         ignore: "[type=hidden], [hidden=hidden], [hidden=hidden] *, .hidden, body .hidden *, body:not(.modal-open) .modal *"
//     });
// }
// catch (e) {
// }

try {
    $.fn.bootstrapTable.defaults.minWidth = 1279
    $.fn.bootstrapTable.defaults.mobileResponsive = true
} catch (e) {
}
try {
    $.validator.methods.range = function (value, element, param) {
        var globalizedValue = value.replace(",", ".");
        return this.optional(element) || (globalizedValue >= param[0] && globalizedValue <= param[1]);
    }

    $.validator.methods.number = function (value, element) {
        return this.optional(element) || /^-?(?:\d+|\d{1,3}(?:[\s\.,]\d{3})+)(?:[\.,]\d+)?$/.test(value);
    }
}
catch (e) {
}

try {
    BootstrapDialog.defaultOptions.spinicon = "fa fa-spinner";
} catch (e) {

}
