<template>
	<div class="m-4">
		<h1>{{ Resource.Settings }}</h1>
		<p></p>
	</div>
</template>

<script>
export default {
	name: "settings-index",
	metaInfo: {
		isSettingsMenu: true
	}
};
</script>

<style scoped>

</style>