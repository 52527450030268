import {Module} from "vuex";

interface IStatusModuleIndex {
	activeTab: string | null;
	deleteStatusModal: boolean;
	deleteStatusRow: object | null;
	downloadStatusModal: boolean;
	downloadStatusRow: object | null;
	lastSyncJobStatus: number;
	syncJobRunWithinLastTenMinutes: boolean;
}

const statusModuleIndex: Module<IStatusModuleIndex, unknown> = {
	namespaced: true,
	state: () => ({
		activeTab: null,
		deleteStatusModal: false,
		deleteStatusRow: null,
		downloadStatusModal: false,
		downloadStatusRow: null,
		lastSyncJobStatus: 0,
		syncJobRunWithinLastTenMinutes: false
	}),
	actions: {
		SET_ACTIVE_TAB(context, args) {
			context.commit("SET_ACTIVE_TAB", args);
		},
		SET_DELETE_STATUS_MODAL(context, args) {
			context.commit("SET_DELETE_STATUS_MODAL", args);
		},
		SET_DELETE_STATUS_ROW(context, args) {
			context.commit("SET_DELETE_STATUS_ROW", args);
		},
		SET_DOWNLOAD_STATUS_MODAL(context, args) {
			context.commit("SET_DOWNLOAD_STATUS_MODAL", args);
		},
		SET_DOWNLOAD_STATUS_ROW(context, args) {
			context.commit("SET_DOWNLOAD_STATUS_ROW", args);
		},
		SET_LAST_SYNC_JOB_STATUS(context, args) {
			context.commit("SET_LAST_SYNC_JOB_STATUS", args);
		},
		SET_SYNC_JOB_RUN_WITHIN_LAST_TEN_MINUTES(context, args) {
			context.commit("SET_SYNC_JOB_RUN_WITHIN_LAST_TEN_MINUTES", args);
		},
	},
	mutations: {
		SET_ACTIVE_TAB(state, args) {
			state.activeTab = args;
		},
		SET_DELETE_STATUS_MODAL(state, args) {
			state.deleteStatusModal = args;
		},
		SET_DELETE_STATUS_ROW(state, args) {
			state.deleteStatusRow = args;
		},
		SET_DOWNLOAD_STATUS_MODAL(state, args) {
			state.downloadStatusModal = args;
		},
		SET_DOWNLOAD_STATUS_ROW(state, args) {
			state.downloadStatusRow = args;
		},
		SET_LAST_SYNC_JOB_STATUS(state, args) {
			state.lastSyncJobStatus = args;
		},
		SET_SYNC_JOB_RUN_WITHIN_LAST_TEN_MINUTES(state, args) {
			state.syncJobRunWithinLastTenMinutes = args;
		}
	},
	getters: {
		getActiveTab(state) { return state.activeTab; },
		getDeleteStatusModal(state) { return state.deleteStatusModal; },
		getDeleteStatusRow(state) { return state.deleteStatusRow; },
		getDownloadStatusModal(state) { return state.downloadStatusModal; },
		getDownloadStatusRow(state) { return state.downloadStatusRow; },
		getLastSyncJobStatus(state) { return state.lastSyncJobStatus; },
		getSyncJobRunWithinLastTenMinutes(state) { return state.syncJobRunWithinLastTenMinutes; },
	}
};

export default statusModuleIndex;