import {Module} from "vuex";

interface ICurrencyTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const currencyTableIndexState: Module<ICurrencyTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        Name(state, getters, rootState) { return getConfig("Currency.ColumnOptions.Name", rootState, true); },
        Description(state, getters, rootState) { return getConfig("Currency.ColumnOptions.Description", rootState, true); },
        ExchangeRate(state, getters, rootState) { return getConfig("Currency.ColumnOptions.ExchangeRate", rootState, true); },
		SortOrder(state, getters, rootState) { return getConfig("Currency.ColumnOptions.SortOrder", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Name: getters.Name,
                Description: getters.Description,
                ExchangeRate: getters.ExchangeRate,
				SortOrder: getters.SortOrder,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Name: true,
                Description: true,
                ExchangeRate: true,
				SortOrder: true,
            };
        }
    }
}

export default currencyTableIndexState;