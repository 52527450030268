import {Module} from "vuex";

interface IEnumTableIndexState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const enumTableIndexState: Module<IEnumTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null,
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args);
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams;
        },
		UID(state, getters, rootState) { return getConfig("Enums.ColumnOptions.UID", rootState, true); },
		Value(state, getters, rootState) { return getConfig("Enums.ColumnOptions.Value", rootState, true); },
		PreviewLanguageUID(state, getters, rootState) { return getConfig("Enums.ColumnOptions.PreviewLanguageUID", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				UID: getters.UID,
				Value: getters.Value,
				PreviewLanguageUID: getters.PreviewLanguageUID,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				UID: true,
				Value: true,
				PreviewLanguageUID: true,
            };
        }
    }
}

export default enumTableIndexState;