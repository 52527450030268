<template functional>
    <span class="wrapper" :class="{'wrap-text': props.wrapText}" >
        <!--suppress HtmlFormInputWithoutLabel -->
        <input v-if="typeof props.row[props.field] === 'boolean'" type="checkbox" class="form-check-input" :checked="props.row[props.field]" onclick="return false;">
        <span v-else-if="props.freeText">
            <template v-if="props.maxDecimals == null || typeof props.row[props.field] !== 'number'">
                {{props.row[props.field]}}
            </template>
            <template v-else>
                {{(props.row[props.field] || 0).toFixed(props.maxDecimals)}}
            </template>
        </span>
        <span v-else-if="typeof props.row[props.field] === 'number' && !props.noDecimals">{{props.formatNumber(props.row[props.field])}}</span>
        <span v-else-if="props.replaceZero">{{  props.row[props.field] === undefined || props.row[props.field] === null || props.row[props.field].toString() == "0" ? "-" :  props.row[props.field]}}</span>
        <span v-else>{{props.row[props.field]}}</span>
    </span>
</template>

<script>
export default {
    name: "fp-table-default-value-formatter",
    abstract: true,
    props: {
        row: Object,
        field: String,
        wrapText: Boolean,
        replaceZero: Boolean,
		noDecimals: Boolean,
        maxDecimals: Number | null,
        freeText: Boolean,
        formatNumber: {
            default() {
                return function(value) {
                    return accounting.formatNumber(value);
                }  
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "FlexSystem/styles/_variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.wrapper {
	padding-left: $fp-table-cell-padding-left;
    white-space: nowrap;
    * {
        white-space: nowrap;
    }
}

.wrap-text {
    white-space: break-spaces !important;
    * {
        display: inline-block;
        white-space: break-spaces !important;
    }
}
</style>
