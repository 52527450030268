import {Module} from "vuex";

interface ICardGroupTableIndexState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const cardGroupTableIndexState: Module<ICardGroupTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        GroupName(state, getters, rootState) { return getConfig("CardGroups.ColumnOptions.GroupName", rootState, true); },
        GroupNumber(state, getters, rootState) { return getConfig("CardGroups.ColumnOptions.GroupNumber", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                GroupName: getters.GroupName,
                GroupNumber: getters.GroupNumber,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                GroupName: true,
                GroupNumber: true,
            };
        }
    }
}

export default cardGroupTableIndexState;