<template>
	<router-link :to="to" class="btn btn-back">
		<font-awesome-icon icon="fa-regular fa-arrow-circle-left" />
		<slot>{{ Resource.GoBack }}</slot>
	</router-link>
</template>

<script>
export default {
	name: "back-button",
	props: {
		to: {
			type: [String, Object],
			required: true
		}
	},
};
</script>