<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.612 16.236">
        <g id="Group_131" data-name="Group 131" transform="translate(-96.87 -70.984)">
            <path id="Path_545" data-name="Path 545" :fill="backColor"
                  d="M1.87,9.776H7.392a5.25,5.25,0,0,1,3.4.935,3.591,3.591,0,0,1,1.133,2.937,4.254,4.254,0,0,1-.5,2.233,3.364,3.364,0,0,1-1.463,1.265,3.424,3.424,0,0,1,2.42,3.586,4.005,4.005,0,0,1-1.21,3.278,5.4,5.4,0,0,1-3.5.99H1.87ZM7.5,18H3.564v5.5H7.612a3.57,3.57,0,0,0,2.277-.627,2.693,2.693,0,0,0,.781-2.211,2.855,2.855,0,0,0-.319-1.408,1.849,1.849,0,0,0-.847-.814A4.788,4.788,0,0,0,7.5,18Zm-.176-6.732H3.564V16.53H7.48a2.755,2.755,0,0,0,2.057-.671,2.809,2.809,0,0,0,.649-2.024,2.474,2.474,0,0,0-.693-1.958A3.24,3.24,0,0,0,7.326,11.272ZM22.9,17.322v5.632q.066.814,1.276.968l-.066,1.3a3.553,3.553,0,0,1-2.618-.88,9.68,9.68,0,0,1-3.96.88,2.989,2.989,0,0,1-2.31-.858,3.5,3.5,0,0,1-.792-2.464,3.091,3.091,0,0,1,.814-2.365A4.316,4.316,0,0,1,17.8,18.6l3.454-.33v-.946a2.214,2.214,0,0,0-.484-1.606,1.784,1.784,0,0,0-1.32-.484,31.634,31.634,0,0,0-3.652.22l-.682.066-.066-1.254a22.171,22.171,0,0,1,4.29-.484,3.692,3.692,0,0,1,2.717.858A3.694,3.694,0,0,1,22.9,17.322ZM16.126,21.81q0,2.024,1.672,2.024a8.9,8.9,0,0,0,2.948-.506l.506-.176v-3.63L18,19.83a2.118,2.118,0,0,0-1.43.572A2.025,2.025,0,0,0,16.126,21.81Zm14.146-8.03a19.007,19.007,0,0,1,2.464.264l.528.066L33.2,15.452a25.81,25.81,0,0,0-2.6-.2,3.021,3.021,0,0,0-2.541.891,5.8,5.8,0,0,0-.671,3.3,6.453,6.453,0,0,0,.627,3.355q.627.946,2.607.946l2.6-.2.066,1.364a22.621,22.621,0,0,1-3.058.308q-2.574,0-3.553-1.32a7.681,7.681,0,0,1-.979-4.455q0-3.135,1.056-4.4A4.351,4.351,0,0,1,30.272,13.78ZM37.4,25H35.75V9.2H37.4v9.328l1.716-.088L42.262,14h1.87l-3.586,5.06L44.286,25h-1.87l-3.278-5.1-1.738.066Z"
                  transform="translate(95 62)"/>
            <path id="Path_544" data-name="Path 544"
                  d="M2.992,17.454a9.778,9.778,0,0,0,.88,4.807q.88,1.463,3.4,1.463t3.388-1.43a9.738,9.738,0,0,0,.869-4.8,10.107,10.107,0,0,0-.9-4.906,3.578,3.578,0,0,0-3.377-1.54q-2.475,0-3.366,1.529A10.084,10.084,0,0,0,2.992,17.454Zm10.3.044q0,4.07-1.3,5.9T7.271,25.22q-3.421,0-4.719-1.87t-1.3-5.885q0-4.015,1.32-5.962T7.26,9.556q3.366,0,4.7,1.936T13.288,17.5Zm4.928-2.068V25h-1.65V15.43H15.18V14h1.386v-.99a5.458,5.458,0,0,1,.66-3.179,2.766,2.766,0,0,1,2.31-.847l2.222.154L21.736,10.5q-1.232-.044-2.035-.044a1.257,1.257,0,0,0-1.144.528,4.167,4.167,0,0,0-.341,2.046V14h3.19v1.43Zm7.282,0V25h-1.65V15.43H22.462V14h1.386v-.99a5.458,5.458,0,0,1,.66-3.179,2.766,2.766,0,0,1,2.31-.847l2.222.154L29.018,10.5q-1.232-.044-2.035-.044a1.257,1.257,0,0,0-1.144.528,4.167,4.167,0,0,0-.341,2.046V14h3.19v1.43ZM30.668,25V14h1.65V25Zm0-13.486V9.6h1.65v1.914ZM39.6,13.78a19.007,19.007,0,0,1,2.464.264l.528.066-.066,1.342a25.81,25.81,0,0,0-2.6-.2,3.021,3.021,0,0,0-2.541.891,5.8,5.8,0,0,0-.671,3.3,6.453,6.453,0,0,0,.627,3.355q.627.946,2.607.946l2.6-.2.066,1.364a22.621,22.621,0,0,1-3.058.308q-2.574,0-3.553-1.32a7.681,7.681,0,0,1-.979-4.455q0-3.135,1.056-4.4A4.351,4.351,0,0,1,39.6,13.78Zm12.826,9.856.638-.066.044,1.3a31.58,31.58,0,0,1-4.29.352,3.867,3.867,0,0,1-3.366-1.375,7.385,7.385,0,0,1-.99-4.279q0-5.786,4.6-5.786a4.206,4.206,0,0,1,3.322,1.243,5.789,5.789,0,0,1,1.1,3.905l-.088,1.254h-7.26a4.511,4.511,0,0,0,.66,2.706,2.7,2.7,0,0,0,2.3.88Q50.732,23.768,52.426,23.636Zm-.594-4.774A4.776,4.776,0,0,0,51.183,16a2.509,2.509,0,0,0-2.112-.836,2.707,2.707,0,0,0-2.2.88,4.442,4.442,0,0,0-.759,2.816Z"
                  transform="translate(140 62)" fill="#ed6a1c"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: "backoffice-logo",
    props: {
        backColor: {
            default: "#707070"
        }
    }
}
</script>

<style scoped>

</style>