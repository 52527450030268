import {Module} from "vuex";

function getConfig(key, rootState, defaultValue) {
	const keyValue = rootState.backendConfigs[key];
	if (keyValue !== null && keyValue !== undefined)
		return keyValue.toLowerCase() === "true";

	return defaultValue;
}

interface IStatusModuleEditTableIndex { 
	deleteStatusLineModal: boolean;
	deleteStatusLineRow: object | null; 
}

const statusModuleEditTableIndex: Module<IStatusModuleEditTableIndex, unknown> = {
	namespaced: true,
	state: () => ({
		deleteStatusLineRow: null,
		deleteStatusLineModal: false,
	}),
	actions: { 
		SET_DELETE_STATUS_LINE_MODAL(context, args) {
			context.commit("SET_DELETE_STATUS_LINE_MODAL", args);
		},
		SET_DELETE_STATUS_LINE_ROW(context, args) {
			context.commit("SET_DELETE_STATUS_LINE_ROW", args);
		},
	},
	mutations: {
		SET_DELETE_STATUS_LINE_MODAL(state, args) {
			state.deleteStatusLineModal = args;
		},
		SET_DELETE_STATUS_LINE_ROW(state, args) {
			state.deleteStatusLineRow = args;
		},
	},
	getters: {
		Barcode(state, getters, rootState) { return true; },
		BeforeStockQuantity(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.BeforeStockQuantity", rootState, true); },
		BeforeTotalStockValue(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.BeforeTotalStockValue", rootState, true); },
		Quantity(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.Quantity", rootState, true); },
		LineQuantityValue(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.LineQuantityValue", rootState, true); },
		AdjustmentAmountBefore(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.AdjustmentAmountBefore", rootState, true); },
		AdjustmentPriceBefore(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.AdjustmentPriceBefore", rootState, true); },

		ProductSupplierNumber(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductSupplierNumber", rootState, false); },
		ProductSupplierName(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductSupplierName", rootState, false); },
		ProductNumber(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductNumber", rootState, true); },
		ProductName(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductName", rootState, true); },
		
		ProductColor(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductColor", rootState, true); },
		ProductSize(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductSize", rootState, true); },
		ProductCombination1(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductCombination1", rootState, true); },
		ProductCombination2(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductCombination2", rootState, true); },
		ProductCombination3(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductCombination3", rootState, true); },
		ProductCombination4(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductCombination4", rootState, true); },
		
		ProductBrandID(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductBrandID", rootState, false); },
		ProductBrandName(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductBrandName", rootState, false); },
		
		ProductFinancialGroupID(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductFinancialGroupID", rootState, false); },
		ProductFinancialGroupName(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductFinancialGroupName", rootState, false); },
		
		ProductDisplayGroupID(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductDisplayGroupID", rootState, false); },
		ProductDisplayGroupName(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductDisplayGroupName", rootState, false); },
		
		ExtendedProductInfoText1(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoText1", rootState, false); },
		ExtendedProductInfoText2(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoText2", rootState, false); },
		ExtendedProductInfoText3(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoText3", rootState, false); },
		ExtendedProductInfoText4(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoText4", rootState, false); },
		ExtendedProductInfoText5(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoText5", rootState, false); },
		ExtendedProductInfoText6(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoText6", rootState, false); },
		ExtendedProductInfoText7(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoText7", rootState, false); },
		ExtendedProductInfoText8(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoText8", rootState, false); },
		ExtendedProductInfoNumber1(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoNumber1", rootState, false); },
		ExtendedProductInfoNumber2(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoNumber2", rootState, false); },
		ExtendedProductInfoDate1(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoDate1", rootState, false); },
		ExtendedProductInfoMemo1(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoMemo1", rootState, false); },
		ExtendedProductInfoUrl1(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoUrl1", rootState, false); },
		ExtendedProductInfoUrl2(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoUrl2", rootState, false); },
		ExtendedProductInfoBoolean1(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoBoolean1", rootState, false); },
		ExtendedProductInfoBoolean2(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ExtendedProductInfoBoolean2", rootState, false); },
		
		ProductDcMatCostPrice(state, getters, rootState) { return getConfig("StatusModuleEditTableIndex.ColumnOptions.ProductDcMatCostPrice", rootState, true); },
 
		getDeleteStatusLineModal(state) { return state.deleteStatusLineModal; },
		getDeleteStatusLineRow(state) { return state.deleteStatusLineRow; },
		
		getColumnVisibility(state, getters, rootState, rootGetters) {
			const combinations = rootGetters["productConfigState/getCombinations"];
			const extendedProductInfo = rootGetters["productConfigState/getExtendedProductInfo"];

			return {
				Barcode: true,
				BeforeStockQuantity: getters.BeforeStockQuantity,
				BeforeTotalStockValue: getters.BeforeTotalStockValue,
				Quantity: getters.Quantity,
				LineQuantityValue: getters.LineQuantityValue,
				AdjustmentAmountBefore: getters.AdjustmentAmountBefore,
				AdjustmentPriceBefore: getters.AdjustmentPriceBefore,

				ProductNumber: getters.ProductNumber,
				ProductSupplierNumber: getters.ProductSupplierNumber,
				ProductSupplierName: getters.ProductSupplierName,
				ProductName: getters.ProductName,

				ProductColor: getters.ProductColor,
				ProductSize: getters.ProductSize,
				ProductCombination1: getters.ProductCombination1 && combinations.combination1 && combinations.combination1.Active,
				ProductCombination2: getters.ProductCombination2 && combinations.combination2 && combinations.combination2.Active,
				ProductCombination3: getters.ProductCombination3 && combinations.combination3 && combinations.combination3.Active,
				ProductCombination4: getters.ProductCombination4 && combinations.combination4 && combinations.combination4.Active,

				ProductBrandID: getters.ProductBrandID,
				ProductBrandName: getters.ProductBrandName,

				ProductFinancialGroupID: getters.ProductFinancialGroupID,
				ProductFinancialGroupName: getters.ProductFinancialGroupName,

				ProductDisplayGroupID: getters.ProductDisplayGroupID,
				ProductDisplayGroupName: getters.ProductDisplayGroupName,

				ExtendedProductInfoText1: getters.ExtendedProductInfoText1 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText2: getters.ExtendedProductInfoText2 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText3: getters.ExtendedProductInfoText3 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText4: getters.ExtendedProductInfoText4 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText5: getters.ExtendedProductInfoText5 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText6: getters.ExtendedProductInfoText6 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText7: getters.ExtendedProductInfoText7 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText8: getters.ExtendedProductInfoText8 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoNumber1: getters.ExtendedProductInfoNumber1 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoNumber2: getters.ExtendedProductInfoNumber2 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoDate1: getters.ExtendedProductInfoDate1 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoMemo1: getters.ExtendedProductInfoMemo1 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoUrl1: getters.ExtendedProductInfoUrl1 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoUrl2: getters.ExtendedProductInfoUrl2 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoBoolean1: getters.ExtendedProductInfoBoolean1 && extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoBoolean2: getters.ExtendedProductInfoBoolean2 && extendedProductInfo.extendedProductInfoActive,
				
				ProductDcMatCostPrice: getters.ProductDcMatCostPrice,
			};
		},
		getConfigVisibility(state, getters, rootState, rootGetters) {
			const combinations = rootGetters["productConfigState/getCombinations"];
			const extendedProductInfo = rootGetters["productConfigState/getExtendedProductInfo"];
			
			return {
				Barcode: true,
				BeforeStockQuantity: true,
				BeforeTotalStockValue: true,
				Quantity: true,
				LineQuantityValue: true,
				AdjustmentAmountBefore: true,
				AdjustmentPriceBefore: true,

				ProductNumber: true,
				ProductSupplierNumber: true,
				ProductSupplierName: true,
				ProductName: true,
				
				ProductColor: true,
				ProductSize: true,
				ProductCombination1: combinations.combination1 && combinations.combination1.Active,
				ProductCombination2: combinations.combination2 && combinations.combination2.Active,
				ProductCombination3: combinations.combination3 && combinations.combination3.Active,
				ProductCombination4: combinations.combination4 && combinations.combination4.Active,

				ProductBrandID: true,
				ProductBrandName: true,

				ProductFinancialGroupID: true,
				ProductFinancialGroupName: true,

				ProductDisplayGroupID: true,
				ProductDisplayGroupName: true,

				ExtendedProductInfoText1: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText2: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText3: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText4: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText5: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText6: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText7: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoText8: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoNumber1: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoNumber2: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoDate1: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoMemo1: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoUrl1: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoUrl2: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoBoolean1: extendedProductInfo.extendedProductInfoActive,
				ExtendedProductInfoBoolean2: extendedProductInfo.extendedProductInfoActive,
				
				ProductDcMatCostPrice: true,
			};
			
		}
	}
};

export default statusModuleEditTableIndex;