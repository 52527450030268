import {Module} from "vuex";

interface ISyncQueueJobTableIndexState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const syncJobQueueTableIndexState: Module<ISyncQueueJobTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        StatusTxt(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.StatusTxt", rootState, true); },
        JobTypeName(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.JobTypeName", rootState, true); },
        RepeatInterval(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.Repeat", rootState, true); },
        RunTime(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.RunTime", rootState, true); },
        StartTime(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.StartTime", rootState, true); },
        FinishedTime(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.FinishedTime", rootState, true); },
        Progress(state, getters, rootState) { return getConfig("SyncJob.ColumnOptions.Progress", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                StatusTxt: getters.StatusTxt,
                JobTypeName: getters.JobTypeName,
                RepeatInterval: getters.RepeatInterval,
                RunTime: getters.RunTime,
                StartTime: getters.StartTime,
                FinishedTime: getters.FinishedTime,
                Progress: getters.Progress,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                FullStatusText: true,
                JobTypeName: true,
                RepeatInterval: true,
                RunTime: true,
                StartTime: true,
                FinishedTime: true,
                Progress: true,
            };
        }
    }
}

export default syncJobQueueTableIndexState;