import {Module} from "vuex";

interface IDynamicReportState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const dynamicReportState: Module<IDynamicReportState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        Test(state, getters, rootState) { return getConfig("Reports.ColumnOptions.Test", rootState, true); },
        Execution(state, getters, rootState) { return getConfig("Reports.ColumnOptions.Execution", rootState, true); },
        Name(state, getters, rootState) { return getConfig("Reports.ColumnOptions.Name", rootState, true); },
        DeliveryTypeName(state, getters, rootState) { return getConfig("Reports.ColumnOptions.DeliveryTypeName", rootState, true); },
        ConnectionTypeName(state, getters, rootState) { return getConfig("Reports.ColumnOptions.ConnectionTypeName", rootState, true); },
        Splitter(state, getters, rootState) { return getConfig("Reports.ColumnOptions.Splitter", rootState, true); },
        ShowDates(state, getters, rootState) { return getConfig("Reports.ColumnOptions.ShowDates", rootState, true); },
        ShowHeaders(state, getters, rootState) { return getConfig("Reports.ColumnOptions.ShowHeaders", rootState, true); },
        ShowOnParentStores(state, getters, rootState) { return getConfig("Reports.ColumnOptions.ShowOnParentStores", rootState, true); },
        TemplateId(state, getters, rootState) { return getConfig("Reports.ColumnOptions.TemplateId", rootState, true); },
        ColumnCount(state, getters, rootState) { return getConfig("Reports.ColumnOptions.ColumnCount", rootState, true); },
		CommandTimeout(state, getters, rootState) { return getConfig("Reports.ColumnOptions.CommandTimeout", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Test: getters.Test,
                Execution: getters.Execution,
                Name: getters.Name,
                DeliveryTypeName: getters.DeliveryTypeName,
                ConnectionTypeName: getters.ConnectionTypeName,
                Splitter: getters.Splitter,
                ShowDates: getters.ShowDates,
                ShowHeaders: getters.ShowHeaders,
                ShowOnParentStores: getters.ShowOnParentStores,
                TemplateId: getters.TemplateId,
                ColumnCount: getters.ColumnCount,
				CommandTimeout: getters.CommandTimeout,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Test: true,
                Execution: true,
                Name: true,
                DeliveryTypeName: true,
                ConnectionTypeName: true,
                Splitter: true,
                ShowDates: true,
                ShowHeaders: true,
                ShowOnParentStores: true,
                TemplateId: true,
                ColumnCount: true,
				CommandTimeout: true,
            };
        }
    }
}

export default dynamicReportState;