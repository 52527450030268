



































































































































import ValidationCustomField from "@components/forms/validation-custom-field.vue";
import {UserApi} from "@services/Autogenerated_v4/V1/api/user-api";
import {zxcvbn} from '@zxcvbn-ts/core'
import {pwnedPassword} from 'hibp';

export default {
	name: "validation-password-field",
	components: {ValidationCustomField},
	watch: {
		"validation.$model": {
			handler: async function (val, oldVal) {
				if(this.hasPasswordPawned) await this.checkHasPasswordBeenPwned(val);
				if (!this.hasPasswordStrength) return {score: null};
				if (val == null || val === "") return {score: null};
				this.passwordStrength = zxcvbn(val);
			},
			immediate: true,
			deep: true,
		},
	},
	props: {
		validation: {required: true},
		id: {required: true},
		text: {required: true},
		zIndex: {required: false, default: null},
		labelShown: {required: false, default: true},
		disabled: {required: false, default: false},
		readonly: {required: false, default: false},
		sameAsOtherResource: {required: false, default: null},
		hasGeneratePassword: {required: false, default: true},
		hasPasswordStrength: {required: false, default: false},
		hasPasswordPawned: {required: false, default: false},
	},
	data() {
		return {
			UserService: new UserApi(this.$swaggerAxiosOptions),
			hidden: true,
			passwordStrength: {score: 0},
			passwordWarning: false,
		}
	},
	methods: {
		async checkHasPasswordBeenPwned(val) {
			let check = await pwnedPassword(val) > 0;
			this.passwordWarning = check;
			this.$emit("password-error", this.passwordWarning);
		},
		async generatePassword() {
			let result = await this.UserService.generatePassword();
			this.validation.$model = result.data;
		},
		hiddenToggle() {
			this.hidden = !this.hidden;
		},
	},
}
