



































































































import {mapActions, mapGetters} from "vuex";
import FpModal from "fp-components/modals/fp-modal.vue";
import UserProfileAccess from "fp-components/top-menu/user-profile-modal/user-profile-access.vue";
import ChangePasswordModal from "fp-components/top-menu/user-profile-modal/change-password-modal.vue";
import {UserApi} from "fp-services/Autogenerated_v4/V1/api/user-api";
import ValidationPanel from "@components/ValidationPanel.vue";

export default {
	name: "user-profile-modal",
	components: {ChangePasswordModal, UserProfileAccess, FpModal, ValidationPanel},
	props: {
		value: {
			required: true,
			validator: function (value) {
				return typeof value === "boolean";
			}
		}
	},
	data() {
		return {
			state: "awaiting-input",
			transition: false,
			val: this.value,
			backdrop: null,

			// change password modal
			changePasswordModal: false,

			// tabs
			activeTab: "main",
			inlineLanguageTextEditor: false,

			validationErrors: [],
			user: {
				CellPhone: "",
				Phone: "",
				Email: "",
				Name: "",
			},
			UserService: new UserApi(this.$swaggerAxiosOptions),
		}
	},
	watch: {
		val(val, old) {
			this.$emit('input', val)
		},
		value(val, old) {
			if (val !== old) {
				this.val = val;
				this.inlineLanguageTextEditor = this.getInlineLanguageTextEditor;
			}
		},
		inlineLanguageTextEditor(val, old) {
			if (val !== old) {
				this.setInlineLanguageTextEditor(val);
				this.inlineLanguageTextEditor = val;
			}
		}
	},
	computed: {
		...mapGetters({
			activeModal: "getActiveModal",
			getUserName: "getUserName",
			getUserUID: "getUserUID",
			getUserCellPhone: "getUserCellPhone",
			getUserPhone: "getUserPhone",
			getUserEmail: "getUserEmail",
			getUserIsAdmin: "getUserIsAdmin",
			getIsFlexPosAdmin: "getIsFlexPosAdmin",
			getInlineLanguageTextEditor: "getInlineLanguageTextEditor",
		}),
		AWAITING_INPUT() {
			return "awaiting-input"
		},
		AWAITING_ACTION() {
			return "awaiting-action"
		},
		SUCCESS() {
			return "success"
		},
		NOT_STARTED() {
			return "not-started"
		},
		RUNNING() {
			return "running"
		},
	},
	methods: {
		...mapActions({setInlineLanguageTextEditor: "SET_INLINE_LANGUAGE_TEXT_EDITOR"}),
		checkName() {
			this.validationErrors = [];

			if (this.user.Name === "") {
				this.validationErrors.push(Resource.UserName + ", " + Resource.CannotBeEmpty);
			}
		},
		checkEmail() {
			this.validationErrors = [];

			if (this.user.Email === "") {
				this.validationErrors.push(Resource.Email + ", " + Resource.CannotBeEmpty);
			}
		},
		checkInfo() {
			if (this.user.Name === "") {
				this.validationErrors.push(Resource.Email + ", " + Resource.CannotBeEmpty);

				if (this.user.Email === "") {
					this.validationErrors.push(Resource.UserName + ", " + Resource.CannotBeEmpty);
				}
			}
		},
		trans(val) {
			this.transition = val;
		},
		async ok(event) {
			await this.checkInfo();
			if (this.validationErrors > 0) return;
			
			await this.UserService.save({
				uid: this.getUserUID,
				name: this.user.Name == null ? "" : this.user.Name,
				email: this.user.Email == null ? "" : this.user.Email,
				cellPhone: this.user.CellPhone == null ? "" : this.user.CellPhone,
				phone: this.user.Phone == null ? "" : this.user.Phone,
			})
				
			this.state = this.AWAITING_ACTION;
			this.$emit("ok-clicked")
			this.close(event);
			this.$emit("ok");
		},
		confirm(event) {
			this.ok(event);
		},
		close(event) {
			this.val = false;
			this.$emit("closing")
		},
		closed() {
			this.state = this.AWAITING_INPUT;
		},
		cancel(event) {
			this.val = false;
			this.$emit("cancel")
			this.$emit("closing")
		},
		browserEvent(event) {
			if (!(event && (event.code === "Escape" || event.code === "Enter"))) return;
			if (event.defaultPrevented || this.val === false) {
				return;
			}
			const activeModal = this.activeModal();
			if (activeModal.$el !== this.$refs.modal.$el) return;

			if (event.key === "Escape") {
				if (this.state === this.AWAITING_INPUT)
					this.cancel(event)
				else
					this.val = false
			} else if (event.key === "Enter") {
				if (this.state === this.AWAITING_INPUT)
					this.confirm(event)
				else
					this.val = false
			}
		}
	},
	async mounted() {
		window.addEventListener("keyup", this.browserEvent);
		this.val = this.value;

		this.user.CellPhone = this.getUserCellPhone;
		this.user.Phone = this.getUserPhone;
		this.user.Email = this.getUserEmail;
		this.user.Name = this.getUserName;
		this.user.IsAdmin = this.getUserIsAdmin;
	},
	beforeDestroy() {
		window.removeEventListener("keyup", this.browserEvent);
	},
}
