import {Module} from "vuex";
import {UnitApi} from "../../_Services/Autogenerated_v4/V1/api/unit-api";
import {
	APIInternalFrontendAPIModelsUnitUnitVersionTooltipModel
} from "../../_Services/Autogenerated_v4/V1/models";
let UnitService: UnitApi;

interface IStringDictionary<V> {
	[index: string]: V;
}
interface IResellerTableIndexState {
    savedSearchParams: object;
	cachedTooltipUnitVersion: IStringDictionary<object>;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const resellerTableIndexState: Module<IResellerTableIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null,
		cachedTooltipUnitVersion: {},
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
		async FETCH_UNIT_TOOLTIP_BY_CLIENT_UID(context, clientUid) {
			if (!UnitService) {
				UnitService = new UnitApi(context.rootGetters["getSwaggerAxiosOptions"]);
			}
			return (await UnitService.getUnitTooltipByClientUid({clientUid: clientUid})).data;
		},

		async SET_UNIT_TOOLTIP_BY_CLIENT_UID(context, args: {uid: string, obj: APIInternalFrontendAPIModelsUnitUnitVersionTooltipModel[] }) {
			context.commit("SET_UNIT_TOOLTIP", args);
		},
		async getCachedTooltipUnitVersion(context, uid) {
			let stocks = context.getters.getCachedTooltipUnitVersion(uid);
			if (!stocks) {
				stocks = await context.dispatch("FETCH_UNIT_TOOLTIP_BY_CLIENT_UID", uid);
				await context.dispatch("SET_UNIT_TOOLTIP_BY_CLIENT_UID", { uid: uid, obj: stocks });
			}
			return stocks;
		},
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
		SET_UNIT_TOOLTIP(state, args: {uid: string, obj: APIInternalFrontendAPIModelsUnitUnitVersionTooltipModel[] }) {
			state.cachedTooltipUnitVersion[args.uid] = args.obj;
		},
		RESET_UNIT_TOOLTIP(state) {
			state.cachedTooltipUnitVersion = {};
		},
    },
    getters: {
		getCachedTooltipUnitVersion(state) {
			return (uid: string) => {
				return state.cachedTooltipUnitVersion[uid];
			};
		},
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        Name(state, getters, rootState) { return getConfig("Reseller.ColumnOptions.Name", rootState, true); },
        ID(state, getters, rootState) { return getConfig("Reseller.ColumnOptions.ID", rootState, true); },
        ParentName(state, getters, rootState) { return getConfig("Reseller.ColumnOptions.ParentName", rootState, true); },
        childCount(state, getters, rootState) { return getConfig("Reseller.ColumnOptions.childCount", rootState, true); },
        CustomerType(state, getters, rootState) { return getConfig("Reseller.ColumnOptions.CustomerType", rootState, true); },
        ReleaseType(state, getters, rootState) { return getConfig("Reseller.ColumnOptions.ReleaseType", rootState, true); },
        GetDataFromParent(state, getters, rootState) { return getConfig("Reseller.ColumnOptions.GetDataFromParent", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Name: getters.Name,
                ID: getters.ID,
                ParentName: getters.ParentName,
                childCount: getters.childCount,
                CustomerType: getters.CustomerType,
                ReleaseType: getters.ReleaseType,
                GetDataFromParent: getters.GetDataFromParent,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Name: true,
                ID: true,
                ParentName: true,
                childCount: true,
                CustomerType: true,
                ReleaseType: true,
                GetDataFromParent: true,
            };
        }
    }
}

export default resellerTableIndexState;