































































import Spinner from "@components/vue-strap/Spinner.vue";
import isNullOrEmpty from "@app/isNullOrEmpty";
import LoadError from "@components/load-error.vue";

export default {
	name: "fp-panel",
	components: {LoadError, Spinner},
	props: {
		title: {
			validator: function (value) {
				return typeof value === "string";
			}
		},
		hasLoadingIcon: {
			type: Boolean,
			default: false
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		loadError: {
			type: Boolean,
			default: false
		},
		loadErrorText: {
			type: String,
			default() {
				return this.Resource.ServerError
			}
		},
		customBodyHeight: {
			type: Number,
			default: 0
		},
		hasToggle: {
			type: Boolean,
			default: false
		},
		defaultIdField: {default: "main"},
		hasHeader: {default: true, type: Boolean},
		hasSideBar: {default: true, type: Boolean},
		tabsActive: {default: true, type: Boolean},
		disableListener: {default: false, type: Boolean},
		setHash: {default: true, type: Boolean}
	},
	data() {
		return {
			tabs: [],
			activeTab: null,
			firstRender: true,
			stopNextScroll: false,
			windowWidth: 1900,
		};
	},
	computed: {
		bodyHeight() {
			return `calc(100vh - ${160 + this.customBodyHeight}px)`;
		}
	},
	watch: {
		tabsActive(val, oldVal) {
			if (val != oldVal && val) {
				this.$nextTick(() => this.listener(null));
			}
		},
		isLoading(val, oldVal) {
			if (val != oldVal && !val) {
				this.$nextTick(() => this.listener(null));
			}
		},
		activeTab(val, oldVal) {
			if (val != oldVal) {
				this.$nextTick(() => this.$emit("activeTabChanged", val));
			}
		}
	},
	created() {
		this._isPanel = true;
		this._hasToggle = this.hasToggle;
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = document.documentElement.clientWidth;
			if (this.$refs != null && this.$refs.topMenu != null && this.$refs.dPanelContentBody != null && this.$refs.dPanelContentBody.style != null) {
				this.$refs.dPanelContentBody.style.maxHeight = `calc(100vh - ${160 + this.customBodyHeight + (this.$refs.topMenu == null ? 0 : this.$refs.topMenu.clientHeight)}px)`;
			}
		}
		document.addEventListener("scroll", this.listener);
		this.listener(null);
	},
	methods: {
		scroll() {
			if (isNullOrEmpty(this.activeTab)) return;
			if(this.$refs.panel == null) return;
			let element = this.$refs.panel.querySelector("#?" + this.activeTab.replace("?", ""));
			if (element == null) return;

			let body = this.$refs.panel.querySelector(".fp-panel-content-body");
			if(body == null) return;
			body.scrollTo(element.offsetLeft, element.offsetTop);
		},
		listener(e) {
			if (!this.hasSideBar) return;
			if (!this.tabsActive) return;
			if (this.stopNextScroll) {
				this.stopNextScroll = false;
				return;
			}
			if (this.firstRender) {
				if(this.tabs.length === 0) return;
				
				this.firstRender = false;
				let handled = false;
				if (location.hash.length !== 0) {
					const idField = location.hash.replace("#?", "");
					for (let i = 0; i < this.tabs.length; i++) {
						if (this.tabs[i].idField === idField) {
							this.setTab(idField, true);
							
							this.stopNextScroll = true;
							handled = true;
							break;
						}
					}
					
					if(this.stopNextScroll) return;
				}

				if (!handled) {
					if (this.tabs.length === 0) this.setTab(this.defaultIdField);
					else {
						const defaultTab = this.tabs.filter(m => m.defaultActive);
						if (defaultTab.length != 0)
							this.setTab(defaultTab[0].idField, true);
						else
							this.setTab(this.tabs[0].idField, true);
					}

					return;
				}
			}

			if (this.disableListener) return;

			let sectionPositions = [];
			for (let i = 0; i < this.tabs.length; i++) {
				const tab = this.tabs[i];
				let element = this.$refs.panel.querySelector("#" + tab.idField);
				let offsetTop = 0;
				if (!this._hasToggle) {
					offsetTop = element.offsetTop + element.offsetHeight;
				}
				sectionPositions.push({idField: tab.idField, offsetTop: offsetTop - 4})
			}

			let panelScrollTop = e === null ? 0 : e.srcElement.scrollTop;
			sectionPositions.reverse();
			let activeTab = null;
			if (panelScrollTop === 0 && sectionPositions.length > 0) {
				activeTab = sectionPositions[sectionPositions.length - 1].idField;
			} else {
				for (let i = 0; i < sectionPositions.length; i++) {
					const sectionPosition = sectionPositions[i];
					if (sectionPosition.offsetTop >= panelScrollTop) {
						activeTab = sectionPosition.idField;
					}
				}
			}

			if (activeTab) {
				this.setTab(activeTab);
			}
		},
		setTab(idField, scrollIntoView = false) {
			if (idField.indexOf("#?") > -1)
				idField = idField.replace("#?", "");

			const tab = this.tabs.filter(m => m.idField == idField);
			if (tab.length === 0) {
				if (this.tabs.length === 0) idField = "main";
				else idField = this.tabs[0].idField;
			}

			let idFieldId = idField;
			if (idFieldId.indexOf("#") == -1)
				idFieldId = "#" + idFieldId;

			this.activeTab = idField;
			if (this.$route.hash !== "#?" && this.$route.hash !== "#?" + idField) {
				if (this.setHash) this.$router.push({hash: "#?" + idField, query: this.$route.query});
			}

			if (scrollIntoView) {
				let item = this.$refs.panel.querySelector(idFieldId);
				if (item != null && scrollIntoView) {
					this.$emit("active-tab-changed", idFieldId);
					this.$nextTick(() => item.scrollIntoView());
				}
			}
		}
	}
};
