import {Module} from "vuex";
import { NotificationApi } from "../../_Services/Autogenerated_v4/V1/api/notification-api";
let NotificationService: NotificationApi;

import {
    APIInternalFrontendAPIDataAccessEntitiesNews,
    APIInternalFrontendAPIDataAccessEntitiesNotifications
} from "../../_Services/Autogenerated_v4/V1/models";

interface INotificationState {
    notifications: APIInternalFrontendAPIDataAccessEntitiesNews[];
    purchaseOrderCount: number;
    salesOrderCount: number;
    newsCount: number;
}

const notificationState: Module<INotificationState, any> = {
    namespaced: true,

    state: () => ({
        notifications: [],
        purchaseOrderCount: 0,
        salesOrderCount: 0,
        newsCount: 0
    }),
    actions: {
        async FETCH_DOTS({ state, commit, rootState, getters, rootGetters }) {
            if (!NotificationService) {
                NotificationService = new NotificationApi(rootGetters["getSwaggerAxiosOptions"])
            }
            
            return (await NotificationService.getDots({showVendor: rootGetters["getFlexPosConfigBool"]("VendorOrdersEnabled")})).data;
        },
        async SET_DOTS({ state, commit, dispatch, rootState, getters }) {
            let dots = await dispatch("FETCH_DOTS")
            commit("SET_DOTS", dots)
        },
        async FETCH_NOTIFICATIONS({ state, commit, rootState, getters, rootGetters }, showInactive = true) {
            if (!NotificationService) {
                NotificationService = new NotificationApi(rootGetters["getSwaggerAxiosOptions"])
            }

			return (await NotificationService.getAllForLocations({ Areas: getters.getAreas.join(","), ShowInactive: showInactive})).data;
		},
        async SET_NOTIFICATIONS({ state, commit, dispatch, rootState, getters }) {
            let notifications = await dispatch("FETCH_NOTIFICATIONS", false);
            commit("SET_NOTIFICATIONS", notifications);
        },

		async setNotifications({ state, commit, dispatch, rootState, getters }) {
            await Promise.all([dispatch("SET_NOTIFICATIONS"), dispatch("SET_DOTS")])
        },
        async markAllNotificationsRead({ state, commit, dispatch, rootState, getters }) {
            await NotificationService.setAllRead();
            await dispatch("SET_NOTIFICATIONS");
        },
        async markAllNotificationsHidden({ state, commit, dispatch, rootState, getters }) {
            await NotificationService.setAllHidden();
            await dispatch("SET_NOTIFICATIONS");
        },
        async markNotificationRead({ state, commit, dispatch, rootState, getters }, uid) {
            await NotificationService.setRead({uid: uid});
            await dispatch("SET_NOTIFICATIONS");
        },
        async markNotificationHidden({ state, commit, dispatch, rootState, getters }, uid) {
            await NotificationService.setHidden({uid: uid});
            await dispatch("SET_NOTIFICATIONS");
        }
    },
    mutations: {
        SET_NOTIFICATIONS(state, newState) {
            state.notifications = newState;
        },
        SET_DOTS(state, newState: APIInternalFrontendAPIDataAccessEntitiesNotifications) {
            state.purchaseOrderCount = newState.PurchaseOrders || 0;
            state.salesOrderCount = newState.SalesOrders || 0;
            state.newsCount = newState.News || 0;
        }        
    },
    getters: {
        getPurchaseOrderCount(state) {
            return state.purchaseOrderCount;
        },
        getSalesOrderCount(state) {
            return state.salesOrderCount;
        },
        getNewsCount(state) {
            return state.newsCount;
        },
        getAreaNotifications(state) {
            return (area: any): APIInternalFrontendAPIDataAccessEntitiesNews[] => {
                return state.notifications.filter(m => m.Area === area);
            }
        },
        getNotifications(state) {
            return state.notifications;
        },
        getAreas(state, getters, rootState, rootGetters) {
			let areas = [0, 1];

			if (rootGetters["navigationGuardState/getIsStatisticsAllowed"])
				areas.push(2);

			if (rootGetters["navigationGuardState/getIsProductsAllowed"])
				areas.push(3, 7, 8, 9);

			if (rootGetters["navigationGuardState/getIsProductsAllowed"] && rootGetters["activeAddonsState/getIsSimpleStockActive"])
				areas.push(4, 17);

			if (rootGetters["navigationGuardState/getIsProductListsAllowed"])
				areas.push(5);

			if (rootGetters["navigationGuardState/getIsWarrantyProfilesAllowed"])
				areas.push(6);

			if (rootGetters["navigationGuardState/getIsCustomersAllowed"])
				areas.push(10, 11);

			if (rootGetters["navigationGuardState/getIsVendorModuleAllowed"])
				areas.push(12, 13, 14);

			if (!rootGetters["getUserIsDegraded"] && rootGetters["navigationGuardState/getIsReportsAllowed"])
				areas.push(15);

			if (rootGetters["navigationGuardState/getIsTransactionHistoryAllowed"])
				areas.push(16);

			if (rootGetters["navigationGuardState/getIsGiftcardsAllowed"])
				areas.push(18);

			if (rootGetters["navigationGuardState/getIsCampaignAllowed"])
				areas.push(19);

			if (rootGetters["getBackendConfigBool"]("UserAccess.LanguageManagementModule", true) && rootGetters["activeAddonsState/getIsResourceActive"])
				areas.push(20);

			if (rootGetters["activeAddonsState/getIsFlexVoucherActive"])
				areas.push(21);

			if (rootGetters["navigationGuardState/getIsCurrencyAllowed"])
				areas.push(22);

			if (rootGetters["navigationGuardState/getIsModulesAllowed"])
				areas.push(23);

			if (rootGetters["navigationGuardState/getIsPosUsersAllowed"])
				areas.push(24);

			if (rootGetters["navigationGuardState/getIsUsersAllowed"])
				areas.push(25);

			if (rootGetters["navigationGuardState/getIsFlexAdAllowed"])
				areas.push(26);

			if (rootGetters["navigationGuardState/getIsFlexTicketConfigAllowed"])
				areas.push(27);

			if (rootGetters["navigationGuardState/getIsBonLayoutAllowed"])
				areas.push(28);

			if (rootGetters["navigationGuardState/getIsDelfiBreeceAllowed"])
				areas.push(29);

			if (rootGetters["navigationGuardState/getIsCardGroupAllowed"])
				areas.push(30);

			if (rootGetters["navigationGuardState/getIsImportAllowed"])
				areas.push(31, 32);

			if (rootGetters["navigationGuardState/getIsCustomerStatisticsAllowed"])
				areas.push(33);

			if (rootGetters["navigationGuardState/getIsDesignAndLayoutAllowed"])
				areas.push(34);

			if (rootGetters["navigationGuardState/getIsEDAccountAllowed"])
				areas.push(35);

			if (rootGetters["navigationGuardState/getIsExchangeLabelsAllowed"])
				areas.push(36);

			if (rootGetters["navigationGuardState/getIsFlexPosConfigAllowed"])
				areas.push(37);

			if (rootGetters["navigationGuardState/getIsFlexPosConfigDynamicAllowed"])
				areas.push(38);

			if (rootGetters["navigationGuardState/getIsLoyaltyAllowed"])
				areas.push(39);

			if (rootGetters["navigationGuardState/getIsMobilePayAllowed"])
				areas.push(40);

			if (rootGetters["navigationGuardState/getIsPaymentTypeAllowed"])
				areas.push(41);

			if (rootGetters["navigationGuardState/getIsSameSystemAllowed"])
				areas.push(42);

			if (rootGetters["navigationGuardState/getIsSyncJobAllowed"])
				areas.push(43);

			if (rootGetters["getIsReseller"])
				areas.push(44);

			if (rootGetters["navigationGuardState/getIsSAFTAllowed"])
				areas.push(45);

			if (rootGetters["navigationGuardState/getIsCashManagementAllowed"])
				areas.push(46);
			
			return areas;
        }
    }
}

export default notificationState;