import {Module} from "vuex";

interface ICustomerStatisticsIndexState {
	hasParentData: boolean;
}
const customerStatisticsIndexState: Module<ICustomerStatisticsIndexState, any> = {
    namespaced: true,
    
    state: () => ({
        hasParentData: false,
    }),
    actions: {
		SET_HAS_PARENT_DATA(context, args) {
            context.commit("SET_HAS_PARENT_DATA", args)
        },
    },
    mutations: {
		SET_HAS_PARENT_DATA(state, newState) {
            state.hasParentData = newState;
        }, 
    },
    getters: {
        getHasParentData(state, getters) {
            return state.hasParentData
        },
    }
}

export default customerStatisticsIndexState;