import {Module} from "vuex";

function getConfig(key, rootState, defaultValue) {
	const keyValue = rootState.backendConfigs[key];
	if (keyValue !== null && keyValue !== undefined)
		return keyValue.toLowerCase() === "true";

	return defaultValue;
}
const statusModuleNotApprovedTableIndex: Module<unknown, unknown> = {
	namespaced: true,
	state: () => ({}),
	actions: {},
	mutations: {},
	getters: {
		CreatedDate(state, getters, rootState) { return getConfig("StatusModuleNotApprovedIndex.ColumnOptions.CreatedDate", rootState, true); },
		EmpNo(state, getters, rootState) { return getConfig("StatusModuleNotApprovedIndex.ColumnOptions.EmpNo", rootState, true); },
		Free1(state, getters, rootState) { return getConfig("StatusModuleNotApprovedIndex.ColumnOptions.Free1", rootState, true); },
		LocationID(state, getters, rootState) { return getConfig("StatusModuleNotApprovedIndex.ColumnOptions.LocationID", rootState, true); },
		ReadyToSync(state, getters, rootState) { return getConfig("StatusModuleNotApprovedIndex.ColumnOptions.ReadyToSync", rootState, true); },
		NumberOfLines(state, getters, rootState) { return getConfig("StatusModuleNotApprovedIndex.ColumnOptions.NumberOfLines", rootState, true); },
		getColumnVisibility(state, getters) {
			return {
				CreatedDate: getters.CreatedDate,
				EmpNo: getters.EmpNo,
				Free1: getters.Free1,
				LocationID: getters.LocationID,
				ReadyToSync: getters.ReadyToSync,
				NumberOfLines: getters.NumberOfLines
			};
		},
		getConfigVisibility() {
			return {
				CreatedDate: true,
				EmployeeID: true,
				Free1: true,
				LocationID: true,
				ReadyToSync: true,
				NumberOfLines: true,
			};
			
		}
	}
};

export default statusModuleNotApprovedTableIndex;