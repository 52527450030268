import {Module} from "vuex";

function getConfig(key, rootState, defaultValue) {
	const keyValue = rootState.backendConfigs[key];
	if (keyValue !== null && keyValue !== undefined)
		return keyValue.toLowerCase() === "true";

	return defaultValue;
}
const productStockDetailsTableState: Module<any, any> = {
	namespaced: true,
	getters: {
		TransactionReferenceUid(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.TransactionReferenceUid", rootState, true); },
		Uid(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.Uid", rootState, true); },
		CreatedDate(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.CreatedDate", rootState, true); },
		LastUpdated(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.LastUpdated", rootState, false); },
		Quantity(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.Quantity", rootState, true); },
		StockMovement(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.StockMovement", rootState, true); },
		Type(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.Type", rootState, true); },
		Status(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.Status", rootState, true); },
		Reason(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.Reason", rootState, true); },
		CostPrice(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.CostPrice", rootState, false); },
		SalesPrice(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.SalesPrice", rootState, false); },
		Reference(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.Reference", rootState, false); },
		ChangedBy(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.ChangedBy", rootState, true); },
		Username(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.Username", rootState, true); },
		UserComment(state, getters, rootState) { return getConfig("ProductStockDetails.ColumnOptions.UserComment", rootState, true); },
		getColumnVisibility(state, getters, rootState, rootGetters) {
			const getIsFlexPosAdmin = rootGetters["getIsFlexPosAdmin"];
			const getIsFlexPosReseller = rootGetters["getIsFlexPosReseller"];
			const getActiveAddons = rootGetters.getActiveAddons("1205C5D0-A88A-4549-BA54-E761938DC03B", false);
			
			return {
				TransactionReferenceUid: getters.TransactionReferenceUid && (getIsFlexPosReseller || getIsFlexPosAdmin),
				Uid: getters.Uid && (getIsFlexPosReseller || getIsFlexPosAdmin),
				CreatedDate: getters.CreatedDate,
				LastUpdated: getters.LastUpdated,
				Quantity: getters.Quantity,
				StockMovement: getters.StockMovement,
				Type: getters.Type,
				Status: getters.Status,
				Reason: getters.Reason,
				CostPrice: getters.CostPrice,
				SalesPrice: getters.SalesPrice,
				Reference: getters.Reference,
				ChangedBy: getters.ChangedBy,
				Username: getters.Username,
				Comment: getters.UserComment,
			};
		},
		getConfigVisibility(state, getters, rootState, rootGetters) {
			const getIsFlexPosAdmin = rootGetters["getIsFlexPosAdmin"];
			const getIsFlexPosReseller = rootGetters["getIsFlexPosReseller"];
			
			return {
				TransactionReferenceUid: getIsFlexPosReseller || getIsFlexPosAdmin,
				Uid: getIsFlexPosReseller || getIsFlexPosAdmin,
				CreatedDate: true,
				LastUpdated: true,
				Quantity: true,
				StockMovement: true,
				Type: true,
				Status: true,
				Reason: true,
				CostPrice: true,
				SalesPrice: true,
				Reference: true,
				ChangedBy: true,
				Username: true,
				UserComment: true,
			};
		}
	}
};

export default productStockDetailsTableState;