/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesProductStockDetail } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesProductStockDetailAndCombination } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesStockTransaction } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsProductStockProductStockTableModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsProductStockReceiveProductsFromStoreResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsProductStockEditProductStockResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsProductStockGetAvailableStockClientsResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsProductStockGetDetailsProductStockResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsProductStockGetEditProductStockResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsProductStockGetTransactionsByDateResponseModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsProductStockProductStockModalSearchViewModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsProductStockProductStockModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsProductStockProductStockTooltipModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel } from '../models';
/**
 * ProductStockApi - axios parameter creator
 * @export
 */
export const ProductStockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIDataAccessEntitiesStockTransaction} [APIInternalFrontendAPIDataAccessEntitiesStockTransaction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV10ProductstockPost: async (APIInternalFrontendAPIDataAccessEntitiesStockTransaction?: APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIDataAccessEntitiesStockTransaction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV10ProductstockProductUidGet: async (productUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUid' is not null or undefined
            assertParamExists('apiV10ProductstockProductUidGet', 'productUid', productUid)
            const localVarPath = `/api/v1.0/productstock/{productUid}`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIDataAccessEntitiesStockTransaction} [APIInternalFrontendAPIDataAccessEntitiesStockTransaction] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        create: async (APIInternalFrontendAPIDataAccessEntitiesStockTransaction?: APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIDataAccessEntitiesStockTransaction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel} [APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProductStock: async (uid: string, APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel?: APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('editProductStock', 'uid', uid)
            const localVarPath = `/api/v1.0/productstock/edit-product-stock/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableStockClients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/get-available-stock-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetails: async (productUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUid' is not null or undefined
            assertParamExists('getDetails', 'productUid', productUid)
            const localVarPath = `/api/v1.0/productstock/get-details/{productUid}`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditProductStock: async (productUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUid' is not null or undefined
            assertParamExists('getEditProductStock', 'productUid', productUid)
            const localVarPath = `/api/v1.0/productstock/get-edit-product-stock/{productUid}`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModalSearches: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/get-modal-searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaged: async (sort?: string, order?: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/get-paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleByProductUid: async (productUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/get-single-by-product-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productUid !== undefined) {
                localVarQueryParameter['productUid'] = productUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/get-stock-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTooltipByProductUid: async (productUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/get-stock-tooltip-by-product-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productUid !== undefined) {
                localVarQueryParameter['productUid'] = productUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {boolean} [isCombinationMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStocksByProductUid: async (productUid?: string, isCombinationMaster?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/get-stocks-by-product-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productUid !== undefined) {
                localVarQueryParameter['productUid'] = productUid;
            }

            if (isCombinationMaster !== undefined) {
                localVarQueryParameter['isCombinationMaster'] = isCombinationMaster;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStocksFromParentAndChildByProductUid: async (productUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/get-stocks-from-parent-and-child-by-product-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productUid !== undefined) {
                localVarQueryParameter['productUid'] = productUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productUid 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionByDate: async (productUid: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUid' is not null or undefined
            assertParamExists('getTransactionByDate', 'productUid', productUid)
            const localVarPath = `/api/v1.0/productstock/get-transaction-by-date/{productUid}`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {Array<number>} [transactionType] 
         * @param {Array<number>} [transactionStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsPaged: async (sort?: string, order?: string, limit?: number, offset?: number, search?: string, transactionType?: Array<number>, transactionStatus?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/get-transactions-paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (transactionType) {
                localVarQueryParameter['transactionType'] = transactionType;
            }

            if (transactionStatus) {
                localVarQueryParameter['transactionStatus'] = transactionStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel} [APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveNewProducts: async (APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel?: APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/receive-new-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel} [APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProductsToStore: async (APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel?: APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/productstock/send-products-to-store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductStockApi - functional programming interface
 * @export
 */
export const ProductStockApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductStockApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIDataAccessEntitiesStockTransaction} [APIInternalFrontendAPIDataAccessEntitiesStockTransaction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV10ProductstockPost(APIInternalFrontendAPIDataAccessEntitiesStockTransaction?: APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV10ProductstockPost(APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV10ProductstockProductUidGet(productUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsProductStockProductStockModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV10ProductstockProductUidGet(productUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIDataAccessEntitiesStockTransaction} [APIInternalFrontendAPIDataAccessEntitiesStockTransaction] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async create(APIInternalFrontendAPIDataAccessEntitiesStockTransaction?: APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel} [APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editProductStock(uid: string, APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel?: APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsProductStockEditProductStockResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editProductStock(uid, APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableStockClients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsProductStockGetAvailableStockClientsResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableStockClients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetails(productUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsProductStockGetDetailsProductStockResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetails(productUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditProductStock(productUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsProductStockGetEditProductStockResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditProductStock(productUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModalSearches(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsProductStockProductStockModalSearchViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModalSearches(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaged(sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsProductStockProductStockTableModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaged(sort, order, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleByProductUid(productUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleByProductUid(productUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string | undefined; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockTooltipByProductUid(productUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsProductStockProductStockTooltipModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockTooltipByProductUid(productUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {boolean} [isCombinationMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStocksByProductUid(productUid?: string, isCombinationMaster?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesProductStockDetailAndCombination>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStocksByProductUid(productUid, isCombinationMaster, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStocksFromParentAndChildByProductUid(productUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesProductStockDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStocksFromParentAndChildByProductUid(productUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productUid 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionByDate(productUid: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsProductStockGetTransactionsByDateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionByDate(productUid, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {Array<number>} [transactionType] 
         * @param {Array<number>} [transactionStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionsPaged(sort?: string, order?: string, limit?: number, offset?: number, search?: string, transactionType?: Array<number>, transactionStatus?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsProductStockReceiveProductsFromStoreResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionsPaged(sort, order, limit, offset, search, transactionType, transactionStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel} [APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveNewProducts(APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel?: APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveNewProducts(APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel} [APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendProductsToStore(APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel?: APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendProductsToStore(APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductStockApi - factory interface
 * @export
 */
export const ProductStockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductStockApiFp(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIDataAccessEntitiesStockTransaction} [APIInternalFrontendAPIDataAccessEntitiesStockTransaction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV10ProductstockPost(APIInternalFrontendAPIDataAccessEntitiesStockTransaction?: APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options?: any): AxiosPromise<void> {
            return localVarFp.apiV10ProductstockPost(APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV10ProductstockProductUidGet(productUid: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsProductStockProductStockModel> {
            return localVarFp.apiV10ProductstockProductUidGet(productUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIDataAccessEntitiesStockTransaction} [APIInternalFrontendAPIDataAccessEntitiesStockTransaction] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        create(APIInternalFrontendAPIDataAccessEntitiesStockTransaction?: APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options?: any): AxiosPromise<void> {
            return localVarFp.create(APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel} [APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProductStock(uid: string, APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel?: APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel, options?: any): AxiosPromise<APIInternalFrontendAPIModelsProductStockEditProductStockResponseModel> {
            return localVarFp.editProductStock(uid, APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableStockClients(options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsProductStockGetAvailableStockClientsResponseModel>> {
            return localVarFp.getAvailableStockClients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetails(productUid: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsProductStockGetDetailsProductStockResponseModel> {
            return localVarFp.getDetails(productUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditProductStock(productUid: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsProductStockGetEditProductStockResponseModel> {
            return localVarFp.getEditProductStock(productUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModalSearches(query?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsProductStockProductStockModalSearchViewModel>> {
            return localVarFp.getModalSearches(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaged(sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsProductStockProductStockTableModel> {
            return localVarFp.getPaged(sort, order, limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleByProductUid(productUid?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getSingleByProductUid(productUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockList(options?: any): AxiosPromise<{ [key: string]: string | undefined; }> {
            return localVarFp.getStockList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTooltipByProductUid(productUid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsProductStockProductStockTooltipModel>> {
            return localVarFp.getStockTooltipByProductUid(productUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {boolean} [isCombinationMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStocksByProductUid(productUid?: string, isCombinationMaster?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesProductStockDetailAndCombination>> {
            return localVarFp.getStocksByProductUid(productUid, isCombinationMaster, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStocksFromParentAndChildByProductUid(productUid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesProductStockDetail>> {
            return localVarFp.getStocksFromParentAndChildByProductUid(productUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productUid 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionByDate(productUid: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsProductStockGetTransactionsByDateResponseModel> {
            return localVarFp.getTransactionByDate(productUid, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {Array<number>} [transactionType] 
         * @param {Array<number>} [transactionStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsPaged(sort?: string, order?: string, limit?: number, offset?: number, search?: string, transactionType?: Array<number>, transactionStatus?: Array<number>, options?: any): AxiosPromise<APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsProductStockReceiveProductsFromStoreResponseModel> {
            return localVarFp.getTransactionsPaged(sort, order, limit, offset, search, transactionType, transactionStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel} [APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveNewProducts(APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel?: APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.receiveNewProducts(APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel} [APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProductsToStore(APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel?: APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.sendProductsToStore(APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV10ProductstockPost operation in ProductStockApi.
 * @export
 * @interface ProductStockApiApiV10ProductstockPostRequest
 */
export interface ProductStockApiApiV10ProductstockPostRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIDataAccessEntitiesStockTransaction}
     * @memberof ProductStockApiApiV10ProductstockPost
     */
    readonly APIInternalFrontendAPIDataAccessEntitiesStockTransaction?: APIInternalFrontendAPIDataAccessEntitiesStockTransaction
}

/**
 * Request parameters for apiV10ProductstockProductUidGet operation in ProductStockApi.
 * @export
 * @interface ProductStockApiApiV10ProductstockProductUidGetRequest
 */
export interface ProductStockApiApiV10ProductstockProductUidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiApiV10ProductstockProductUidGet
     */
    readonly productUid: string
}

/**
 * Request parameters for create operation in ProductStockApi.
 * @export
 * @interface ProductStockApiCreateRequest
 */
export interface ProductStockApiCreateRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIDataAccessEntitiesStockTransaction}
     * @memberof ProductStockApiCreate
     */
    readonly APIInternalFrontendAPIDataAccessEntitiesStockTransaction?: APIInternalFrontendAPIDataAccessEntitiesStockTransaction
}

/**
 * Request parameters for editProductStock operation in ProductStockApi.
 * @export
 * @interface ProductStockApiEditProductStockRequest
 */
export interface ProductStockApiEditProductStockRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiEditProductStock
     */
    readonly uid: string

    /**
     * 
     * @type {APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel}
     * @memberof ProductStockApiEditProductStock
     */
    readonly APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel?: APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel
}

/**
 * Request parameters for getDetails operation in ProductStockApi.
 * @export
 * @interface ProductStockApiGetDetailsRequest
 */
export interface ProductStockApiGetDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetDetails
     */
    readonly productUid: string
}

/**
 * Request parameters for getEditProductStock operation in ProductStockApi.
 * @export
 * @interface ProductStockApiGetEditProductStockRequest
 */
export interface ProductStockApiGetEditProductStockRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetEditProductStock
     */
    readonly productUid: string
}

/**
 * Request parameters for getModalSearches operation in ProductStockApi.
 * @export
 * @interface ProductStockApiGetModalSearchesRequest
 */
export interface ProductStockApiGetModalSearchesRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetModalSearches
     */
    readonly query?: string
}

/**
 * Request parameters for getPaged operation in ProductStockApi.
 * @export
 * @interface ProductStockApiGetPagedRequest
 */
export interface ProductStockApiGetPagedRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetPaged
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetPaged
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof ProductStockApiGetPaged
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ProductStockApiGetPaged
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetPaged
     */
    readonly search?: string
}

/**
 * Request parameters for getSingleByProductUid operation in ProductStockApi.
 * @export
 * @interface ProductStockApiGetSingleByProductUidRequest
 */
export interface ProductStockApiGetSingleByProductUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetSingleByProductUid
     */
    readonly productUid?: string
}

/**
 * Request parameters for getStockTooltipByProductUid operation in ProductStockApi.
 * @export
 * @interface ProductStockApiGetStockTooltipByProductUidRequest
 */
export interface ProductStockApiGetStockTooltipByProductUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetStockTooltipByProductUid
     */
    readonly productUid?: string
}

/**
 * Request parameters for getStocksByProductUid operation in ProductStockApi.
 * @export
 * @interface ProductStockApiGetStocksByProductUidRequest
 */
export interface ProductStockApiGetStocksByProductUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetStocksByProductUid
     */
    readonly productUid?: string

    /**
     * 
     * @type {boolean}
     * @memberof ProductStockApiGetStocksByProductUid
     */
    readonly isCombinationMaster?: boolean
}

/**
 * Request parameters for getStocksFromParentAndChildByProductUid operation in ProductStockApi.
 * @export
 * @interface ProductStockApiGetStocksFromParentAndChildByProductUidRequest
 */
export interface ProductStockApiGetStocksFromParentAndChildByProductUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetStocksFromParentAndChildByProductUid
     */
    readonly productUid?: string
}

/**
 * Request parameters for getTransactionByDate operation in ProductStockApi.
 * @export
 * @interface ProductStockApiGetTransactionByDateRequest
 */
export interface ProductStockApiGetTransactionByDateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetTransactionByDate
     */
    readonly productUid: string

    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetTransactionByDate
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetTransactionByDate
     */
    readonly toDate?: string
}

/**
 * Request parameters for getTransactionsPaged operation in ProductStockApi.
 * @export
 * @interface ProductStockApiGetTransactionsPagedRequest
 */
export interface ProductStockApiGetTransactionsPagedRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetTransactionsPaged
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetTransactionsPaged
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof ProductStockApiGetTransactionsPaged
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof ProductStockApiGetTransactionsPaged
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof ProductStockApiGetTransactionsPaged
     */
    readonly search?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ProductStockApiGetTransactionsPaged
     */
    readonly transactionType?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ProductStockApiGetTransactionsPaged
     */
    readonly transactionStatus?: Array<number>
}

/**
 * Request parameters for receiveNewProducts operation in ProductStockApi.
 * @export
 * @interface ProductStockApiReceiveNewProductsRequest
 */
export interface ProductStockApiReceiveNewProductsRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel}
     * @memberof ProductStockApiReceiveNewProducts
     */
    readonly APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel?: APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel
}

/**
 * Request parameters for sendProductsToStore operation in ProductStockApi.
 * @export
 * @interface ProductStockApiSendProductsToStoreRequest
 */
export interface ProductStockApiSendProductsToStoreRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel}
     * @memberof ProductStockApiSendProductsToStore
     */
    readonly APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel?: APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel
}

/**
 * ProductStockApi - object-oriented interface
 * @export
 * @class ProductStockApi
 * @extends {BaseAPI}
 */
export class ProductStockApi extends BaseAPI {
    /**
     * 
     * @param {ProductStockApiApiV10ProductstockPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public apiV10ProductstockPost(requestParameters: ProductStockApiApiV10ProductstockPostRequest = {}, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).apiV10ProductstockPost(requestParameters.APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiApiV10ProductstockProductUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public apiV10ProductstockProductUidGet(requestParameters: ProductStockApiApiV10ProductstockProductUidGetRequest, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).apiV10ProductstockProductUidGet(requestParameters.productUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public create(requestParameters: ProductStockApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).create(requestParameters.APIInternalFrontendAPIDataAccessEntitiesStockTransaction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiEditProductStockRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public editProductStock(requestParameters: ProductStockApiEditProductStockRequest, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).editProductStock(requestParameters.uid, requestParameters.APIInternalFrontendAPIModelsProductStockProductStockEditRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getAvailableStockClients(options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getAvailableStockClients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiGetDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getDetails(requestParameters: ProductStockApiGetDetailsRequest, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getDetails(requestParameters.productUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiGetEditProductStockRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getEditProductStock(requestParameters: ProductStockApiGetEditProductStockRequest, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getEditProductStock(requestParameters.productUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiGetModalSearchesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getModalSearches(requestParameters: ProductStockApiGetModalSearchesRequest = {}, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getModalSearches(requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiGetPagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getPaged(requestParameters: ProductStockApiGetPagedRequest = {}, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getPaged(requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiGetSingleByProductUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getSingleByProductUid(requestParameters: ProductStockApiGetSingleByProductUidRequest = {}, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getSingleByProductUid(requestParameters.productUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getStockList(options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getStockList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiGetStockTooltipByProductUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getStockTooltipByProductUid(requestParameters: ProductStockApiGetStockTooltipByProductUidRequest = {}, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getStockTooltipByProductUid(requestParameters.productUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiGetStocksByProductUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getStocksByProductUid(requestParameters: ProductStockApiGetStocksByProductUidRequest = {}, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getStocksByProductUid(requestParameters.productUid, requestParameters.isCombinationMaster, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiGetStocksFromParentAndChildByProductUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getStocksFromParentAndChildByProductUid(requestParameters: ProductStockApiGetStocksFromParentAndChildByProductUidRequest = {}, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getStocksFromParentAndChildByProductUid(requestParameters.productUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiGetTransactionByDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getTransactionByDate(requestParameters: ProductStockApiGetTransactionByDateRequest, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getTransactionByDate(requestParameters.productUid, requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiGetTransactionsPagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public getTransactionsPaged(requestParameters: ProductStockApiGetTransactionsPagedRequest = {}, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).getTransactionsPaged(requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, requestParameters.transactionType, requestParameters.transactionStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiReceiveNewProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public receiveNewProducts(requestParameters: ProductStockApiReceiveNewProductsRequest = {}, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).receiveNewProducts(requestParameters.APIInternalFrontendAPIModelsProductStockReceiveNewProductsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStockApiSendProductsToStoreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStockApi
     */
    public sendProductsToStore(requestParameters: ProductStockApiSendProductsToStoreRequest = {}, options?: AxiosRequestConfig) {
        return ProductStockApiFp(this.configuration).sendProductsToStore(requestParameters.APIInternalFrontendAPIModelsProductStockSendProductsToStoreRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}
