/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesReport } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIDataAccessEntitiesReportConnectionType } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsDynamicReportDynamicReport } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsDynamicReportDynamicReport } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsDynamicReportDynamicReportColumn } from '../models';
/**
 * DynamicReportApi - axios parameter creator
 * @export
 */
export const DynamicReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReport} [APIInternalFrontendAPIModelsDynamicReportDynamicReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (APIInternalFrontendAPIModelsDynamicReportDynamicReport?: APIInternalFrontendAPIModelsDynamicReportDynamicReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsDynamicReportDynamicReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn} [APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createColumn: async (APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn?: APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/create-column`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReport} [APIInternalFrontendAPIModelsDynamicReportDynamicReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit: async (APIInternalFrontendAPIModelsDynamicReportDynamicReport?: APIInternalFrontendAPIModelsDynamicReportDynamicReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsDynamicReportDynamicReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn} [APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editColumn: async (APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn?: APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/edit-column`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConnectionTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/get-all-connection-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveryTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/get-all-delivery-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplateTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/get-all-template-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {string} [reportUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColumns: async (sort?: string, order?: string, limit?: number, offset?: number, search?: string, reportUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/get-columns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (reportUid !== undefined) {
                localVarQueryParameter['reportUid'] = reportUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaged: async (sort?: string, order?: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/get-paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsAccessForUserAccess: async (languageUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/get-reports-access-for-user-access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageUid !== undefined) {
                localVarQueryParameter['languageUid'] = languageUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingle: async (uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/get-single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleColumn: async (uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/dynamicreport/get-single-column`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DynamicReportApi - functional programming interface
 * @export
 */
export const DynamicReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DynamicReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReport} [APIInternalFrontendAPIModelsDynamicReportDynamicReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(APIInternalFrontendAPIModelsDynamicReportDynamicReport?: APIInternalFrontendAPIModelsDynamicReportDynamicReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(APIInternalFrontendAPIModelsDynamicReportDynamicReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn} [APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createColumn(APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn?: APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createColumn(APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReport} [APIInternalFrontendAPIModelsDynamicReportDynamicReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edit(APIInternalFrontendAPIModelsDynamicReportDynamicReport?: APIInternalFrontendAPIModelsDynamicReportDynamicReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edit(APIInternalFrontendAPIModelsDynamicReportDynamicReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn} [APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editColumn(APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn?: APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editColumn(APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllConnectionTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesReportConnectionType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConnectionTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDeliveryTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesReportConnectionType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDeliveryTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTemplateTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesReportConnectionType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTemplateTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {string} [reportUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getColumns(sort?: string, order?: string, limit?: number, offset?: number, search?: string, reportUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsDynamicReportDynamicReportColumn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getColumns(sort, order, limit, offset, search, reportUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaged(sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsDynamicReportDynamicReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaged(sort, order, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportsAccessForUserAccess(languageUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportsAccessForUserAccess(languageUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingle(uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsDynamicReportDynamicReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingle(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleColumn(uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleColumn(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DynamicReportApi - factory interface
 * @export
 */
export const DynamicReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DynamicReportApiFp(configuration)
    return {
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReport} [APIInternalFrontendAPIModelsDynamicReportDynamicReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(APIInternalFrontendAPIModelsDynamicReportDynamicReport?: APIInternalFrontendAPIModelsDynamicReportDynamicReport, options?: any): AxiosPromise<boolean> {
            return localVarFp.create(APIInternalFrontendAPIModelsDynamicReportDynamicReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn} [APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createColumn(APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn?: APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options?: any): AxiosPromise<boolean> {
            return localVarFp.createColumn(APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReport} [APIInternalFrontendAPIModelsDynamicReportDynamicReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit(APIInternalFrontendAPIModelsDynamicReportDynamicReport?: APIInternalFrontendAPIModelsDynamicReportDynamicReport, options?: any): AxiosPromise<boolean> {
            return localVarFp.edit(APIInternalFrontendAPIModelsDynamicReportDynamicReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn} [APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editColumn(APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn?: APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options?: any): AxiosPromise<boolean> {
            return localVarFp.editColumn(APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConnectionTypes(options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesReportConnectionType>> {
            return localVarFp.getAllConnectionTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveryTypes(options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesReportConnectionType>> {
            return localVarFp.getAllDeliveryTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplateTypes(options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesReportConnectionType>> {
            return localVarFp.getAllTemplateTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {string} [reportUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColumns(sort?: string, order?: string, limit?: number, offset?: number, search?: string, reportUid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsDynamicReportDynamicReportColumn> {
            return localVarFp.getColumns(sort, order, limit, offset, search, reportUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaged(sort?: string, order?: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsPagedListViewModelOfAPIInternalFrontendAPIModelsDynamicReportDynamicReport> {
            return localVarFp.getPaged(sort, order, limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsAccessForUserAccess(languageUid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIDataAccessEntitiesReport>> {
            return localVarFp.getReportsAccessForUserAccess(languageUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingle(uid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsDynamicReportDynamicReport> {
            return localVarFp.getSingle(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleColumn(uid?: string, options?: any): AxiosPromise<APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn> {
            return localVarFp.getSingleColumn(uid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for create operation in DynamicReportApi.
 * @export
 * @interface DynamicReportApiCreateRequest
 */
export interface DynamicReportApiCreateRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsDynamicReportDynamicReport}
     * @memberof DynamicReportApiCreate
     */
    readonly APIInternalFrontendAPIModelsDynamicReportDynamicReport?: APIInternalFrontendAPIModelsDynamicReportDynamicReport
}

/**
 * Request parameters for createColumn operation in DynamicReportApi.
 * @export
 * @interface DynamicReportApiCreateColumnRequest
 */
export interface DynamicReportApiCreateColumnRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn}
     * @memberof DynamicReportApiCreateColumn
     */
    readonly APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn?: APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn
}

/**
 * Request parameters for edit operation in DynamicReportApi.
 * @export
 * @interface DynamicReportApiEditRequest
 */
export interface DynamicReportApiEditRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsDynamicReportDynamicReport}
     * @memberof DynamicReportApiEdit
     */
    readonly APIInternalFrontendAPIModelsDynamicReportDynamicReport?: APIInternalFrontendAPIModelsDynamicReportDynamicReport
}

/**
 * Request parameters for editColumn operation in DynamicReportApi.
 * @export
 * @interface DynamicReportApiEditColumnRequest
 */
export interface DynamicReportApiEditColumnRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn}
     * @memberof DynamicReportApiEditColumn
     */
    readonly APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn?: APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn
}

/**
 * Request parameters for getColumns operation in DynamicReportApi.
 * @export
 * @interface DynamicReportApiGetColumnsRequest
 */
export interface DynamicReportApiGetColumnsRequest {
    /**
     * 
     * @type {string}
     * @memberof DynamicReportApiGetColumns
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof DynamicReportApiGetColumns
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof DynamicReportApiGetColumns
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof DynamicReportApiGetColumns
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof DynamicReportApiGetColumns
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof DynamicReportApiGetColumns
     */
    readonly reportUid?: string
}

/**
 * Request parameters for getPaged operation in DynamicReportApi.
 * @export
 * @interface DynamicReportApiGetPagedRequest
 */
export interface DynamicReportApiGetPagedRequest {
    /**
     * 
     * @type {string}
     * @memberof DynamicReportApiGetPaged
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof DynamicReportApiGetPaged
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof DynamicReportApiGetPaged
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof DynamicReportApiGetPaged
     */
    readonly offset?: number

    /**
     * 
     * @type {string}
     * @memberof DynamicReportApiGetPaged
     */
    readonly search?: string
}

/**
 * Request parameters for getReportsAccessForUserAccess operation in DynamicReportApi.
 * @export
 * @interface DynamicReportApiGetReportsAccessForUserAccessRequest
 */
export interface DynamicReportApiGetReportsAccessForUserAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof DynamicReportApiGetReportsAccessForUserAccess
     */
    readonly languageUid?: string
}

/**
 * Request parameters for getSingle operation in DynamicReportApi.
 * @export
 * @interface DynamicReportApiGetSingleRequest
 */
export interface DynamicReportApiGetSingleRequest {
    /**
     * 
     * @type {string}
     * @memberof DynamicReportApiGetSingle
     */
    readonly uid?: string
}

/**
 * Request parameters for getSingleColumn operation in DynamicReportApi.
 * @export
 * @interface DynamicReportApiGetSingleColumnRequest
 */
export interface DynamicReportApiGetSingleColumnRequest {
    /**
     * 
     * @type {string}
     * @memberof DynamicReportApiGetSingleColumn
     */
    readonly uid?: string
}

/**
 * DynamicReportApi - object-oriented interface
 * @export
 * @class DynamicReportApi
 * @extends {BaseAPI}
 */
export class DynamicReportApi extends BaseAPI {
    /**
     * 
     * @param {DynamicReportApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public create(requestParameters: DynamicReportApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).create(requestParameters.APIInternalFrontendAPIModelsDynamicReportDynamicReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicReportApiCreateColumnRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public createColumn(requestParameters: DynamicReportApiCreateColumnRequest = {}, options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).createColumn(requestParameters.APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicReportApiEditRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public edit(requestParameters: DynamicReportApiEditRequest = {}, options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).edit(requestParameters.APIInternalFrontendAPIModelsDynamicReportDynamicReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicReportApiEditColumnRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public editColumn(requestParameters: DynamicReportApiEditColumnRequest = {}, options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).editColumn(requestParameters.APIInternalFrontendAPIModelsDynamicReportDynamicReportColumn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public getAllConnectionTypes(options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).getAllConnectionTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public getAllDeliveryTypes(options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).getAllDeliveryTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public getAllTemplateTypes(options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).getAllTemplateTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicReportApiGetColumnsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public getColumns(requestParameters: DynamicReportApiGetColumnsRequest = {}, options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).getColumns(requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, requestParameters.reportUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicReportApiGetPagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public getPaged(requestParameters: DynamicReportApiGetPagedRequest = {}, options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).getPaged(requestParameters.sort, requestParameters.order, requestParameters.limit, requestParameters.offset, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicReportApiGetReportsAccessForUserAccessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public getReportsAccessForUserAccess(requestParameters: DynamicReportApiGetReportsAccessForUserAccessRequest = {}, options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).getReportsAccessForUserAccess(requestParameters.languageUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicReportApiGetSingleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public getSingle(requestParameters: DynamicReportApiGetSingleRequest = {}, options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).getSingle(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicReportApiGetSingleColumnRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicReportApi
     */
    public getSingleColumn(requestParameters: DynamicReportApiGetSingleColumnRequest = {}, options?: AxiosRequestConfig) {
        return DynamicReportApiFp(this.configuration).getSingleColumn(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }
}
