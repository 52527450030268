import {Module} from "vuex";

interface IPaymentTypesState {
	isCloneOpen: boolean;
	unitUid: string;
}
const paymentTypesState: Module<IPaymentTypesState, any> = {
    namespaced: true,

	state: () => ({
		isCloneOpen: false,
		unitUid: new GUID().Empty,
	}),
	actions: {
		SET_UNIT_UID(context, state) {
			context.commit("SET_UNIT_UID", state);
		},
		SET_CLONE(context, state) {
			context.commit("SET_CLONE", state);
		},
	},
	mutations: {
		SET_UNIT_UID(state, newState) {
			state.unitUid = newState;
		},
		SET_CLONE(state, newState) {
			state.isCloneOpen = newState;
		},
	},
	getters: {
		isCloneOpen(state, getters, rootState, rootGetters) {
			return state.isCloneOpen;
		},
		unitUid(state, getters, rootState, rootGetters) {
			return state.unitUid;
		},
	}
}

export default paymentTypesState;