import {Module} from "vuex";

interface IVendorTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const vendorTableState: Module<IVendorTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },
    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
		ClientUID(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.ClientUID", rootState, true); },
        Name(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.Name", rootState, true); },
        Phone(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.Phone", rootState, true); },
        Email(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.Email", rootState, true); },
        Address(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.Address", rootState, true); },
        ZipCode(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.ZipCode", rootState, true); },
        City(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.City", rootState, true); },
        ContactPerson(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.ContactPerson", rootState, true); },
        IsActive(state, getters, rootState) { return getConfig("Vendor.ColumnOptions.IsActive", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUID: getters.ClientUID,
                Name: getters.Name,
                Phone: getters.Phone,
                Email: getters.Email,
                Address: getters.Address,
                ZipCode: getters.ZipCode,
                City: getters.City,
                ContactPerson: getters.ContactPerson,
                IsActive: getters.IsActive,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				ClientUID: true,
                Name: true,
                Phone: true,
                Email: true,
                Address: true,
                ZipCode: true,
                City: true,
                ContactPerson: true,
                IsActive: true,
            };
        }
    }
}

export default vendorTableState;