import {Module} from "vuex";
type KeyValueStringArray = { [key: string]: unknown[] };
type KeyValueStringUnkown = { [key: string]: unknown };

interface IProductAutocompleteState {
	autocompleteQueries: KeyValueStringArray;
	productByBarcodeResponse: KeyValueStringUnkown
	productGETResponse: KeyValueStringUnkown
	pricesGETResponse: KeyValueStringUnkown
}
const productAutocompleteState: Module<IProductAutocompleteState, any> = {
	namespaced: true,
    
	state: () => ({
		autocompleteQueries: {},
		productByBarcodeResponse: {},
		productGETResponse: {},
		pricesGETResponse: {},
	}),
	actions: {
		RESET_VALUES(context) {
			context.commit("RESET_VALUES");
		},
		SET_AUTOCOMPLETE_QUERY(context, args: {query: string, results: unknown[]}) {
			context.commit("SET_AUTOCOMPLETE_QUERY", args);
		},
		SET_PRODUCT_BY_BARCODE_RESPONSE(context, args: {query: string, result: unknown}) {
			context.commit("SET_PRODUCT_BY_BARCODE_RESPONSE", args);
		},
		SET_PRODUCT_GET_RESPONSE(context, args: {query: string, result: unknown}) {
			context.commit("SET_PRODUCT_GET_RESPONSE", args);
		},
		SET_PRICES_GET_RESPONSE(context, args: {query: string, result: unknown}) {
			context.commit("SET_PRICES_GET_RESPONSE", args);
		},
	},
	mutations: {
		RESET_VALUES(state) {
			state.autocompleteQueries = {};
			state.productByBarcodeResponse = {};
			state.productGETResponse = {};
			state.pricesGETResponse = {};
		},
		SET_AUTOCOMPLETE_QUERY(state, newState: {query: string, results: unknown[]}) {
			state.autocompleteQueries[newState.query] = newState.results;
		},
		SET_PRODUCT_BY_BARCODE_RESPONSE(state, newState: {query: string, result: unknown}) {
			state.productByBarcodeResponse[newState.query] = newState.result;
		},
		SET_PRODUCT_GET_RESPONSE(state, newState: {query: string, result: unknown}) {
			state.productGETResponse[newState.query] = newState.result;
		},
		SET_PRICES_GET_RESPONSE(state, newState: {query: string, result: unknown}) {
			state.pricesGETResponse[newState.query] = newState.result;
		},
	},
	getters: {
		getAutocompleteQuery(state) {
			return (query) => state.autocompleteQueries[query];
		},
		getProductByBarcodeResponse(state) {
			return (productUid) => state.productByBarcodeResponse[productUid];
		},
		getProductGetResponse(state) {
			return (productUid) => state.productGETResponse[productUid];
		},
		getPricesGetResponse(state) {
			return (productUid) => state.pricesGETResponse[productUid];
		},
	}
};

export default productAutocompleteState;