import {Module} from "vuex";

export interface ITransactionHistoryDetailsState {
}
const transactionHistoryDetailsState: Module<ITransactionHistoryDetailsState, any> = {
    namespaced: true,

    state: () => ({
    }),
    actions: {
    },
    mutations: {
    },
    getters: {
        getCombinations(state, getters, rootState, rootGetters) {
            return {
                combination1: { Active: rootGetters.getFlexPosConfigBool("Combination1FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination1FieldName", Resource.Combination1) },
                combination2: { Active: rootGetters.getFlexPosConfigBool("Combination2FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination2FieldName", Resource.Combination2) },
                combination3: { Active: rootGetters.getFlexPosConfigBool("Combination3FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination3FieldName", Resource.Combination3) },
                combination4: { Active: rootGetters.getFlexPosConfigBool("Combination4FieldActive", false), Title: rootGetters.getFlexPosConfigString("Combination4FieldName", Resource.Combination4) },
            }
        }
    }
}

export default transactionHistoryDetailsState;