import {Module} from "vuex";

interface IGiftCardTableState {
    savedSearchParams: object;
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const giftCardTableIndexState: Module<IGiftCardTableState, any> = {
    namespaced: true,
    
    state: () => ({
        savedSearchParams: null
    }),
    actions: {
        SET_SAVED_SEARCH_PARAMS(context, args) {
            context.commit("SET_SAVED_SEARCH_PARAMS", args)
        },
    },
    mutations: {
        SET_SAVED_SEARCH_PARAMS(state, newState) {
            state.savedSearchParams = newState;
        },
    },

    getters: {
        getSavedSearchParams(state, getters) {
            return state.savedSearchParams
        },
        Redeemed(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.Redeemed", rootState, true); },
        Id(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.Id", rootState, true); },
        Value(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.Value", rootState, true); },
        Type(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.Type", rootState, true); },
        CreateDate(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.CreateDate", rootState, true); },
        CreatedOnJournalNo(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.CreatedOnJournalNo", rootState, true); },
        UnitId(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.UnitId", rootState, true); },
        Status(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.Status", rootState, true); },
        Text(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.Text", rootState, true); },
        UsedDate(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.UsedDate", rootState, true); },
        UsedOnJournalNo(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.UsedOnJournalNo", rootState, true); },
        UsedOnUnitId(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.UsedOnUnitId", rootState, true); },
		ExpireDate(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.ExpireDate", rootState, true); },
        ChangedBy(state, getters, rootState) { return getConfig("GiftCard.ColumnOptions.ChangedBy", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
                Redeemed: getters.Redeemed,
                Id: getters.Id,
                Value: getters.Value,
                Type: getters.Type,
                CreateDate: getters.CreateDate,
                CreatedOnJournalNo: getters.CreatedOnJournalNo,
                UnitId: getters.UnitId,
                Status: getters.Status,
                Text: getters.Text,
                UsedDate: getters.UsedDate,
                UsedOnJournalNo: getters.UsedOnJournalNo,
                UsedOnUnitId: getters.UsedOnUnitId,
				ExpireDate: getters.ExpireDate,
                ChangedBy: getters.ChangedBy,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
                Redeemed: true,
                Id: true,
                Value: true,
                Type: true,
                CreateDate: true,
                CreatedOnJournalNo: true,
                UnitId: true,
                Status: true,
                Text: true,
                UsedDate: true,
                UsedOnJournalNo: true,
                UsedOnUnitId: true,
				ExpireDate: true,
                ChangedBy: true,
            };
        }
    }
}

export default giftCardTableIndexState;