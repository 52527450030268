















export default {
    name: "fp-table-column-header",
    props: {
        column: {
            required: true
        }
    },
    methods: {
        clickEvent(ev) {
            this.$emit("clicked")
        }
    },
    computed: {
        name() {
            if (!this.column) return "&nbsp;"
            if (!this.column.name) return "&nbsp;"
            return this.column.name;
        }
    }
}
