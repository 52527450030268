import {Module} from "vuex";

interface ICustomerBulkDeleteTableState {
}
function getConfig(key, rootState, defaultValue) {
    const keyValue = rootState.backendConfigs[key]
    if (keyValue !== null && keyValue !== undefined)
        return keyValue.toLowerCase() === "true";

    return defaultValue;
}
const customerBulkDeleteTableState: Module<ICustomerBulkDeleteTableState, any> = {
    namespaced: true,
    
    state: () => ({
    }),
    actions: {
    },
    mutations: {
    },
    getters: {
		Id(state, getters, rootState) { return getConfig("CustomerBulkDelete.ColumnOptions.Id", rootState, true); },
		Name(state, getters, rootState) { return getConfig("CustomerBulkDelete.ColumnOptions.Name", rootState, true); },
		Phone(state, getters, rootState) { return getConfig("CustomerBulkDelete.ColumnOptions.Phone", rootState, true); },
		Email(state, getters, rootState) { return getConfig("CustomerBulkDelete.ColumnOptions.Email", rootState, true); },
		Address(state, getters, rootState) { return getConfig("CustomerBulkDelete.ColumnOptions.Address", rootState, true); },
		ZipCode(state, getters, rootState) { return getConfig("CustomerBulkDelete.ColumnOptions.ZipCode", rootState, true); },
		City(state, getters, rootState) { return getConfig("CustomerBulkDelete.ColumnOptions.City", rootState, true); },
        getColumnVisibility(state, getters, rootState, rootGetters) {
            return {
				Id: getters.Id,
                Name: getters.Name,
				Phone: getters.Phone,
				Email: getters.Email,
				Address: getters.Address,
				ZipCode: getters.ZipCode,
				City: getters.City,
            };
        },
        getConfigVisibility(state, getters, rootState, rootGetters) {
            return {
				Id: true,
                Name: true,
				Phone: true,
				Email: true,
				Address: true,
				ZipCode: true,
				City: true,
            };
        }
    }
}

export default customerBulkDeleteTableState;