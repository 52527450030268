/* tslint:disable */
/* eslint-disable */
/**
 * FlexPOS FrontendAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIInternalFrontendAPIModelsAuthenticationApiAuthenticationApiViewModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel } from '../models';
// @ts-ignore
import { APIInternalFrontendAPIModelsTooltipScopeTooltipModel } from '../models';
/**
 * AuthenticationApiApi - axios parameter creator
 * @export
 */
export const AuthenticationApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientUid 
         * @param {boolean} [createForSubClients] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV10AuthenticationApiClientUidPost: async (clientUid: string, createForSubClients?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUid' is not null or undefined
            assertParamExists('apiV10AuthenticationApiClientUidPost', 'clientUid', clientUid)
            const localVarPath = `/api/v1.0/authentication-api/{clientUid}`
                .replace(`{${"clientUid"}}`, encodeURIComponent(String(clientUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (createForSubClients !== undefined) {
                localVarQueryParameter['createForSubClients'] = createForSubClients;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV10AuthenticationApiGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/authentication-api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel} [APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey: async (APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel?: APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/authentication-api/create-api-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByClientUid: async (clientUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUid' is not null or undefined
            assertParamExists('getAllByClientUid', 'clientUid', clientUid)
            const localVarPath = `/api/v1.0/authentication-api/get-all-by-client-uid/{clientUid}`
                .replace(`{${"clientUid"}}`, encodeURIComponent(String(clientUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopesAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/authentication-api/get-scopes-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [voucherAuthenticationUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopesTooltipByVoucherAuthenticationUid: async (voucherAuthenticationUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/authentication-api/get-scopes-tooltip-by-voucher-authentication-uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (voucherAuthenticationUid !== undefined) {
                localVarQueryParameter['voucherAuthenticationUid'] = voucherAuthenticationUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel} [APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApiKey: async (APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel?: APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.0/authentication-api/update-api-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApiApi - functional programming interface
 * @export
 */
export const AuthenticationApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientUid 
         * @param {boolean} [createForSubClients] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV10AuthenticationApiClientUidPost(clientUid: string, createForSubClients?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsAuthenticationApiAuthenticationApiViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV10AuthenticationApiClientUidPost(clientUid, createForSubClients, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV10AuthenticationApiGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsAuthenticationApiAuthenticationApiViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV10AuthenticationApiGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel} [APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApiKey(APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel?: APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApiKey(APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllByClientUid(clientUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsAuthenticationApiAuthenticationApiViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllByClientUid(clientUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScopesAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsTooltipScopeTooltipModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScopesAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [voucherAuthenticationUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScopesTooltipByVoucherAuthenticationUid(voucherAuthenticationUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIInternalFrontendAPIModelsTooltipScopeTooltipModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScopesTooltipByVoucherAuthenticationUid(voucherAuthenticationUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel} [APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApiKey(APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel?: APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApiKey(APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApiApi - factory interface
 * @export
 */
export const AuthenticationApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiApiFp(configuration)
    return {
        /**
         * 
         * @param {string} clientUid 
         * @param {boolean} [createForSubClients] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV10AuthenticationApiClientUidPost(clientUid: string, createForSubClients?: boolean, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsAuthenticationApiAuthenticationApiViewModel>> {
            return localVarFp.apiV10AuthenticationApiClientUidPost(clientUid, createForSubClients, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV10AuthenticationApiGet(options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsAuthenticationApiAuthenticationApiViewModel>> {
            return localVarFp.apiV10AuthenticationApiGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel} [APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel?: APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.createApiKey(APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByClientUid(clientUid: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsAuthenticationApiAuthenticationApiViewModel>> {
            return localVarFp.getAllByClientUid(clientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopesAll(options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsTooltipScopeTooltipModel>> {
            return localVarFp.getScopesAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [voucherAuthenticationUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopesTooltipByVoucherAuthenticationUid(voucherAuthenticationUid?: string, options?: any): AxiosPromise<Array<APIInternalFrontendAPIModelsTooltipScopeTooltipModel>> {
            return localVarFp.getScopesTooltipByVoucherAuthenticationUid(voucherAuthenticationUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel} [APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApiKey(APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel?: APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.updateApiKey(APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV10AuthenticationApiClientUidPost operation in AuthenticationApiApi.
 * @export
 * @interface AuthenticationApiApiApiV10AuthenticationApiClientUidPostRequest
 */
export interface AuthenticationApiApiApiV10AuthenticationApiClientUidPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiApiApiV10AuthenticationApiClientUidPost
     */
    readonly clientUid: string

    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationApiApiApiV10AuthenticationApiClientUidPost
     */
    readonly createForSubClients?: boolean
}

/**
 * Request parameters for createApiKey operation in AuthenticationApiApi.
 * @export
 * @interface AuthenticationApiApiCreateApiKeyRequest
 */
export interface AuthenticationApiApiCreateApiKeyRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel}
     * @memberof AuthenticationApiApiCreateApiKey
     */
    readonly APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel?: APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel
}

/**
 * Request parameters for getAllByClientUid operation in AuthenticationApiApi.
 * @export
 * @interface AuthenticationApiApiGetAllByClientUidRequest
 */
export interface AuthenticationApiApiGetAllByClientUidRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiApiGetAllByClientUid
     */
    readonly clientUid: string
}

/**
 * Request parameters for getScopesTooltipByVoucherAuthenticationUid operation in AuthenticationApiApi.
 * @export
 * @interface AuthenticationApiApiGetScopesTooltipByVoucherAuthenticationUidRequest
 */
export interface AuthenticationApiApiGetScopesTooltipByVoucherAuthenticationUidRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiApiGetScopesTooltipByVoucherAuthenticationUid
     */
    readonly voucherAuthenticationUid?: string
}

/**
 * Request parameters for updateApiKey operation in AuthenticationApiApi.
 * @export
 * @interface AuthenticationApiApiUpdateApiKeyRequest
 */
export interface AuthenticationApiApiUpdateApiKeyRequest {
    /**
     * 
     * @type {APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel}
     * @memberof AuthenticationApiApiUpdateApiKey
     */
    readonly APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel?: APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel
}

/**
 * AuthenticationApiApi - object-oriented interface
 * @export
 * @class AuthenticationApiApi
 * @extends {BaseAPI}
 */
export class AuthenticationApiApi extends BaseAPI {
    /**
     * 
     * @param {AuthenticationApiApiApiV10AuthenticationApiClientUidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiApi
     */
    public apiV10AuthenticationApiClientUidPost(requestParameters: AuthenticationApiApiApiV10AuthenticationApiClientUidPostRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiApiFp(this.configuration).apiV10AuthenticationApiClientUidPost(requestParameters.clientUid, requestParameters.createForSubClients, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiApi
     */
    public apiV10AuthenticationApiGet(options?: AxiosRequestConfig) {
        return AuthenticationApiApiFp(this.configuration).apiV10AuthenticationApiGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiApiCreateApiKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiApi
     */
    public createApiKey(requestParameters: AuthenticationApiApiCreateApiKeyRequest = {}, options?: AxiosRequestConfig) {
        return AuthenticationApiApiFp(this.configuration).createApiKey(requestParameters.APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiApiGetAllByClientUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiApi
     */
    public getAllByClientUid(requestParameters: AuthenticationApiApiGetAllByClientUidRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiApiFp(this.configuration).getAllByClientUid(requestParameters.clientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiApi
     */
    public getScopesAll(options?: AxiosRequestConfig) {
        return AuthenticationApiApiFp(this.configuration).getScopesAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiApiGetScopesTooltipByVoucherAuthenticationUidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiApi
     */
    public getScopesTooltipByVoucherAuthenticationUid(requestParameters: AuthenticationApiApiGetScopesTooltipByVoucherAuthenticationUidRequest = {}, options?: AxiosRequestConfig) {
        return AuthenticationApiApiFp(this.configuration).getScopesTooltipByVoucherAuthenticationUid(requestParameters.voucherAuthenticationUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiApiUpdateApiKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiApi
     */
    public updateApiKey(requestParameters: AuthenticationApiApiUpdateApiKeyRequest = {}, options?: AxiosRequestConfig) {
        return AuthenticationApiApiFp(this.configuration).updateApiKey(requestParameters.APIInternalFrontendAPIModelsAuthenticationApiAuthenticationViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}
